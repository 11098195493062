import { useMutation } from '@tanstack/react-query';
import { MUIDataTableMeta } from 'mui-datatables';
import React from 'react';
import { EditableCell } from 'src/components/MUITSDatatable';
import { HTMLElementInputTypeEnum } from 'src/components/MUITSDatatable/MUITSDatatableInterface';
import { useTouchwayApi } from 'src/services/touchway-api';
import { ISimManager } from 'src/services/touchway-base-api';

interface SimPinEditableCellProps {
  value: string;
  tableMeta: MUIDataTableMeta;
  updateValue: (value: string) => void;
  object: ISimManager;
}

const SimcardEditableCell: React.FC<SimPinEditableCellProps> = ({
  value,
  tableMeta,
  updateValue,
  object,
}) => {
  const touchwayApi = useTouchwayApi();
  const mutation = useMutation(touchwayApi.api.simcards.simControllerUpsert);

  return (
    <EditableCell
      type={HTMLElementInputTypeEnum.Text}
      value={value}
      tableMeta={tableMeta}
      mutation={mutation}
      object={object}
      updateValue={updateValue}
      rules={{
        required: false,
      }}
    />
  );
};

export { SimcardEditableCell };
