import { FC, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { MUITSColumns, MUITSDatatable } from 'src/components/MUITSDatatable';
import { IVehicle } from 'src/services/touchway-base-api';
import { useTouchwayApi } from 'src/services/touchway-api';
import { queryKeys } from 'src/config/query';

const ConfiguratorLocatorsAvailable: FC = () => {
  const touchwayApi = useTouchwayApi();
  // const gsesRealtime = useRealtime<IGseFirebaseCollection>({
  //   collection: firebaseRealtimeEnum.vehicles,
  //   path: 'root',
  // });

  const vehiclesQuery = useQuery(
    queryKeys.vehicleControllerList.get({
      with_relations: true,
    }),
    () =>
      touchwayApi.api.vehicles.vehicleControllerList({
        // @ts-ignore
        with_relations: true,
      }),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 10000,
    },
  );

  const vehiclesRes = vehiclesQuery?.data?.vehicles;
  const vehicles = useMemo(() => {
    const vehiclesRaw = vehiclesRes || [];
    return vehiclesRaw.filter(({ vhc_name }) => vhc_name.startsWith('IMEI'));
  }, [vehiclesRes]);

  const id: keyof IVehicle = 'vhc_id';
  const columns: MUITSColumns<IVehicle> = [
    {
      name: 'vhc_id',
      label: 'vhc_id',
      options: { filter: true, display: false, sort: true },
    },
    {
      name: 'vhc_name',
      label: 'Nome',
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      // @ts-ignore
      name: 'typeVehicle.tpv_name',
      label: 'Tipo',
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: 'vhc_fuel_avg_cons_lph',
      label: 'Consumo médio (L/h)',
      options: {
        filter: true,
        display: true,
        sort: true,
        customBodyRender: (value) => `${value.toFixed(2)}`,
      },
    },
    {
      name: 'vhc_hour_meter',
      label: 'Horímetro (h)',
      options: {
        filter: true,
        display: true,
        sort: true,
        customBodyRender: (value) => `${value.toFixed(2)}`,
      },
    },
    {
      // @ts-ignore
      name: 'locator.lct_imei',
      label: 'IMEI',
      options: {
        filter: true,
        display: true,
        sort: true,
        customBodyRender: (value) => `${value}`,
      },
    },
    {
      // @ts-ignore
      name: 'locator.sim_card.sim_iccid',
      label: 'ICCID',
      options: {
        filter: false,
        display: true,
        sort: false,
      },
    },
    // sim_phone_number
    {
      // @ts-ignore
      name: 'locator.sim_card.sim_phone_number',
      label: 'Telefone',
      options: {
        filter: false,
        display: true,
        sort: false,
        // sim_phone_number: "+5519997258245"
        customBodyRender(value) {
          try {
            // create a regex to format the phone number to +55 (19) 99725-8245
            return `${value.slice(0, 3)} (${value.slice(3, 5)}) ${value.slice(
              5,
              10,
            )}-${value.slice(10)}`;
          } catch (error) {
            return '---';
          }
        },
      },
    },
    {
      name: 'vhc_id',
      label: 'ID do equipamento',
      options: { filter: true, display: true, sort: true },
    },
  ];

  return (
    <MUITSDatatable
      title="Equipamentos"
      data={vehicles}
      columns={columns}
      options={{ enableNestedDataAccess: '.' }}
      idKey={id}
      pathname="/veiculos"
      showViewButton
      showEditButton={false}
    />
  );
};

export default ConfiguratorLocatorsAvailable;
