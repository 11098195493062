import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { calendarApi } from '../__fakeApi__/calendarApi';
import type { AppThunk } from '../store';
import type { CalendarEvent } from '../types/calendar';
import { touchwayApi } from 'src/services/touchway-api';
import toast from 'react-hot-toast';
import { getServerErrorArrayMessage } from 'src/utils/get-server-error-message';
import { format, parseISO, getTime } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import { DEFAULT_TIMEZONE } from 'src/config';

interface CalendarState {
  events: CalendarEvent[];
  isModalOpen: boolean;
  selectedEventId: string | null;
  selectedRange: {
    start: number;
    end: number;
  } | null;
}

const initialState: CalendarState = {
  events: [],
  isModalOpen: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    getEvents(
      state: CalendarState,
      action: PayloadAction<CalendarEvent[]>,
    ): void {
      state.events = action.payload;
    },
    createEvent(
      state: CalendarState,
      action: PayloadAction<CalendarEvent>,
    ): void {
      state.events.push(action.payload);
    },
    selectEvent(state: CalendarState, action: PayloadAction<string>): void {
      state.isModalOpen = true;
      state.selectedEventId = action.payload;
    },
    updateEvent(
      state: CalendarState,
      action: PayloadAction<CalendarEvent>,
    ): void {
      const event = action.payload;
      console.log('🚀 ~ event:', event);

      state.events = state.events.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }

        return _event;
      });
    },
    deleteEvent(state: CalendarState, action: PayloadAction<string>): void {
      state.events = state.events.filter(
        (event) => event.id !== action.payload,
      );
    },
    selectRange(
      state: CalendarState,
      action: PayloadAction<{ start: number; end: number }>,
    ): void {
      const { start, end } = action.payload;

      state.isModalOpen = true;
      state.selectedRange = {
        start,
        end,
      };
    },
    openModal(state: CalendarState): void {
      state.isModalOpen = true;
    },
    closeModal(state: CalendarState): void {
      state.isModalOpen = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
  },
});

export const { reducer } = slice;

export const getEvents =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const res = await touchwayApi.equipment.equipmentControllerMaintenanceList({
      with_relations: true,
    });

    const data: CalendarEvent[] = res.maintenances.map((maintenance) => ({
      id: String(maintenance.eqm_id),
      title: `${maintenance.equipment?.eqp_name} - ${maintenance.typeMaintenance?.tem_name}`,
      description: `${maintenance.maintenanceStatus?.mts_name}`,
      start: adjustToSaoPauloTimezone(maintenance.eqm_forecast),
      end: adjustToSaoPauloTimezone(maintenance.eqm_forecast),
      allDay: true,
      extraProps: {
        maintenanceSimple: maintenance,
      },
    }));

    dispatch(slice.actions.getEvents(data));
  };

export const createEvent =
  (createData: any): AppThunk =>
  async (dispatch): Promise<void> => {
    const data = await calendarApi.createEvent(createData);

    dispatch(slice.actions.createEvent(data));
  };

export const selectEvent =
  (eventId?: string): AppThunk =>
  async (dispatch): Promise<void> => {
    if (!eventId) return;
    dispatch(slice.actions.selectEvent(eventId));
  };

export const updateEvent =
  (
    eventId: string,
    update: { allDay: boolean; end: null | Date; start: null | Date },
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    const eqpMaintenance =
      await touchwayApi.equipment.equipmentControllerMaintenanceList({
        eqm_id: eventId,
        with_relations: true,
      });
    const maintenance = eqpMaintenance.maintenances[0];

    try {
      const res =
        await touchwayApi.equipment.equipmentControllerMaintenanceUpsert({
          ...maintenance,
          eqm_forecast: format(update.start ?? new Date(), 'yyyy-MM-dd'),
        });

      dispatch(
        slice.actions.updateEvent({
          allDay: true,
          id: String(res.eqm_id),
          description: Array.isArray(res.eqm_notes)
            ? res.eqm_notes.join(', ')
            : res.eqm_notes || '',
          start: adjustToSaoPauloTimezone(res.eqm_forecast),
          end: adjustToSaoPauloTimezone(res.eqm_forecast),
          title: `${res.eqm_id}`,
          extraProps: {
            maintenanceSimple: maintenance,
          },
        }),
      );

      toast.success('Manutenção atualizada');
    } catch (error) {
      getServerErrorArrayMessage(error).forEach((message) =>
        toast.error(message),
      );

      dispatch(
        slice.actions.updateEvent({
          allDay: true,
          id: String(maintenance.eqm_id),
          description: Array.isArray(maintenance.eqm_notes)
            ? maintenance.eqm_notes.join(', ')
            : maintenance.eqm_notes || '',
          start: adjustToSaoPauloTimezone(maintenance.eqm_forecast),
          end: adjustToSaoPauloTimezone(maintenance.eqm_forecast),
          title: `${maintenance.eqm_id}`,
          extraProps: {
            maintenanceSimple: maintenance,
          },
        }),
      );
    }
  };

export const deleteEvent =
  (eventId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    // await calendarApi.deleteEvent(eventId);

    try {
      await touchwayApi.equipment.equipmentControllerMaintenanceDelete({
        eqm_id: eventId,
      });
      toast.success('Manutenção deletada');
    } catch (error) {
      getServerErrorArrayMessage(error).forEach((message) =>
        toast.error(message),
      );
    }

    dispatch(slice.actions.deleteEvent(eventId));
  };

export const selectRange =
  (start: number, end: number): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.selectRange({ start, end }));
  };

export const openModal =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.openModal());
  };

export const closeModal =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.closeModal());
  };

export default slice;

export const adjustToSaoPauloTimezone = (
  dateString: string | undefined | null,
): number => {
  if (!dateString) return 0;
  const date = new Date(dateString);
  const zonedDate = utcToZonedTime(date, DEFAULT_TIMEZONE);
  return zonedDate.getTime();
};
