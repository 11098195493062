import { Box, LinearProgress } from '@material-ui/core';
import { UseMutationResult } from '@tanstack/react-query';
import { MUIDataTableMeta } from 'mui-datatables';
import React, { useState, useEffect, useRef } from 'react';

type EditableCellProps = {
  type: React.InputHTMLAttributes<HTMLInputElement>['type'];
  value: string;
  tableMeta: MUIDataTableMeta;
  updateValue: (value: string) => void;
  mutation: UseMutationResult<any, unknown, any, unknown>;
  object: any;
  updateTime?: number;
  rules: {
    required: boolean;
    // Add more rules as needed
  };
};

const EditableCell: React.FC<EditableCellProps> = ({
  type,
  value,
  tableMeta,
  updateValue,
  mutation,
  updateTime = 20000,
  object,
  // rules,
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const updateFlagRef = useRef(false); // Flag to indicate a delayed update is needed
  const timerRef = useRef<number | undefined>();

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const startEditing = () => {
    if (inputRef.current) {
      inputRef.current.value = value;
    }
    setEditing(true);
  };

  const handleInputChange = () => {
    updateFlagRef.current = true; // Set the flag when input value changes
    // Reset the timer when input value changes
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = window.setTimeout(() => {
      handleUpdateValue();
    }, updateTime); // 1 second of inactivity before calling the endpoint
  };

  const handleUpdateValue = async () => {
    const newValue = inputRef.current?.value || '';
    // Only trigger update if the flag is set and no other input changes occurred
    if (updateFlagRef.current && newValue !== value) {
      updateFlagRef.current = false; // Reset the flag
      setEditing(false);
      // Call the endpoint to update the value
      await mutation.mutateAsync({
        ...object,
        [tableMeta.columnData.name]: newValue,
      });
      updateValue(newValue);
    }
  };

  const handleCancelEditing = () => {
    if (inputRef.current) {
      inputRef.current.value = value;
    }
    setEditing(false);
  };

  useEffect(
    () => () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      updateFlagRef.current = false;
    },
    [],
  );

  return (
    <div>
      {editing ? (
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            flexDirection="row"
          >
            <input
              type={type}
              defaultValue={value}
              onChange={handleInputChange}
              onBlur={handleUpdateValue} // Handle case when the user clicks outside the input
              ref={inputRef}
            />
            <button type="button" onClick={handleUpdateValue}>
              Salvar
            </button>
            <button type="button" onClick={handleCancelEditing}>
              Cancelar
            </button>
          </Box>
          {mutation.isLoading && (
            <Box mt={1}>
              <LinearProgress />
            </Box>
          )}
        </Box>
      ) : (
        <Box display="flex" flexDirection="row">
          <div
            role="button"
            tabIndex={0}
            onClick={startEditing}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                startEditing();
              }
            }}
          >
            {value}
          </div>
        </Box>
      )}
    </div>
  );
};

export default EditableCell;
