export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE',
};

export const PT_BR_THEME_NAMES = {
  LIGHT: 'Claro',
  DARK: 'Escuro',
  NATURE: 'Cinza',
};

export function getThemeName(theme: keyof typeof THEMES | string) {
  if (typeof theme === 'string') {
    return PT_BR_THEME_NAMES[theme as keyof typeof THEMES];
  }
  return PT_BR_THEME_NAMES[theme];
}
