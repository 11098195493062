/* eslint-disable jsx-a11y/media-has-caption */
import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  IconButton,
  Skeleton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { UseQueryResult, useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { WidgetResult } from 'react-cloudinary-upload-widget';
import { CloudinaryImageUpload } from 'src/components/cloudinary/CloudinaryImageUpload';
import useAuth from 'src/hooks/useAuth';
import { useTouchwayApi } from 'src/services/touchway-api';
import {
  IImageItem,
  IImageListResponse,
  IImageUpsertDto,
} from 'src/services/touchway-base-api';
import { getImageName } from 'src/utils/getImageName';
// @ts-ignore
import { DefaultPlayer as Video } from 'react-html5video';
import { DEFAULT_PLACEHOLDER } from 'src/hooks/useVehiclePhoto';

interface DevicePhotoProps {
  tpi_id: string;
  tpi_name: string;
  vhc_id: string;
  image: IImageItem | undefined;
  queryVehicleImages: UseQueryResult<IImageListResponse, unknown>;
}

export const DevicePhotoCard: React.FC<DevicePhotoProps> = ({
  tpi_id,
  tpi_name,
  vhc_id,
  image,
  queryVehicleImages,
}) => {
  const [open, setOpen] = useState(false);
  const { isTouchway } = useAuth();
  const { api } = useTouchwayApi();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function triggerRefetch() {
    queryVehicleImages.refetch();
  }

  const uploadMutation = useMutation({
    mutationFn: async (_image: IImageUpsertDto) =>
      api.images.imageControllerUpsert(_image),
    onSuccess() {
      triggerRefetch();
    },
  });

  function onSuccess(result: WidgetResult): void {
    uploadMutation.mutate({
      tpi_id,
      vhc_id,
      vhi_url: result.info.secure_url,
      vhi_id: image?.vhi_id,
    });
  }
  function onFailure(error: unknown, result: WidgetResult): void {
    console.error('Error uploading image', error, result);
    // eslint-disable-next-line no-alert
    alert(JSON.stringify(error));
  }

  const hasImageOrVideoUploaded = image !== undefined;
  const mediaUrl = image?.vhi_url;

  if (uploadMutation.isLoading) {
    return <Skeleton variant="rectangular" width="100%" height={300} />;
  }

  const isVideo = mediaUrl?.endsWith('.mp4') || mediaUrl?.endsWith('.webm');

  return (
    <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box onClick={handleOpen}>
        {!isVideo ? (
          <img
            alt={getImageName({ tpi_name })}
            src={hasImageOrVideoUploaded ? mediaUrl : '/static/placeholder.png'}
            style={{ width: '100%', height: 'auto' }}
          />
        ) : (
          <Video
            autoPlay={false}
            loop={false}
            muted
            controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
            poster={DEFAULT_PLACEHOLDER}
            onCanPlayThrough={() => {
              // Do stuff
            }}
          >
            <source src={mediaUrl} type="video/mp4" />
          </Video>
        )}
      </Box>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          style={{ position: 'absolute', right: 20, top: 0 }} // Adicione padding aqui
        >
          <Close />
        </IconButton>
        <Box
          style={{
            overflowX: 'hidden', // Adicione esta linha para remover o scrollbar horizontal
          }}
        >
          {isVideo ? (
            <Video
              autoPlay
              loop
              muted={false}
              controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
              poster={DEFAULT_PLACEHOLDER}
              onCanPlayThrough={() => {
                // Do stuff
              }}
            >
              <source src={mediaUrl} type="video/mp4" />
            </Video>
          ) : (
            <img
              alt={getImageName({ tpi_name })}
              src={hasImageOrVideoUploaded ? mediaUrl : DEFAULT_PLACEHOLDER}
              style={{ width: '100%', height: 'auto' }}
            />
          )}
        </Box>
        <DialogContent>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={2}
            mb={2}
          >
            <Typography component="figcaption">
              {getImageName({ tpi_name })}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
      <CardContent style={{ marginTop: 'auto' }}>
        <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
          <Typography component="figcaption">
            {getImageName({ tpi_name })}
          </Typography>
          {isTouchway && (
            <CloudinaryImageUpload
              onSuccess={onSuccess}
              onFailure={onFailure}
              cloudinaryProps={{
                folder: `vehicles/${vhc_id}/installations/`,
              }}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
