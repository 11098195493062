import { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Page } from 'src/components/layouts/Page';
import { MUITSColumns, MUITSDatatable } from 'src/components/MUITSDatatable';
import { Airplane } from '../gses/IGSE.type';
import { getAllAirplanes } from './services/getAllAirplanes';

const AeronaveList: FC = () => {
  const airplanesQuery = useQuery(['getAllAirplanes'], getAllAirplanes, {
    refetchOnWindowFocus: false,
  });
  const airplanes = airplanesQuery?.data?.data || [];

  const id: keyof Airplane = 'airplane_uuid';
  const columns: MUITSColumns<Airplane> = [
    {
      name: 'matricula',
      label: 'Matricula ',
      options: { filter: true, display: true, sort: true },
    },
    {
      name: 'serial_number',
      label: 'Número serial',
      options: { filter: true, display: true, sort: true },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        display: true,
        sort: true,
        customBodyRenderLite: (dataIndex) =>
          airplanes[dataIndex].status === 'active' ? 'Ativo' : 'Inativo',
      },
    },
  ];

  return (
    <Page
      title="Gerenciamento de Aeronaves"
      breadcrumbs={[
        { to: '/', label: 'Painel' },
        { to: 'Aeronaves', label: 'Aeronaves' },
      ]}
      query={airplanesQuery}
    >
      <MUITSDatatable
        title="Aeronaves"
        data={airplanes}
        columns={columns}
        options={{ enableNestedDataAccess: '.' }}
        idKey={id}
        showEditButton={false}
      />
    </Page>
  );
};

export default AeronaveList;
