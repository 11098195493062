/* eslint-disable react/no-unescaped-entities */
import type { FC } from 'react';

import toast from 'react-hot-toast';
import {
  Box,
  Card,
  CardContent,
  Dialog,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  IMaintenanceStatusMtsConstantEnum,
  IVehicleMaintenancePlanner,
} from 'src/services/touchway-base-api';
import {
  getMaintenanceByConstant,
  getMaintenanceById,
  isValidTransition,
} from 'src/utils/getMaintenanceById';
import { IVehicleFirebaseRealtimeType } from 'src/pages/gses/types/IGSEFirebaseRealtime.type';
import { Check, DriveFileMove } from '@material-ui/icons';
import useRealtime from 'src/hooks/firebase/useRealtime';
import { firebaseRealtimeEnum } from 'src/config';
import { DevicePhotoAvatar } from 'src/pages/devices/components/DeviceImage/DevicePhotoAvatar';
import { ImageTpinameEnum } from 'src/utils/getImageName';
import { moveCard } from '../../../slices/kanban';
import { useDispatch } from '../../../store';
import type { Card as ICard, Column } from '../../../types/kanban';
import KanbanCardAction from './KanbanCardAction';
import KanbanChecklist from './KanbanChecklist';
import KanbanComment from './KanbanComment';
import KanbanCommentAdd from './KanbanCommentAdd';
import { KanbanCardContent } from './KanbanCardContext';

interface KanbanCardModalProps {
  card: ICard;
  column: Column;
  onClose?: () => void;
  open: boolean;
  maintenance: IVehicleMaintenancePlanner;
}

const KanbanCardModal: FC<KanbanCardModalProps> = (props) => {
  const { card, column, onClose, open, maintenance, ...other } = props;
  const dispatch = useDispatch();

  const gseRealtime = useRealtime<IVehicleFirebaseRealtimeType>({
    collection: firebaseRealtimeEnum.vehicles,
    key: card?.extraProps?.vehicle?.vhc_id,
    enabled: !!card?.extraProps?.vehicle?.vhc_id,
  });

  // const handleDetailsUpdate = debounce(async (update) => {
  //   try {
  //     await dispatch(updateCard(card.id, update));
  //     toast.success('Card updated!');
  //   } catch (err) {
  //     console.error(err);
  //     toast.error('Something went wrong!');
  //   }
  // }, 1000);

  // const handleSubscribe = async (): Promise<void> => {
  //   try {
  //     await dispatch(updateCard(card.id, { isSubscribed: true }));
  //     toast.success('Unsubscribed!');
  //   } catch (err) {
  //     console.error(err);
  //     toast.error('Something went wrong!');
  //   }
  // };

  // const handleUnsubscribe = async (): Promise<void> => {
  //   try {
  //     await dispatch(updateCard(card.id, { isSubscribed: false }));
  //     toast.success('Subscribed!');
  //   } catch (err) {
  //     console.error(err);
  //     toast.error('Something went wrong!');
  //   }
  // };

  // const handleDelete = async (): Promise<void> => {
  //   try {
  //     await dispatch(deleteCard(card.id));
  //     toast.success('Card archived!');
  //   } catch (err) {
  //     console.error(err);
  //     toast.error('Something went wrong!');
  //   }
  // };

  // const handleAddChecklist = async (): Promise<void> => {
  //   try {
  //     await dispatch(addChecklist(card.id, 'Untitled Checklist'));
  //     toast.success('Checklist added!');
  //   } catch (err) {
  //     console.error(err);
  //     toast.error('Something went wrong!');
  //   }
  // };

  return (
    <Dialog fullWidth maxWidth="md" onClose={onClose} open={open} {...other}>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={5}>
          <Grid item sm={8} xs={12}>
            <Box
              display="flex"
              flexDirection="row"
              alignContent="center"
              gap={2}
            >
              <DevicePhotoAvatar
                vhc_id={card?.extraProps?.vehicle?.vhc_id}
                image={ImageTpinameEnum.AFTER_INSTALL_LOCATOR_MEDIA}
                width={60}
                height={60}
              />
              <Box display="flex" flexDirection="column">
                <Box display="flex" justifyContent="space-between">
                  <Typography color="textPrimary" variant="h4">
                    {card?.name}
                  </Typography>
                  <Typography
                    color="textPrimary"
                    variant="h4"
                    fontWeight="normal"
                  >
                    {gseRealtime?.iata || '---'}
                  </Typography>
                </Box>
                <Typography color="textPrimary" variant="body1">
                  Manutenção de {maintenance.typeMaintenance.tpm_in_hours} horas
                </Typography>
              </Box>
            </Box>
            <Box sx={{ mt: 3 }}>
              {/* <TextField
                defaultValue={card.description}
                fullWidth
                multiline
                onChange={(event): Promise<void> =>
                  // @ts-ignore
                  handleDetailsUpdate({ description: event.target.value })
                }
                placeholder=""
                label="Descrição"
                rows={6}
                variant="outlined"
              /> */}
              {/* <pre>{JSON.stringify(maintenance, null, 2)}</pre> */}
            </Box>
            {/* Detalhes da Manutenção */}

            <Card variant="outlined">
              <CardContent>
                <Typography mb={1} variant="h6">
                  Detalhes da Manutenção
                </Typography>

                <KanbanCardContent extraProps={card.extraProps} comments={[]} />
              </CardContent>
            </Card>

            <Box mb={2} />

            {/* <Divider sx={{ my: 2 }} /> */}

            {card.checklists.length > 0 && (
              <Box sx={{ mt: 5 }}>
                {card.checklists.map((checklist) => (
                  <KanbanChecklist
                    card={card}
                    checklist={checklist}
                    key={checklist.id}
                    sx={{ mb: 3 }}
                  />
                ))}
              </Box>
            )}
            <Box sx={{ mt: 3 }}>
              <Typography color="textPrimary" variant="h6">
                Notas de manutenção
              </Typography>
              <Box sx={{ mt: 2 }}>
                {card.comments.length === 0 && (
                  <Typography
                    mb={2}
                    mt={-1}
                    color="textSecondary"
                    variant="body1"
                  >
                    Nenhuma nota de manutenção foi adicionada.
                  </Typography>
                )}
                <KanbanCommentAdd cardId={card.id} />
                {card.comments.length > 0 && (
                  <Box sx={{ mt: 3 }}>
                    {[...card.comments].reverse().map((comment, i) => (
                      <KanbanComment
                        createdAt={comment.createdAt}
                        key={comment.id}
                        memberId={comment.memberId}
                        message={comment.message}
                        index={i + 1}
                      />
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            {/* <Typography
              color="textPrimary"
              component="h4"
              sx={{
                fontWeight: 600,
                mb: 2,
              }}
              variant="overline"
            >
              Adicionar ao cartão
            </Typography>
            <KanbanCardAction
              icon={<CheckIcon fontSize="small" />}
              onClick={handleAddChecklist}
            >
              Checklist
            </KanbanCardAction>
            <KanbanCardAction disabled icon={<UsersIcon fontSize="small" />}>
              Membros
            </KanbanCardAction>
            <KanbanCardAction disabled icon={<LabelIcon fontSize="small" />}>
              Etiquetas
            </KanbanCardAction>
            <KanbanCardAction
              disabled
              icon={<DocumentTextIcon fontSize="small" />}
            >
              Anexos
            </KanbanCardAction> */}
            <Box sx={{ mt: 0 }}>
              <Typography
                color="textPrimary"
                component="h4"
                sx={{
                  fontWeight: 600,
                  mb: 2,
                }}
                variant="overline"
              >
                Ações
              </Typography>
              <KanbanCardAction
                disabled={
                  !isValidTransition(
                    getMaintenanceById({ mts_id: card.columnId }),
                    getMaintenanceByConstant({
                      mts_constant: IMaintenanceStatusMtsConstantEnum.PLANNED,
                    }),
                  ).isValid || !!card.extraProps.vmp_execution_date
                }
                onClick={() => {
                  dispatch(
                    // cardId, position, columnId
                    moveCard(
                      card.id,
                      0,
                      getMaintenanceByConstant({
                        mts_constant: IMaintenanceStatusMtsConstantEnum.PLANNED,
                      }).mts_id,
                    ),
                  );

                  toast.success('Equipamento movido com sucesso');
                }}
                icon={<DriveFileMove fontSize="small" />}
              >
                Marcar como Planejada
              </KanbanCardAction>
              <KanbanCardAction
                disabled={
                  !isValidTransition(
                    getMaintenanceById({ mts_id: card.columnId }),
                    getMaintenanceByConstant({
                      mts_constant: IMaintenanceStatusMtsConstantEnum.DOING,
                    }),
                  ).isValid || !!card.extraProps.vmp_execution_date
                }
                onClick={() => {
                  dispatch(
                    // cardId, position, columnId
                    moveCard(
                      card.id,
                      0,
                      getMaintenanceByConstant({
                        mts_constant: IMaintenanceStatusMtsConstantEnum.DOING,
                      }).mts_id,
                    ),
                  );

                  toast.success('Equipamento movido com sucesso');
                }}
                icon={<DriveFileMove fontSize="small" />}
              >
                Marcar como "Material enviado"
              </KanbanCardAction>
              {
                <>
                  <KanbanCardAction
                    disabled={
                      !isValidTransition(
                        getMaintenanceById({ mts_id: card.columnId }),
                        getMaintenanceByConstant({
                          mts_constant: IMaintenanceStatusMtsConstantEnum.DONE,
                        }),
                      ).isValid
                    }
                    onClick={() => {
                      dispatch(
                        // cardId, position, columnId
                        moveCard(
                          card.id,
                          0,
                          getMaintenanceByConstant({
                            mts_constant:
                              IMaintenanceStatusMtsConstantEnum.DONE,
                          }).mts_id,
                        ),
                      );

                      toast.success('Equipamento movido com sucesso');
                    }}
                    icon={<Check fontSize="small" />}
                  >
                    Marcar como Concluída
                  </KanbanCardAction>
                </>
              }
              {/* <Divider sx={{ my: 2 }} />
              {card.isSubscribed ? (
                <KanbanCardAction
                  icon={<EyeOffIcon fontSize="small" />}
                  onClick={handleUnsubscribe}
                >
                  Deixar de assistir
                </KanbanCardAction>
              ) : (
                <KanbanCardAction
                  icon={<EyeIcon fontSize="small" />}
                  onClick={handleSubscribe}
                >
                  Assistir
                </KanbanCardAction>
              )}
              <KanbanCardAction
                icon={<ArchiveIcon fontSize="small" />}
                onClick={handleDelete}
              >
                Arquivar
              </KanbanCardAction> */}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default KanbanCardModal;
