/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback } from 'react';
import { GoogleMap, GoogleMapProps } from '@react-google-maps/api';
import { isMobile } from 'react-device-detect';
import { DEFAULT_MAPS } from 'src/config/maps-default';
import useSettings from 'src/hooks/useSettings';
import { THEMES } from 'src/constants';

interface MapsProps extends GoogleMapProps {
  children?: React.ReactNode;
  setMapCallback?: React.Dispatch<React.SetStateAction<null>>;
  defaultZoom?: number;
  defaultHeight?: string | number;
}

export enum MAPS_STYLES_ID {
  LIGHT = '83595eaaa470bf26',
  DARK = 'b5ebdd91d65d2744',
}

export const CustomMaps: React.FC<MapsProps> = ({
  children,
  setMapCallback,
  defaultZoom = 15,
  defaultHeight,
  ...props
}) => {
  const { settings } = useSettings();
  const [map, setMap] = React.useState(null);

  const handleLocationError = useCallback(
    (
      browserHasGeolocation: boolean,
      infoWindow: google.maps.InfoWindow,
      pos: google.maps.LatLng,
    ) => {
      infoWindow.setPosition(pos);
      infoWindow.setContent(
        browserHasGeolocation
          ? 'Erro: o serviço de geolocalização falhou.'
          : 'Erro: seu navegador não suporta a geolocalização.',
      );
      infoWindow.open(map);
    },
    [map],
  );

  const onLoad = React.useCallback(
    (mapLoad) => {
      const infoWindow = new google.maps.InfoWindow();
      const locationButton = document.createElement('button');

      locationButton.textContent = 'Ver minha localização';
      locationButton.classList.add('custom-map-control-button');

      mapLoad.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(
        locationButton,
      );

      locationButton.addEventListener('click', () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position: GeolocationPosition) => {
              const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };

              infoWindow.setPosition(pos);
              infoWindow.setContent('Você está aqui.');
              infoWindow.open(mapLoad);
              mapLoad.setCenter(pos);
            },
            () => {
              handleLocationError(true, infoWindow, mapLoad.getCenter()!);
            },
          );
        } else {
          handleLocationError(false, infoWindow, mapLoad.getCenter()!);
        }
      });

      setMap(mapLoad);
      if (setMapCallback) {
        setMapCallback(mapLoad);
      }
    },
    [handleLocationError, setMapCallback],
  );

  const onUnmount = React.useCallback(() => {
    setMap(null);
    if (setMapCallback) {
      setMapCallback(null);
    }
  }, [setMapCallback, setMap]);

  const isMobileSizePx = isMobile ? '60px' : '0px';
  const mapHeight = defaultHeight ?? `calc(100vh - 64px - ${isMobileSizePx})`;
  return (
    <>
      <GoogleMap
        key={`map-${settings.theme}`}
        mapContainerStyle={{
          height: mapHeight,
          width: '100%',
        }}
        center={DEFAULT_MAPS.center}
        zoom={defaultZoom ?? 15}
        options={{
          mapTypeControl: true,
          mapId:
            settings.theme === THEMES.LIGHT
              ? MAPS_STYLES_ID.LIGHT
              : MAPS_STYLES_ID.DARK,
        }}
        onLoad={onLoad}
        onUnmount={onUnmount}
        {...props}
      >
        {children}
      </GoogleMap>
    </>
  );
};
