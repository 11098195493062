import { FC, useMemo } from 'react';
import { Grid, GridSize } from '@material-ui/core';
import { IOption, IVehicle } from 'src/services/touchway-base-api';
import { firebaseRealtimeEnum } from 'src/config';
import useRealtime from 'src/hooks/firebase/useRealtime';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'src/config/query';
import { useTouchwayApi } from 'src/services/touchway-api';
import { Ordering, useRemoteControl } from './contexts/RemoteControlContext';
import { IVehicleFirebaseRealtimeType } from '../gses/types/IGSEFirebaseRealtime.type';
import { VehicleSocketCard } from '../gses/components/VehicleSocketCard';

interface IVehiclesRealtimeProps {
  selectedAirports: IOption[];
}

interface RemapArr {
  fb: IVehicleFirebaseRealtimeType;
  vhc_id: string;
  vehicle: IVehicle | undefined;
}

export const VehiclesRealtime: FC<IVehiclesRealtimeProps> = ({
  selectedAirports = [],
}) => {
  const { state } = useRemoteControl();
  const { api } = useTouchwayApi();

  const all = useRealtime<IVehicleFirebaseRealtimeType>({
    collection: firebaseRealtimeEnum.vehicles,
    key: '',
  });

  const params = { with_maintenances: true, with_relations: true };
  const vehicleQuery = useQuery({
    queryKey: queryKeys.vehicleControllerList.get(params),
    queryFn: () => api.vehicles.vehicleControllerList(params),
    cacheTime: 1000 * 60 * 60,
  });

  const queryRes = vehicleQuery.data?.vehicles;
  const allVehicles = useMemo(
    () => (queryRes as unknown as IVehicle[]) || [],
    [queryRes],
  );

  const remap: RemapArr[] = useMemo(() => {
    if (!all) return [];

    return Object.entries(all).map(([vhc_id, fb]) => ({
      fb,
      vhc_id,
      vehicle: allVehicles.find((v) => v.vhc_id === vhc_id),
    }));
  }, [all, allVehicles]);

  const filterBySelectedBase = useMemo(
    () =>
      remap.filter(({ fb, vehicle }) => {
        if (selectedAirports.length === 0) return true;
        if (vehicle?.vhc_name.includes('IMEI')) return false;

        return selectedAirports.includes(fb.iata as unknown as IOption);
      }),
    [remap, selectedAirports],
  );

  const order = state.ordering;

  const orderArr = useMemo(() => {
    if (order === Ordering.ACTIVE_FIRST) {
      return filterBySelectedBase.sort((a, b) => {
        if (a.fb.ignition && !b.fb.ignition) return -1;
        if (!a.fb.ignition && b.fb.ignition) return 1;
        return 0;
      });
    }

    if (order === Ordering.MAINTENANCE_SOON) {
      return filterBySelectedBase.sort((a, b) => {
        // Para cada veículo, encontrar o menor percentual de conclusão de manutenção
        const minCompletionA = Math.max(
          ...(a.vehicle?.maintenances || []).map(
            (m) => m.vmp_completion_percentage,
          ),
        );
        const minCompletionB = Math.max(
          ...(b.vehicle?.maintenances || []).map(
            (m) => m.vmp_completion_percentage,
          ),
        );

        // Comparar os veículos pelo menor percentual de conclusão de manutenção
        return minCompletionB - minCompletionA;
      });
    }

    return filterBySelectedBase;
  }, [filterBySelectedBase, order]);

  return (
    <Grid container spacing={2}>
      {orderArr.map(({ fb, vhc_id, vehicle }) => {
        const xs = convertNumberToGridSize(state.otherSettings.cardsPerRow);
        if (!vehicle || !fb) {
          return null;
        }

        if (vehicle?.vhc_name.includes('IMEI')) {
          return null;
        }

        return (
          <Grid
            item
            key={`${vhc_id}-${xs}`}
            xs={convertNumberToGridSize(state.otherSettings.cardsPerRow)}
          >
            <VehicleSocketCard vehicle={vehicle} fb={fb} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default VehiclesRealtime;

function convertNumberToGridSize(number: number): GridSize {
  if (number < 1) return 'auto';
  if (number > 12) return 'auto';

  if (number === 1) return 12;
  if (number === 2) return 6;
  if (number === 3) return 4;
  if (number === 4) return 3;
  if (number === 6) return 2;
  if (number === 12) return 1;

  return 4;
}
