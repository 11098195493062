import { touchwayApi } from 'src/services/touchway-api';
import { IMaintenanceStatusMtsConstantEnum } from 'src/services/touchway-base-api';

export type Maintenance = {
  mts_id: string;
  mts_name: string;
  mts_constant: IMaintenanceStatusMtsConstantEnum;
};

export enum IMaintenanceStatusMtsConstantEnumID {
  PLANNED_ID = 'd3a73a0a-25fd-43fe-bd26-378471f61d5d',
  DOING_ID = 'adea4f63-8ebc-42d9-8945-e42b74cf10b7',
  DONE_ID = 'af27fdf4-e80b-400a-b2ea-9f961c94437d',
}

export const maintenanceMockPlanned = {
  mts_id: IMaintenanceStatusMtsConstantEnumID.PLANNED_ID,
  mts_name: 'Planejada',
  mts_constant: IMaintenanceStatusMtsConstantEnum.PLANNED,
};

export const maintenanceMockDoing = {
  mts_id: IMaintenanceStatusMtsConstantEnumID.DOING_ID,
  mts_name: 'Material enviado',
  mts_constant: IMaintenanceStatusMtsConstantEnum.DOING,
};

export const maintenanceMockDone = {
  mts_id: IMaintenanceStatusMtsConstantEnumID.DONE_ID,
  mts_name: 'Concluida',
  mts_constant: IMaintenanceStatusMtsConstantEnum.DONE,
};

// TODO: remove this mock on the future
export const maintenanceMock = [
  maintenanceMockPlanned,
  maintenanceMockDoing,
  maintenanceMockDone,
];

export function getMaintenanceById({
  mts_id,
}: {
  mts_id: string;
}): Maintenance {
  const res = maintenanceMock.find(
    (maintenance) => maintenance.mts_id === mts_id,
  );
  if (!res) throw new Error('Maintenance not found');
  return res;
}

export function getMaintenanceByConstant({
  mts_constant,
}: {
  mts_constant: IMaintenanceStatusMtsConstantEnum;
}): Maintenance {
  const res = maintenanceMock.find(
    (maintenance) => maintenance.mts_constant === mts_constant,
  );
  if (!res) throw new Error('Maintenance not found');
  return res;
}

// Dada uma transição do estado "from" para o estado "to", verifique se a transição é válida
export function isValidTransition(
  from: Maintenance,
  to: Maintenance,
): {
  isValid: boolean;
  requiresConfirmation: boolean;
  message: string;
} {
  if (
    from.mts_constant === IMaintenanceStatusMtsConstantEnum.PLANNED &&
    to.mts_constant === IMaintenanceStatusMtsConstantEnum.DOING
  ) {
    return {
      isValid: true,
      requiresConfirmation: false,
      message: '',
    };
  }

  if (
    from.mts_constant === IMaintenanceStatusMtsConstantEnum.PLANNED &&
    to.mts_constant === IMaintenanceStatusMtsConstantEnum.DONE
  ) {
    return {
      isValid: true,
      requiresConfirmation: true,
      message:
        'Você tem certeza que deseja concluir essa manutenção? Essa ação não pode ser desfeita.',
    };
  }

  if (
    from.mts_constant === IMaintenanceStatusMtsConstantEnum.DOING &&
    to.mts_constant === IMaintenanceStatusMtsConstantEnum.PLANNED
  ) {
    return {
      isValid: true,
      requiresConfirmation: false,
      message: '',
    };
  }

  if (
    from.mts_constant === IMaintenanceStatusMtsConstantEnum.DOING &&
    to.mts_constant === IMaintenanceStatusMtsConstantEnum.DONE
  ) {
    return {
      isValid: true,
      requiresConfirmation: true,
      message:
        'Você tem certeza que deseja concluir essa manutenção? Essa ação não pode ser desfeita.',
    };
  }

  if (from.mts_constant === IMaintenanceStatusMtsConstantEnum.DONE) {
    return {
      isValid: false,
      requiresConfirmation: false,
      message: 'Essa manutenção já foi concluída e não pode mais ser movida.',
    };
  }

  return {
    isValid: false,
    requiresConfirmation: false,
    message: `${from.mts_name} não pode ser seguido por ${to.mts_name}`,
  };
}
