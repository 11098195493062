/* eslint-disable @typescript-eslint/naming-convention */
import { Box, colors, Stack, Typography } from '@material-ui/core';
import { useMemo } from 'react';
import {
  IEquipmentMaintenanceSimpleOrDetailed,
  IMaintenanceStatusMtsConstantEnum,
} from 'src/services/touchway-base-api';

interface EqpCalendarContentProps {
  eventInfo: any;
}

export const EqpCalendarContent: React.FC<EqpCalendarContentProps> = ({
  eventInfo,
}) => {
  const maintenance = eventInfo?.event?.extendedProps?.extraProps
    ?.maintenanceSimple as IEquipmentMaintenanceSimpleOrDetailed | undefined;

  const name = maintenance?.equipment?.eqp_name;
  const frequency = maintenance?.typeMaintenance?.tem_name;
  const status = maintenance?.maintenanceStatus?.mts_name;

  const constant = maintenance?.maintenanceStatus?.mts_constant;

  const executed = maintenance?.eqm_executed;
  const forecast = maintenance?.eqm_forecast;
  const color = useMemo(() => {
    const defaultColor = '#688EFF';

    const isExecuted = executed || false;

    if (isExecuted) {
      return colors.green[500];
    }

    const isDelayed = forecast && new Date(forecast) < new Date();

    if (isDelayed && constant === IMaintenanceStatusMtsConstantEnum.DOING) {
      return colors.orange[500];
    }

    if (isDelayed) {
      return colors.red[500];
    }

    // se faltar 7 dias para a manutenção, a cor é amarela
    if (constant === IMaintenanceStatusMtsConstantEnum.DOING) {
      return colors.orange[500];
    }

    return defaultColor;
  }, [constant, executed, forecast]);

  return (
    <Box
      bgcolor={color}
      borderRadius={0.25}
      overflow="hidden"
      textOverflow="ellipsis"
    >
      <Stack spacing={0} p={0.5}>
        <Typography variant="body2">{name}</Typography>
        <Typography variant="caption">{frequency}</Typography>
        <Typography variant="caption">{status}</Typography>
      </Stack>

      {/* <CustomLinearProgress
        value={remainingPercentage}
        maintence={100}
        color={remainingPercentage ? 'primary' : 'secondary'}
        variant={remainingPercentage ? 'determinate' : 'determinate'}
      /> */}
    </Box>
  );
};
