import { Suspense, lazy, LazyExoticComponent } from 'react';

import { Outlet, PartialRouteObject } from 'react-router';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import { AirportProvider } from './pages/airports/contexts/AirportsContext';
import AuthorizationRequired from './pages/AuthorizationRequired';
import Kanban from './pages/dashboard/Kanban';
import AeronaveList from './pages/devices/AeronaveList';
import { AeroportoList } from './pages/devices/AirplaneList';
// import Socket from './pages/dashboard/Socket';

import { MapsAirport } from './pages/devices/components/MapsAirport';
import { MapsTrolleys } from './pages/devices/components/MapsTrolleys';
import DeviceCreate from './pages/devices/DeviceCreate';
import DeviceDetails from './pages/devices/DeviceDetails';
import DeviceEdit from './pages/devices/DeviceEdit';
import VehiclesRootPage from './pages/devices/VehiclesRootPage';
import TrolleysList from './pages/devices/TrolleysList';
import { GseRealtime } from './pages/gses/GsesRealtime';
import NotFound from './pages/NotFound';
import ServerError from './pages/ServerError';
import UsersRootPage from './pages/dashboard/users/UsersRootPage';
import { VehiclesReport } from './pages/gses/VehiclesReport';
import VehiclesTab from './pages/devices/VehiclesTab';
import ConfiguratorTab from './pages/devices/ConfiguratorTab';
import { InstallationReport } from './pages/gses/InstallationReport';
import Analytics from './pages/dashboard/Analytics';
import { LocatorsRealtime } from './pages/locators/LocatorsRealtime';
import EquipmentsRootPage from './pages/equipments/EquipmentRootPage';
import EquipmentsTab from './pages/equipments/EquipmentTabsPage';
import EquipmentCreate from './pages/equipments/EquipmentCreate';
import EquipmentDetails from './pages/equipments/EquipmentTabs';
import TypeEquipmentRootPage from './pages/type-equipment-maintenance/TypeEquipmentMaintenanceRootPage';
import TypeEquipmentsTab from './pages/type-equipment-maintenance/TypeEquipmentMaintenanceTab';
import { TypeEquipmentMaintenanceCreate } from './pages/type-equipment-maintenance/TypeEquipmentMaintenanceCreate';
import TypeEquipmentMaintenanceDetails from './pages/type-equipment-maintenance/TypeEquipmentMaintenanceDetails';
import { TypeEquipmentMaintenanceEdit } from './pages/type-equipment-maintenance/TypeEquipmentMaintenanceEdit';
import { EquipmentCalendarPage } from './pages/equipments/EquipmentCalendarPage';
import { EquipmentTabProvider } from './pages/equipments/provider/EquipmentContext';
import { LiveTv } from './pages/live/LiveTv';
import { RemoteControl } from './pages/live/RemoteControl';
import { RemoteControlProvider } from './pages/live/contexts/RemoteControlContext';

const Loadable =
  (Component: LazyExoticComponent<React.FC<{}>>) => (props: any) =>
    (
      <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
      </Suspense>
    );

// Authentication pages

const Login = Loadable(
  lazy(() => import('./pages/authentication/pages/Login')),
);
const PasswordRecovery = Loadable(
  lazy(() => import('./pages/authentication/pages/PasswordRecovery')),
);
const PasswordReset = Loadable(
  lazy(() => import('./pages/authentication/pages/PasswordReset')),
);
const Register = Loadable(
  lazy(() => import('./pages/authentication/pages/Register')),
);
const VerifyCode = Loadable(
  lazy(() => import('./pages/authentication/pages/VerifyCode')),
);

// Painel pages

const UserDetails = Loadable(
  lazy(() => import('./pages/dashboard/users/UserDetails')),
);
const UserEdit = Loadable(
  lazy(() => import('./pages/dashboard/users/UserEdit')),
);
const UserCreate = Loadable(
  lazy(() => import('./pages/dashboard/users/UserCreate')),
);
const UserList = Loadable(
  lazy(() => import('./pages/dashboard/users/UserList')),
);

const Notification = Loadable(
  lazy(() => import('./pages/notifications/NotificationsPage')),
);

const routes: PartialRouteObject[] = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <AirportProvider>
          <DashboardLayout />
        </AirportProvider>
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <GseRealtime />,
      },
      {
        path: '/dashboard',
        element: <Analytics />,
      },
      {
        path: '/realtime',
        element: <GseRealtime />,
      },
      {
        path: '/localizadores',
        element: <LocatorsRealtime />,
      },
      {
        path: '/instalador',
        element: <ConfiguratorTab />,
      },
      {
        path: '/mapa',
        element: <MapsAirport />,
      },
      {
        path: '/mapa-trolleys',
        element: <MapsTrolleys />,
      },
      {
        path: '/trolleys',
        element: <TrolleysList />,
      },
      {
        path: '/aeronaves',
        element: <AeronaveList />,
      },
      {
        path: '/bases',
        element: <AeroportoList />,
      },
      {
        path: '/kanban',
        element: <Kanban />,
      },
      {
        path: '/relatorios',
        element: <VehiclesRootPage />,
        children: [
          {
            path: '/instalacao',
            element: <InstallationReport />,
          },
          {
            path: '/equipamentos',
            element: <VehiclesReport />,
          },
        ],
      },
      {
        path: '/notificacoes',
        element: <Notification />,
      },
      {
        path: 'veiculos',
        element: <VehiclesRootPage />,
        children: [
          {
            path: '/',
            element: <VehiclesTab />,
          },
          {
            path: 'novo',
            element: <DeviceCreate />,
          },
          {
            path: ':gseId',
            element: <DeviceDetails />,
          },
          {
            path: ':gseId/editar',
            element: <DeviceEdit />,
          },
        ],
      },
      {
        path: '/equipamentos',
        element: <EquipmentsRootPage />,
        children: [
          {
            path: '/',
            element: <EquipmentsTab />,
          },
          {
            path: '/calendario',
            element: <EquipmentCalendarPage />,
          },
          {
            path: 'novo',
            element: <EquipmentCreate />,
          },
          {
            path: ':eqp_id',
            element: (
              <EquipmentTabProvider>
                <EquipmentDetails />
              </EquipmentTabProvider>
            ),
          },
          {
            path: ':gseId/editar',
            element: <DeviceEdit />,
          },
        ],
      },
      {
        path: '/frequencia-manutencao',
        element: <TypeEquipmentRootPage />,
        children: [
          {
            path: '/',
            element: <TypeEquipmentsTab />,
          },
          {
            path: 'novo',
            element: <TypeEquipmentMaintenanceCreate />,
          },
          {
            path: ':tem_id',
            element: <TypeEquipmentMaintenanceDetails />,
          },
          {
            path: ':tem_id/editar',
            element: <TypeEquipmentMaintenanceEdit />,
          },
        ],
      },
      {
        path: 'usuarios',
        element: <UsersRootPage />,
        children: [
          {
            path: '/',
            element: <UserList />,
          },
          {
            path: 'novo',
            element: <UserCreate />,
          },
          {
            path: ':userId',
            element: <UserDetails />,
          },
          {
            path: ':userId/editar',
            element: <UserEdit />,
          },
        ],
      },
    ],
  },
  {
    path: 'live',
    element: (
      <AuthGuard>
        <AirportProvider>
          <RemoteControlProvider>
            <Outlet />
          </RemoteControlProvider>
        </AirportProvider>
      </AuthGuard>
    ),
    children: [
      {
        path: '/tv',
        element: <LiveTv />,
      },
      {
        path: '/controle',
        element: <RemoteControl />,
      },
    ],
  },
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      { path: 'login-unguarded', element: <Login /> },
      { path: 'password-recovery', element: <PasswordRecovery /> },
      { path: 'password-reset', element: <PasswordReset /> },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      { path: 'register-unguarded', element: <Register /> },
      { path: 'verify-code', element: <VerifyCode /> },
    ],
  },
  {
    path: '*',
    element: <DashboardLayout />,
    children: [
      {
        path: '/',
        element: <NotFound />,
        // element: <Thanks />,
      },
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
