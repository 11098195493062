import { AUTH_TOKEN } from 'src/services/axiosService';
import { AUTHENTICATE_USER, IAuthContext } from '../AuthInterface';

export function authenticateUserAction(
  state: IAuthContext,
  action: AUTHENTICATE_USER,
): IAuthContext {
  window.localStorage.setItem(AUTH_TOKEN, action.token);

  return {
    ...state,
    loggedUser: action.loggedUser,
    token: action.token,
    // firebaseUser: action.firebaseUser,
  };
}
