import { FC, useState } from 'react';
import { Page } from 'src/components/layouts/Page';
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';
import { TypeEquipmentMaintenanceForm } from './forms/TypeEquipmentMaintenanceForm';

export const TypeEquipmentMaintenanceCreate: FC = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(true);

  function handleClose() {
    setOpen(false);
    navigate('/frequencia-manutencao');
  }

  function onSuccessfulSubmit() {
    toast.success('Frequência de manutenção criada com sucesso');
    handleClose();
  }

  return (
    <Page
      title="Criar novo dispositivo"
      breadcrumbs={[
        { to: '/', label: 'Painel' },
        { to: 'equipamentos', label: 'Equipamentos' },
        { to: 'novo', label: 'Novo equipamento' },
      ]}
    >
      <TypeEquipmentMaintenanceForm
        typeEquipmentMaintenanceToBeEdited={null}
        open={open}
        onClose={handleClose}
        onSuccess={onSuccessfulSubmit}
      />
    </Page>
  );
};
