import { format } from 'date-fns';
import { LocatorConverter } from '../class/Locator';
import { IMergedLocators } from './merge-locators';
import { ILocator } from 'src/services/touchway-base-api';

// get geolocation_date(): Date | null {
// get heartbeat_date(): Date | null {
// get ignition_off_date(): Date | null {
// get ignition_on_date(): Date | null {
// get info_date(): Date | null {

interface TmpGroupBy {
  fb_locator: LocatorConverter | null;
  locator: ILocator;
  lastCommunication: Date | null;
}

export function groupLocatorsByLastDate(locators: IMergedLocators[]) {
  const locatorsByLastDate: TmpGroupBy[] = [];

  locators.forEach(({ db_locator, fb_locator, lct_id }) => {
    const dates = [
      fb_locator?.geolocation_date,
      fb_locator?.heartbeat_date,
      fb_locator?.ignition_off_date,
      fb_locator?.ignition_on_date,
      fb_locator?.info_date,
    ].filter((date): date is Date => date instanceof Date);

    const maxDateTimestamp = dates.reduce(
      (max, date) => Math.max(max, date.getTime()),
      0,
    );

    if (maxDateTimestamp === 0) {
      locatorsByLastDate.push({
        fb_locator: fb_locator,
        lastCommunication: null,
        locator: db_locator,
      });
    } else {
      const lastCommunication = new Date(maxDateTimestamp);
      locatorsByLastDate.push({
        fb_locator: fb_locator,
        lastCommunication,
        locator: db_locator,
      });
    }
  });

  // Mova o return para fora do forEach para retornar o valor correto do método.
  return groupByDayMonthYear(locatorsByLastDate);
}

function groupByDayMonthYear(arr: TmpGroupBy[]) {
  const grouped: {
    [key: string]: {
      order: number;
      arr: TmpGroupBy[];
      title: string;
    };
  } = {};

  arr.forEach((item, index) => {
    const date = item.lastCommunication;

    // Define uma chave padrão para itens sem data
    let key = 'No Date';

    if (date) {
      // Se houver uma data, formata a chave com base na data
      key = format(date, 'dd/MM/yyyy');
    }

    if (!grouped[key]) {
      grouped[key] = {
        arr: [],
        title: key,
        order: date ? index : 99999,
      };
    }

    grouped[key].arr.push(item);
  });

  return grouped;
}
