import { useQuery } from '@tanstack/react-query';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { queryKeys } from 'src/config/query';
import { touchwayApi } from 'src/services/touchway-api';
import { IOption } from 'src/services/touchway-base-api';

interface KanbanProviderInterface {
  formats: string[];
  setFormats: React.Dispatch<React.SetStateAction<string[]>>;
  isLoading: boolean;
  data: IOption[];
  hasFilter: boolean;
  searchValue: string; // Added state for filter
  setSearchValue: React.Dispatch<React.SetStateAction<string>>; // Added setter for filter
}

export const KanbanContext = createContext<KanbanProviderInterface>({
  formats: [],
  setFormats: () => {},
  isLoading: true,
  data: [],
  hasFilter: false,
  searchValue: '', // Initialized filter state
  setSearchValue: () => {}, // Initialized filter setter
});

const KanbanProvider: React.FC = ({ children }) => {
  const [hasFilter, setHasFilter] = useState<boolean>(false);
  const [formats, setFormats] = React.useState<string[]>(() => []);
  const [searchValue, setSearchValue] = useState<string>(''); // Initialized filter state

  const { data, isLoading } = useQuery(
    queryKeys.autocompleteControllerTypeMaintenance.get(),
    () => touchwayApi.autocomplete.autocompleteControllerTypeMaintenance(),
    {
      onSuccess({ options }) {
        setFormats(options.map(({ id }) => id));
      },
    },
  );

  const options = useMemo(() => data?.options || [], [data]);

  useEffect(() => {
    if (formats.length !== options.length || searchValue !== '') {
      // Added condition for filter
      setHasFilter(true);
    } else {
      setHasFilter(false);
    }
  }, [formats, options, searchValue]); // Added filter dependency

  const globals: KanbanProviderInterface = {
    data: options,
    formats,
    setFormats,
    isLoading,
    hasFilter,
    searchValue,
    setSearchValue,
  };

  return (
    <KanbanContext.Provider value={globals}>{children}</KanbanContext.Provider>
  );
};

export default KanbanProvider;
