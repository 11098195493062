/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useMemo, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Typography,
} from '@material-ui/core';
import styled, { css, keyframes } from 'styled-components';
import PulsingBadge from 'src/components/PulsingBadge';
import useRealtime from 'src/hooks/firebase/useRealtime';
import { firebaseRealtimeEnum } from 'src/config';
import { IVehicleSimple } from 'src/services/touchway-base-api';
import {
  DEFAULT_PLACEHOLDER,
  useVehiclePhoto,
} from 'src/hooks/useVehiclePhoto';
import { ILocatorFirebaseRealtimeType } from 'src/pages/locators/types/ILocatorFirebaseRealtime.type';
import { LocatorConverter } from 'src/pages/locators/class/Locator';
import { IVehicleFirebaseRealtimeType } from '../types/IGSEFirebaseRealtime.type';
import { GseMaintenances } from './GseMaintenances';

interface GseSocketCardProps {
  vehicle: IVehicleSimple;
  showCard?: boolean;
  showIgnitionStatus?: boolean;
  onClick?: () => void;
  onChangeIgnition?: (value: boolean) => void;
  onChangeBase?: (value: string) => void;
  p?: number;
  showPhoto?: boolean;
  showTelemetry?: boolean;
  showLastUpdate?: boolean;
}

function blinkingEffect() {
  return keyframes`
    50% {
      opacity: 0.15;
    }
  `;
}

interface IBlink {
  enableBlink: boolean;
}

const BlinkingCard = styled(Card)<IBlink>`
  ${({ enableBlink }) =>
    enableBlink &&
    css`
      animation: ${blinkingEffect} 1.33s linear infinite;
    `}
`;

interface AnimatedComponentProps extends IBlink {
  onClick?: () => void;
  elevation?: number;
  style?: React.CSSProperties;
}

export const AnimatedComponent: React.FC<AnimatedComponentProps> = ({
  enableBlink,
  children,
  ...props
}) => (
  <BlinkingCard enableBlink={enableBlink} {...props}>
    {children}
  </BlinkingCard>
);

const MIN = 0;
export const MAINTENANCE1_TIME = 300;
export const MAINTENANCE2_TIME = 5000;
export const MAINTENANCE3_TIME = 10000;

export const normaliseFun = (value: number, max: number) =>
  ((value - MIN) * 100) / (max - MIN);

interface IBlinkState {
  hours_300: false | 'danger' | 'warning';
  hours_5000: false | 'danger' | 'warning';
  hours_10000: false | 'danger' | 'warning';
}

export const GseSocketCard: React.FC<GseSocketCardProps> = ({
  vehicle: gse,
  showCard = true,
  showIgnitionStatus = true,
  onClick,
  onChangeIgnition,
  onChangeBase,
  showPhoto = false,
  showTelemetry = false,
  showLastUpdate = false,
  p = 2,
}) => {
  const [locatorRealtime, setLocatorRealtime] = useState<LocatorConverter>(
    new LocatorConverter(undefined),
  );

  const { images, query } = useVehiclePhoto({
    vhc_id: gse?.vhc_id,
  });

  const [blinkState, setShouldBlinkState] = useState<IBlinkState>({
    hours_300: false,
    hours_5000: false,
    hours_10000: false,
  });

  const gseRealtime = useRealtime<IVehicleFirebaseRealtimeType>({
    collection: firebaseRealtimeEnum.vehicles,
    key: gse?.vhc_id,
    onValueChange: (value) => {
      if (onChangeIgnition) {
        onChangeIgnition(value?.ignition);
      }

      if (onChangeBase) {
        onChangeBase(value?.iata);
      }
    },
  });

  const locator = useRealtime<ILocatorFirebaseRealtimeType>({
    collection: firebaseRealtimeEnum.locators,
    key: gse?.lct_id,
    enabled: showTelemetry || showLastUpdate,
    onValueChange: (value) => {
      setLocatorRealtime(new LocatorConverter(value));
    },
  });

  const horimeter = gseRealtime?.hour_meter || gse.vhc_hour_meter;
  const isDeviceOn = gseRealtime?.ignition;
  const assetUrl = gseRealtime?.asset_url || DEFAULT_PLACEHOLDER;

  function triggerBlinkingEffect(
    key: 'hours_300' | 'hours_5000' | 'hours_10000',
    newValue: false | 'danger' | 'warning',
  ) {
    setShouldBlinkState((prevState) => ({
      ...prevState,
      [key]: newValue,
    }));
  }

  const isAtLeastOneWithAlert = useMemo(
    () => Object.values(blinkState).some((value) => value === 'danger'),
    [blinkState],
  );

  const isAtLeastOneWithWarning = useMemo(
    () => Object.values(blinkState).some((value) => value === 'warning'),
    [blinkState],
  );

  const shouldBlink = isAtLeastOneWithAlert && !!isDeviceOn;

  return (
    <Box p={p}>
      <AnimatedComponent
        onClick={() => onClick && onClick()}
        enableBlink={false}
        elevation={showCard ? 1 : 0}
        style={{
          cursor: onClick ? 'pointer' : 'default',
        }}
      >
        {showPhoto && <CardMedia component="img" image={assetUrl} />}
        <CardHeader
          mb={-2}
          title={
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                flexDirection="row"
              >
                <Typography color="textPrimary" variant="h6">
                  {gseRealtime?.name || gse?.vhc_name}
                </Typography>
                {showIgnitionStatus && (
                  <PulsingBadge
                    variant={
                      isDeviceOn === undefined
                        ? 'danger'
                        : isDeviceOn
                        ? 'success'
                        : 'danger'
                    }
                    withBorder={false}
                    badgeLabel={isDeviceOn ? 'Ligado' : 'Desligado'}
                  />
                )}
              </Box>
            </>
          }
        />
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
            mt={-2}
          >
            {/* <GseSocketRow
              label="Aeronave"
              value={gseRealtime?.tail_number || '---'}
            /> */}
            <GseSocketRow
              label="Localização"
              value={gseRealtime?.iata || '---'}
            />

            <Box mx={-2} my={2}>
              <Divider style={{ borderStyle: 'dotted' }} />
            </Box>

            <Box mb={2}>
              <GseSocketRow
                label="Horímetro"
                value={
                  horimeter
                    ? `${
                        // segundo para hora
                        horimeter.toFixed(2)
                      } horas`
                    : '---'
                }
              />
            </Box>

            {gseRealtime && (
              <GseMaintenances
                ignition={gseRealtime.ignition}
                triggerBlinkingEffect={triggerBlinkingEffect}
                vhc_id={gse?.vhc_id}
              />
            )}

            {locatorRealtime && showTelemetry && (
              <Box display="flex" flexDirection="column" gap={1}>
                <Box mx={-2} my={2}>
                  <Divider style={{ borderStyle: 'dotted' }} />
                </Box>

                <GseSocketRowHeader label="Telemetria" />

                <GseSocketRow
                  label="Bateria"
                  endIcon={
                    <PulsingBadge
                      variant={
                        locatorRealtime.backup_battery_percentage_status.status
                      }
                      badgeLabel={
                        locatorRealtime.backup_battery_percentage_status.label
                      }
                    />
                  }
                />

                <GseSocketRow
                  label="Força do sinal"
                  endIcon={
                    <PulsingBadge
                      variant={locatorRealtime.signal_strength.status}
                      badgeLabel={locatorRealtime.signal_strength.label}
                    />
                  }
                />

                <GseSocketRow
                  label="Qualidade do sinal"
                  endIcon={
                    <PulsingBadge
                      variant={locatorRealtime.signal_quality.status}
                      badgeLabel={locatorRealtime.signal_quality.label}
                    />
                  }
                />

                <GseSocketRow
                  label="Precisão GPS"
                  endIcon={
                    <PulsingBadge
                      variant={locatorRealtime.gps_accuracy.status}
                      badgeLabel={locatorRealtime.gps_accuracy.label}
                    />
                  }
                />
                {/* <GseSocketRow
                  label="HDOP"
                  endIcon={
                    <PulsingBadge
                      badgeLabel={String(locatorRealtime.hdop_description.hdop)}
                      variant={locatorRealtime.hdop_description.status}
                    />
                  }
                /> */}

                {/* <GseSocketRow
                  label="Carregando"
                  endIcon={
                    <PulsingBadge
                      variant={locatorRealtime.chargingStatus.status}
                      badgeLabel={locatorRealtime.chargingStatus.label}
                    />
                  }
                /> */}
                <GseSocketRow
                  label="Energia"
                  endIcon={
                    <PulsingBadge
                      variant={locatorRealtime.externalPowerSupplyStatus.status}
                      badgeLabel={
                        locatorRealtime.externalPowerSupplyStatus.label
                      }
                    />
                  }
                />
                <GseSocketRow
                  label="Voltagem"
                  endIcon={
                    <PulsingBadge
                      variant={locatorRealtime.externalPowerVoltage.status}
                      badgeLabel={locatorRealtime.externalPowerVoltage.label}
                    />
                  }
                />
              </Box>
            )}

            {locatorRealtime && showLastUpdate && (
              <Box display="flex" flexDirection="column" gap={1}>
                <Box mx={-2} my={2}>
                  <Divider style={{ borderStyle: 'dotted' }} />
                </Box>

                <GseSocketRowHeader label="Últimas Atualizações" />

                <GseSocketRow
                  label="Localização"
                  endIcon={
                    <PulsingBadge
                      variant={locatorRealtime.geolocation_date_status}
                      badgeLabel={locatorRealtime.geolocation_date_formatted}
                    />
                  }
                />
                <GseSocketRow
                  label="Heartbeat"
                  endIcon={
                    <PulsingBadge
                      variant={locatorRealtime.heartbeat_date_status}
                      badgeLabel={locatorRealtime.heartbeat_date_formatted}
                    />
                  }
                />
                <GseSocketRow
                  label="Info"
                  endIcon={
                    <PulsingBadge
                      variant="success"
                      badgeLabel={locatorRealtime.info_date_formatted}
                    />
                  }
                />
                <GseSocketRow
                  label="Ligou"
                  endIcon={
                    <PulsingBadge
                      variant={locatorRealtime.ignition_on_date_status}
                      badgeLabel={locatorRealtime.ignition_on_date_formatted}
                    />
                  }
                />
                <GseSocketRow
                  label="Desligou"
                  endIcon={
                    <PulsingBadge
                      variant={locatorRealtime.ignition_off_date_status}
                      badgeLabel={locatorRealtime.ignition_off_date_formatted}
                    />
                  }
                />
              </Box>
            )}
          </Box>
        </CardContent>
      </AnimatedComponent>
    </Box>
  );
};

export default GseSocketCard;

interface IGseSocketRow {
  label: string;
  value?: string | number | undefined;
  icon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

export const GseSocketRow: React.FC<IGseSocketRow> = ({
  label,
  value,
  icon,
  endIcon,
}) => (
  <Box display="flex" justifyContent="space-between" flexDirection="row">
    {icon || null}
    <Typography color="textPrimary" variant="body1">
      {label}
    </Typography>

    <Box display="flex" flexDirection="row" alignItems="center">
      <Typography color="textPrimary" variant="body1">
        {value}
      </Typography>
      {endIcon || null}
    </Box>
  </Box>
);

export const GseSocketRowHeader: React.FC<{ label: string }> = ({ label }) => (
  <Typography color="textPrimary" variant="overline">
    {label}
  </Typography>
);
