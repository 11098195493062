import type { FC } from 'react';
import { Grid } from '@material-ui/core';
import { Page } from 'src/components/layouts/Page';
import VehicleList from './VehicleList';

const VehiclesTab: FC = () => (
  <>
    <Page
      title="Equipamentos"
      // query={gseQuery}
      breadcrumbs={[
        { to: '/', label: 'Painel' },
        { to: 'veiculos', label: 'Equipamentos' },
        // { to: localGSE?.vhc_id, label: localGSE?.vhc_name },
      ]}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <VehicleList />
        </Grid>
      </Grid>
    </Page>
  </>
);

export default VehiclesTab;
