import { useState } from 'react';
import type { ChangeEvent, FC, KeyboardEvent } from 'react';

import toast from 'react-hot-toast';
import { Avatar, Box, TextField } from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';
import { addComment } from '../../../slices/kanban';
import { useDispatch } from '../../../store';

interface KanbanCommentAddProps {
  cardId: string;
}

const KanbanCommentAdd: FC<KanbanCommentAddProps> = (props) => {
  const { cardId, ...other } = props;
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [message, setMessage] = useState<string>('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setMessage(event.target.value);
  };

  const handleKeyUp = async (
    event: KeyboardEvent<HTMLInputElement>,
  ): Promise<void> => {
    try {
      if (event.code === 'Enter' && message) {
        await dispatch(addComment(cardId, message));
        setMessage('');
        toast.success('Nota adicionada com sucesso');
      }
    } catch (err) {
      console.error(err);
      toast.error('Algo deu errado');
    }
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
      }}
      {...other}
    >
      <Avatar src={user?.usu_name} sx={{ mr: 2 }} />
      <TextField
        fullWidth
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        placeholder="Adicione uma nota"
        size="small"
        value={message}
        variant="outlined"
      />
    </Box>
  );
};

export default KanbanCommentAdd;
