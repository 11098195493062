interface AxiosError {
  response?: {
    data?: {
      message?: string[] | string;
    };
  };
}

export function getServerErrorMessage(err: AxiosError | Error): string {
  // Type guard to ensure we're working with AxiosError
  if (
    'response' in err &&
    err.response &&
    err.response.data &&
    Array.isArray(err.response.data.message)
  ) {
    return err.response.data.message.join('\n');
  }

  // Default to returning the entire error if the structure is not as expected
  return 'An error occurred, please try again later.';
}

export function getServerErrorArrayMessage(
  err: AxiosError | Error | unknown,
): string[] {
  if (isAxiosError(err)) {
    if (err.response && err.response.data) {
      const { message } = err.response.data;
      if (Array.isArray(message)) {
        return message;
      }
      if (typeof message === 'string') {
        return [message];
      }
    }

    if (err.response && typeof err.response.data === 'string') {
      return [err.response.data];
    }
  }

  // Default to returning a generic error message
  return ['An error occurred, please try again later.'];
}

// Type guard to check if the error is an AxiosError
function isAxiosError(error: any): error is AxiosError {
  return error && typeof error === 'object' && 'isAxiosError' in error;
}
