import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Skeleton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { useState } from 'react';
import { useVehiclePhoto } from 'src/hooks/useVehiclePhoto';
import { ImageTpinameEnum, getImageName } from 'src/utils/getImageName';

interface DevicePhotoProps {
  vhc_id: string | undefined;
  image: ImageTpinameEnum;
  type?: 'avatar' | 'img';
  width?: number | string;
  height?: number | string;
}

export const DevicePhotoAvatar: React.FC<DevicePhotoProps> = ({
  vhc_id,
  image,
  type = 'avatar',
  width = 40,
  height = 40,
}) => {
  const [open, setOpen] = useState(false);

  const { images, query } = useVehiclePhoto({ vhc_id });

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  if (query.isLoading) {
    return <Skeleton variant="circular" width={40} height={40} />;
  }

  return (
    <>
      <Box onClick={handleOpen}>
        {type === 'avatar' ? (
          <Avatar alt={image} src={images[image]} style={{ width, height }} />
        ) : (
          <img
            alt={getImageName({ tpi_name: image })}
            src={images[image]}
            style={{ width: '100%', height: 'auto', borderRadius: 4 }}
          />
        )}
      </Box>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          style={{ position: 'absolute', right: 20, top: 0 }} // Adicione padding aqui
        >
          <Close />
        </IconButton>
        <Box
          style={{
            overflowX: 'hidden', // Adicione esta linha para remover o scrollbar horizontal
          }}
        >
          <img
            alt={getImageName({ tpi_name: image })}
            src={images[image]}
            style={{ width: '100%', height: 'auto' }}
          />
        </Box>
        <DialogContent>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={2}
            mb={2}
          >
            <Typography component="figcaption">
              {getImageName({ tpi_name: image })}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
