import { subDays } from 'date-fns';
import {
  IMaintenanceDetailedResponse,
  IAutocompleteDefaultResponse,
} from 'src/services/touchway-base-api';
import { Board } from 'src/types/kanban';

export function convertMaintenanceToBoard(
  payload: IMaintenanceDetailedResponse,
  columns: IAutocompleteDefaultResponse,
): Board {
  const now = new Date();

  return {
    cards: payload.maintenances.map((maintenance) => {
      return {
        id: maintenance.vmp_id,
        attachments: [],
        checklists: [],
        columnId: maintenance.maintenanceStatus.mts_id,
        comments: maintenance.vmp_notes.map((note, i) => ({
          id: `${maintenance.mts_id}-${i}`,
          cardId: maintenance.mts_id,
          createdAt: subDays(now, 5).getTime(),
          memberId: '5e887ac47eed253091be10cb',
          message: note,
        })),
        cover: null,
        description: maintenance.typeMaintenance.tpm_description,
        due: subDays(now, 5).getTime(),
        isSubscribed: false,
        memberIds: [],
        name: `${maintenance?.vehicle?.vhc_name}`,
        extraProps: maintenance,
      };
    }),
    columns: columns.options.map(({ id, value }) => ({
      id: id.toString(),
      name: value,
      cardIds: payload.maintenances
        .filter((maintenance) => maintenance.maintenanceStatus.mts_id === id)
        .map((maintenance) => maintenance.vmp_id.toString()),
    })),
    members: [],
  };
}
