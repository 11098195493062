/* eslint-disable react/no-array-index-key */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  Grid,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from '@material-ui/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { differenceInDays, format } from 'date-fns';
import React, { useState } from 'react';
import EmptyPlaceholder from 'src/components/layouts/EmptyPlaceholder';
import { queryKeys } from 'src/config/query';
import { useTouchwayApi } from 'src/services/touchway-api';
import {
  IEquipmentListDetailedResponse,
  IEquipmentMaintenance,
  IMaintenanceStatusMtsConstantEnum,
} from 'src/services/touchway-base-api';
import { RenderMaintenanceStatusEnum } from 'src/components/RenderMaintenanceStatusEnum';
import { ExpandMore } from '@material-ui/icons';
import toast from 'react-hot-toast';
import { getServerErrorArrayMessage } from 'src/utils/get-server-error-message';
import { useConfirm } from 'material-ui-confirm';
import { utcToZonedTime } from 'date-fns-tz';
import { ptBR } from 'date-fns/locale';
import { DEFAULT_TIMEZONE } from 'src/config';
import EquipmentMaintenanceForm from '../forms/EquipmentMaintenanceForm';
import EquipmentMaintenanceStatusChange from '../forms/EquipmentMaintenanceStatusChange';
import {
  EquipmentTabsEnum,
  useEquipmentTabs,
} from '../provider/EquipmentContext';

interface EquipmentMaintenanceCardDetailsProps {
  eqp_id: string;
  filter?: IMaintenanceStatusMtsConstantEnum[];
  showCreateOnEmpty?: boolean;
}

export const EquipmentMaintenanceCardDetails: React.FC<EquipmentMaintenanceCardDetailsProps> =
  ({ eqp_id, filter, showCreateOnEmpty = true }) => {
    const { changeTab } = useEquipmentTabs();
    const { api } = useTouchwayApi();

    const eclParams = {
      eqp_id,
      with_relations: true,
    };

    const equipmentQuery = useQuery(queryKeys.equipment.get(eclParams), () =>
      api.equipment.equipmentControllerList(eclParams),
    );

    if (equipmentQuery.isLoading) {
      return <Skeleton />;
    }

    const eqp = equipmentQuery.data;
    const typeEqp =
      (eqp as any).equipments && (eqp as any).equipments.length > 0
        ? (eqp as IEquipmentListDetailedResponse).equipments[0]
        : undefined;

    if (!typeEqp) {
      return (
        <EmptyPlaceholder title="Equipamento não encontrado" subtitle="" />
      );
    }

    const filteredMaintenances = typeEqp.maintenances.filter((maintenance) => {
      if (!filter) {
        return true;
      }

      return filter.includes(maintenance.maintenanceStatus.mts_constant);
    });

    const NoMaintenance = () => (
      <EmptyPlaceholder
        title="Nenhum histórico de manutenção"
        subtitle="Clique no botão abaixo para visualizar todas as manutenções que estão plenadas ou em andamento"
        showImage={false}
        buttonTitle="Visualizar manutenções"
        onClick={() => changeTab(EquipmentTabsEnum.maintenance)}
      />
    );

    if (filteredMaintenances.length === 0) {
      return showCreateOnEmpty ? (
        <EquipmentMaintenanceForm
          eqpMaintenance={null}
          eqp_id={eqp_id}
          allowChangeEquipment={false}
          editableFields={['eqm_start', 'mts_id', 'tem_id']}
        />
      ) : (
        <NoMaintenance />
      );
    }

    return (
      <>
        {typeEqp.maintenances.length === 0 ? (
          <>
            {showCreateOnEmpty ? (
              <EquipmentMaintenanceForm
                eqpMaintenance={null}
                eqp_id={eqp_id}
                allowChangeEquipment={false}
                editableFields={['eqm_start', 'mts_id', 'tem_id']}
              />
            ) : (
              <NoMaintenance />
            )}
          </>
        ) : (
          <Stack spacing={2}>
            {filteredMaintenances.map((maintenance) => (
              <RenderMaintenances
                maintenance={maintenance}
                key={maintenance.eqm_id}
              />
            ))}
          </Stack>
        )}
      </>
    );
  };

interface RenderMaintenancesProps {
  maintenance: IEquipmentMaintenance;
}

const RenderMaintenances: React.FunctionComponent<RenderMaintenancesProps> = ({
  maintenance,
}) => {
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const [openTypeMan, setOpenTypeMan] = useState<boolean>(false);
  const { api } = useTouchwayApi();

  const params = { tem_id: maintenance.tem_id };
  const detailedMaintenanceQuery = useQuery(
    queryKeys.typeEquipmentMaintenance.get(params),
    () =>
      api.typeEquipmentMaintenance.typeEquipmentMaintenanceControllerList(
        params,
      ),
  );

  // const statusQuery = useQuery({
  //   queryKey: queryKeys.autocompleteControllerStatusMaintenance.get(),
  //   queryFn: () => api.autocomplete.autocompleteControllerStatusMaintenance(),
  // });

  const deleteMutation = useMutation({
    mutationFn: api.equipment.equipmentControllerMaintenanceDelete,
    onSuccess() {
      toast.success('Manutenção excluída com sucesso');
      queryClient.invalidateQueries(queryKeys.equipment.get());
      queryClient.invalidateQueries(
        queryKeys.equipment.get({
          eqp_id: maintenance.eqp_id,
        }),
      );
    },
    onError: (error) =>
      getServerErrorArrayMessage(error).forEach((message) =>
        toast.error(message),
      ),
  });

  const arquiveMutation = useMutation({
    mutationFn: api.equipment.equipmentControllerMaintenanceArchived,
    onSuccess() {
      toast.success('Manutenção arquivada com sucesso');
      queryClient.invalidateQueries(queryKeys.equipment.get());
      queryClient.invalidateQueries(
        queryKeys.equipment.get({
          eqp_id: maintenance.eqp_id,
        }),
      );
    },
    onError: (error) =>
      getServerErrorArrayMessage(error).forEach((message) =>
        toast.error(message),
      ),
  });

  if (detailedMaintenanceQuery.isLoading) {
    return <Skeleton />;
  }

  const detailedMaintenance =
    detailedMaintenanceQuery.data?.type_equipment_maintenances[0] || null;

  if (!detailedMaintenance) {
    return <EmptyPlaceholder title="Manutenção não encontrada" subtitle="" />;
  }

  const isDone =
    maintenance.maintenanceStatus.mts_constant ===
    IMaintenanceStatusMtsConstantEnum.DONE;

  function handleDelete() {
    confirm({
      title: 'Excluir manutenção',
      description: `Deseja realmente excluir a manutenção de ${format(
        new Date(maintenance.eqm_forecast),
        'dd/MM/yyyy',
      )}?`,
    }).then(() => {
      deleteMutation.mutate({
        eqm_id: maintenance.eqm_id,
      });
    });
  }

  function handleArchive() {
    if (!maintenance.eqm_id) {
      toast.error('Manutenção não encontrada');
      return;
    }

    confirm({
      title: 'Arquivar manutenção',
      description: `Deseja realmente arquivar a manutenção de ${format(
        new Date(maintenance.eqm_forecast),
        'dd/MM/yyyy',
      )}?`,
    }).then(() => {
      arquiveMutation.mutate({
        eqm_ids: [maintenance.eqm_id!],
      });
    });
  }

  const formattedDate = format(
    utcToZonedTime(new Date(maintenance.eqm_forecast), DEFAULT_TIMEZONE),
    'dd/MM/yyyy',
    { locale: ptBR },
  );

  const daysDiff = differenceInDays(
    utcToZonedTime(new Date(maintenance.eqm_forecast), DEFAULT_TIMEZONE),
    utcToZonedTime(new Date(), DEFAULT_TIMEZONE),
  );

  return (
    <>
      <Card key={detailedMaintenance.tem_id}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="caption">Previsão de execução</Typography>
              <Typography variant="body1">
                {/* {maintenance.eqm_forecast
                  ? format(new Date(maintenance.eqm_forecast), 'dd/MM/yyyy')
                  : 'Não informado'}{' '} */}
                {formattedDate} {daysDiff ? `(em ${daysDiff} dias)` : ''}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption">Status</Typography>
              <Typography variant="body1">
                <RenderMaintenanceStatusEnum
                  maintenanceStatus={maintenance.maintenanceStatus}
                />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption">
                Frequência de manutenção
              </Typography>
              <Typography variant="body1">
                {detailedMaintenance.tem_name}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption">Executado em</Typography>
              <Typography variant="body1">
                {maintenance.eqm_executed
                  ? format(new Date(maintenance.eqm_executed), 'dd/MM/yyyy')
                  : 'Ainda não executado'}
              </Typography>
            </Grid>
            {/* <Grid item xs={12}>
            <Typography variant="caption">Arquivado</Typography>
            <Typography variant="body1">
              {maintenance.eqm_archived ? 'Sim' : 'Não'}
            </Typography>
          </Grid> */}
            {maintenance.created_at && (
              <Grid item xs={6}>
                <Typography variant="caption">Criado em</Typography>
                <Typography variant="body1">
                  {format(new Date(maintenance.created_at), 'dd/MM/yyyy HH:mm')}
                </Typography>
              </Grid>
            )}
            {maintenance.updated_at && (
              <Grid item xs={6}>
                <Typography variant="caption">Última atualização</Typography>
                <Typography variant="body1">
                  {format(new Date(maintenance.updated_at), 'dd/MM/yyyy HH:mm')}
                </Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
        <CardActions>
          <EquipmentMaintenanceStatusChange
            maintenance={maintenance}
            inDialog
          />
          <Button
            style={{
              display: isDone ? 'none' : 'block',
            }}
            variant="contained"
            color="primary"
            onClick={() => setOpenTypeMan(true)}
          >
            Editar
          </Button>
          <Button
            onClick={handleArchive}
            disabled
            variant="outlined"
            color="primary"
          >
            Arquivar manutenção
          </Button>
          <Button onClick={handleDelete} variant="contained" color="secondary">
            Excluir manutenção
          </Button>
        </CardActions>
        {/* <CustomLinearProgress
          value={remainingPercentage}
          maintence={100}
          color={remainingPercentage ? 'primary' : 'secondary'}
          variant={remainingPercentage ? 'determinate' : 'determinate'}
        /> */}
        <Box mt={2} />
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              Notas de manutenção (
              {maintenance.eqm_notes?.length !== 0
                ? (maintenance.eqm_notes || []).length
                : 0}
              )
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {maintenance.eqm_notes && maintenance.eqm_notes.length > 0 ? (
              <List>
                {maintenance.eqm_notes.map((note, index) => (
                  <ListItem key={`note-${index}`}>
                    <ListItemText primary={`#${index + 1}: ${note}`} />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography>Nenhuma nota de manutenção cadastrada</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Card>
      <Dialog open={openTypeMan} onClose={() => setOpenTypeMan(false)}>
        <EquipmentMaintenanceForm
          editableFields={['eqm_start', 'eqm_executed', 'tem_id', 'eqm_notes']}
          eqpMaintenance={maintenance}
          eqp_id={maintenance.eqp_id}
          allowChangeEquipment={false}
        />
      </Dialog>
    </>
  );
};
