import { useCallback, useEffect, useState } from 'react';
import { firebaseRealtimeEnum } from 'src/config';
import { firebaseAdapter } from 'src/lib/firebase';
import { IVehicleFirebaseRealtimeType } from 'src/pages/gses/types/IGSEFirebaseRealtime.type';
import { IEsperDeviceLocationDto } from 'src/services/touchway-base-api';

interface IUseRealtimeProps<T> {
  collection: firebaseRealtimeEnum;
  key?: 'root' | string;
  disable?: boolean;
  enabled?: boolean;
  onValueChange?: (value: T) => void;
}

interface IUseUpdateRealtimeProps {
  collection: firebaseRealtimeEnum;
  key?: 'root' | string;
}

export interface IGseFirebaseCollection {
  [key: string]: IVehicleFirebaseRealtimeType;
}

export interface ITechnicalFirebaseCollection {
  [key: string]: IEsperDeviceLocationDto;
}

export default function useRealtime<T>({
  collection,
  key: path = '',
  disable = false,
  onValueChange,
  enabled = true,
}: IUseRealtimeProps<T>): T | null {
  const prefix = process.env.REACT_APP_FB_DB_PREFIX;
  if (!prefix) throw new Error('REACT_APP_FB_DB_PREFIX is not defined');

  const [value, setValue] = useState<T | null>(null);

  useEffect(() => {
    if (!enabled) {
      // Check if the feature is enabled
      return;
    }

    if (disable) {
      return;
    }

    const ref = getRefPath(prefix, collection, path);
    const gsesRef = firebaseAdapter.database().ref(ref);

    gsesRef.on('value', (snapshot) => {
      const data = snapshot.val();
      setValue(data);

      if (onValueChange) {
        onValueChange(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection, disable, path, prefix, enabled]);

  return value;
}

export function useUpdateRealtime<T>({
  collection,
  key: path = '',
}: IUseUpdateRealtimeProps) {
  const prefix = process.env.REACT_APP_FB_DB_PREFIX;
  if (!prefix) throw new Error('REACT_APP_FB_DB_PREFIX is not defined');

  const updateValue = useCallback(
    (value: T) => {
      const ref = getRefPath(prefix, collection, path);
      const gsesRef = firebaseAdapter.database().ref(ref);
      gsesRef.set(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collection, path, prefix],
  );

  return updateValue;
}

function getRefPath(
  prefix: string,
  collection: firebaseRealtimeEnum,
  key: string,
): string {
  const path = `/${prefix}/${collection}/${key}`;
  return path;
}
