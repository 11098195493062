/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Typography,
} from '@material-ui/core';
import styled, { css, keyframes } from 'styled-components';
import { format } from 'date-fns';
import PulsingBadge from 'src/components/PulsingBadge';
import { DEFAULT_PLACEHOLDER } from 'src/hooks/useVehiclePhoto';
import { IVehicleFirebaseRealtimeType } from '../types/IGSEFirebaseRealtime.type';

interface GseStaticCardProps {
  vehicle: Partial<IVehicleFirebaseRealtimeType>;
  showCard?: boolean;
  showIgnitionStatus?: boolean;
  onClick?: () => void;
}

function blinkingEffect() {
  return keyframes`
    50% {
      opacity: 0.15;
    }
  `;
}

interface IBlink {
  enableBlink: boolean;
}

const AnimatedComponent = styled(Card)<IBlink>`
  ${({ enableBlink }) =>
    enableBlink &&
    css`
      animation: ${blinkingEffect} 1.33s linear infinite;
    `}
`;

const MIN = 0;
export const MAINTENANCE1_TIME = 300;
export const MAINTENANCE2_TIME = 5000;
export const MAINTENANCE3_TIME = 10000;

export const normaliseFun = (value: number, max: number) =>
  ((value - MIN) * 100) / (max - MIN);

export const GseStaticCard: React.FC<GseStaticCardProps> = ({
  vehicle: gse,
  showCard = true,
  onClick,
}) => {
  const {
    hour_meter,
    ignition,
    last_update,
    latitude,
    longitude,
    name,
    type,
    asset_url,
  } = gse;

  return (
    <Box>
      <AnimatedComponent
        onClick={() => onClick && onClick()}
        enableBlink={false}
        elevation={showCard ? 1 : 0}
        style={{
          cursor: onClick ? 'pointer' : 'default',
        }}
      >
        <Box p={showCard ? 2 : 0}>
          <CardMedia
            height="200px"
            src={asset_url || DEFAULT_PLACEHOLDER}
            component="img"
          />
          <CardHeader
            mb={-2}
            title={
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="row"
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection="column"
                  >
                    <Typography color="textPrimary" variant="h6">
                      {name || '---'}
                    </Typography>
                    <Typography color="textPrimary" variant="body2">
                      {type || '---'}
                    </Typography>
                  </Box>
                  <PulsingBadge
                    variant={
                      ignition === undefined
                        ? 'danger'
                        : ignition
                        ? 'success'
                        : 'danger'
                    }
                    withBorder={false}
                    badgeLabel={ignition ? 'Ligado' : 'Desligado'}
                  />
                </Box>
              </>
            }
          />
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
              mt={-2}
            >
              <Box mx={-2} my={2}>
                <Divider style={{ borderStyle: 'dotted' }} />
              </Box>

              <Box mb={2}>
                <GseSocketRow
                  label="Horímetro"
                  value={`${
                    // segundo para hora
                    hour_meter ? hour_meter.toFixed(2) : '---'
                  } h`}
                />

                <GseSocketRow label="Latitude" value={latitude || '---'} />

                <GseSocketRow label="Longitude" value={longitude || '---'} />

                {/* <GseSocketRow label="Tipo" value={type || '---'} /> */}

                <Box mx={-2} my={2}>
                  <Divider style={{ borderStyle: 'dotted' }} />
                </Box>

                <GseSocketRow
                  label="Última atualização"
                  value={
                    last_update
                      ? format(new Date(last_update), 'dd/MM/yyyy HH:mm')
                      : ''
                  }
                />

                {/* tail_number, */}
                {/* type, */}
              </Box>
            </Box>
          </CardContent>
        </Box>
      </AnimatedComponent>
    </Box>
  );
};

export default GseStaticCard;

interface IGseSocketRow {
  label: string;
  value: string | number | undefined;
  icon?: React.ReactNode;
}

export const GseSocketRow: React.FC<IGseSocketRow> = ({
  label,
  value,
  icon,
}) => (
  <Box display="flex" justifyContent="space-between" flexDirection="row">
    {icon || null}
    <Typography color="textPrimary" variant="body1">
      {label}
    </Typography>
    <Typography color="textPrimary" variant="body1">
      {value || '---'}
    </Typography>
  </Box>
);
