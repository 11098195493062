/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-alert */
import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Box, Button, Stack, TextField, Typography } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import MutatingButton from 'src/components/forms/MutatingButton';
import {
  IEquipmentMaintenance,
  IEquipmentMaintenanceSimpleOrDetailed,
  IEquipmentMaintenanceUpsertDto,
  IMaintenanceStatusMtsConstantEnum,
} from 'src/services/touchway-base-api';
import { useTouchwayApi } from 'src/services/touchway-api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/config/query';
import { yupResolver } from '@hookform/resolvers/yup';
import { SchemaOf, array, bool, date, object, string } from 'yup';
import FormSection from 'src/components/forms/FormSection';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import MobileDatePicker from '@material-ui/lab/MobileDatePicker';
import { SelectController } from 'src/components/forms/SelectController';
import EquipmentStatusMaintenanceSelect from 'src/pages/type-equipment-maintenance/TypeEquipmentStatusMaintenanceSelectCreate';
import { format } from 'date-fns';
import { useDispatch } from 'src/store';
import { closeModal, getEvents } from 'src/slices/calendar';
import { getServerErrorArrayMessage } from 'src/utils/get-server-error-message';
import { useConfirm } from 'material-ui-confirm';

type KeysIEquipmentMaintenanceUpsertDto = keyof IEquipmentMaintenanceUpsertDto;

interface EquipmentFormInterMaintenanceface {
  eqpMaintenance: IEquipmentMaintenance | null;
  eqp_id: string;
  onSuccessCallback?: (data: IEquipmentMaintenanceSimpleOrDetailed) => void;
  allowChangeEquipment?: boolean;
  editableFields?: KeysIEquipmentMaintenanceUpsertDto[];
  personalization?: {
    title: string;
    description: string;
    submitText: string;
  };
}

const EquipmentMaintenanceForm: React.FC<EquipmentFormInterMaintenanceface> = ({
  eqpMaintenance,
  eqp_id,
  onSuccessCallback,
  allowChangeEquipment = true,
  personalization,
  editableFields = ['mts_id', 'eqp_id'],
}) => {
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryCache = useQueryClient();
  const { api } = useTouchwayApi();
  const isEditForm = !!eqpMaintenance?.eqm_id;

  // @ts-ignore
  const schema: SchemaOf<IEquipmentMaintenanceUpsertDto> = object({
    eqp_id: string().required(),
    eqm_id: string().nullable(),
    tem_id: string().nullable(),
    mts_id: string().nullable(),
    eqm_notes: array().of(string().nullable()).nullable(),
    eqm_start: date().nullable(),
    eqm_executed: string().nullable(),
    eqm_archived: bool().nullable(),
  });

  const mtsQuery = useQuery({
    queryFn: () => api.autocomplete.autocompleteControllerStatusMaintenance(),
    queryKey: queryKeys.autocompleteControllerStatusMaintenance.get(),
  });

  const eqpParams = { eqp_id };
  const eqpQuery = useQuery({
    queryFn: () => api.equipment.equipmentControllerList(eqpParams),
    queryKey: queryKeys.equipment.get(eqpParams),
  });
  const allEqpQuery = useQuery({
    queryFn: () => api.equipment.equipmentControllerList(),
    queryKey: queryKeys.equipment.get(),
  });
  const defaultEqp = eqpQuery.data?.equipments
    ? eqpQuery.data.equipments[0]
    : undefined;
  const allEqp = allEqpQuery.data?.equipments || [];

  function clearCache() {
    dispatch(getEvents());
    queryCache.refetchQueries(queryKeys.equipment.get(eqpParams));
    queryCache.refetchQueries({
      predicate(query) {
        return query.queryKey.includes('equipment');
      },
    });
    queryCache.refetchQueries({
      predicate(query) {
        return query.queryKey.includes('equipments');
      },
    });
  }

  const mutateEdit = useMutation(
    api.equipment.equipmentControllerMaintenanceUpsert,
    {
      onSuccess: (data) => {
        dispatch(closeModal());
        clearCache();
        toast.success('Manutenção salva com sucesso!');
        return onSuccessCallback && onSuccessCallback(data);
      },
      onError: (error) =>
        getServerErrorArrayMessage(error).forEach((message) =>
          toast.error(message),
        ),
    },
  );

  const deleteMutation = useMutation({
    mutationFn: api.equipment.equipmentControllerMaintenanceDelete,
    onSuccess() {
      toast.success('Manutenção excluída com sucesso!');
      dispatch(closeModal());
      clearCache();
    },
    onError: (error) =>
      getServerErrorArrayMessage(error).forEach((message) =>
        toast.error(message),
      ),
  });

  const { register, handleSubmit, control, watch, setValue } =
    useForm<IEquipmentMaintenanceUpsertDto>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      // @ts-ignore
      resolver: yupResolver(schema),
      defaultValues: {
        eqm_executed: eqpMaintenance?.eqm_executed || undefined,
        eqm_forecast: eqpMaintenance?.eqm_forecast || undefined,
        eqm_id: eqpMaintenance?.eqm_id || undefined,
        eqm_notes: eqpMaintenance?.eqm_notes || [],
        eqm_start: eqpMaintenance?.eqm_start || new Date().toISOString(),
        eqp_id: eqpMaintenance?.eqp_id || eqp_id,
        mts_id: eqpMaintenance?.mts_id || undefined,
        tem_id: eqpMaintenance?.tem_id || undefined,
        eqm_archived: eqpMaintenance?.eqm_archived || false,
      },
    });

  useEffect(() => {
    if (defaultEqp?.eqp_id) {
      setValue('eqp_id', defaultEqp.eqp_id);
    }
  }, [defaultEqp, setValue]);

  useEffect(() => {
    const planned = mtsQuery.data?.options.find(
      ({ value }) => value === 'Planejada',
    );

    if (planned) {
      setValue('mts_id', planned.id);
    }
  }, [mtsQuery.data, setValue]);

  const handleEditEquipment = (data: IEquipmentMaintenanceUpsertDto) =>
    mutateEdit.mutateAsync(data, {
      onError: (error) =>
        getServerErrorArrayMessage(error).forEach((message) =>
          toast.error(message),
        ),
      onSuccess: (_data) => {
        toast.success('Equipamento editado com sucesso!');
        onSuccessCallback?.(_data);
        clearCache();
      },
      onSettled: () => {},
    });

  function handleCreateEquipment(data: IEquipmentMaintenanceUpsertDto) {
    mutateEdit.mutateAsync(data, {
      onError: (error) =>
        getServerErrorArrayMessage(error).forEach((message) =>
          toast.error(message),
        ),
      onSuccess: (response) => {
        toast.success('Equipamento criado com sucesso!');
        clearCache();
        onSuccessCallback?.(response);
        navigate('/equipamentos');
      },
    });
  }

  const onSubmit = async (data: IEquipmentMaintenanceUpsertDto) => {
    if (!data.tem_id) {
      toast.error('Selecione a frequência de manutenção');
      return;
    }

    const { type_equipment_maintenances } =
      await api.typeEquipmentMaintenance.typeEquipmentMaintenanceControllerList(
        {
          tem_id: data.tem_id,
        },
      );
    const maintenanceType = type_equipment_maintenances[0];
    if (!maintenanceType) {
      toast.error('Tipo de manutenção não encontrado');
      return;
    }

    if (!data.eqm_start) {
      toast.error('Selecione a data de início');
      return;
    }

    const formattedData: IEquipmentMaintenanceUpsertDto = {
      ...data,
      eqm_start: format(new Date(data.eqm_start), 'yyyy-MM-dd'),
      eqm_executed: data.eqm_executed
        ? format(new Date(data.eqm_executed), 'yyyy-MM-dd')
        : null,
    };

    if (isEditForm) {
      await handleEditEquipment(formattedData);
      clearCache();
    } else {
      await handleCreateEquipment(formattedData);
      clearCache();
    }
  };

  const crudTitle = isEditForm ? 'Editar' : 'Criar';
  const _title = personalization?.title || crudTitle;

  const crudDescription =
    'Descreva o equipamento e adicione informações adicionais.';
  const _description = personalization?.description || crudDescription;

  const _submitText = personalization?.submitText || crudTitle;

  // TODO: change from string to constant
  const canEditExecutedDate =
    eqpMaintenance?.maintenanceStatus?.mts_constant ===
      IMaintenanceStatusMtsConstantEnum.DONE ||
    mtsQuery.data?.options.find(({ value }) => value === 'Concluída')?.id ===
      watch('mts_id');

  const { fields, append } = useFieldArray<IEquipmentMaintenanceUpsertDto>({
    control,
    // @ts-ignore
    name: 'eqm_notes',
  });

  function handleDelete() {
    confirm({
      title: 'Excluir manutenção',
      description: 'Deseja realmente excluir a manutenção?',
    }).then(() => {
      deleteMutation.mutate({
        eqm_id: eqpMaintenance?.eqm_id,
      });
    });
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormSection
          title={_title}
          description={
            <Stack>
              <Typography variant="body2">{_description}</Typography>
            </Stack>
          }
          actions={
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                style={{ display: isEditForm ? 'block' : 'none' }}
                onClick={() => {
                  navigate(`/equipamentos/${eqp_id}`);
                }}
              >
                Ver equipamento
              </Button>
              <MutatingButton
                startIcon={<Save fontSize="small" />}
                type="submit"
                variant="contained"
                disabled={mutateEdit.isLoading}
                loadingText="Salvando..."
              >
                {_submitText}
              </MutatingButton>

              <Button
                style={{ display: isEditForm ? 'block' : 'none' }}
                variant="contained"
                color="secondary"
                onClick={handleDelete}
              >
                Excluir manutenção
              </Button>
            </Stack>
          }
        >
          <Stack spacing={3}>
            <RenderBox allowedFields={editableFields} field="eqm_start">
              <MobileDatePicker
                label="Data do ínicio da manutenção"
                inputFormat="dd/MM/yyyy"
                onChange={(newDate) =>
                  setValue('eqm_start', newDate ?? undefined)
                }
                renderInput={(inputProps) => (
                  <TextField variant="outlined" {...inputProps} />
                )}
                value={watch('eqm_start')}
              />
            </RenderBox>

            <RenderBox allowedFields={editableFields} field="mts_id">
              <SelectController
                control={control}
                name="mts_id"
                label="Status da manutenção"
                values={(mtsQuery.data?.options || []).map(({ id, value }) => ({
                  key: id,
                  label: value,
                }))}
                loading={mtsQuery.isLoading}
                defaultValue={watch('mts_id')}
                rules={{ required: true }}
                helperMessage="Selecione o status da manutenção"
              />
            </RenderBox>

            <Box sx={{ display: allowChangeEquipment ? 'block' : 'none' }}>
              <RenderBox allowedFields={editableFields} field="eqp_id">
                <SelectController
                  control={control}
                  name="eqp_id"
                  label="Equipamento"
                  values={allEqp.map((eqpMap) => ({
                    key: String(eqpMap.eqp_id),
                    label: eqpMap.eqp_name,
                  }))}
                  disabled={!allowChangeEquipment}
                  defaultValue={watch('eqp_id')}
                  rules={{ required: true }}
                  helperMessage="Selecione o equipamento"
                />
              </RenderBox>
            </Box>

            <RenderBox
              allowedFields={editableFields}
              otherConditions={[canEditExecutedDate]}
              field="eqm_executed"
            >
              <Stack>
                <MobileDatePicker
                  label="Data de execução"
                  inputFormat="dd/MM/yyyy"
                  onChange={(newDate) => setValue('eqm_executed', newDate)}
                  renderInput={(inputProps) => (
                    <TextField variant="outlined" {...inputProps} />
                  )}
                  value={watch('eqm_executed')}
                />
                <Box mt={-2} ml={2}>
                  <Typography variant="caption" color="textSecondary">
                    Data de quando essa manutenção foi executada, a próxima
                    manutenção será calculada a partir dessa data com a
                  </Typography>
                </Box>
              </Stack>
            </RenderBox>

            <RenderBox allowedFields={editableFields} field="tem_id">
              <EquipmentStatusMaintenanceSelect
                defaultValue={eqpMaintenance?.tem_id}
                onChange={(value) => setValue('tem_id', String(value))}
              />
            </RenderBox>

            <RenderBox allowedFields={editableFields} field="eqm_notes">
              {fields.map((note, index) => {
                const tmp_note = note as unknown as any;
                delete tmp_note.id;

                const strnote = Object.values(tmp_note).join('');

                return (
                  <Controller
                    key={note.id}
                    control={control}
                    name={`eqm_notes.${index}`}
                    render={({ field }) => (
                      <TextField
                        {...register(`eqm_notes.${index}` as const)}
                        variant="outlined"
                        label={`Notas #${index + 1}`}
                        placeholder="Ex: Troca de peça X"
                        fullWidth
                        defaultValue={strnote}
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                );
              })}

              {/* @ts-ignore */}
              <Button onClick={() => append('')}>Adicionar observação</Button>
            </RenderBox>
          </Stack>
        </FormSection>
      </form>
    </>
  );
};

export default EquipmentMaintenanceForm;

interface IRenderBox {
  allowedFields: KeysIEquipmentMaintenanceUpsertDto[];
  field: KeysIEquipmentMaintenanceUpsertDto;
  children: React.ReactNode;
  otherConditions?: boolean[];
}

export const RenderBox: React.FC<IRenderBox> = ({
  allowedFields,
  children,
  field,
  otherConditions = [],
}) => {
  const show = allowedFields.includes(field);

  if (otherConditions.length > 0) {
    const shouldShow = otherConditions.every((condition) => condition);
    if (!shouldShow) {
      return null;
    }
  }

  return (
    <>
      <Box
        style={{
          display: show ? 'block' : 'none',
        }}
      >
        {children}
      </Box>
    </>
  );
};
