/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { MarkerF } from '@react-google-maps/api';
import { Box, Link } from '@material-ui/core';
import ReactDOMServer from 'react-dom/server';
// import { useQuery } from '@tanstack/react-query';
import GseStaticCard from 'src/pages/gses/components/GseStaticCard';
// import { useTouchwayApi } from 'src/services/touchway-api';
import { DEFAULT_MAPS } from 'src/config/maps-default';
import { IVehicleFirebaseRealtimeType } from '../gses/types/IGSEFirebaseRealtime.type';
// import { queryKeys } from 'src/config/query';

interface MapsMarkerProps {
  vhc_id: string;
  value: IVehicleFirebaseRealtimeType;
  labelColor: string;
  map: any;
  clusterer: any;
}

const MapsMarker: React.FC<MapsMarkerProps> = ({
  value,
  vhc_id,
  clusterer,
  labelColor,
  map,
}) => {
  // const vehicleDetail = vehiclesQuery.data?.vehicles.find(
  //   ({ vhc_id }) => vhc_id === key,
  // );
  const { ignition, latitude, longitude, name } = value;

  if (ignition) {
    return (
      <>
        <MarkerF
          // animation={window.google.maps.Animation.DROP}
          icon={{
            // ...DEFAULT_MAPS.icon,
            path: window.google.maps.SymbolPath.CIRCLE,
            size: new window.google.maps.Size(
              DEFAULT_MAPS.icon.size.width,
              DEFAULT_MAPS.icon.size.height,
            ),
            scale: DEFAULT_MAPS.icon.scale,
            scaledSize: new window.google.maps.Size(
              DEFAULT_MAPS.icon.size.width,
              DEFAULT_MAPS.icon.size.height,
            ),
            labelOrigin: new window.google.maps.Point(
              DEFAULT_MAPS.icon.labelOrigin.x,
              DEFAULT_MAPS.icon.labelOrigin.y,
            ),
            strokeColor: DEFAULT_MAPS.icon.color.ignition.on,
          }}
          key={`location-${latitude}-${longitude}-${ignition}`}
          position={{
            lat: Number(latitude),
            lng: Number(longitude),
          }}
          label={{
            text: name,
            color: labelColor,
            fontWeight: 'bold',
          }}
          title="Clique para ver mais informações"
          clusterer={clusterer}
          onClick={() => {
            if (!name) {
              return;
            }

            const content = ReactDOMServer.renderToStaticMarkup(
              <Box width="350px">
                <GseStaticCard
                  showIgnitionStatus
                  showCard={false}
                  vehicle={value}
                />
                <Box mx={2}>
                  <Link
                    component="a"
                    variant="button"
                    href={`/veiculos/${vhc_id}`}
                    underline="always"
                  >
                    Ver mais
                  </Link>
                </Box>
              </Box>,
            );

            const lat = Number(latitude);
            const lng = Number(longitude);

            const pos = { lat, lng };
            const infoWindow = new google.maps.InfoWindow({
              position: pos,
              pixelOffset: new google.maps.Size(0, -70),
              content,
            });

            infoWindow.open(map);
          }}
        />
      </>
    );
  }

  return (
    <>
      <MarkerF
        icon={{
          // ...DEFAULT_MAPS.icon,
          size: new window.google.maps.Size(
            DEFAULT_MAPS.icon.size.width,
            DEFAULT_MAPS.icon.size.height,
          ),
          scale: DEFAULT_MAPS.icon.scale,
          scaledSize: new window.google.maps.Size(
            DEFAULT_MAPS.icon.size.width,
            DEFAULT_MAPS.icon.size.height,
          ),
          labelOrigin: new window.google.maps.Point(
            DEFAULT_MAPS.icon.labelOrigin.x,
            DEFAULT_MAPS.icon.labelOrigin.y,
          ),
          path: window.google.maps.SymbolPath.CIRCLE,
          strokeColor: DEFAULT_MAPS.icon.color.ignition.off,
        }}
        key={`location-${latitude}-${longitude}-${ignition}`}
        position={{
          lat: Number(latitude),
          lng: Number(longitude),
        }}
        label={{
          text: name,
          color: labelColor,
          fontWeight: 'bold',
        }}
        title="Clique para ver mais informações"
        clusterer={clusterer}
        onClick={() => {
          if (!name) {
            return;
          }

          const content = ReactDOMServer.renderToStaticMarkup(
            <Box width="350px">
              <GseStaticCard
                showIgnitionStatus
                showCard={false}
                vehicle={value}
              />
              <Box mx={2}>
                <Link
                  component="a"
                  variant="button"
                  href={`/veiculos/${vhc_id}`}
                  underline="always"
                >
                  Ver mais
                </Link>
              </Box>
            </Box>,
          );

          const lat = Number(latitude);
          const lng = Number(longitude);

          const pos = { lat, lng };
          const infoWindow = new google.maps.InfoWindow({
            position: pos,
            pixelOffset: new google.maps.Size(0, -70),
            content,
          });

          infoWindow.open(map);
        }}
      />
    </>
  );
};

export default MapsMarker;
