/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import {
  GoogleMap,
  MarkerClustererF,
  MarkerF,
  useJsApiLoader,
} from '@react-google-maps/api';
import { isMobile } from 'react-device-detect';
import { useQuery } from '@tanstack/react-query';
import { firebaseAdapter } from 'src/lib/firebase';
import { IGseFirebaseCollection } from 'src/hooks/firebase/useRealtime';
import { getAllDevices } from '../services/getAllDevices';

const containerStyle = {
  width: '400px',
  height: '400px',
};

const center = { lat: -23.007404, lng: -47.134502 };

interface MapsProps {}

export const MapsTrolleys: React.FC<MapsProps> = () => {
  const [map, setMap] = React.useState(null);
  const [gsesRealtime, setGsesRealtime] =
    useState<IGseFirebaseCollection | null>(null);

  const gsesQuery = useQuery(['getAllGses'], getAllDevices);

  useEffect(() => {
    const gsesRef = firebaseAdapter.database().ref('gses');

    gsesRef.on('value', (snapshot) => {
      const data = snapshot.val();
      setGsesRealtime(data);
    });
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCfim7KkPBm88rTS3VAi1s_WAS2DHJO4Yo',
  });

  const handleLocationError = useCallback(
    (
      browserHasGeolocation: boolean,
      infoWindow: google.maps.InfoWindow,
      pos: google.maps.LatLng,
    ) => {
      infoWindow.setPosition(pos);
      infoWindow.setContent(
        browserHasGeolocation
          ? 'Erro: o serviço de geolocalização falhou.'
          : 'Erro: seu navegador não suporta a geolocalização.',
      );
      infoWindow.open(map);
    },
    [map],
  );

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onLoad = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (map) => {
      // This is just an example of getting and using the map instance!!! don't just blindly copy!
      // const bounds = new window.google.maps.LatLngBounds(center);
      // map.fitBounds(bounds);

      const infoWindow = new google.maps.InfoWindow();
      const locationButton = document.createElement('button');

      locationButton.textContent = 'Ver minha localização';
      locationButton.classList.add('custom-map-control-button');

      map.controls[google.maps.ControlPosition.TOP_CENTER].push(locationButton);

      locationButton.addEventListener('click', () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position: GeolocationPosition) => {
              const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };

              infoWindow.setPosition(pos);
              infoWindow.setContent('Você está aqui.');
              infoWindow.open(map);
              map.setCenter(pos);
            },
            () => {
              handleLocationError(true, infoWindow, map.getCenter()!);
            },
          );
        } else {
          handleLocationError(false, infoWindow, map.getCenter()!);
        }
      });

      setMap(map);
    },
    [handleLocationError],
  );

  const onUnmount = React.useCallback((_map) => {
    setMap(null);
  }, []);

  const isMobileSizePx = isMobile ? '60px' : '0px';

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        height: `calc(100vh - 64px - ${isMobileSizePx})`,
        width: '100%',
      }}
      center={center}
      zoom={7}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <MarkerClustererF maxZoom={15}>
        {(clusterer) => (
          <>
            {fakeCoords.map(({ lat, lng, id }) => (
              <>
                <MarkerF
                  animation={window.google.maps.Animation.DROP}
                  icon={{
                    url: 'https://cdn.discordapp.com/attachments/678605421581434929/1042963772752527430/Inserir_um_titulo_1.png',
                    scaledSize: new window.google.maps.Size(40, 40),
                    labelOrigin: new window.google.maps.Point(23, 46),
                    origin: new window.google.maps.Point(0, 0),
                  }}
                  label={{
                    text: id.toString(),
                    color: 'black',
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }}
                  key={`location-${lat}-${lng}`}
                  position={{ lat: Number(lat), lng: Number(lng) }}
                  clusterer={clusterer}
                  onClick={() => {}}
                />
              </>
            ))}
          </>
        )}
      </MarkerClustererF>
    </GoogleMap>
  ) : (
    <></>
  );
};

export interface IMockAirports {
  iata: string;
  lat: number;
  lng: number;
}
export const mockedAirports: IMockAirports[] = [
  { iata: 'VDC', lat: -14.907885, lng: -40.914804 },
  { iata: 'JJD', lat: -2.906425, lng: -40.357338 },
  { iata: 'CDJ', lat: -8.34835, lng: -49.301498 },
  { iata: 'ARX', lat: -4.568896, lng: -37.805135 },
  { iata: 'AAA', lat: -22.875099, lng: -43.384701 },
  { iata: 'BBB', lat: -16.2292, lng: -48.964298 },
  { iata: 'AQA', lat: -21.812, lng: -48.132999 },
  { iata: 'AJU', lat: -10.984, lng: -37.070301 },
  { iata: 'AFL', lat: -9.866389, lng: -56.106298 },
  { iata: 'ARU', lat: -21.141479, lng: -50.424575 },
  { iata: 'BEL', lat: -1.379279, lng: -48.476207 },
  { iata: 'BGX', lat: -31.390499, lng: -54.112202 },
  { iata: 'PLU', lat: -19.8512, lng: -43.9506 },
  { iata: 'BFH', lat: -25.4051, lng: -49.231998 },
  { iata: 'AAA', lat: -21.2672, lng: -43.76101 },
  { iata: 'BSB', lat: -15.869167, lng: -47.920834 },
  { iata: 'BAT', lat: -20.584499359131, lng: -48.594100952148 },
  { iata: 'BVB', lat: 2.845855, lng: -60.690944 },
  { iata: 'BPG', lat: -15.861417, lng: -52.389008 },
  { iata: 'BZC', lat: -22.770881, lng: -41.96308 },
  { iata: 'CAC', lat: -25.000323, lng: -53.501208 },
  { iata: 'AAA', lat: -9.33394, lng: -54.965401 },
  { iata: 'CNF', lat: -19.63571, lng: -43.966928 },
  { iata: 'CGR', lat: -20.469998, lng: -54.673988 },
  { iata: 'XAP', lat: -27.134199, lng: -52.656601 },
  { iata: 'CLN', lat: -7.32044, lng: -47.458698 },
  { iata: 'CKS', lat: -6.117841, lng: -50.003372 },
  { iata: 'CCM', lat: -28.725743, lng: -49.424475 },
  { iata: 'AAA', lat: -29.945928, lng: -51.144413 },
  { iata: 'CAW', lat: -21.698299, lng: -41.301701 },
  { iata: 'CMG', lat: -19.01193, lng: -57.672772 },
  { iata: 'CWB', lat: -25.5285, lng: -49.1758 },
  { iata: 'CRQ', lat: -17.6523, lng: -39.253101 },
  { iata: 'CXJ', lat: -29.197183, lng: -51.187647 },
  { iata: 'CGB', lat: -15.6529, lng: -56.116699 },
  { iata: 'CZS', lat: -7.59991, lng: -72.769501 },
  { iata: 'PPB', lat: -22.1751, lng: -51.424599 },
  { iata: 'MAO', lat: -3.03861, lng: -60.049702 },
  { iata: 'JCR', lat: -6.23316, lng: -57.776901 },
  { iata: 'IGU', lat: -25.594167, lng: -54.489444 },
  { iata: 'FLN', lat: -27.670279, lng: -48.552502 },
  { iata: 'FEN', lat: -3.854471, lng: -32.423302 },
  { iata: 'FOR', lat: -3.77628, lng: -38.5326 },
  { iata: 'GIG', lat: -22.809999, lng: -43.250557 },
  { iata: 'GJM', lat: -10.78641, lng: -65.28486 },
  { iata: 'GYN', lat: -16.632, lng: -49.220699 },
  { iata: 'GRU', lat: -23.431944, lng: -46.467778 },
  { iata: 'GUJ', lat: -22.791599, lng: -45.2048 },
  { iata: 'ATM', lat: -3.253144, lng: -52.253938 },
  { iata: 'ITA', lat: -3.126539, lng: -58.481668 },
  { iata: 'ITB', lat: -4.242131, lng: -56.000651 },
  { iata: 'IOS', lat: -14.815929, lng: -39.033458 },
  { iata: 'IPN', lat: -19.470699, lng: -42.487598 },
  { iata: 'IMP', lat: -5.53129, lng: -47.459999 },
  { iata: 'JDF', lat: -21.791482, lng: -43.386072 },
  { iata: 'JHF', lat: -23.426886, lng: -47.165658 },
  { iata: 'JPA', lat: -7.148691, lng: -34.950554 },
  { iata: 'JOI', lat: -26.224501, lng: -48.797401 },
  { iata: 'CPV', lat: -7.269662, lng: -35.896057 },
  { iata: 'VCP', lat: -23.007404, lng: -47.134502 },
  { iata: 'LAJ', lat: -27.782101, lng: -50.281502 },
  { iata: 'LIP', lat: -21.663999557495, lng: -49.730499267578 },
  { iata: 'LDB', lat: -23.333599, lng: -51.1301 },
  { iata: 'LAZ', lat: -13.2621, lng: -43.4081 },
  { iata: 'MAB', lat: -5.36859, lng: -49.138 },
  { iata: 'MQH', lat: -13.5491, lng: -48.195301 },
  { iata: 'MEU', lat: -0.889839, lng: -52.6022 },
  { iata: 'MEA', lat: -22.343, lng: -41.765999 },
  { iata: 'MGF', lat: -23.47606, lng: -52.016187 },
  { iata: 'MOC', lat: -16.706919, lng: -43.818901 },
  { iata: 'MII', lat: -22.196899, lng: -49.926485 },
  { iata: 'PLL', lat: -3.14604, lng: -59.986301 },
  { iata: 'MCZ', lat: -9.51081, lng: -35.791698 },
  { iata: 'MCP', lat: 0.050664, lng: -51.072201 },
  { iata: 'MVF', lat: -5.20192, lng: -37.3643 },
  { iata: 'RTE', lat: -23.5091, lng: -46.637798 },
  { iata: 'MNX', lat: -5.81138, lng: -61.278301 },
  { iata: 'NVT', lat: -26.879431, lng: -48.650979 },
  { iata: 'GEL', lat: -28.282503, lng: -54.169623 },
  { iata: 'AAA', lat: -5.91142, lng: -35.2477 },
  { iata: 'OYK', lat: 3.85412, lng: -51.797056 },
  { iata: 'POA', lat: -29.994712, lng: -51.166592 },
  { iata: 'PHB', lat: -2.89375, lng: -41.731998 },
  { iata: 'POO', lat: -21.842529, lng: -46.569768 },
  { iata: 'PFB', lat: -28.243976, lng: -52.32777 },
  { iata: 'PMW', lat: -10.2915, lng: -48.356998 },
  { iata: 'PET', lat: -31.717253, lng: -52.327952 },
  { iata: 'PNZ', lat: -9.362422, lng: -40.569098 },
  { iata: 'PNB', lat: -10.719402, lng: -48.3997 },
  { iata: 'PMG', lat: -22.549601, lng: -55.702599 },
  { iata: 'BPS', lat: -16.438426, lng: -39.080584 },
  { iata: 'PVH', lat: -8.707854, lng: -63.90242 },
  { iata: 'RBR', lat: -9.869031, lng: -67.893984 },
  { iata: 'REC', lat: -8.125984, lng: -34.923316 },
  { iata: 'SDU', lat: -22.9105, lng: -43.163101 },
  { iata: 'RAO', lat: -21.134314, lng: -47.774053 },
  { iata: 'SNZ', lat: -22.9324, lng: -43.719101 },
  { iata: 'NAT', lat: -5.769804, lng: -35.366578 },
  { iata: 'SJK', lat: -23.2292, lng: -45.8615 },
  { iata: 'SLZ', lat: -2.58536, lng: -44.2341 },
  { iata: 'RIA', lat: -29.711399, lng: -53.688202 },
  { iata: 'STM', lat: -2.422423, lng: -54.79306 },
  { iata: 'CGH', lat: -23.627657, lng: -46.654601 },
  { iata: 'SJP', lat: -20.817113, lng: -49.406963 },
  { iata: 'SSZ', lat: -23.928192, lng: -46.300195 },
  { iata: 'SSA', lat: -12.908611, lng: -38.322498 },
  { iata: 'AAA', lat: -23.0401, lng: -45.51615 },
  { iata: 'TMT', lat: -1.4896, lng: -56.396801 },
  { iata: 'THE', lat: -5.06025, lng: -42.823712 },
  { iata: 'TFF', lat: -3.38294, lng: -64.724098 },
  { iata: 'TRQ', lat: -8.155534, lng: -70.782985 },
  { iata: 'TEC', lat: -24.317801, lng: -50.6516 },
  { iata: 'AAA', lat: 2.224537, lng: -55.944711 },
  { iata: 'TBT', lat: -4.25567, lng: -69.935799 },
  { iata: 'TUR', lat: -3.78601, lng: -49.720299 },
  { iata: 'SJL', lat: -0.14835, lng: -66.9855 },
  { iata: 'PAV', lat: -9.40088, lng: -38.250599 },
  { iata: 'URG', lat: -29.7822, lng: -57.0382 },
  { iata: 'UDI', lat: -18.883579, lng: -48.225936 },
  { iata: 'UBA', lat: -19.764722824097, lng: -47.966110229492 },
  { iata: 'VAG', lat: -21.591375, lng: -45.474116 },
  { iata: 'BVH', lat: -12.6944, lng: -60.098301 },
  { iata: 'VIX', lat: -20.258, lng: -40.285 },
  { iata: 'QPS', lat: -21.984692, lng: -47.341207 },
  { iata: 'IZA', lat: -21.513086, lng: -43.173069 },
  { iata: 'JTC', lat: -22.160755, lng: -49.070325 },
  { iata: 'EEA', lat: -27.634128, lng: -50.358253 },
  { iata: 'AAA', lat: -4.94727, lng: -38.008202 },
  { iata: 'PGZ', lat: -25.184476, lng: -50.143822 },
  { iata: 'AUX', lat: -7.22787, lng: -48.240501 },
  { iata: 'ROO', lat: -16.586, lng: -54.7248 },
];

// for each airport, create a 10 fake coordinates around them
const fakeCoords: { lat: number; lng: number; id: number }[] =
  mockedAirports.flatMap((airport) => {
    const { lat, lng } = airport;
    const fakeCoords2 = [];
    // qnt between 30 and 150
    const qnt = Math.floor(Math.random() * 120) + 30;
    for (let i = 0; i < qnt; i++) {
      fakeCoords2.push({
        // close proximity to the lat/lng
        lat: lat + (Math.random() - 0.5) * 0.01,
        lng: lng + (Math.random() - 0.5) * 0.01,
        // 6 digits
        id: Math.floor(Math.random() * 1000000),
      });
    }
    return fakeCoords2;
  });
