import React, { useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import { KanbanContext } from 'src/contexts/KanbanContext';

export default function MaintenanceSearch() {
  const { setSearchValue, searchValue } = useContext(KanbanContext);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  return (
    <>
      <TextField
        size="medium"
        label="Filtrar por"
        placeholder="Nome ou base"
        variant="outlined"
        value={searchValue}
        onChange={handleSearch}
        inputProps={{
          style: {
            height: 16,
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: 14,
          },
        }}
      />
    </>
  );
}
