import type { FC } from 'react';
import type { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'src/config/query';
import { touchwayApi } from 'src/services/touchway-api';
import InformationCircleIcon from '../../../icons/InformationCircle';

const AnalyticsSocialMediaSources: FC = () => {
  const theme = useTheme();

  const vehiclesQuery = useQuery(
    queryKeys.vehicleControllerList.get({
      with_relations: false,
    }),
    () =>
      touchwayApi.vehicles.vehicleControllerList({
        // @ts-ignore
        with_relations: false,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const eqpsMarcas = vehiclesQuery.data?.vehicles.map(
    ({ vhc_name }) => vhc_name.split(' ')[0],
  );
  // get all that not contains IMEI
  const filtered = eqpsMarcas?.filter(
    (item) => item.toLowerCase().includes('imei') === false,
  );

  const groupedFiltered = groupByCount(filtered || []);

  const chartLabels = Object.keys(groupedFiltered);
  const chartSeries = Object.values(groupedFiltered);

  const chartOptions: ApexOptions = {
    chart: {
      background: 'transparent',
      stacked: true,
      toolbar: {
        show: true,
      },
    },
    colors: ['rgba(86, 100, 210, 0.5)', '#FFB547', '#7BC67E', '#64B6F7'],
    dataLabels: {
      enabled: true,
    },
    labels: chartLabels,
    legend: {
      fontSize: '14px',
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.subtitle2.fontWeight,
      itemMargin: {
        vertical: 8,
      },
      labels: {
        colors: theme.palette.text.primary,
      },
      markers: {
        width: 8,
        height: 8,
      },
      show: true,
    },
    stroke: {
      width: 0,
    },
    theme: {
      mode: theme.palette.mode,
    },
  };

  return (
    <Card>
      <CardHeader
        disableTypography
        title={
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography color="textPrimary" variant="h6">
              Marcas de Equipamentos
            </Typography>
            <Tooltip title="Mostra todas as marcas de equipamentos que estão cadastrados na plataforma">
              <InformationCircleIcon fontSize="small" />
            </Tooltip>
          </Box>
        }
      />
      <CardContent>
        <Chart
          height={300}
          options={chartOptions}
          series={chartSeries}
          type="donut"
        />
      </CardContent>
      {/* <CardActions
        sx={{
          px: 2,
          py: 1.5,
          backgroundColor: 'background.default',
        }}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon fontSize="small" />}
          variant="text"
        >
          See all visits
        </Button>
      </CardActions> */}
    </Card>
  );
};

const groupByCount = (items: string[]) =>
  items.reduce<{ [key: string]: number }>((acc, item) => {
    if (!acc[item]) {
      acc[item] = 0;
    }
    acc[item] += 1;
    return acc;
  }, {});

export default AnalyticsSocialMediaSources;
