import { MarkerF } from '@react-google-maps/api';
import React from 'react';
import { isMobile } from 'react-device-detect';
import LoadingScreen from 'src/components/LoadingScreen';
import { firebaseRealtimeEnum } from 'src/config';
import useRealtime from 'src/hooks/firebase/useRealtime';
import { IVehicle } from 'src/services/touchway-base-api';
import { DEFAULT_MAPS } from 'src/config/maps-default';
import { CustomMaps } from 'src/components/CustomMaps';
import { IVehicleFirebaseRealtimeType } from '../gses/types/IGSEFirebaseRealtime.type';

interface GSEDetailsWithMapProps {
  gse: IVehicle;
}

export const GSEDetailsWithMap: React.FC<GSEDetailsWithMapProps> = ({
  gse,
}) => {
  const [, setMap] = React.useState(null);

  const gseRealtime = useRealtime<IVehicleFirebaseRealtimeType>({
    collection: firebaseRealtimeEnum.vehicles,
    key: gse?.vhc_id,
  });

  const center = {
    lat: gseRealtime?.latitude
      ? Number(gseRealtime?.latitude)
      : DEFAULT_MAPS.center.lat,
    lng: gseRealtime?.longitude
      ? Number(gseRealtime?.longitude)
      : DEFAULT_MAPS.center.lng,
  };

  const isMobileSizePx = isMobile ? '60px' : '0px';

  return (
    <CustomMaps
      mapContainerStyle={{
        height: `calc(70vh - 64px - ${isMobileSizePx})`,
        width: '100%',
      }}
      center={center}
      setMapCallback={setMap}
      zoom={17}
    >
      {gseRealtime ? (
        <>
          {gseRealtime?.ignition ? (
            <MarkerF
              animation={window.google.maps.Animation.DROP}
              icon={{
                // ...DEFAULT_MAPS.icon,
                path: window.google.maps.SymbolPath.CIRCLE,
                // size: new window.google.maps.Size(
                //   DEFAULT_MAPS.icon.size.width,
                //   DEFAULT_MAPS.icon.size.height,
                // ),
                scale: DEFAULT_MAPS.icon.scale,
                // scaledSize: new window.google.maps.Size(
                //   DEFAULT_MAPS.icon.size.width,
                //   DEFAULT_MAPS.icon.size.height,
                // ),
                labelOrigin: new window.google.maps.Point(
                  DEFAULT_MAPS.icon.labelOrigin.x,
                  DEFAULT_MAPS.icon.labelOrigin.y,
                ),
                strokeColor: DEFAULT_MAPS.icon.color.ignition.on,
              }}
              key={`location-ignition-on-${gseRealtime.latitude}-${gseRealtime.longitude}`}
              label={gseRealtime.name}
              position={{
                lat: Number(gseRealtime.latitude),
                lng: Number(gseRealtime.longitude),
              }}
            />
          ) : (
            <MarkerF
              animation={window.google.maps.Animation.DROP}
              icon={{
                // ...DEFAULT_MAPS.icon,
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: DEFAULT_MAPS.icon.scale,
                size: new window.google.maps.Size(20, 20),
                scaledSize: new window.google.maps.Size(20, 20),
                labelOrigin: new window.google.maps.Point(0, 3), // Ajustado aqui
                strokeColor: DEFAULT_MAPS.icon.color.ignition.off,
              }}
              key={`location-ignition-off-${gseRealtime.latitude}-${gseRealtime.longitude}`}
              label={gseRealtime.name}
              position={{
                lat: Number(gseRealtime.latitude),
                lng: Number(gseRealtime.longitude),
              }}
            />
          )}
        </>
      ) : (
        <LoadingScreen />
      )}
    </CustomMaps>
  );
};
