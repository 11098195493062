import { differenceInMinutes, format, parseISO } from 'date-fns';
import {
  GeolocationReport,
  HeatbeatReport,
  IgnitionOReport,
  ILocatorFirebaseRealtimeType,
  InfoReport,
  Locator,
} from '../types/ILocatorFirebaseRealtime.type';
import { ptBR } from 'date-fns/locale';
import { diffDays } from '@fullcalendar/react';

type ColorStatus = 'success' | 'warning' | 'danger' | 'info';

interface IGeolocationReportLabels {
  [key: string]: { label: string; description: string };
}

export class LocatorConverter {
  private locator: ILocatorFirebaseRealtimeType | undefined;
  private geolocationReportLabels: IGeolocationReportLabels = {
    altitude: { label: 'Altitude', description: 'A altitude do dispositivo.' },
    backup_battery_percentage: {
      label: 'Percentual da Bateria de Backup',
      description: 'O percentual da bateria de backup do dispositivo.',
    },
    cell_id: {
      label: 'ID da Célula',
      description: 'O ID da célula do dispositivo.',
    },
    count_number: {
      label: 'Número de Contagem',
      description: 'O número de contagem.',
    },
    device_name: {
      label: 'Nome do Dispositivo',
      description: 'O nome do dispositivo.',
    },
    device_status: {
      label: 'Status do Dispositivo',
      description: 'O status do dispositivo.',
    },
    event_name: { label: 'Nome do Evento', description: 'O nome do evento.' },
    external_power_voltage: {
      label: 'Voltagem da Alimentação Externa',
      description: 'A voltagem da alimentação externa do dispositivo.',
    },
    gps_accuracy: {
      label: 'Precisão do GNSS',
      description: 'A precisão do GNSS.',
    },
    gps_utc_time: {
      label: 'Hora UTC do GPS',
      description: 'A hora UTC do GPS.',
    },
    heading: { label: 'Direção', description: 'A direção.' },
    hour_meter_conut: {
      label: 'Contagem do Horímetro',
      description: 'A contagem do horímetro.',
    },
    lac_id: { label: 'ID do LAC', description: 'O ID do LAC do dispositivo.' },
    latitude: { label: 'Latitude', description: 'A latitude.' },
    longitude: { label: 'Longitude', description: 'A longitude.' },
    mcc: { label: 'MCC', description: 'O Código do País Móvel.' },
    mileage: { label: 'Quilometragem', description: 'A quilometragem.' },
    mnc: { label: 'MNC', description: 'O Código da Rede Móvel.' },
    number: {
      label: 'Número',
      description:
        'O número de posições GNSS incluídas na mensagem de relatório.',
    },
    protocol_version: {
      label: 'Versão do Protocolo',
      description: 'A versão do protocolo.',
    },
    report_id_report_type: {
      label: 'ID e Tipo de Relatório',
      description: 'O ID e tipo do relatório.',
    },
    send_time: { label: 'Hora de Envio', description: 'A hora de envio.' },
    speed: { label: 'Velocidade', description: 'A velocidade.' },
    unique_id: { label: 'ID Único', description: 'O ID único.' },
  };

  private heart_beat_report_labels = {
    count_number: 'Número de Contagem',
    device_name: 'Nome do Dispositivo',
    event_name: 'Nome do Evento',
    protocol_version: 'Versão do Protocolo',
    send_time: 'Hora de Envio',
    unique_id: 'ID Único',
  };

  private ignition_on_report_labels = {
    altitude: 'Altitude',
    cell_id: 'ID da Célula',
    count_number: 'Número de Contagem',
    device_name: 'Nome do Dispositivo',
    duration_of_ignition_on: 'Duração da Ignição Ligada',
    event_name: 'Nome do Evento',
    gps_accuracy: 'Precisão do GNSS',
    gps_utc_time: 'Hora UTC do GPS',
    heading: 'Direção',
    hour_meter_count: 'Contagem do Horímetro',
    lac: 'ID do LAC',
    latitude: 'Latitude',
    longitude: 'Longitude',
    mcc: 'MCC',
    mileage: 'Quilometragem',
    mnc: 'MNC',
    protocol_version: 'Versão do Protocolo',
    send_time: 'Hora de Envio',
    speed: 'Velocidade',
    unique_id: 'ID Único',
  };

  private ignition_off_report_labels = {
    altitude: 'Altitude',
    cell_id: 'ID da Célula',
    count_number: 'Número de Contagem',
    device_name: 'Nome do Dispositivo',
    duration_of_ignition_off: 'Duração da Ignição Desligada',
    event_name: 'Nome do Evento',
    gps_accuracy: 'Precisão do GNSS',
    gps_utc_time: 'Hora UTC do GPS',
    heading: 'Direção',
    hour_meter_count: 'Contagem do Horímetro',
    lac: 'ID do LAC',
    latitude: 'Latitude',
    longitude: 'Longitude',
    mcc: 'MCC',
    mileage: 'Quilometragem',
    mnc: 'MNC',
    protocol_version: 'Versão do Protocolo',
    send_time: 'Hora de Envio',
    speed: 'Velocidade',
    unique_id: 'ID Único',
  };

  private info_report_labels = {
    backup_battery_voltage: 'Voltagem da Bateria de Backup',
    charging: 'Carregando',
    count_number: 'Número de Contagem',
    csq_ber: 'Qualidade do Sinal',
    csq_rssi: 'Força do Sinal',
    daylight_saving: 'Horário de Verão',
    device_name: 'Nome do Dispositivo',
    digital_input: 'Entrada Digital',
    event_name: 'Nome do Evento',
    external_power_supply: 'Fonte de Alimentação Externa',
    external_power_voltage: 'Voltagem da Alimentação Externa',
    iccid: 'ICCID',
    last_fix_utc_time: 'Última Hora de Fixação UTC',
    led_on: 'LED Ligado',
    protocol_version: 'Versão do Protocolo',
    report_id_report_type: 'ID e Tipo de Relatório',
    send_time: 'Hora de Envio',
    state: 'Estado',
  };

  constructor(raw: ILocatorFirebaseRealtimeType | undefined) {
    this.locator = raw;
  }

  fromJson(raw: ILocatorFirebaseRealtimeType) {
    this.locator = raw;
  }

  // ------
  // GeoLocation
  // ------

  get geolocation_date(): Date | null {
    return this?.locator?.geolocation_date
      ? parseISO(this.locator.geolocation_date)
      : null;
  }

  get geolocation_date_formatted(): string {
    return this.geolocation_date
      ? this.format_date(this.geolocation_date)
      : 'N/A';
  }

  get geolocation_date_status(): ColorStatus {
    if (!this.geolocation_date) return 'danger';

    const diff = differenceInMinutes(new Date(), this.geolocation_date);
    if (diff < 5) {
      return 'success';
    } else if (diff < 10) {
      return 'warning';
    } else {
      return 'danger';
    }
  }

  get geolocation_report() {
    return this.locator ? this.locator.geolocation_report : null;
  }

  // ------
  // HEATBEAT
  // ------

  get heartbeat_date(): Date | null {
    return this?.locator?.heartbeat_date
      ? parseISO(this.locator.heartbeat_date)
      : null;
  }

  get heartbeat_date_formatted(): string {
    return this.heartbeat_date ? this.format_date(this.heartbeat_date) : 'N/A';
  }

  get heartbeat_date_status(): ColorStatus {
    if (!this.heartbeat_date) return 'danger';

    const diff = differenceInMinutes(new Date(), this.heartbeat_date);
    if (diff < 5) {
      return 'success';
    } else if (diff < 10) {
      return 'warning';
    } else {
      return 'danger';
    }
  }

  get heatbeat_report() {
    return this.locator ? this.locator.heatbeat_report : null;
  }

  // ------
  // IGNITION OFF
  // ------

  get ignition_off_date(): Date | null {
    const ignitionOffDate = this.locator?.ignition_off_date;
    return ignitionOffDate ? parseISO(ignitionOffDate) : null;
  }

  get ignition_off_date_formatted(): string {
    return this.ignition_off_date
      ? this.format_date(this.ignition_off_date)
      : 'N/A';
  }

  get ignition_off_date_status(): ColorStatus {
    if (!this.ignition_on_date) return 'danger';

    const ignitionOnDate = this.locator?.ignition_on_date
      ? parseISO(this.locator.ignition_on_date)
      : null;
    if (!ignitionOnDate) return 'danger';

    const diff = diffDays(new Date(), ignitionOnDate);
    if (diff < 1) {
      return 'success';
    } else if (diff < 2) {
      return 'info';
    } else if (diff < 3) {
      return 'warning';
    } else {
      return 'danger';
    }
  }

  get ignition_off_report() {
    return this?.locator?.ignition_off_date
      ? this.locator.ignition_off_report
      : null;
  }

  // ------
  // IGNITION ON
  // ------

  get ignition_on_date(): Date | null {
    return this?.locator?.ignition_on_date
      ? parseISO(this.locator.ignition_on_date)
      : null;
  }

  get ignition_on_date_formatted(): string {
    return this.ignition_on_date
      ? this.format_date(this.ignition_on_date)
      : 'N/A';
  }

  get ignition_on_date_status(): ColorStatus {
    if (!this.ignition_on_date) return 'danger';

    const diff = diffDays(new Date(), this.ignition_on_date);
    if (diff < 1) {
      return 'success';
    } else if (diff < 2) {
      return 'info';
    } else if (diff < 3) {
      return 'warning';
    } else {
      return 'danger';
    }
  }

  get ignition_on_report() {
    return this.locator ? this.locator.ignition_on_report : null;
  }

  // ------
  // INFO
  // ------

  get info_date(): Date | null {
    return this?.locator?.info_date ? parseISO(this.locator.info_date) : null;
  }

  get info_date_formatted(): string {
    return this.info_date ? this.format_date(this.info_date) : 'N/A';
  }

  get info_date_status(): ColorStatus {
    if (!this.info_date) return 'danger';

    const diff = differenceInMinutes(new Date(), this.info_date);
    if (diff < 5) {
      return 'success';
    } else if (diff < 10) {
      return 'warning';
    } else {
      return 'danger';
    }
  }

  get info_report() {
    return this.locator ? this.locator.info_report : null;
  }

  get locator_data(): Locator | null {
    return this.locator && this.locator.locator ? this.locator.locator : null;
  }

  // ------
  // MISC
  // ------

  // backup_battery_percentage
  //  <Backup Battery Percentage>: The current volume of the backup battery in percentage.
  get backup_battery_percentage(): number | null {
    return this.locator?.geolocation_report?.backup_battery_percentage
      ? parseFloat(this.locator.geolocation_report.backup_battery_percentage)
      : null;
  }

  get backup_battery_percentage_status(): StatusLabel {
    if (!this.backup_battery_percentage)
      return {
        label: 'Desconhecida',
        status: 'danger',
      };

    if (this.backup_battery_percentage < 20) {
      return {
        label: `${this.backup_battery_percentage}%`,
        status: 'danger',
      };
    } else if (this.backup_battery_percentage < 50) {
      return {
        label: `${this.backup_battery_percentage}%`,
        status: 'warning',
      };
    } else {
      return {
        label: `${this.backup_battery_percentage}%`,
        status: 'success',
      };
    }
  }

  get gps_accuracy(): StatusLabel {
    return this.hdop_description;
  }

  // ------
  // PRIVATE METHODS
  // ------

  private format_date(date: Date): string {
    return format(date, 'dd/MM/yyyy HH:mm:ss', {
      locale: ptBR,
    });
  }

  /**
   <GNSS Accuracy>: A numeral to indicate the GNSS fix status and HDOP of the GNSS position.
    0 indicates the current GNSS fix fails and the last known GNSS position is used. A non-zero
    value (1 - 50) indicates the current GNSS fix is successful and represents the HDOP of the
    current GNSS position.
   */
  get hdop_description(): StatusLabel & { hdop: number } {
    const accuracy = this.locator?.geolocation_report?.gps_accuracy
      ? parseFloat(this.locator.geolocation_report.gps_accuracy)
      : 0;

    if (accuracy === 0) {
      return { label: 'Sem sinal', status: 'danger', hdop: accuracy };
    } else if (accuracy <= 1) {
      return { label: 'Excelente', status: 'success', hdop: accuracy };
    } else if (accuracy <= 2) {
      return { label: 'Muito Bom', status: 'success', hdop: accuracy };
    } else if (accuracy <= 5) {
      return { label: 'Bom', status: 'warning', hdop: accuracy };
    } else if (accuracy <= 10) {
      return { label: 'Moderado', status: 'warning', hdop: accuracy };
    } else {
      return { label: 'Ruim', status: 'danger', hdop: accuracy };
    }
  }

  get chargingStatus(): StatusLabel {
    const status =
      this?.info_report?.external_power_supply === '1' ? 'success' : 'warning';

    return this?.info_report?.charging === '1'
      ? { label: 'Carregando', status }
      : { label: 'Carregado', status };
  }

  get externalPowerSupplyStatus(): StatusLabel {
    return this?.info_report?.external_power_supply === '1'
      ? { label: 'Fonte Externa', status: 'success' }
      : { label: 'Sem Fonte Externa', status: 'warning' };
  }

  get externalPowerVoltage(): StatusLabel {
    const voltage = this.locator?.geolocation_report?.external_power_voltage
      ? parseFloat(this.locator.geolocation_report.external_power_voltage)
      : 0;

    if (voltage === 0) {
      return { label: 'Desconhecida', status: 'info' };
    }

    if (voltage < 11.5) {
      return { label: 'Baixa', status: 'danger' };
    } else if (voltage < 12.5) {
      return { label: 'Média', status: 'warning' };
    } else {
      return { label: 'Alta', status: 'success' };
    }
  }

  //   <CSQ RSSI>: The signal strength level.
  // CSQ RSSI Signal Strength (dBm)
  // 0 <-113
  // 1 -111
  // 2 – 30 -109 – -53
  // 31 >-51
  // 99 Unknown
  get signal_strength(): StatusLabel {
    const rssi = this.locator?.info_report?.csq_rssi
      ? parseFloat(this.locator.info_report.csq_rssi)
      : 99;

    if (rssi === 99) {
      return { label: 'Desconhecido', status: 'info' };
    }

    if (rssi < -113) {
      return { label: `Sem sinal`, status: 'danger' };
    } else if (rssi < -111) {
      return { label: `Fraco`, status: 'danger' };
    } else if (rssi < -109) {
      return { label: `Médio`, status: 'warning' };
    } else {
      return { label: `Forte`, status: 'success' };
    }
  }

  //  <CSQ BER>: The quality of the GSM signal. The range is 0-7, and 0 is for unknown strength of
  // signal.
  get signal_quality(): StatusLabel {
    const ber = this.locator?.info_report?.csq_ber
      ? parseFloat(this.locator.info_report.csq_ber)
      : 0;

    if (ber === 0) {
      return { label: 'Desconhecido', status: 'info' };
    }

    if (ber < 1) {
      return { label: `Excelente`, status: 'success' };
    } else if (ber < 3) {
      return { label: `Bom`, status: 'success' };
    } else if (ber < 5) {
      return { label: `Regular`, status: 'warning' };
    } else {
      return { label: `Ruim`, status: 'danger' };
    }
  }

  get detailed_geo_location_report() {
    return {
      title: 'Geolocalização',
      description:
        'Informações detalhadas sobre a geolocalização do dispositivo.',
      fields: Object.entries(this.geolocationReportLabels).map(
        ([key, value]) => {
          const _report = this.locator?.geolocation_report;
          const _value = _report
            ? _report[key as keyof GeolocationReport]
            : null;

          return {
            label: value.label,
            description: value.description,
            value: _value ? _value : '---',
          };
        },
      ),
    };
  }

  get detailed_heartbeat_report() {
    return {
      title: 'Heartbeat',
      description:
        'O Heartbeat é usado para manter o contato entre o dispositivo e o servidor backend no caso de comunicação GPRS. O pacote de heartbeat é enviado para o servidor backend em intervalos',
      fields: Object.entries(this.heart_beat_report_labels).map(
        ([key, value]) => {
          const _report = this.locator?.heatbeat_report;
          const _value = _report ? _report[key as keyof HeatbeatReport] : null;

          return {
            label: value,
            value: _value ? _value : '---',
            description: '',
          };
        },
      ),
    };
  }

  get detailed_info_report() {
    return {
      title: 'Informações',
      description:
        'Informações detalhadas sobre o dispositivo e sua configuração.',
      fields: Object.entries(this.info_report_labels).map(([key, value]) => {
        const _report = this.locator?.info_report;
        const _value = _report ? _report[key as keyof InfoReport] : null;

        return {
          label: value,
          value: _value ? _value : '---',
          description: '',
        };
      }),
    };
  }

  get detailed_ignition_on_report() {
    return {
      title: 'Ignição Ligada',
      description: 'Informações detalhadas sobre a ignição do veículo.',
      fields: Object.entries(this.ignition_on_report_labels).map(
        ([key, value]) => {
          const _report = this.locator?.ignition_on_report;
          const _value = _report ? _report[key as keyof IgnitionOReport] : null;

          return {
            label: value,
            value: _value ? _value : '---',
            description: '',
          };
        },
      ),
    };
  }

  get detailed_ignition_off_report() {
    return {
      title: 'Ignição Desligada',
      description: 'Informações detalhadas sobre a ignição do veículo.',
      fields: Object.entries(this.ignition_off_report_labels).map(
        ([key, value]) => {
          const _report = this.locator?.ignition_off_report;
          const _value = _report ? _report[key as keyof IgnitionOReport] : null;

          return {
            label: value,
            value: _value ? _value : '---',
            description: '',
          };
        },
      ),
    };
  }
}

interface StatusLabel {
  label: string;
  status: ColorStatus;
}
