import { FC, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { MUITSColumns, MUITSDatatable } from 'src/components/MUITSDatatable';
import { ISimManager } from 'src/services/touchway-base-api';
import { useTouchwayApi } from 'src/services/touchway-api';
import { queryKeys } from 'src/config/query';
import { Button, Divider, Grid } from '@material-ui/core';
import { StatisticsCard } from 'src/components/StatisticsCard';
import { RefetchStatusCard } from 'src/components/RefetchStatusCard';
import { SimcardEditableCell } from './components/SimcardEditableCell';

const REFETCH_INTERVAL = 10000;

const SimcardsList: FC = () => {
  const [enabledAutoRefetch, setEnabledAutoRefetch] = useState<boolean>(false);
  const touchwayApi = useTouchwayApi();

  const simcardsQuery = useQuery(
    queryKeys.vehicleControllerList.get({
      with_relations: true,
    }),
    () =>
      touchwayApi.api.simcards.simControllerList({
        with_relations: true,
      }),
    {
      refetchOnWindowFocus: false,
      // 60 seconds
      refetchInterval: enabledAutoRefetch ? REFETCH_INTERVAL : false,
    },
  );

  const simcards = simcardsQuery?.data?.simcards as unknown as ISimManager[];

  const [activeCard, setActiveCard] = useState('');

  const handleSetActiveCard = (cardName: string) => {
    setActiveCard(cardName);
  };

  const handleClearActiveCard = () => {
    setActiveCard('');
  };

  const id: keyof ISimManager = 'sim_apn';
  const newColumns: MUITSColumns<ISimManager> = [
    {
      name: 'sim_id',
      label: 'SIM ID',
      options: { filter: true, display: false, sort: true },
    },
    {
      name: 'sim_phone_number',
      label: 'Telefone',
      options: {
        filter: true,
        display: true,
        sort: true,
        customBodyRender: (value) =>
          `${value.slice(0, 3)} (${value.slice(3, 5)}) ${value.slice(
            5,
            10,
          )}-${value.slice(10)}`,
      },
    },
    {
      name: 'sim_iccid',
      label: 'ICCID',
      options: {
        filter: false,
        display: true,
        sort: false,
      },
    },
    {
      name: 'sim_pin',
      label: 'PIN',
      options: {
        filter: false,
        display: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const simcard = simcards[tableMeta.rowIndex];
          return (
            <SimcardEditableCell
              value={value}
              tableMeta={tableMeta}
              updateValue={updateValue}
              object={simcard}
            />
          );
        },
      },
    },
    {
      name: 'sim_pin2',
      label: 'PIN2',
      options: {
        filter: false,
        display: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const simcard = simcards[tableMeta.rowIndex];
          return (
            <SimcardEditableCell
              value={value}
              tableMeta={tableMeta}
              updateValue={updateValue}
              object={simcard}
            />
          );
        },
      },
    },
    {
      name: 'sim_puk',
      label: 'PUK',
      options: {
        filter: false,
        display: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const simcard = simcards[tableMeta.rowIndex];
          return (
            <SimcardEditableCell
              value={value}
              tableMeta={tableMeta}
              updateValue={updateValue}
              object={simcard}
            />
          );
        },
      },
    },
    {
      name: 'sim_puk2',
      label: 'PUK2',
      options: {
        filter: false,
        display: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const simcard = simcards[tableMeta.rowIndex];
          return (
            <SimcardEditableCell
              value={value}
              tableMeta={tableMeta}
              updateValue={updateValue}
              object={simcard}
            />
          );
        },
      },
    },
    {
      // @ts-ignore
      name: 'locator.lct_imei',
      label: 'GV vinculado no KITE',
      options: {
        filter: true,
        display: true,
        sort: true,
        customBodyRender: (value) => {
          if (value === undefined) return 'Localizador não vinculado no KITE';
          return `${value}`;
        },
      },
    },
    // {
    //   // @ts-ignore
    //   name: 'locator.vehicle.vhc_name',
    //   label: 'Nome do Equipamento',
    //   options: {
    //     filter: true,
    //     display: true,
    //     sort: true,
    //     customBodyRender: (value, { rowData }) => {
    //       if (value) return `${value}`;

    //       return (
    //         <VinculateAndCreateVehicle
    //           key={`${rowData[0]}-${rowData[1]}`}
    //           simcardId={`${rowData[0]}`}
    //         />
    //       );
    //     },
    //   },
    // },
    {
      name: 'sim_apn',
      label: 'APN',
      options: {
        filter: false,
        display: false,
        sort: false,
      },
    },
    {
      name: 'sim_apn_user',
      label: 'APN User',
      options: {
        filter: false,
        display: false,
        sort: false,
      },
    },
    {
      name: 'sim_apn_password',
      label: 'APN Password',
      options: {
        filter: false,
        display: false,
        sort: false,
      },
    },
  ];

  const simcardsWithLocators = useMemo(
    () => simcards?.filter((simcard) => simcard.locator !== null),
    [simcards],
  );

  const simcardsWithoutLocators = useMemo(
    () => simcards?.filter((simcard) => simcard.locator === null),
    [simcards],
  );

  const simcardsTotal = useMemo(() => simcards?.length, [simcards]);

  function toogleRefetch() {
    const newStatus = !enabledAutoRefetch;
    setEnabledAutoRefetch(newStatus);

    if (newStatus) {
      simcardsQuery.refetch();
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={10}>
        {activeCard === '' && (
          <MUITSDatatable
            title="Simcards"
            data={simcards}
            columns={newColumns}
            options={{ enableNestedDataAccess: '.' }}
            idKey={id}
            showViewButton={false}
            showEditButton={false}
          />
        )}
        {activeCard === 'emUso' && (
          <MUITSDatatable
            title="Simcards em uso"
            data={simcardsWithLocators}
            columns={newColumns}
            options={{ enableNestedDataAccess: '.' }}
            idKey={id}
            showViewButton={false}
            showEditButton={false}
          />
        )}
        {activeCard === 'disponiveis' && (
          <MUITSDatatable
            title="Simcards disponíveis"
            data={simcardsWithoutLocators}
            columns={newColumns}
            options={{ enableNestedDataAccess: '.' }}
            idKey={id}
            showViewButton={false}
            showEditButton={false}
          />
        )}
      </Grid>
      <Grid item xs={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RefetchStatusCard
              refetchInterval={REFETCH_INTERVAL}
              query={simcardsQuery}
            />
          </Grid>
          {/* align content on center */}
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Button size="small" variant="outlined" onClick={toogleRefetch}>
              {enabledAutoRefetch ? 'Desativar' : 'Ativar'} atualização
              automática
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <StatisticsCard
              number={simcardsTotal}
              legend="Simcards na plataforma"
              isClicked={activeCard === ''}
              onCardClick={() => handleSetActiveCard('')}
              setIsClicked={handleClearActiveCard}
            />
          </Grid>
          <Grid item xs={12}>
            <StatisticsCard
              number={simcardsWithLocators?.length}
              legend="Simcards em uso"
              isClicked={activeCard === 'emUso'}
              onCardClick={() => handleSetActiveCard('emUso')}
              setIsClicked={handleClearActiveCard}
            />
          </Grid>
          <Grid item xs={12}>
            <StatisticsCard
              number={simcardsWithoutLocators?.length}
              legend="Simcards disponíveis"
              isClicked={activeCard === 'disponiveis'}
              onCardClick={() => handleSetActiveCard('disponiveis')}
              setIsClicked={handleClearActiveCard}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SimcardsList;
