export enum ImageTpinameEnum {
  AFTER_INSTALL_LOCATOR_MEDIA = 'AFTER_INSTALL_LOCATOR_MEDIA',
  BEFORE_INSTALL_LOCATOR_MEDIA = 'BEFORE_INSTALL_LOCATOR_MEDIA',
  HOUR_METER = 'HOUR_METER',
  LOCATOR_INSTALLED = 'LOCATOR_INSTALLED',
  VEHICLE = 'VEHICLE',
  VEHICLE_LOCATOR_ATTACHMENT = 'VEHICLE_LOCATOR_ATTACHMENT',
}

const possibilities = [
  {
    id: '83f47b79-b3d2-41ec-8611-5219bf1f4205',
    value: ImageTpinameEnum.AFTER_INSTALL_LOCATOR_MEDIA,
    label: 'Após instalar o localizador',
  },
  {
    id: 'e6dd4f6d-0c66-454c-a422-feae15a1f59c',
    value: ImageTpinameEnum.BEFORE_INSTALL_LOCATOR_MEDIA,
    label: 'Antes de instalar o localizador',
  },
  {
    id: 'bd822ba2-aa95-4e6f-abe5-d0d63aa26329',
    value: ImageTpinameEnum.HOUR_METER,
    label: 'Horímetro',
  },
  {
    id: '90712edc-ecb0-4052-be9d-6dd397b08859',
    value: ImageTpinameEnum.LOCATOR_INSTALLED,
    label: 'Local de instalação',
  },
  {
    id: '6a2793d8-9379-4009-8830-546d3f19c064',
    value: ImageTpinameEnum.VEHICLE,
    label: 'GPU',
  },
  {
    id: '991a4ba0-6458-4703-a510-e6236582f013',
    value: ImageTpinameEnum.VEHICLE_LOCATOR_ATTACHMENT,
    label: 'Anexo do Localizador do Veículo',
  },
];

export function getImageName({
  tpi_name,
}: {
  tpi_name: string | ImageTpinameEnum;
}) {
  const found = possibilities.find((item) => item.value === tpi_name);
  return found ? found.label : '';
}
