/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface IErrorResponse {
  /**
   * Error message
   * @example ["Bad Request Error Message"]
   */
  message: string[];
  /**
   * Error code
   * @example 400
   */
  code: number;
  /** @example "Bad Request Error" */
  error: string;
}

export interface IApiUnauthorized {
  /**
   * HTTP Status
   * @default 401
   */
  statusCode: object;
  /**
   * message
   * @default "Unauthorized"
   */
  message: object;
}

export type IAirportDetail = object;

export interface ITypeEquipmentMaintenance {
  /**
   * Type equipment maintenance id
   * @format uuid
   */
  tem_id: string;
  /** Type equipment maintenance name */
  tem_name: string;
  /**
   * Type equipment maintenance duration in decimal hours used to calculate the next date of maintenance to set parameter eqm_forecast
   * @default 0
   */
  tem_duration_in_decimal_hours: number;
  /**
   * Creation date
   * @format date-time
   */
  created_at: string;
  /**
   * Last update date
   * @format date-time
   */
  updated_at: string;
  /**
   * Deletion date
   * @format date-time
   */
  deleted_at: string | null;
}

export interface ITypeVehicle {
  tpv_id: string;
  tpv_name: string;
  vehicle: IVehicle[];
}

export interface ISimManager {
  sim_id: string;
  sim_phone_number: string;
  sim_iccid: string;
  sim_pin: string;
  sim_pin2: string;
  sim_puk: string;
  sim_puk2: string;
  sim_apn: string;
  sim_apn_user: string;
  sim_apn_password: string;
  locator: ILocator;
}

export interface ILocatorHistory {
  /**
   * The ID of locator history
   * @format uuid
   * @example "0881b112-42b7-428d-844e-e1ef2e76b727"
   */
  lch_id: string;
  /**
   * The ID of the locator
   * @format uuid
   * @example "0881b112-42b7-428d-844e-e1ef2e76b727"
   */
  lct_id: string;
  /** The name of the event trigged by the locator */
  lch_type: string;
  /**
   * The raw string of the event
   * @example "+RESP:GTIGN,310201,153759012345670,gv65,1111,0,0.4,0,112.1,121.389694,31.166979,20150320093414,0000,0000,0000,0000,00,,0.6,20150320175239,0208$"
   */
  lch_raw_data: string;
  /**
   * The parsed date from the report event
   * @format date-time
   * @example "2024-06-11 00:00:00"
   */
  lch_report_date: string;
  locator: ILocator;
}

export interface ILocator {
  lct_id: string;
  sim_id: string;
  lct_imei: string;
  lct_password: string;
  lct_latitude: number;
  lct_longitude: number;
  vehicle: IVehicle;
  sim_card: ISimManager;
  history: ILocatorHistory[];
}

export interface ITypeImage {
  /** @format uuid */
  tpi_id: string;
  /** @maxLength 100 */
  tpi_name: string;
}

export interface IVehicleImages {
  /** @format uuid */
  vhi_id: string;
  /** @format uuid */
  vhc_id: string;
  /** @format uuid */
  tpi_id: string;
  /** @format url */
  vhi_url: string;
  vehicle: IVehicle;
  typeImage: ITypeImage;
}

export interface IVehicle {
  vhc_id: string;
  tpv_id: string;
  lct_id: string;
  vhc_name: string;
  vhc_fuel_avg_cons_lph: number;
  vhc_hour_meter: number;
  typeVehicle: ITypeVehicle;
  locator: ILocator;
  maintenances: IVehicleMaintenancePlanner[];
  images: IVehicleImages[];
}

export interface ITypeMaintenance {
  tpm_id: string;
  tpm_name: string;
  tpm_in_hours: number;
  tpm_description: string;
  vehicleMaintenancePlanner: IVehicleMaintenancePlanner[];
  /**
   * All emails that will receive notifications
   * @example "["email1@example.com", "email2@example.com"]"
   */
  notificationEmails: string[];
}

export interface IVehicleMaintenancePlanner {
  vmp_id: string;
  vhc_id: string;
  tpm_id: string;
  mts_id: string;
  vmp_hour_meter_forecast: number;
  vmp_notes: string[];
  /** @format date-time */
  vmp_execution_date: string;
  /** Stores the horimeter at the time of maintenance execution. */
  vmp_horimeter_at_done: number;
  vmp_completion_percentage: number;
  /**
   * Indicates if the maintenance planner is archived or not.
   * @default false
   */
  vmp_archived?: boolean;
  vehicle: IVehicle;
  typeMaintenance: ITypeMaintenance;
  maintenanceStatus: IMaintenanceStatus;
  /**
   * The date when this record was created
   * @format date-time
   */
  created_at: string;
  /**
   * The date when this record was last updated
   * @format date-time
   */
  updated_at: string;
  /**
   * The date when this record was deleted
   * @format date-time
   */
  deleted_at: string;
}

export interface IMaintenanceStatus {
  mts_id: string;
  mts_name: string;
  mts_constant: IMaintenanceStatusMtsConstantEnum;
  vehicleMaintenancePlanner: IVehicleMaintenancePlanner[];
  equipmentMaintenance: IEquipmentMaintenance[];
}

export interface IProfile {
  prf_id: string;
  prf_name: string;
  prf_constant: string;
}

export interface IUser {
  usu_id: string;
  prf_id: string;
  firebase_uid: string;
  usu_name: string;
  usu_email: string;
  profile: IProfile;
}

export interface IEquipmentMaintenanceHistory {
  /**
   * The date the record was created
   * @format date-time
   */
  created_at: string;
  /**
   * The date the record was last updated
   * @format date-time
   */
  updated_at: string;
  /**
   * The date the record was deleted
   * @format date-time
   */
  deleted_at: string;
  /** The user who performed the action */
  user: IUser;
  /** The equipment maintenance record */
  equipmentMaintenance: IEquipmentMaintenance;
}

export interface IEquipmentMaintenance {
  /**
   * Equipment maintenance id
   * @format uuid
   */
  eqm_id?: string | null;
  /**
   * Equipment id
   * @format uuid
   */
  eqp_id: string;
  /**
   * Type equipment maintenance id
   * @format uuid
   */
  tem_id: string;
  /**
   * Maintenance status id
   * @format uuid
   */
  mts_id: string;
  /** Equipment maintenance details */
  eqm_notes?: string[] | null;
  /**
   * Start date to considering to init the calculations
   * @format date-time
   */
  eqm_start: string;
  /**
   * Forecast date to execute the maintenance
   * @format date-time
   */
  eqm_forecast: string;
  /**
   * Date when the maintenance was executed. The API set this date automatically when the status of the maintenanche changes to DONE.
   * @format date-time
   */
  eqm_executed?: string | null;
  /**
   * Flag to indicate if the maintenance is archived
   * @default false
   */
  eqm_archived?: boolean;
  /**
   * Date when the record was created
   * @format date-time
   */
  created_at?: string;
  /**
   * Date when the record was updated
   * @format date-time
   */
  updated_at?: string;
  /**
   * Date when the record was deleted
   * @format date-time
   */
  deleted_at?: string | null;
  /** Equipment */
  equipment: IEquipment;
  /** Type equipment maintenance */
  typeMaintenance: ITypeEquipmentMaintenance;
  /** Maintenance status */
  maintenanceStatus: IMaintenanceStatus;
  /** Equipment maintenance history */
  history?: IEquipmentMaintenanceHistory[];
  /** Percentage of time left to execute the maintenance */
  eqm_percentage?: number | null;
}

export interface IEquipment {
  /** Equipment id */
  eqp_id?: string | null;
  /**
   * The Airport UUID this equipment belongs to
   * @pattern uuid
   */
  air_id?: string | null;
  /** Equipment name */
  eqp_name: string;
  /** Equipment tag */
  eqp_tag?: string | null;
  /** Equipment serial number */
  eqp_serial_number?: string | null;
  /** P/N field from equipment */
  eqp_pn?: string | null;
  /** Fleet field from equipment */
  eqp_fleet?: string | null;
  /** Equipment external id */
  eqp_external_id?: string | null;
  /** Equipment maintenances */
  maintenances: IEquipmentMaintenance[];
  /** The airport where the equipment is based */
  airport: IAirports | null;
}

export interface IAirports {
  /**
   * Unique ID of the airport
   * @format uuid
   */
  air_id: string;
  /**
   * IATA code of the airport
   * @maxLength 3
   * @example "GRU"
   */
  air_iata: string;
  /**
   * Full name of the airport
   * @example {"details":{"website":null,"visible":true,"code":{"iata":"GRU","icao":"SBGR"},"name":"Guarulhos International Airport","position":{"latitude":-23.435556,"longitude":-46.473056,"altitude":1000,"country":{"id":null,"name":"Brazil","code":"BR"},"region":{"city":"São Paulo"}},"timezone":{"offsetHours":"-3","offset":-3,"name":"America/Sao_Paulo","abbr":"BRST","isDst":false,"abbrName":"BRST"},"stats":{"arrivals":{"delayIndex":null,"delayAvg":null,"total":null,"hourly":[],"stats":[]},"departures":{"delayIndex":null,"delayAvg":null,"total":null,"hourly":[],"stats":[]}}}}
   */
  air_data?: IAirportDetail | null;
  /**
   * Latitude of the airport
   * @example -23.435556
   */
  air_latitude: number;
  /**
   * Longitude of the airport
   * @example -46.473056
   */
  air_longitude: number;
  /** Equipments based at the airport */
  equipments: IEquipment[];
}

export interface IAirportsResponse {
  /** List of airports */
  airports: IAirports[];
}

export interface IApiAudit {
  api_id: string;
  usu_id: string;
  api_method: string;
  api_url: string;
  api_payload: string;
}

export interface IAuditListApiResponse {
  total: object;
  page: object;
  pages: object;
  size: object;
  data: IApiAudit[];
}

export interface IEventsAudit {
  eva_id: string;
  eva_type: string;
  eva_data: string;
  eva_error: string;
}

export interface IAuditListEventsResponse {
  total: object;
  page: object;
  pages: object;
  size: object;
  data: IEventsAudit[];
}

export interface IOmitTypeClass {
  twl_id: string;
  usu_id: string;
  twl_to: string;
  twl_subject: string;
  twl_data_send: string;
  twl_data_received: string;
  twl_error: string;
  /**
   * Date of creation
   * @format date-time
   */
  created_at: string;
  /**
   * Date of last update
   * @format date-time
   */
  updated_at: string;
  /**
   * Date of deletion
   * @format date-time
   */
  deleted_at: string;
}

export interface IAuditListTwilioResponse {
  total: object;
  page: object;
  pages: object;
  size: object;
  data: IOmitTypeClass[];
}

export interface IVehicleHistory {
  vhh_id: string;
  vhc_id: string;
  vhc_name: string;
  tpv_name: string;
  vhc_fuel_avg_cons_lph: number;
  lct_id: string;
  vhh_iata: string;
  vhh_tail_number: string;
  vhh_latitude: number;
  vhh_longitude: number;
  vhh_fuel_consumed_in_liters: number;
  /** @format date-time */
  vhh_ignition_on_date: string;
  /** @format date-time */
  vhh_ignition_off_date: string;
  vhh_duration_in_seconds: number;
  vhh_raw_ignition_on_date: string;
  vhh_raw_ignition_off_date: string;
  vehicle: IVehicle;
  locator: ILocator;
}

export interface IIDistance {
  air_iata: string;
  distance: number;
}

export interface IIatageoResponse {
  name: string;
  code: string;
  IATA: string;
  ICAO: string;
  distance_meters: string;
}

export type IError = object;

export interface IICronAudit {
  vehicle_history: IVehicleHistory;
  airport: IIDistance;
  iatageo: IIatageoResponse;
  error: IError;
  message: string;
}

export interface ICrontabAudit {
  cron_id: string;
  cron_data: IICronAudit[];
}

export interface IAuditListTableSizes {
  /**
   * The numbers of rows in api_audit table
   * @example 10
   */
  api_audits?: number | null;
  /**
   * The numbers of rows in twilio_audit table
   * @example 10
   */
  crontab_audits?: number | null;
  /**
   * The numbers of rows in events_audit table
   * @example 10
   */
  events_audits?: number | null;
}

export interface IAuthDto {
  /**
   * The Firebase idToken provided by front-end integration
   * @format uid
   * @example "eyJhbGciOiJSUzI1NiIsImtpZCI6InRCME0yQSJ9.eyJpc3MiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGUuY29tLyIsImF1ZCI6ImdyaXR6a28tZDYzZjAiLCJpYXQiOjE2ODM0NjQwNzUsImV4cCI6MTY4NDY3MzY3NSwidXNlcl9pZCI6ImZiNHlmbXhyVnlaSzUxYkFLek55OXphbGFkQzIiLCJlbWFpbCI6ImNsaWVudGVAcGVuc2VhcHAuY29tLmJyIiwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIiwidmVyaWZpZWQiOmZhbHNlfQ.PAmIT2IYJQr69KQ2Nf9SU48CtEFhCfO2xn-XOeCB-CFyTIt5XfoaHDNHkwLm8oQH2QqyAR3wcve1QPMipzKEc6VaWYjPTrY6FVCRGk-IyGggxIhkGNRLeFpywXp-EH2rGk7bETf3bUS8yzvXvBQZ_UdjayCO8xzUhnqix_Y5sYHtCj-Qgf3EMmyR3wyE8nJHPxy-tm5y0nP65psN1wzTeSClRmcsBB8P-myOkPMG0mylJWxqdtH1D32cwBRlexvwi0MgFeF0miWaCrMb3mYyM9umATtJg4YByeJt1qzynFUp1EzQY0dlG2A_ag0DPuOhtpIbfFXFiEfEEy7eZcreIA"
   */
  firebase_idToken: string;
}

export interface IAuthLoginResponse {
  /** @format jwt */
  token: string;
  user: IOmitTypeClass;
}

export interface IAuthInfoResponse {
  user: IUser;
}

export interface IAuthExternalDto {
  email: string;
  password: string;
}

export interface IOption {
  id: string;
  value: string;
}

export interface IAutocompleteDefaultResponse {
  options: IOption[];
}

export interface IConfigResponse {
  conf_apn: string;
  conf_apn_user: string;
  conf_apn_password: string;
  conf_main_server_host: string;
  conf_main_server_port: string;
  conf_backup_server_host: string;
  conf_backup_server_port: string;
  conf_sms_gateway: string;
  conf_heartbeat_interval: string;
  conf_report_info_enable: string;
  conf_report_info_interval: string;
}

export interface IConfigUpsertDto {
  /**
   * The SIM card Access Point Network.
   * @maxLength 40
   */
  conf_apn: string;
  /**
   * The SIM card APN user name
   * @maxLength 30
   */
  conf_apn_user: string | null;
  /**
   * The SIM card APN password
   * @maxLength 30
   */
  conf_apn_password: string | null;
  /**
   * The main socket server host or IP
   * @maxLength 60
   */
  conf_main_server_host: string;
  /**
   * The main socket server port
   * @maxLength 5
   */
  conf_main_server_port: string;
  /**
   * The backup socket server host or IP
   * @maxLength 60
   */
  conf_backup_server_host: string;
  /**
   * The backup socket server port
   * @maxLength 5
   */
  conf_backup_server_port: string;
  /**
   * The SMS Gateway or phone number to be used as backup sms receiver
   * @maxLength 20
   */
  conf_sms_gateway: string;
  /**
   * The interval in seconds to heartbeat call
   * @maxLength 3
   */
  conf_heartbeat_interval: string;
  /**
   * The switch on/off to report info
   * @maxLength 1
   */
  conf_report_info_enable: IConfigUpsertDtoConfReportInfoEnableEnum;
  /**
   * The interval to send info report in seconds
   * @example "300"
   */
  conf_report_info_interval: string | null;
}

export interface IEquipmentListDetailedResponse {
  equipments: IEquipment[];
}

export interface IEquipmentListSimple {
  /** Equipment id */
  eqp_id?: string | null;
  /**
   * The Airport UUID this equipment belongs to
   * @pattern uuid
   */
  air_id?: string | null;
  /** Equipment name */
  eqp_name: string;
  /** Equipment tag */
  eqp_tag?: string | null;
  /** Equipment serial number */
  eqp_serial_number?: string | null;
  /** P/N field from equipment */
  eqp_pn?: string | null;
  /** Fleet field from equipment */
  eqp_fleet?: string | null;
  /** Equipment external id */
  eqp_external_id?: string | null;
  /** The airport where the equipment is based */
  airport: IAirports | null;
}

export interface IEquipmentListSimpleResponse {
  equipments: IEquipmentListSimple[];
}

export interface IEquipmentMaintenanceSimpleOrDetailed {
  /** Equipment */
  equipment?: IEquipment;
  /** Type equipment maintenance */
  typeMaintenance?: ITypeEquipmentMaintenance;
  /** Maintenance status */
  maintenanceStatus?: IMaintenanceStatus;
  /**
   * Equipment maintenance id
   * @format uuid
   */
  eqm_id?: string | null;
  /**
   * Equipment id
   * @format uuid
   */
  eqp_id: string;
  /**
   * Type equipment maintenance id
   * @format uuid
   */
  tem_id: string;
  /**
   * Maintenance status id
   * @format uuid
   */
  mts_id: string;
  /** Equipment maintenance details */
  eqm_notes?: string[] | null;
  /**
   * Start date to considering to init the calculations
   * @format date-time
   */
  eqm_start: string;
  /**
   * Forecast date to execute the maintenance
   * @format date-time
   */
  eqm_forecast: string;
  /**
   * Date when the maintenance was executed. The API set this date automatically when the status of the maintenanche changes to DONE.
   * @format date-time
   */
  eqm_executed?: string | null;
  /**
   * Flag to indicate if the maintenance is archived
   * @default false
   */
  eqm_archived?: boolean;
  /**
   * Date when the record was created
   * @format date-time
   */
  created_at?: string;
  /**
   * Date when the record was updated
   * @format date-time
   */
  updated_at?: string;
  /**
   * Date when the record was deleted
   * @format date-time
   */
  deleted_at?: string | null;
  /** Equipment maintenance history */
  history?: IEquipmentMaintenanceHistory[];
  /** Percentage of time left to execute the maintenance */
  eqm_percentage?: number | null;
}

export interface IEquipmentUpsertDto {
  /**
   * Equipment id, if emtpy will create a new equipment, otherwise will update the equipment
   * @format uuid
   */
  eqp_id?: string | null;
  /**
   * Airport id
   * @format uuid
   */
  air_id?: string | null;
  /**
   * eqp_name to filter equipment using like operator {eqp_name}%
   * @minLength 3
   * @maxLength 100
   */
  eqp_name: string;
  /**
   * eqp_tag to filter equipment
   * @maxLength 100
   */
  eqp_tag?: string | null;
  /**
   * eqp_serial_number to filter equipment
   * @maxLength 100
   */
  eqp_serial_number?: string | null;
  /**
   * eqp_pn to filter equipment
   * @maxLength 100
   */
  eqp_pn?: string | null;
  /**
   * eqp_fleet to filter equipment
   * @maxLength 100
   */
  eqp_fleet?: string | null;
  /**
   * eqp_external_id to filter equipment
   * @maxLength 100
   */
  eqp_external_id?: string | null;
}

export interface IEquipmentDeleteDto {
  /**
   * Equipment id you want to delete
   * @format uuid
   */
  eqp_id: string;
}

export interface IApiMessagesDto {
  /** @example ["Data excluded with success"] */
  messages: string[];
}

export interface IEquipmentMaintenanceListResponse {
  maintenances: IEquipmentMaintenanceSimpleOrDetailed[];
}

export interface IEquipmentMaintenanceUpsertDto {
  /**
   * The equipment maintenance UUID
   * @format uuid
   */
  eqm_id?: string | null;
  /**
   * The equipment UUID
   * @format uuid
   */
  eqp_id: string;
  /**
   * The equipment type maintenance UUID
   * @format uuid
   */
  tem_id: string;
  /**
   * The maintenance status UUID
   * @format uuid
   */
  mts_id: string;
  /** The equipment maintenance notes */
  eqm_notes?: string[] | null;
  /**
   * The equipment maintenance start date. MUST_NOT be in future
   * @format date-time
   * @default "2024-07-15T03:35:49.264Z"
   */
  eqm_start?: string;
  /**
   * The equipment maintenance forecast date. If null system will fill this field with addHours(eqm_start,tem_duration_in_decimal_hours)
   * @format date-time
   * @default null
   */
  eqm_forecast?: string | null;
  /**
   * The equipment maintenance executed date. MUST_NOT be in future
   * @format date-time
   */
  eqm_executed?: string | null;
  /**
   * The equipment maintenance archived flag to filter the list and show only active maintenance cards
   * @default false
   */
  eqm_archived?: boolean | null;
}

export interface IEquipmentMaintenanceArchivedDto {
  /** A List of equipment maintenance ids to be archived */
  eqm_ids: string[];
}

export interface IEquipmentMaintenanceDeleteDto {
  /**
   * The equipment maintenance UUID
   * @format uuid
   */
  eqm_id?: string | null;
}

export interface IEquipmentMaintenanceHistoryDto {
  /**
   * The uuid of the equipment maintenance item you want to get the history for
   * @format uuid
   */
  eqm_id: string;
}

export interface IEquipmentMaintenanceHistorySimpleResponse {
  /**
   * The date the record was created
   * @format date-time
   */
  created_at: string;
  /**
   * The date the record was last updated
   * @format date-time
   */
  updated_at: string;
  /**
   * The date the record was deleted
   * @format date-time
   */
  deleted_at: string;
  /** The user who performed the action */
  user: IUser;
}

export interface IEquipmentMaintenanceHistoryResponse {
  history: IEquipmentMaintenanceHistorySimpleResponse[];
}

export interface ILivenessResponse {
  /**
   * Status of the service in boolean
   * @example true
   */
  status: boolean;
  /**
   * The version of the api
   * @example "v1.0.0"
   */
  version: string;
}

export interface IReadinessIntegrationResponse {
  /**
   * The name of the integration
   * @example "Database"
   */
  name: string;
  /**
   * Status of the integration in boolean
   * @example true
   */
  status: boolean;
  /**
   * The time it took to complete the integration check in seconds
   * @example 0.2
   */
  response_time: number;
  /**
   * The url of the integration
   * @example "https://api.example.com/database"
   */
  url: string;
  /**
   * The error of the integration
   * @example {"message":"Database is not available"}
   */
  error: object;
}

export interface IReadinessResponse {
  /**
   * The name of the application
   * @example "Touchway API"
   */
  name: string;
  /**
   * The version of the api
   * @example "v1.0.0"
   */
  version: string;
  /**
   * Status of the service in boolean
   * @example true
   */
  status: boolean;
  /**
   * The date of the readiness check
   * @format date-time
   * @example "2024-07-15T03:35:49.244Z"
   */
  date: string;
  /**
   * The time it took to complete the readiness check in seconds
   * @example 0.333
   */
  duration: number;
  /**
   * The integrations used in the readiness check
   * @example [{"name":"Database","status":true,"response_time":0.2,"url":"https://api.example.com/database","error":null}]
   */
  integrations: IReadinessIntegrationResponse[];
}

export interface IImageItem {
  /** @format uuid */
  vhi_id: string;
  /** @format uuid */
  vhc_id: string;
  /** @format uuid */
  tpi_id: string;
  /** @format url */
  vhi_url: string;
  typeImage: ITypeImage;
}

export interface IImageListResponse {
  images: IImageItem[];
}

export interface IImageUpsertDto {
  /** @format uuid */
  vhi_id?: string;
  /** @format uuid */
  vhc_id: string;
  /** @format uuid */
  tpi_id: string;
  /** @maxLength 65535 */
  vhi_url: string;
}

export interface IImageDeleteDto {
  /** @format uuid */
  vhi_id: string;
}

export interface ILocatorListResponse {
  locators: ILocator[];
}

export interface ILocatorUpsertDto {
  /**
   * The locator UUID, if empty or not present will create a new locator, if present with valid UUID, will update locator values
   * @example "32fe5547-09c2-43be-bb0d-2a7ff5675c78"
   */
  lct_id: string | null;
  /**
   * The locator uniq identifier IMEI
   * @example "863830037906597"
   */
  lct_imei: string;
  /**
   * @minLength 4
   * @maxLength 20
   * @default "gv55"
   * @pattern ^[a-zA-Z0-9\-_]+$
   */
  lct_password: string;
  /**
   * The latitude of the locator. This prop is optional.
   * @default -22.123456
   */
  lct_latitude: number | null;
  /**
   * The locator longitude of the locator. This prop is optional
   * @example -47.123456
   */
  lct_longitude?: number;
}

export interface ILocatorDeleteDto {
  /**
   * A valid UUID for a locator ID
   * @example "a21114a9-a457-4085-a48a-5afddfac8e9d"
   */
  lct_id: string;
}

export interface ILocatorHistorySimple {
  /**
   * The ID of locator history
   * @format uuid
   * @example "0881b112-42b7-428d-844e-e1ef2e76b727"
   */
  lch_id: string;
  /**
   * The ID of the locator
   * @format uuid
   * @example "0881b112-42b7-428d-844e-e1ef2e76b727"
   */
  lct_id: string;
  /** The name of the event trigged by the locator */
  lch_type: string;
  /**
   * The raw string of the event
   * @example "+RESP:GTIGN,310201,153759012345670,gv65,1111,0,0.4,0,112.1,121.389694,31.166979,20150320093414,0000,0000,0000,0000,00,,0.6,20150320175239,0208$"
   */
  lch_raw_data: string;
  /**
   * The parsed date from the report event
   * @format date-time
   * @example "2024-06-11 00:00:00"
   */
  lch_report_date: string;
}

export interface ILocatorHistoryListResponse {
  locator_history: ILocatorHistorySimple[];
}

export interface IMaintenanceSimple {
  vmp_id: string;
  vhc_id: string;
  tpm_id: string;
  mts_id: string;
  vmp_hour_meter_forecast: number;
  vmp_notes: string[];
  /** @format date-time */
  vmp_execution_date: string;
  /** Stores the horimeter at the time of maintenance execution. */
  vmp_horimeter_at_done: number;
  vmp_completion_percentage: number;
  /**
   * Indicates if the maintenance planner is archived or not.
   * @default false
   */
  vmp_archived?: boolean;
  /**
   * The date when this record was created
   * @format date-time
   */
  created_at: string;
  /**
   * The date when this record was last updated
   * @format date-time
   */
  updated_at: string;
  /**
   * The date when this record was deleted
   * @format date-time
   */
  deleted_at: string;
}

export interface IMaintenanceSimpleResponse {
  maintenances: IMaintenanceSimple[];
}

export interface IMaintenanceDetailedResponse {
  maintenances: IVehicleMaintenancePlanner[];
}

export interface IMaintenanceUpsertDto {
  /**
   * The maintenance UUID
   * @format uuid
   * @example "a270baee-2463-429a-b07e-6f6627ace210"
   */
  vmp_id?: string | null;
  /**
   * The maintenance vehicle UUID
   * @format uuid
   * @example "a630eafb-3cc7-4486-8286-51544d4a80ad"
   */
  vhc_id: string;
  /**
   * The maintenance type UUID
   * @format uuid
   * @example "b7a6247b-68dc-4004-bfa2-1e4fdf02b13d"
   */
  tpm_id: string;
  /**
   * The maintenance status UUID
   * @format uuid
   * @example "889c33ff-55af-4c43-a9b7-2dc7bb6176b7"
   */
  mts_id: string;
  /**
   * The predicted hour meter to execute this maintenance. By default the backend calculate this value. Use this field only to override the default value!
   * @example 1000
   */
  vmp_hour_meter_forecast?: number;
  /**
   * The maintenance notes.
   * @example "Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo quibusdam sint culpa debitis commodi, odit praesentium consectetur delectus corporis accusamus doloribus inventore nemo modi deleniti? Molestiae ex at exercitationem repudiandae."
   */
  vmp_notes: any[];
  /**
   * The date of this maintenance was executed!
   * @format date-time
   * @example "2023-01-31 00:00:00"
   */
  vmp_execution_date?: string | null;
  /**
   * The vehicle maintenance archived flag to filter the list and show only active maintenance cards
   * @default false
   */
  vmp_archived?: boolean | null;
}

export interface IMaintenanceDeleteDto {
  /**
   * The maintenance UUID
   * @format uuid
   * @example "a270baee-2463-429a-b07e-6f6627ace210"
   */
  vmp_id: string;
  /**
   * The maintenance vehicle UUID
   * @format uuid
   * @example "a630eafb-3cc7-4486-8286-51544d4a80ad"
   */
  vhc_id: string;
}

export interface IMaintenanceTypeEmailUpdateDto {
  /**
   * The maintenance type UUID
   * @format uuid
   */
  tmp_id: string;
  /**
   * All emails that will receive notifications
   * @example "["email1@example.com", "email2@example.com"]"
   */
  notificationEmails: string[];
}

export interface IMaintenanceArchivedDto {
  /** List of vehicle maintenance planner ids */
  vmp_ids: string[];
}

export interface IParameters {
  /**
   * The parameter UUID key
   * @pattern uuid
   */
  prm_id?: string | null;
  /**
   * @pattern ^[A-Z0-9_]+$
   * @example "SOME_PARAMETER_KEY"
   */
  prm_key: string;
  /** @example "SOME_PARAMETER_VALUE" */
  prm_value: string;
  /**
   * The date the record was created
   * @format date-time
   */
  created_at: string;
  /**
   * The date the record was last updated
   * @format date-time
   */
  updated_at: string;
  /**
   * The date the record was deleted
   * @format date-time
   */
  deleted_at: string;
}

export interface IParametersListResponse {
  /** The list of parameters */
  parameters: IParameters[];
}

export interface IReportListDto {
  /** The airport IATA codes to generate report */
  iata?: string[];
  /**
   * The start date of report data
   * @format YYYY-MM-DD
   */
  start: string;
  /**
   * The date limit to list data
   * @format YYYY-MM-DD
   */
  end: string;
  /** An array of vehicle ids to filter the report */
  vhc_ids?: any[];
}

export interface IReportSimple {
  vhh_id: string;
  vhc_id: string;
  vhc_name: string;
  tpv_name: string;
  vhc_fuel_avg_cons_lph: number;
  lct_id: string;
  vhh_iata: string;
  vhh_tail_number: string;
  vhh_latitude: number;
  vhh_longitude: number;
  vhh_fuel_consumed_in_liters: number;
  /** @format date-time */
  vhh_ignition_on_date: string;
  /** @format date-time */
  vhh_ignition_off_date: string;
  vhh_duration_in_seconds: number;
  vhh_raw_ignition_on_date: string;
  vhh_raw_ignition_off_date: string;
}

export interface IReportFormatedHourMeter {
  /**
   * The hours part of the time
   * @example 2867
   */
  hours: number;
  /**
   * The minutes part of the time
   * @example 46
   */
  minutes: number;
  /**
   * The seconds part of the time
   * @example 40
   */
  seconds: number;
}

export interface IReportSums {
  /**
   * The total fuel consumed in liters
   * @example 34413.33
   */
  total_fuel_consumed_in_liters: number;
  /**
   * The total time in seconds
   * @example 10324000
   */
  total_seconds: number;
  /** The formatted hour meter */
  formated_hour_meter: IReportFormatedHourMeter;
}

export interface IReportByBase {
  /**
   * The index number + 1
   * @example "1"
   */
  index: number;
  /**
   * The total number of uses
   * @example 10
   */
  total_uses: number;
  /**
   * The total time in hours
   * @example 1032
   */
  total_hours: number;
  /**
   * The average time in hours
   * @example 103.2
   */
  average_hours: number;
  /**
   * The average fuel consumption in liters
   * @example 103.2
   */
  fuel_consumption: number;
  /**
   * The base IATA
   * @example "VCP"
   */
  iata: string;
}

export interface IReportByTail {
  /**
   * The index number + 1
   * @example "1"
   */
  index: number;
  /**
   * The total number of uses
   * @example 10
   */
  total_uses: number;
  /**
   * The total time in hours
   * @example 1032
   */
  total_hours: number;
  /**
   * The average time in hours
   * @example 103.2
   */
  average_hours: number;
  /**
   * The average fuel consumption in liters
   * @example 103.2
   */
  fuel_consumption: number;
  /**
   * The tail number
   * @example "PR-AAA"
   */
  tail_number: string;
}

export interface IReportSumBy {
  /**
   * The total number of uses
   * @example 10
   */
  total_uses: number;
  /**
   * The total time in hours
   * @example 1032
   */
  total_hours: number;
  /**
   * The average time in hours
   * @example 103.2
   */
  average_hours: number;
  /**
   * The average fuel consumption in liters
   * @example 103.2
   */
  fuel_consumption: number;
}

export interface IReportResponse {
  user: IUser;
  /** @format date-time */
  date: string;
  /** @example ["VCP","GRU"] */
  iata: string[];
  data: IReportSimple[];
  /** @example ["VHC-001","VHC-002"] */
  vehicle_names: string[];
  sums: IReportSums;
  report_by_base: IReportByBase[];
  report_by_tail: IReportByTail[];
  report_sum_by_base: IReportSumBy;
  report_sum_by_tail: IReportSumBy;
}

export type IBuffer = object;

export interface ISimSimple {
  sim_id: string;
  sim_phone_number: string;
  sim_iccid: string;
  sim_pin: string;
  sim_pin2: string;
  sim_puk: string;
  sim_puk2: string;
  sim_apn: string;
  sim_apn_user: string;
  sim_apn_password: string;
}

export interface ISimListSimpleResponse {
  simcards: ISimSimple;
}

export interface ISimListDetailedResponse {
  simcards: ISimManager;
}

export interface ISimUpsertDto {
  /** @format uuid */
  sim_id?: string;
  /**
   * The SIM card phone number
   * @minLength 14
   * @maxLength 20
   * @pattern +5519999999999
   */
  sim_phone_number: string;
  /**
   * The SIM card ICCID number provided in the SIM package
   * @minLength 20
   * @maxLength 20
   */
  sim_iccid: string;
  /**
   * The PIN number in the SIM card package
   * @minLength 4
   * @maxLength 4
   * @pattern /^[0-9]{4}$/
   */
  sim_pin: string;
  /**
   * The PIN2 number in the SIM card package
   * @minLength 4
   * @maxLength 4
   * @pattern ^[0-9]{4}$
   */
  sim_pin2: string;
  /**
   * The PUK number in the SIM card package
   * @minLength 4
   * @maxLength 4
   * @pattern ^[0-9]{8}$
   */
  sim_puk: string;
  /**
   * The PUK2 number in the SIM card package
   * @minLength 4
   * @maxLength 4
   * @pattern ^[0-9]{8}$
   */
  sim_puk2: string;
}

export interface ISimDeleteDto {
  /** @format uuid */
  sim_id: string;
}

export interface ISimRemoteConfigDto {
  /** List of SIM cards to be remote configured. If emtpy, all SIM numbers will be configured. */
  sim_ids?: any[] | null;
}

export interface ISimRemoteConfigResponse {
  /**
   * The amount of messages send with twilio integraion
   * @example 2
   */
  executed_messages: number;
}

export interface ITypeEquipmentMaintenanceListResponse {
  type_equipment_maintenances: ITypeEquipmentMaintenance[];
}

export interface ITypeEquipmentMaintenanceUpsertDto {
  /**
   * The Type Equipment Maintenance UUID. If empty, it will create a new record instead of updating it.
   * @pattern uuid
   */
  tem_id?: string;
  /**
   * The Type Equipment Maintenance Name
   * @minLength 3
   * @maxLength 100
   */
  tem_name: string;
  /** The Type Equipment Maintenance Duration in Decimal Hours */
  tem_duration_in_decimal_hours: number;
}

export interface ITypeEquipmentMaintenanceDeleteDto {
  /**
   * The Type Equipment Maintenance UUID
   * @pattern uuid
   */
  tem_id: string;
}

export interface IVehicleSimple {
  vhc_id: string;
  tpv_id: string;
  lct_id: string;
  vhc_name: string;
  vhc_fuel_avg_cons_lph: number;
  vhc_hour_meter: number;
}

export interface IVehicleListSimpleResponse {
  vehicles: IVehicleSimple[];
}

export interface IVehicleListDetailedResponse {
  vehicles: IVehicle[];
}

export interface IVehicleUpsertDto {
  /**
   * The vehicle UUID identifier. If not present or null, will create a new vehicle, if with valid uuid, will update vehicle values
   * @format uuid
   * @example "1d1ef7e0-b7d8-41b5-9b5b-53fc20928758"
   */
  vhc_id: string | null;
  /**
   * The type vehicle uuid
   * @format uuid
   * @example "353da056-538e-40f9-b2f3-258eb8921d7b"
   */
  tpv_id: string;
  /**
   * The locator UUID to bond with this vehicle
   * @format uuid
   * @example "c7e51434-fd28-4ee3-a362-7a4178b54c1c"
   */
  lct_id: string;
  /**
   * The name of this vehicle
   * @maxLength 100
   * @example "VEHICLE_01"
   */
  vhc_name: string;
  /**
   * The fuel average consumption in liters per hour
   * @example 0.5
   */
  vhc_fuel_avg_cons_lph: number;
  /**
   * The vehicle current hour meter value in decimal representation
   * @example 1000.123456
   */
  vhc_hour_meter: number;
}

export interface IVehicleDeleteDto {
  /**
   * The vehicle UUID identifier
   * @format uuid
   * @example "1d1ef7e0-b7d8-41b5-9b5b-53fc20928758"
   */
  vhc_id: string;
}

export interface IVehicleHourMeterUpdateDto {
  /**
   * The vehicle UUID identifier. If not present or null, will create a new vehicle, if with valid uuid, will update vehicle values
   * @format uuid
   * @example "1d1ef7e0-b7d8-41b5-9b5b-53fc20928758"
   */
  vhc_id: string;
  /**
   * The vehicle current hour meter value in decimal representation
   * @example 1000.123456
   */
  vhc_hour_meter: number;
}

export interface IAirplane {
  /** @pattern ^(?=.*[A-Z]{2})(?=.*[0-9]{4})[A-Z0-9]{6}$ */
  icao: string;
  /**
   * @min -90
   * @max 90
   * @pattern ^[0-9]{1,2}\.[0-9]{4}
   */
  latitude: string;
  /**
   * @min -180
   * @max 180
   * @pattern ^[0-9]{1,3}\.[0-9]{4}
   */
  longitude: string;
  /** @pattern ^(?=.*[A-Z]{1})(?=.*[A-Z0-9]{5})[A-Z]{1}\-[A-Z0-9]{5}$ */
  aircraft_type: string;
  /** @pattern ^[A-Z]{2}-[A-Z]{3}$ */
  registration: string;
  /** @pattern ^[A-Z]{3}$ */
  departure: string;
  /** @pattern ^[A-Z]{3}$ */
  arrival: string;
  /** @pattern ^[A-Z0-9]{6}$ */
  info: string;
  /** @pattern ^[A-Z]{3}[0-9]{4}$ */
  aircraft_code: string;
  distance: number;
}

export interface IValidateResponse {
  /** @pattern ^[A-Z]{3}$ */
  iata: string;
  /** @pattern ^[A-Z]{2}-[0-9]{3}$ */
  tail_number: string;
  aircrafts: IAirplane[];
}

export interface IEsperLocationDto {
  /** @example 1 */
  id: number;
  /** @example "-22.7578687000" */
  latitude: string;
  /** @example "-47.3443542000" */
  longitude: string;
  /** @example "582.60004" */
  altitude: string;
  /** @example "Americana" */
  city: string;
  /** @example "São Paulo" */
  state: string;
  /** @example "Brazil" */
  country: string;
  /** @example "13468-150" */
  zipcode: string;
  /** @example "2023-07-05T23:24:47.539831Z" */
  last_updated_on: string;
  /** @example null */
  status_id: object;
  /** @example "https://qbcnz-api.esper.cloud/api/enterprise/df320903-a130-4011-a455-9dcf0b418307/device/4366a56b-f626-4cf2-b26a-16c2b3729e0f/" */
  device: string;
  /** @example "https://qbcnz-api.esper.cloud/api/enterprise/df320903-a130-4011-a455-9dcf0b418307/" */
  enterprise: string;
}

export interface IEsperDeviceLocationDto {
  /** @example "07afc385-0329-4a43-be4b-9c2dd796e1a0" */
  device_id: string;
  /** @example "name" */
  device_name: string;
  location: IEsperLocationDto;
}

export enum IMaintenanceStatusMtsConstantEnum {
  PLANNED = 'PLANNED',
  DOING = 'DOING',
  DONE = 'DONE',
}

/**
 * The switch on/off to report info
 * @maxLength 1
 */
export enum IConfigUpsertDtoConfReportInfoEnableEnum {
  Value0 = 0,
  Value1 = 1,
}

/** The name of the event of the locator to search for */
export enum IAuditControllerListEventsParamsEvaTypeEnum {
  ValueRESPGTINF = '+RESP:GTINF',
  ValueBUFFGTIGF = '+BUFF:GTIGF',
  ValueRESPGTIGF = '+RESP:GTIGF',
  ValueBUFFGTIGN = '+BUFF:GTIGN',
  ValueRESPGTIGN = '+RESP:GTIGN',
  ValueRESPGTFRI = '+RESP:GTFRI',
}

/**
 * The equipment maintenance archived flag to filter the list and show only active maintenance cards
 * @default "false"
 */
export enum IEquipmentControllerMaintenanceListParamsEqmArchivedEnum {
  False = 'false',
  True = 'true',
  All = 'all',
}

/**
 * The vehicle maintenance archived flag to filter the list and show only active maintenance cards
 * @default "false"
 */
export enum IMaintenanceControllerListParamsVmpArchivedEnum {
  False = 'false',
  True = 'true',
  All = 'all',
}

export enum IGeolocationControllerGetGeolocatorParamsProviderEnum {
  Iatageo = 'iatageo',
  Flightradar24 = 'flightradar24',
}

export enum IGeolocationControllerGetPositionParamsProviderEnum {
  Iatageo = 'iatageo',
  Flightradar24 = 'flightradar24',
}

export enum IGeolocationControllerFixAirportParamsProviderEnum {
  Iatageo = 'iatageo',
  Flightradar24 = 'flightradar24',
}

import type {
  AxiosInstance,
  AxiosRequestConfig,
  HeadersDefaults,
  ResponseType,
} from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || '',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig,
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] =
        property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem),
        );
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body);
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { 'Content-Type': type }
            : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data);
  };
}

/**
 * @title Touchway API
 * @version 1.0.0
 * @contact
 *
 * # Wellcome to Touchway API
 *
 *
 * This `API` use the consept of `upset` that if in the request, the `uuid` is not present, them is a creation action, but if present, it's a update action.
 *
 * The services below has the objective of delivery a interface to `CRUD` `vehicles, maintenances, locators and SIM cards` to enable the `SOCKET` interface to receive the events of the locatos `GV55` to generate reports of `vehicles, IATAs, fuel consumption`.
 *
 * To generate a valid `TOKEN` to authorize those collections, you `MUST` login in the `FIREBASE` first, then use the `/auth/login` to generate the token. Once you has the token, use the button below `AUTHORIZE` and paste the value in the input, then click in `authorize` button!
 *
 *
 * _*Developers:* `@gritzkoo` `@joaomantovani`_
 */
export class Api<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  airport = {
    /**
     * No description
     *
     * @tags airport
     * @name AirportControllerList
     * @request GET:/airport
     * @secure
     * @response `200` `IAirportsResponse` Successfully retrieved airports
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    airportControllerList: (
      query?: {
        /** A string to find airport by iata. Used a condition `ILIKE 'air_iata%' */
        air_iata?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<IAirportsResponse, IErrorResponse | IApiUnauthorized>({
        path: `/airport`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  audits = {
    /**
     * No description
     *
     * @tags audits
     * @name AuditControllerListApiAudits
     * @request GET:/audits/api
     * @secure
     * @response `200` `IAuditListApiResponse` List api_audit records by criteria
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    auditControllerListApiAudits: (
      query: {
        /**
         * The page is the number to paginate response
         * @min 1
         */
        page: number;
        /**
         * The ammount of data in the result
         * @min 10
         * @max 100
         */
        size: number;
        /** User UUID */
        usu_id: string;
        /** One of the API endpoints URI like `/maintenance/list` */
        api_url: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<IAuditListApiResponse, IErrorResponse | IApiUnauthorized>({
        path: `/audits/api`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags audits
     * @name AuditControllerListEvents
     * @request GET:/audits/events
     * @secure
     * @response `200` `IAuditListEventsResponse` List events_audit records by criteria
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    auditControllerListEvents: (
      query: {
        /**
         * The page is the number to paginate response
         * @min 1
         */
        page: number;
        /**
         * The ammount of data in the result
         * @min 10
         * @max 100
         */
        size: number;
        /** The name of the event of the locator to search for */
        eva_type?: IAuditControllerListEventsParamsEvaTypeEnum;
        /** The locator IMEI to filter events for */
        lct_imei?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<IAuditListEventsResponse, IErrorResponse | IApiUnauthorized>(
        {
          path: `/audits/events`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        },
      ),

    /**
     * No description
     *
     * @tags audits
     * @name AuditControllerListTwilio
     * @request GET:/audits/twilio
     * @secure
     * @response `200` `IAuditListTwilioResponse` List twilio_autid records by criteria
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    auditControllerListTwilio: (
      query: {
        /**
         * The page is the number to paginate response
         * @min 1
         */
        page: number;
        /**
         * The ammount of data in the result
         * @min 10
         * @max 100
         */
        size: number;
        /** A valid User UUID */
        usu_id?: string;
        /** A valid phone number like `+5511123456789` */
        twl_to?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<IAuditListTwilioResponse, IErrorResponse | IApiUnauthorized>(
        {
          path: `/audits/twilio`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        },
      ),

    /**
     * No description
     *
     * @tags audits
     * @name AuditControllerListCrontab
     * @request GET:/audits/crontab
     * @secure
     * @response `200` `ICrontabAudit` Get the last crontab
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    auditControllerListCrontab: (params: RequestParams = {}) =>
      this.request<ICrontabAudit, IErrorResponse | IApiUnauthorized>({
        path: `/audits/crontab`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags audits
     * @name AuditControllerGetSize
     * @request GET:/audits/size
     * @secure
     * @response `200` `IAuditListTableSizes` Get the number of rows in tables: (api_audit, twilio_audit, events_audit)
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    auditControllerGetSize: (params: RequestParams = {}) =>
      this.request<IAuditListTableSizes, IErrorResponse | IApiUnauthorized>({
        path: `/audits/size`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  auth = {
    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerLogin
     * @request POST:/auth/login
     * @response `200` `IAuthLoginResponse` Login successful
     * @response `400` `IErrorResponse` Bad request
     */
    authControllerLogin: (data: IAuthDto, params: RequestParams = {}) =>
      this.request<IAuthLoginResponse, IErrorResponse>({
        path: `/auth/login`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerValidate
     * @request GET:/auth/info
     * @secure
     * @response `200` `IAuthInfoResponse`
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    authControllerValidate: (params: RequestParams = {}) =>
      this.request<IAuthInfoResponse, IErrorResponse | IApiUnauthorized>({
        path: `/auth/info`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerFblogin
     * @request POST:/auth/fblogin
     * @response `200` `IAuthLoginResponse` Login flux to use 2 steps login in firebase, backend acting like front-end requesting fb token then returning firebase token
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    authControllerFblogin: (
      data: IAuthExternalDto,
      params: RequestParams = {},
    ) =>
      this.request<IAuthLoginResponse, IErrorResponse | IApiUnauthorized>({
        path: `/auth/fblogin`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  autocomplete = {
    /**
     * No description
     *
     * @tags autocomplete
     * @name AutocompleteControllerSimcards
     * @request GET:/autocomplete/simcards
     * @secure
     * @response `200` `IAutocompleteDefaultResponse` List `SIM CARDS` as options
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    autocompleteControllerSimcards: (
      query?: {
        /**
         * Used to filter SIM cards by phone number. Condigion `ILIKE phone_number%` or any number that initiates with.
         * @example "+5519"
         */
        phone_number?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        IAutocompleteDefaultResponse,
        IErrorResponse | IApiUnauthorized
      >({
        path: `/autocomplete/simcards`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags autocomplete
     * @name AutocompleteControllerLocators
     * @request GET:/autocomplete/locators
     * @secure
     * @response `200` `IAutocompleteDefaultResponse` List `LOCATORS` as options
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    autocompleteControllerLocators: (
      query?: {
        /**
         * Used to filter Locators by imei. Comdition `ILIKE imei%`
         * @minLength 3
         */
        imei?: string | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        IAutocompleteDefaultResponse,
        IErrorResponse | IApiUnauthorized
      >({
        path: `/autocomplete/locators`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags autocomplete
     * @name AutocompleteControllerTypeVehicles
     * @request GET:/autocomplete/type-vehicles
     * @secure
     * @response `200` `IAutocompleteDefaultResponse` List `TYPE VEHICLES` as options
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    autocompleteControllerTypeVehicles: (params: RequestParams = {}) =>
      this.request<
        IAutocompleteDefaultResponse,
        IErrorResponse | IApiUnauthorized
      >({
        path: `/autocomplete/type-vehicles`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags autocomplete
     * @name AutocompleteControllerTypeMaintenance
     * @request GET:/autocomplete/type-maintenances
     * @secure
     * @response `200` `IAutocompleteDefaultResponse` List `TYPE MAINTENANCE` as options
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    autocompleteControllerTypeMaintenance: (params: RequestParams = {}) =>
      this.request<
        IAutocompleteDefaultResponse,
        IErrorResponse | IApiUnauthorized
      >({
        path: `/autocomplete/type-maintenances`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags autocomplete
     * @name AutocompleteControllerStatusMaintenance
     * @request GET:/autocomplete/status-maintenances
     * @secure
     * @response `200` `IAutocompleteDefaultResponse` List `Status MAINTENANCE` as options
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    autocompleteControllerStatusMaintenance: (params: RequestParams = {}) =>
      this.request<
        IAutocompleteDefaultResponse,
        IErrorResponse | IApiUnauthorized
      >({
        path: `/autocomplete/status-maintenances`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags autocomplete
     * @name AutocompleteControllerProfiles
     * @request GET:/autocomplete/profiles
     * @secure
     * @response `200` `IAutocompleteDefaultResponse` List `PROFILES` as options
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    autocompleteControllerProfiles: (params: RequestParams = {}) =>
      this.request<
        IAutocompleteDefaultResponse,
        IErrorResponse | IApiUnauthorized
      >({
        path: `/autocomplete/profiles`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags autocomplete
     * @name AutocompleteControllerVehicles
     * @request GET:/autocomplete/vehicles
     * @secure
     * @response `200` `IAutocompleteDefaultResponse` List `VEHICLES` as options
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    autocompleteControllerVehicles: (
      query?: {
        /**
         * Used to filter vehicles by name. Condigion `ILIKE vhc_name%`
         * @minLength 3
         */
        vhc_name?: string | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        IAutocompleteDefaultResponse,
        IErrorResponse | IApiUnauthorized
      >({
        path: `/autocomplete/vehicles`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags autocomplete
     * @name AutocompleteControllerAirports
     * @request GET:/autocomplete/airports
     * @secure
     * @response `200` `IAutocompleteDefaultResponse` List `AIRPORTS` as options
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    autocompleteControllerAirports: (
      query?: {
        /** @pattern /[A-Z]{3}/ */
        air_iata?: string[];
        /** @default 10 */
        limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        IAutocompleteDefaultResponse,
        IErrorResponse | IApiUnauthorized
      >({
        path: `/autocomplete/airports`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags autocomplete
     * @name AutocompleteControllerTypeImages
     * @request GET:/autocomplete/type-images
     * @secure
     * @response `200` `IAutocompleteDefaultResponse` List `TYPE_IMAGES` as options
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    autocompleteControllerTypeImages: (params: RequestParams = {}) =>
      this.request<
        IAutocompleteDefaultResponse,
        IErrorResponse | IApiUnauthorized
      >({
        path: `/autocomplete/type-images`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags autocomplete
     * @name AutocompleteControllerTypeEquipmentMaintenance
     * @request GET:/autocomplete/type-equipment-maintenance
     * @secure
     * @response `200` `IAutocompleteDefaultResponse` List `type_equipments_maintenance` as options
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    autocompleteControllerTypeEquipmentMaintenance: (
      params: RequestParams = {},
    ) =>
      this.request<
        IAutocompleteDefaultResponse,
        IErrorResponse | IApiUnauthorized
      >({
        path: `/autocomplete/type-equipment-maintenance`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags autocomplete
     * @name AutocompleteControllerAirportList
     * @request GET:/autocomplete/airport-list
     * @secure
     * @response `200` `IAutocompleteDefaultResponse` List `AIRPORTS` as options
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    autocompleteControllerAirportList: (
      query?: {
        /** @pattern /[A-Z]{3}/ */
        air_iata?: string[];
        /** @default 10 */
        limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        IAutocompleteDefaultResponse,
        IErrorResponse | IApiUnauthorized
      >({
        path: `/autocomplete/airport-list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  config = {
    /**
     * No description
     *
     * @tags config
     * @name ConfigControllerList
     * @request GET:/config
     * @secure
     * @response `200` `IConfigResponse` Successfully retrieved config
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    configControllerList: (params: RequestParams = {}) =>
      this.request<IConfigResponse, IErrorResponse | IApiUnauthorized>({
        path: `/config`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags config
     * @name ConfigControllerUpsert
     * @request POST:/config
     * @secure
     * @response `200` `IConfigResponse` Successfully upserted config
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    configControllerUpsert: (
      data: IConfigUpsertDto,
      params: RequestParams = {},
    ) =>
      this.request<IConfigResponse, IErrorResponse | IApiUnauthorized>({
        path: `/config`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  equipment = {
    /**
     * No description
     *
     * @tags equipment
     * @name EquipmentControllerList
     * @request GET:/equipment/list
     * @secure
     * @response `200` `(IEquipmentListDetailedResponse | IEquipmentListSimpleResponse)` When `with_relations: false` then response `EquipmentListSimpleResponse`When `with_relations: true` then response `EquipmentListDetailedResponse`
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    equipmentControllerList: (
      query?: {
        /**
         * Equipment id
         * @format uuid
         */
        eqp_id?: string | null;
        /** eqp_name to filter equipment */
        eqp_name?: string | null;
        /** eqp_tag to filter equipment */
        eqp_tag?: string | null;
        /** eqp_serial_number to filter equipment */
        eqp_serial_number?: string | null;
        /** eqp_pn to filter equipment */
        eqp_pn?: string | null;
        /** eqp_fleet to filter equipment */
        eqp_fleet?: string | null;
        /** eqp_external_id to filter equipment */
        eqp_external_id?: string | null;
        /**
         * A flag to return relationships of the equipmet like maintenances and type_maintenance
         * @default false
         * @example true
         */
        with_relations?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        IEquipmentListDetailedResponse | IEquipmentListSimpleResponse,
        IErrorResponse | IApiUnauthorized
      >({
        path: `/equipment/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags equipment
     * @name EquipmentControllerUpsert
     * @request POST:/equipment/upsert
     * @secure
     * @response `200` `IEquipmentListSimple` Upserted equipment
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    equipmentControllerUpsert: (
      data: IEquipmentUpsertDto,
      params: RequestParams = {},
    ) =>
      this.request<IEquipmentListSimple, IErrorResponse | IApiUnauthorized>({
        path: `/equipment/upsert`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags equipment
     * @name EquipmentControllerDelete
     * @request POST:/equipment/delete
     * @secure
     * @response `200` `IApiMessagesDto` Deleted equipment
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    equipmentControllerDelete: (
      data: IEquipmentDeleteDto,
      params: RequestParams = {},
    ) =>
      this.request<IApiMessagesDto, IErrorResponse | IApiUnauthorized>({
        path: `/equipment/delete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags equipment
     * @name EquipmentControllerMaintenanceList
     * @request GET:/equipment/maintenance/list
     * @secure
     * @response `200` `IEquipmentMaintenanceListResponse` List of equipment maintenance
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    equipmentControllerMaintenanceList: (
      query?: {
        /**
         * The equipment maintenance UUID
         * @format uuid
         */
        eqm_id?: string | null;
        /**
         * The equipment UUID
         * @format uuid
         */
        eqp_id?: string | null;
        /**
         * The equipment type maintenance UUID
         * @format uuid
         */
        tem_id?: string | null;
        /**
         * The maintenance type UUID
         * @format uuid
         */
        mts_id?: string | null;
        /**
         * The equipment maintenance archived flag to filter the list and show only active maintenance cards
         * @default "false"
         */
        eqm_archived?: IEquipmentControllerMaintenanceListParamsEqmArchivedEnum;
        /**
         * The date the maintenance was executed. Will use only YYYY-MM-DD and ignore the hour on date
         * @format date-time
         */
        eqm_executed?: string | null;
        /**
         * The date of forecast. Will use only Will use only YYYY-MM-DD and ignore the hour on date
         * @format date-time
         */
        eqm_forecast?: string | null;
        /**
         * The date of start of the givem maintenance. Will use only YYYY-MM-DD and ignore the hour on date
         * @format date-time
         * @default "CURRENT_TIMESTAMP"
         */
        eqm_start?: string | null;
        /**
         * A flag to return relationships of the vehicle_maintenance_planner like type_maintenance maintenance_status
         * @default false
         * @example true
         */
        with_relations?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        IEquipmentMaintenanceListResponse,
        IErrorResponse | IApiUnauthorized
      >({
        path: `/equipment/maintenance/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags equipment
     * @name EquipmentControllerMaintenanceUpsert
     * @request POST:/equipment/maintenance/upsert
     * @secure
     * @response `200` `IEquipmentMaintenanceSimpleOrDetailed` Upserted equipment maintenance
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    equipmentControllerMaintenanceUpsert: (
      data: IEquipmentMaintenanceUpsertDto,
      params: RequestParams = {},
    ) =>
      this.request<
        IEquipmentMaintenanceSimpleOrDetailed,
        IErrorResponse | IApiUnauthorized
      >({
        path: `/equipment/maintenance/upsert`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags equipment
     * @name EquipmentControllerMaintenanceArchived
     * @request POST:/equipment/maintenance/archived
     * @secure
     * @response `200` `IApiMessagesDto` Archived equipment maintenance
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    equipmentControllerMaintenanceArchived: (
      data: IEquipmentMaintenanceArchivedDto,
      params: RequestParams = {},
    ) =>
      this.request<IApiMessagesDto, IErrorResponse | IApiUnauthorized>({
        path: `/equipment/maintenance/archived`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags equipment
     * @name EquipmentControllerMaintenanceDelete
     * @request POST:/equipment/maintenance/delete
     * @secure
     * @response `200` `IApiMessagesDto` Deleted equipment
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    equipmentControllerMaintenanceDelete: (
      data: IEquipmentMaintenanceDeleteDto,
      params: RequestParams = {},
    ) =>
      this.request<IApiMessagesDto, IErrorResponse | IApiUnauthorized>({
        path: `/equipment/maintenance/delete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags equipment
     * @name EquipmentControllerMaintenanceHistory
     * @request GET:/equipment/maintenance/history
     * @secure
     * @response `200` `IEquipmentMaintenanceHistoryResponse` History of equipment maintenance
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    equipmentControllerMaintenanceHistory: (
      data: IEquipmentMaintenanceHistoryDto,
      params: RequestParams = {},
    ) =>
      this.request<
        IEquipmentMaintenanceHistoryResponse,
        IErrorResponse | IApiUnauthorized
      >({
        path: `/equipment/maintenance/history`,
        method: 'GET',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  healthCheck = {
    /**
     * No description
     *
     * @tags health-check
     * @name HealthCheckControllerLiveness
     * @request GET:/health-check/liveness
     * @response `200` `ILivenessResponse` Liveness check
     */
    healthCheckControllerLiveness: (params: RequestParams = {}) =>
      this.request<ILivenessResponse, any>({
        path: `/health-check/liveness`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags health-check
     * @name HealthCheckControllerReadiness
     * @request GET:/health-check/readiness
     * @response `200` `IReadinessResponse` Readiness check
     */
    healthCheckControllerReadiness: (params: RequestParams = {}) =>
      this.request<IReadinessResponse, any>({
        path: `/health-check/readiness`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  images = {
    /**
     * No description
     *
     * @tags images
     * @name ImageControllerList
     * @request GET:/images/list
     * @secure
     * @response `200` `IImageListResponse` List of images
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    imageControllerList: (
      query: {
        /** @format uuid */
        vhc_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<IImageListResponse, IErrorResponse | IApiUnauthorized>({
        path: `/images/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags images
     * @name ImageControllerUpsert
     * @request POST:/images/upsert
     * @secure
     * @response `200` `IImageItem` Upsert image
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    imageControllerUpsert: (
      data: IImageUpsertDto,
      params: RequestParams = {},
    ) =>
      this.request<IImageItem, IErrorResponse | IApiUnauthorized>({
        path: `/images/upsert`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags images
     * @name ImageControllerDelete
     * @request POST:/images/delete
     * @secure
     * @response `200` `IApiMessagesDto` Image deleted
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    imageControllerDelete: (
      data: IImageDeleteDto,
      params: RequestParams = {},
    ) =>
      this.request<IApiMessagesDto, IErrorResponse | IApiUnauthorized>({
        path: `/images/delete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  locator = {
    /**
     * No description
     *
     * @tags locator
     * @name LocatorControllerList
     * @request GET:/locator/list
     * @secure
     * @response `200` `ILocatorListResponse` List of locators
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    locatorControllerList: (
      query: {
        /**
         * The locator IMEI number
         * @example "12345678912345"
         */
        lct_imei: string | null;
        /**
         * The locator phone number
         * @example "11123456789"
         */
        lct_cell_number: string | null;
        /**
         * A flag to return relationships of the sim_manager
         * @default false
         * @example true
         */
        with_relations?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ILocatorListResponse, IErrorResponse | IApiUnauthorized>({
        path: `/locator/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags locator
     * @name LocatorControllerCreate
     * @request POST:/locator/upsert
     * @secure
     * @response `200` `ILocator` Upsert locator
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    locatorControllerCreate: (
      data: ILocatorUpsertDto,
      params: RequestParams = {},
    ) =>
      this.request<ILocator, IErrorResponse | IApiUnauthorized>({
        path: `/locator/upsert`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags locator
     * @name LocatorControllerDelete
     * @request POST:/locator/delete
     * @secure
     * @response `200` `IApiMessagesDto` Delete locator
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    locatorControllerDelete: (
      data: ILocatorDeleteDto,
      params: RequestParams = {},
    ) =>
      this.request<IApiMessagesDto, IErrorResponse | IApiUnauthorized>({
        path: `/locator/delete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags locator
     * @name LocatorControllerHistory
     * @request GET:/locator/history
     * @secure
     * @response `200` `ILocatorHistoryListResponse` List of locators history
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    locatorControllerHistory: (
      query?: {
        /**
         * Used to filter a single locator data
         * @format uuid
         */
        lct_id?: string;
        /**
         * The start date of history data
         * @format YYYY-MM-DD
         */
        start?: string | null;
        /**
         * The date limit to list history data
         * @format YYYY-MM-DD
         */
        end?: string;
        /**
         * Used to limit the number os rows of the given locator history
         * @min 10
         * @max 1000
         * @default 100
         */
        limit?: number | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        ILocatorHistoryListResponse,
        IErrorResponse | IApiUnauthorized
      >({
        path: `/locator/history`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  maintenance = {
    /**
     * No description
     *
     * @tags maintenance
     * @name MaintenanceControllerList
     * @request GET:/maintenance/list
     * @secure
     * @response `200` `(IMaintenanceSimpleResponse | IMaintenanceDetailedResponse)` If `with_relations: false` then response `MaintenanceSimpleResponse`If `with_relations: true` then response `MaintenanceDetailedResponse`
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    maintenanceControllerList: (
      query?: {
        /**
         * The vehicle UUID to filter maintenance resuts. Must be a valid vehicle UUID.
         * @format uuid
         * @example "74cb3d53-bf72-4696-8529-0b73cddc7ec5"
         */
        vhc_id?: string;
        /**
         * Used to filter maintenances by type. Must be a valid type maintenance UUID.
         * @format uuid
         * @example "1b3131e0-95a2-4390-b7df-389dc18d4495"
         */
        tpm_id?: string;
        /**
         * Used to filter maintenances by status. Must be a valid maintenance status UUID.
         * @format uuid
         * @example "b25d380f-ca27-4def-b212-cb2fe0ab8455"
         */
        mts_id?: string;
        /**
         * The date of conclusion
         * @format date-time
         * @example "2023-01-31 00:00:00"
         */
        vmp_execution_date?: string;
        /**
         * A flag to return relationships of the vehicle_maintenance_planner like type_maintenance maintenance_status
         * @default false
         * @example true
         */
        with_relations?: boolean;
        /**
         * The vehicle maintenance archived flag to filter the list and show only active maintenance cards
         * @default "false"
         */
        vmp_archived?: IMaintenanceControllerListParamsVmpArchivedEnum;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        IMaintenanceSimpleResponse | IMaintenanceDetailedResponse,
        IErrorResponse | IApiUnauthorized
      >({
        path: `/maintenance/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags maintenance
     * @name MaintenanceControllerUpsert
     * @request POST:/maintenance/upsert
     * @secure
     * @response `200` `IMaintenanceSimple` Upsert maintenance
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    maintenanceControllerUpsert: (
      data: IMaintenanceUpsertDto,
      params: RequestParams = {},
    ) =>
      this.request<IMaintenanceSimple, IErrorResponse | IApiUnauthorized>({
        path: `/maintenance/upsert`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags maintenance
     * @name MaintenanceControllerDelete
     * @request POST:/maintenance/delete
     * @secure
     * @response `200` `IApiMessagesDto` Maintenance deleted
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    maintenanceControllerDelete: (
      data: IMaintenanceDeleteDto,
      params: RequestParams = {},
    ) =>
      this.request<IApiMessagesDto, IErrorResponse | IApiUnauthorized>({
        path: `/maintenance/delete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags maintenance
     * @name MaintenanceControllerUpdateEmails
     * @request POST:/maintenance/update-emails
     * @secure
     * @response `200` `IApiMessagesDto` Emails updated
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    maintenanceControllerUpdateEmails: (
      data: IMaintenanceTypeEmailUpdateDto,
      params: RequestParams = {},
    ) =>
      this.request<IApiMessagesDto, IErrorResponse | IApiUnauthorized>({
        path: `/maintenance/update-emails`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags maintenance
     * @name MaintenanceControllerTypes
     * @request GET:/maintenance/types
     * @secure
     * @response `200` `(ITypeMaintenance)[]` List of maintenance types
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    maintenanceControllerTypes: (params: RequestParams = {}) =>
      this.request<ITypeMaintenance[], IErrorResponse | IApiUnauthorized>({
        path: `/maintenance/types`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags maintenance
     * @name MaintenanceControllerArchived
     * @request POST:/maintenance/archived
     * @secure
     * @response `200` `IApiMessagesDto` Maintenance archived
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    maintenanceControllerArchived: (
      data: IMaintenanceArchivedDto,
      params: RequestParams = {},
    ) =>
      this.request<IApiMessagesDto, IErrorResponse | IApiUnauthorized>({
        path: `/maintenance/archived`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  parameters = {
    /**
     * No description
     *
     * @tags parameters
     * @name ParametersControllerList
     * @request GET:/parameters/list
     * @secure
     * @response `200` `IParametersListResponse` List of parameters
     * @response `400` `IErrorResponse` Bad request
     * @response `403` `IApiUnauthorized` Unauthorized response
     */
    parametersControllerList: (params: RequestParams = {}) =>
      this.request<IParametersListResponse, IErrorResponse | IApiUnauthorized>({
        path: `/parameters/list`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags parameters
     * @name ParametersControllerUpsert
     * @request POST:/parameters/upsert
     * @secure
     * @response `200` `IParameters` Upsert parameters
     * @response `400` `IErrorResponse` Bad request
     * @response `403` `IApiUnauthorized` Unauthorized response
     */
    parametersControllerUpsert: (params: RequestParams = {}) =>
      this.request<IParameters, IErrorResponse | IApiUnauthorized>({
        path: `/parameters/upsert`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags parameters
     * @name ParametersControllerDelete
     * @request POST:/parameters/delete
     * @secure
     * @response `200` `IApiMessagesDto` Parameters deleted
     * @response `400` `IErrorResponse` Bad request
     * @response `403` `IApiUnauthorized` Unauthorized response
     */
    parametersControllerDelete: (params: RequestParams = {}) =>
      this.request<IApiMessagesDto, IErrorResponse | IApiUnauthorized>({
        path: `/parameters/delete`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  report = {
    /**
     * No description
     *
     * @tags report
     * @name ReportControllerReport
     * @request POST:/report
     * @secure
     * @response `200` `IReportResponse` Report generated
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    reportControllerReport: (
      data: IReportListDto,
      params: RequestParams = {},
    ) =>
      this.request<IReportResponse, IErrorResponse | IApiUnauthorized>({
        path: `/report`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags report
     * @name ReportControllerGetReport
     * @request POST:/report/excel
     * @secure
     * @response `200` `IBuffer` Excel report generated successfully
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    reportControllerGetReport: (
      data: IReportListDto,
      params: RequestParams = {},
    ) =>
      this.request<IBuffer, IErrorResponse | IApiUnauthorized>({
        path: `/report/excel`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags report
     * @name ReportControllerGetVehicleLastIgnition
     * @request GET:/report/vehicles/last-ignition
     * @secure
     * @response `200` `IReportResponse` Last ignition report
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    reportControllerGetVehicleLastIgnition: (params: RequestParams = {}) =>
      this.request<IReportResponse, IErrorResponse | IApiUnauthorized>({
        path: `/report/vehicles/last-ignition`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  simcards = {
    /**
     * No description
     *
     * @tags simcards
     * @name SimControllerList
     * @request GET:/simcards/list
     * @secure
     * @response `200` `(ISimListSimpleResponse | ISimListDetailedResponse)` If `with_relations: false` then response `SimListSimpleResponse`If `with_relations: true` then response `SimListDetailedResponse`
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    simControllerList: (
      query?: {
        /**
         * Used to list SIMs with they related locators
         * @default false
         */
        with_relations?: boolean;
        /** @format uuid */
        sim_id?: string;
        /**
         * The SIM card phone number
         * @maxLength 20
         * @pattern ILIKE %sim_phone_number%
         */
        sim_phone_number?: string;
        /**
         * The SIM card ICCID number provided in the SIM package
         * @minLength 20
         * @maxLength 20
         */
        sim_iccid?: string;
        /**
         * The PIN number in the SIM card package
         * @minLength 4
         * @maxLength 4
         */
        sim_pin?: string;
        /**
         * The PIN2 number in the SIM card package
         * @minLength 4
         * @maxLength 4
         */
        sim_pin2?: string;
        /**
         * The PUK number in the SIM card package
         * @minLength 4
         * @maxLength 4
         */
        sim_puk?: string;
        /**
         * The PUK2 number in the SIM card package
         * @minLength 4
         * @maxLength 4
         */
        sim_puk2?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        ISimListSimpleResponse | ISimListDetailedResponse,
        IErrorResponse | IApiUnauthorized
      >({
        path: `/simcards/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags simcards
     * @name SimControllerUpsert
     * @request POST:/simcards/upsert
     * @secure
     * @response `200` `ISimListSimpleResponse` Upsert simcard
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    simControllerUpsert: (data: ISimUpsertDto, params: RequestParams = {}) =>
      this.request<ISimListSimpleResponse, IErrorResponse | IApiUnauthorized>({
        path: `/simcards/upsert`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags simcards
     * @name SimControllerDelete
     * @request POST:/simcards/delete
     * @secure
     * @response `200` `IApiMessagesDto` Simcard deleted
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    simControllerDelete: (data: ISimDeleteDto, params: RequestParams = {}) =>
      this.request<IApiMessagesDto, IErrorResponse | IApiUnauthorized>({
        path: `/simcards/delete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags simcards
     * @name SimControllerRemoteConfig
     * @request POST:/simcards/remote-config
     * @secure
     * @response `200` `ISimRemoteConfigResponse` Remote config
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    simControllerRemoteConfig: (
      data: ISimRemoteConfigDto,
      params: RequestParams = {},
    ) =>
      this.request<ISimRemoteConfigResponse, IErrorResponse | IApiUnauthorized>(
        {
          path: `/simcards/remote-config`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        },
      ),
  };
  typeEquipmentMaintenance = {
    /**
     * No description
     *
     * @tags type-equipment-maintenance
     * @name TypeEquipmentMaintenanceControllerList
     * @request GET:/type-equipment-maintenance/list
     * @secure
     * @response `200` `ITypeEquipmentMaintenanceListResponse` List of type equipment maintenance
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    typeEquipmentMaintenanceControllerList: (
      query?: {
        /**
         * The Type Equipment Maintenance UUID
         * @pattern uuid
         */
        tem_id?: string;
        /** The Type Equipment Maintenance Name to use `ILIKE $tem_name%` */
        tem_name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        ITypeEquipmentMaintenanceListResponse,
        IErrorResponse | IApiUnauthorized
      >({
        path: `/type-equipment-maintenance/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags type-equipment-maintenance
     * @name TypeEquipmentMaintenanceControllerUpsert
     * @request POST:/type-equipment-maintenance/upsert
     * @secure
     * @response `200` `ITypeEquipmentMaintenance` Upserted type equipment maintenance
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    typeEquipmentMaintenanceControllerUpsert: (
      data: ITypeEquipmentMaintenanceUpsertDto,
      params: RequestParams = {},
    ) =>
      this.request<
        ITypeEquipmentMaintenance,
        IErrorResponse | IApiUnauthorized
      >({
        path: `/type-equipment-maintenance/upsert`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags type-equipment-maintenance
     * @name TypeEquipmentMaintenanceControllerDelete
     * @request POST:/type-equipment-maintenance/delete
     * @secure
     * @response `200` `IApiMessagesDto` Type equipment maintenance deleted
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    typeEquipmentMaintenanceControllerDelete: (
      data: ITypeEquipmentMaintenanceDeleteDto,
      params: RequestParams = {},
    ) =>
      this.request<IApiMessagesDto, IErrorResponse | IApiUnauthorized>({
        path: `/type-equipment-maintenance/delete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  vehicles = {
    /**
     * No description
     *
     * @tags vehicles
     * @name VehicleControllerList
     * @request GET:/vehicles/list
     * @secure
     * @response `200` `(IVehicleListSimpleResponse | IVehicleListDetailedResponse)` When `with_relations: false` then response is `VehicleListSimpleResponse`. If `with_relations: true` then response `VehicleListDetailedResponse`
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    vehicleControllerList: (
      query?: {
        /**
         * The vehicle UUID identifier
         * @format uuid
         * @example "1d1ef7e0-b7d8-41b5-9b5b-53fc20928758"
         */
        vhc_id?: string | null;
        /**
         * The type vehicle uuid
         * @format uuid
         * @example "353da056-538e-40f9-b2f3-258eb8921d7b"
         */
        tpv_id?: string | null;
        /**
         * The locator UUID to bond with this vehicle
         * @format uuid
         * @example "c7e51434-fd28-4ee3-a362-7a4178b54c1c"
         */
        lct_id?: string | null;
        /**
         * The name of this vehicle
         * @maxLength 100
         * @example "VEHICLE_01"
         */
        vhc_name?: string | null;
        /**
         * The fuel average consumption in liters per hour
         * @example 0.5
         */
        vhc_fuel_avg_cons_lph?: number | null;
        /**
         * The vehicle current hour meter value
         * @example 123456
         */
        vhc_hour_meter?: number | null;
        /**
         * Flag to list vehicle with all related relations
         * @default false
         * @example true
         */
        with_maintenances?: boolean | null;
        /**
         * Used to list deleted vehicles
         * @default false
         * @example false
         */
        with_trashed?: boolean | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        IVehicleListSimpleResponse | IVehicleListDetailedResponse,
        IErrorResponse | IApiUnauthorized
      >({
        path: `/vehicles/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags vehicles
     * @name VehicleControllerUpsert
     * @request POST:/vehicles/upsert
     * @secure
     * @response `200` `IVehicle` Upserted vehicle
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    vehicleControllerUpsert: (
      data: IVehicleUpsertDto,
      params: RequestParams = {},
    ) =>
      this.request<IVehicle, IErrorResponse | IApiUnauthorized>({
        path: `/vehicles/upsert`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags vehicles
     * @name VehicleControllerDelete
     * @request POST:/vehicles/delete
     * @secure
     * @response `200` `IApiMessagesDto` Vehicle deleted
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    vehicleControllerDelete: (
      data: IVehicleDeleteDto,
      params: RequestParams = {},
    ) =>
      this.request<IApiMessagesDto, IErrorResponse | IApiUnauthorized>({
        path: `/vehicles/delete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags vehicles
     * @name VehicleControllerUpdateHourMeter
     * @request POST:/vehicles/update-hour-meter
     * @secure
     * @response `200` `IVehicle` Updated vehicle hour meter
     * @response `400` `IErrorResponse` Bad request
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    vehicleControllerUpdateHourMeter: (
      data: IVehicleHourMeterUpdateDto,
      params: RequestParams = {},
    ) =>
      this.request<IVehicle, IErrorResponse | IApiUnauthorized>({
        path: `/vehicles/update-hour-meter`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  sendgrid = {
    /**
     * No description
     *
     * @name SendGridControllerSendEmail
     * @request GET:/sendgrid
     * @response `200` `void`
     */
    sendGridControllerSendEmail: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/sendgrid`,
        method: 'GET',
        ...params,
      }),
  };
  flight = {
    /**
     * No description
     *
     * @tags flight
     * @name Flighradar24ControllerTest
     * @request GET:/flight
     * @secure
     * @response `200` `IValidateResponse`
     */
    flighradar24ControllerTest: (
      query: {
        /**
         * @min -90
         * @max 90
         * @example -12.200615
         */
        latitude: number;
        /**
         * @min -180
         * @max 180
         * @example -38.906391
         */
        longitude: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<IValidateResponse, any>({
        path: `/flight`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  geolocation = {
    /**
     * No description
     *
     * @tags geolocation
     * @name GeolocationControllerGetGeolocator
     * @request GET:/geolocation/airport/{iata}
     * @response `200` `void`
     */
    geolocationControllerGetGeolocator: (
      iata: string,
      query: {
        provider: IGeolocationControllerGetGeolocatorParamsProviderEnum;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/geolocation/airport/${iata}`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags geolocation
     * @name GeolocationControllerGetPosition
     * @request GET:/geolocation/closest-airport
     * @response `200` `void`
     */
    geolocationControllerGetPosition: (
      query: {
        /**
         * @min -90
         * @max 90
         * @example -12.200615
         */
        latitude: number;
        /**
         * @min -180
         * @max 180
         * @example -38.906391
         */
        longitude: number;
        provider: IGeolocationControllerGetPositionParamsProviderEnum;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/geolocation/closest-airport`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags geolocation
     * @name GeolocationControllerFixAirport
     * @summary Get airport by IATA code
     * @request GET:/geolocation/fix/airport
     * @response `200` `IValidateResponse`
     */
    geolocationControllerFixAirport: (
      query: {
        provider: IGeolocationControllerFixAirportParamsProviderEnum;
        /** @example "6b3ec454-cf6f-4e32-b8c7-2460ed364ddf" */
        vhc_id: string;
        /**
         * @maxLength 3
         * @example "FEC"
         */
        iata: string;
        /** @example 10 */
        limit?: number;
        /** @example 0 */
        offset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<IValidateResponse, any>({
        path: `/geolocation/fix/airport`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  };
  esper = {
    /**
     * No description
     *
     * @tags esper
     * @name EsperControllerUpdateLocation
     * @request GET:/esper/update-location
     * @secure
     * @response `200` `(IEsperDeviceLocationDto)` Update location on firebase based on esper location
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    esperControllerUpdateLocation: (params: RequestParams = {}) =>
      this.request<IEsperDeviceLocationDto, IApiUnauthorized>({
        path: `/esper/update-location`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
}
