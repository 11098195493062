import { FC, useMemo } from 'react';
import { MUITSColumns, MUITSDatatable } from 'src/components/MUITSDatatable';
import { format } from 'date-fns/esm';
import ptBR from 'date-fns/locale/pt-BR';
import { Button } from '@material-ui/core';
import { IReportSimple, IVehicle } from 'src/services/touchway-base-api';
import { useQuery } from '@tanstack/react-query';
import { AUTH_TOKEN } from 'src/services/axiosService';
import { useTouchwayApi } from 'src/services/touchway-api';
import { secondsToHours } from 'src/utils/time-convert';
import { OverviewDateRange } from './components/OverviewDateRange';

interface IGSEHistory {
  gse: IVehicle;
  startDate: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date>> | undefined;
  endDate: Date;
  setEndDate: React.Dispatch<React.SetStateAction<Date>> | undefined;
  vhc_id: string;
}

export const IGSEHistoryList: FC<IGSEHistory> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  gse,
  vhc_id,
}) => {
  const touchwayApi = useTouchwayApi();
  const gseId = gse?.vhc_id;
  const downloadEndpoint = useMemo(() => {
    const base = `${process.env.REACT_APP_REPORT_URL}`;
    const start = startDate.toISOString();
    const end = endDate.toISOString();
    return `${base}/?start_date=${start}&end_date=${end}&vhc_id=${vhc_id}&token=${localStorage.getItem(
      AUTH_TOKEN,
    )}`;
  }, [startDate, endDate, vhc_id]);
  console.log('🚀 ~ downloadEndpoint ~ downloadEndpoint:', downloadEndpoint);

  const historyQuery = useQuery({
    enabled: !!gseId,
    queryFn: () =>
      touchwayApi.api.report.reportControllerReport({
        start: format(startDate, 'yyyy-MM-dd'),
        end: format(endDate, 'yyyy-MM-dd'),
        vhc_ids: [gseId],
      }),
    queryKey: ['report', gseId, startDate, endDate],
  });

  const id: keyof IReportSimple = 'vhh_id';
  const columns: MUITSColumns<IReportSimple> = [
    {
      name: 'vhh_id',
      label: 'UUID',
      options: {
        filter: false,
        display: false,
        sort: false,
      },
    },
    {
      name: 'vhc_id',
      label: 'ID',
      options: {
        filter: true,
        display: false,
        sort: true,
      },
    },
    {
      name: 'vhc_name',
      label: 'Equipamento',
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: 'vhh_iata',
      label: 'Base',
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: 'vhh_ignition_on_date',
      label: 'Ignição',
      options: {
        filter: false,
        display: true,
        sort: true,
        // TODO: add 9 hours
        customBodyRender: (value: string) =>
          value
            ? format(new Date(value), 'dd/MM/yyyy HH:mm', {
                locale: ptBR,
              })
            : '---',
      },
    },
    {
      name: 'vhh_ignition_off_date',
      label: 'Desligamento',
      options: {
        filter: false,
        display: true,
        sort: true,
        // TODO: add 6 hours
        customBodyRender: (value: string) =>
          value
            ? format(new Date(value), 'dd/MM/yyyy HH:mm', {
                locale: ptBR,
              })
            : '---',
      },
    },
    {
      name: 'vhh_duration_in_seconds',
      label: 'Duração (h)',
      options: {
        filter: false,
        display: true,
        sort: true,
        customBodyRender: (value: string) => {
          if (!value) return '---';
          const durationInSeconds = parseInt(value, 10);
          return secondsToHours(durationInSeconds);
        },
      },
    },
    {
      name: 'vhh_fuel_consumed_in_liters',
      label: 'Combustível Consumido (L)',
      options: {
        filter: false,
        display: true,
        sort: true,
        customBodyRender: (value: number) => {
          if (!value) return '---';
          return value.toFixed(2);
        },
      },
    },
    // {
    //   name: 'created_at',
    //   label: 'Data de Criação',
    //   options: {
    //     filter: false,
    //     display: true,
    //     sort: true,
    //     customBodyRender: (value: string) =>
    //       value
    //         ? format(new Date(value), 'dd/MM/yyyy HH:mm', { locale: ptBR })
    //         : '---',
    //   },
    // },
    // {
    //   name: 'updated_at',
    //   label: 'Data de Atualização',
    //   options: {
    //     filter: false,
    //     display: true,
    //     sort: true,
    //     customBodyRender: (value: string) =>
    //       value
    //         ? format(new Date(value), 'dd/MM/yyyy HH:mm', { locale: ptBR })
    //         : '---',
    //   },
    // },
  ];

  // const data = useMemo(
  //   () =>
  //   historyQuery.data.filter((h) => {
  //       const createdAt = new Date(h.created_at);
  //       return createdAt >= startDate && createdAt <= endDate;
  //     }),
  //   [history, startDate, endDate],
  // );

  return (
    <>
      <MUITSDatatable
        title={
          <OverviewDateRange
            startDate={startDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
          />
        }
        data={historyQuery.data?.data || []}
        columns={columns}
        options={{
          enableNestedDataAccess: '.',
          print: false,
          sortOrder: {
            name: 'vhh_ignition_on_date',
            direction: 'desc',
          },
          filter: true,
        }}
        idKey={id}
        showEditButton={false}
        showViewButton={false}
        customToolbar={() => (
          <a target="_blank" href={downloadEndpoint} rel="noreferrer">
            <Button variant="contained" color="primary">
              🧾 Abrir Relatório
            </Button>
          </a>
        )}
      />
    </>
  );
};

// 15/11/2023

// 0|main     | [Nest] 586418  - 11/15/2023,  PM     LOG [SocketService]
// received data: +RESP:GTIGF,2F0D00,,,1115,0,,,,-34.948050,-7.146921,
// ,0724,0011,52BF,5CE5,00,,,20231115201013,F59B$

// IGNICAO DESLIGADA: 863830038074007
// HORA REAL: 17:10:14
// HORA GV  : 20:10:07
