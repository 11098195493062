import { useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Divider,
  Grid,
  Tab,
  Tabs,
  CircularProgress,
  Card,
  CardHeader,
  CardContent,
  Typography,
} from '@material-ui/core';
import { Page } from 'src/components/layouts/Page';
import { useQuery } from '@tanstack/react-query';
import useSettings from 'src/hooks/useSettings';
import { endOfMonth, set, startOfMonth } from 'date-fns';
import LoadingScreen from 'src/components/LoadingScreen';
import { IVehicle } from 'src/services/touchway-base-api';
import { useTouchwayApi } from 'src/services/touchway-api';
import { queryKeys } from 'src/config/query';
import { GseSocketCard } from '../gses/components/GseSocketCard';
import { IGSEHistoryList } from './GseHistoryList';
import { GSEDetailsWithMap } from './GSEDetailsWithMap';
import DeviceForm from './forms/DeviceForm';
import { DeviceInstallation } from './components/DeviceImage/DeviceInstallation';
import { LocatorTelemetryDetailed } from '../locators/LocatorTelemetryDetailed';
import LocatorsHistory from '../locators/LocatorsHistory';

enum TabsEnum {
  maps,
  history,
  installation,
  details,
  telemetry,
  events,
}

const tabs: { label: string; value: TabsEnum; debug: boolean }[] = [
  { label: 'Histórico', value: TabsEnum.history, debug: false },
  { label: 'Mapa', value: TabsEnum.maps, debug: false },
  { label: 'Detalhes', value: TabsEnum.details, debug: false },
  { label: 'Fotos', value: TabsEnum.installation, debug: false },
  { label: 'Telemetria', value: TabsEnum.telemetry, debug: false },
  { label: 'Eventos', value: TabsEnum.events, debug: false },
];

const START_TIME = { hours: 0, minutes: 0, seconds: 0 };
const START_DAY = startOfMonth(set(new Date(), START_TIME));
const END_TIME = { hours: 23, minutes: 59, seconds: 59 };
const END_DATE = endOfMonth(set(new Date(), END_TIME));

const DeviceDetails: FC = () => {
  const touchwayApi = useTouchwayApi();
  const [startDate, setStartDate] = useState<Date>(START_DAY);
  const [endDate, setEndDate] = useState<Date>(END_DATE);
  const { gseId } = useParams();
  const { settings } = useSettings();
  const [currentTab, setCurrentTab] = useState<TabsEnum>(TabsEnum.history);

  const gseQuery = useQuery(
    queryKeys.vehicleControllerList.get({
      vhc_id: gseId,
    }),
    () =>
      touchwayApi.api.vehicles.vehicleControllerList({
        vhc_id: gseId,
      }),
  );

  const handleTabsChange = (event: ChangeEvent<{}>, value: TabsEnum): void => {
    setCurrentTab(value);
  };

  const localGSE = gseQuery.data?.vehicles[0] as IVehicle;

  return (
    <>
      <Page
        title={localGSE?.vhc_name || 'Carregando...'}
        query={gseQuery}
        breadcrumbs={[
          { to: '/', label: 'Painel' },
          { to: 'veiculos', label: 'Equipamentos' },
          { to: localGSE?.vhc_id, label: localGSE?.vhc_name },
        ]}
      >
        <Box sx={{ mt: 3 }}>
          <Tabs
            indicatorColor="primary"
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="primary"
            value={currentTab}
            variant="scrollable"
          >
            {tabs
              .filter((tab) => (tab.debug ? settings.debugMode : true))
              .map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
          </Tabs>
        </Box>
        <Divider />
        <Box sx={{ mt: 3 }}>
          {currentTab === TabsEnum.history && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {localGSE && (
                  <IGSEHistoryList
                    startDate={startDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                    gse={localGSE}
                    vhc_id={gseId}
                  />
                )}
              </Grid>
            </Grid>
          )}

          {currentTab === TabsEnum.details && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    title={<Typography variant="h6">Editar</Typography>}
                    subheader={localGSE?.vhc_name}
                  />
                  <CardContent>
                    {localGSE && <DeviceForm deviceToBeEdited={localGSE} />}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}

          {currentTab === TabsEnum.maps && (
            <Grid container spacing={3}>
              <Grid item md={8} xs={12}>
                {localGSE ? (
                  <GSEDetailsWithMap gse={localGSE} />
                ) : (
                  <LoadingScreen />
                )}
              </Grid>
              <Grid item md={4} xs={12} mt={-2}>
                {localGSE ? (
                  <GseSocketCard
                    vehicle={localGSE}
                    showPhoto
                    showTelemetry
                    showLastUpdate
                  />
                ) : (
                  <CircularProgress />
                )}
              </Grid>
            </Grid>
          )}

          {currentTab === TabsEnum.installation && (
            <DeviceInstallation vhc_id={gseId} />
          )}

          {currentTab === TabsEnum.telemetry && gseId && (
            <LocatorTelemetryDetailed vhc_id={gseId} />
          )}

          {currentTab === TabsEnum.events && gseId && (
            <LocatorsHistory lct_id={localGSE.lct_id} />
          )}
        </Box>
      </Page>
    </>
  );
};

export default DeviceDetails;
