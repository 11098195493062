/* eslint-disable @typescript-eslint/naming-convention */
import { useLocalStorage } from '@penseapp/uselocalstorage';
import React, { createContext, useContext } from 'react';
import { useParams } from 'react-router';

// Definição do tipo para as tabs
enum EquipmentTabsEnum {
  maintenance = 'maintenance',
  history = 'history',
  details = 'details',
  map = 'maps',
}

interface IEquipmentTab {
  label: string;
  value: EquipmentTabsEnum;
  debug: boolean;
}

// Definição do tipo para o estado do contexto
type EquipmentTabContextType = {
  currentTab: EquipmentTabsEnum;
  changeTab: (tab: EquipmentTabsEnum) => void;
  allTabs: IEquipmentTab[];
  default: IEquipmentTab;
};

// Criação do contexto com um valor padrão
const EquipmentTabContext = createContext<EquipmentTabContextType | undefined>(
  undefined,
);

// Definição das tabs
const equipmentTabs: IEquipmentTab[] = [
  { label: 'Manutenção', value: EquipmentTabsEnum.maintenance, debug: false },
  { label: 'Histórico', value: EquipmentTabsEnum.history, debug: false },
  { label: 'Detalhes', value: EquipmentTabsEnum.details, debug: false },
  { label: 'Mapa', value: EquipmentTabsEnum.map, debug: false },
];

// Criação do Provider
const EquipmentTabProvider: React.FC = ({ children }) => {
  const { eqp_id } = useParams();
  const tabKey = `@equipment-selected-tab-${eqp_id}`;
  const [_currentTab, setCurrentTab] = useLocalStorage(
    tabKey,
    equipmentTabs[0],
    3600 * 8,
  );
  const DEFAULT_TAB = equipmentTabs[0];
  const allTabs = equipmentTabs;

  function changeTab(tab: EquipmentTabsEnum) {
    setCurrentTab(equipmentTabs.find((t) => t.value === tab) || DEFAULT_TAB);
  }

  const currentTab =
    allTabs.find((t) => t.value === _currentTab.value)?.value ||
    DEFAULT_TAB.value;

  return (
    <EquipmentTabContext.Provider
      value={{
        currentTab,
        changeTab,
        allTabs,
        default: DEFAULT_TAB,
      }}
    >
      {children}
    </EquipmentTabContext.Provider>
  );
};

// Hook personalizado para usar o contexto
const useEquipmentTabs = () => {
  const context = useContext(EquipmentTabContext);
  if (context === undefined) {
    throw new Error(
      'useEquipmentTabs must be used within an EquipmentTabProvider',
    );
  }
  return context;
};

export { EquipmentTabProvider, useEquipmentTabs, EquipmentTabsEnum };
