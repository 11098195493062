import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import { Refresh as RefreshIcon } from '@material-ui/icons';
import { UseQueryResult } from '@tanstack/react-query';

interface RefetchStatusCardProps {
  query: UseQueryResult<any, unknown>; // Substitua "any" pelo tipo correto da resposta da query
  refetchInterval: number; // Intervalo de refetch em milissegundos
}

const RefetchStatusCard: React.FC<RefetchStatusCardProps> = ({
  query,
  refetchInterval,
}) => {
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null);

  // Atualiza o tempo restante até o próximo refetch
  useEffect(() => {
    const calculateTimeRemaining = () => {
      if (query.dataUpdatedAt) {
        const currentTime = new Date();
        const nextRefetchTime = new Date(query.dataUpdatedAt + refetchInterval);
        const remaining = nextRefetchTime.getTime() - currentTime.getTime();
        setTimeRemaining(remaining > 0 ? remaining : null);
      } else {
        setTimeRemaining(null);
      }
    };

    calculateTimeRemaining();

    const intervalId = setInterval(calculateTimeRemaining, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [query.dataUpdatedAt, refetchInterval]);

  // Formatar o tempo restante em minutos e segundos
  const minutesRemaining = timeRemaining
    ? Math.floor(timeRemaining / 60000)
    : null;
  const secondsRemaining = timeRemaining
    ? Math.ceil((timeRemaining % 60000) / 1000)
    : null;

  // Reseta o timer
  const resetTimer = useCallback(() => {
    if (query.dataUpdatedAt) {
      const currentTime = new Date();
      const nextRefetchTime = new Date(query.dataUpdatedAt + refetchInterval);
      const remaining = nextRefetchTime.getTime() - currentTime.getTime();
      setTimeRemaining(remaining > 0 ? remaining : null);
    }
  }, [query.dataUpdatedAt, refetchInterval]);

  useEffect(() => {
    resetTimer();
  }, [query.dataUpdatedAt, refetchInterval, resetTimer]);

  return (
    <Card variant="outlined">
      <CardContent style={{ position: 'relative' }}>
        <Typography variant="h5">Atualização</Typography>
        {query.isFetching ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CircularProgress size={20} style={{ marginRight: 8 }} />
            <Typography>Buscando dados...</Typography>
          </div>
        ) : (
          <>
            <Typography>Próxima atualização em:</Typography>
            {timeRemaining !== null ? (
              <Typography>
                {minutesRemaining} minutos e {secondsRemaining} segundos
              </Typography>
            ) : (
              <Typography>---</Typography>
            )}
          </>
        )}
        <IconButton
          disabled={query.isFetching}
          onClick={() => query.refetch()}
          style={{ position: 'absolute', top: 8, right: 8 }}
        >
          <RefreshIcon />
        </IconButton>
      </CardContent>
    </Card>
  );
};

export { RefetchStatusCard };
