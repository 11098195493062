import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Page } from 'src/components/layouts/Page';
import { MUITSColumns, MUITSDatatable } from 'src/components/MUITSDatatable';
import { IAirport } from '../gses/IGSE.type';

export const AeroportoList: FC = () => {
  // const airportsQuery = useQuery(['getAllAirports'], getAllAirports, {
  //   refetchOnWindowFocus: false,
  // });
  // const airports = airportsQuery?.data?.data || [];
  const airports = [
    {
      id: '6294',
      iata_code: 'VCP',
      ident: 'SBKP',
      name: 'Aeroporto Internacional de Viracopos',
      latitude_deg: '-23.0075',
      longitude_deg: '-47.1345',
      elevation_ft: '2170',
      iso_region: 'BR-SP',
      municipality: 'Campinas',
      gps_code: 'SBKP',
      local_code: 'VCP',
      home_link: 'https://www.viracopos.com/',
      wikipedia_link:
        'https://pt.wikipedia.org/wiki/Aeroporto_Internacional_de_Viracopos',
      keywords: 'Viracopos, Campinas International',
      type: 'large_airport',
    },
  ];

  const id: keyof IAirport = 'airport_uuid';
  const columns: MUITSColumns<IAirport> = [
    {
      name: 'id',
      label: 'ID',
      options: { filter: true, display: false, sort: true },
    },
    {
      name: 'iata_code',
      label: 'IATA',
      options: { filter: true, display: true, sort: true },
    },
    {
      name: 'ident',
      label: 'Ident',
      options: { filter: true, display: false, sort: true },
    },
    {
      name: 'name',
      label: 'Nome',
      options: { filter: true, display: true, sort: true },
    },
    {
      name: 'latitude_deg',
      label: 'Latitude',
      options: { filter: true, display: true, sort: true },
    },
    {
      name: 'longitude_deg',
      label: 'Longitude',
      options: { filter: true, display: true, sort: true },
    },
    {
      name: 'elevation_ft',
      label: 'Elevação',
      options: { filter: true, display: false, sort: true },
    },
    {
      name: 'iso_region',
      label: 'Região',
      options: { filter: true, display: true, sort: true },
    },
    {
      name: 'municipality',
      label: 'Munícipio',
      options: { filter: true, display: true, sort: true },
    },
    {
      name: 'gps_code',
      label: 'Cód. GPS',
      options: { filter: true, display: false, sort: true },
    },
    {
      name: 'local_code',
      label: 'Código local',
      options: { filter: true, display: false, sort: true },
    },
    {
      name: 'home_link',
      label: 'Link',
      options: {
        filter: true,
        display: false,
        sort: true,
        customBodyRenderLite: (dataIndex: number) => (
          <Link to={airports[dataIndex].home_link}>Abrir</Link>
        ),
      },
    },
    {
      name: 'wikipedia_link',
      label: 'Wikipedia',
      options: {
        filter: true,
        display: false,
        sort: true,
        customBodyRenderLite: (dataIndex: number) =>
          airports[dataIndex].wikipedia_link ? (
            <Link
              to={airports[dataIndex].wikipedia_link}
              target="_blank"
              rel="noreferrer"
            >
              Abrir
            </Link>
          ) : (
            '---'
          ),
      },
    },
    {
      name: 'keywords',
      label: 'Keywords',
      options: { filter: true, display: false, sort: true },
    },
    {
      name: 'type',
      label: 'Tipo',
      options: {
        filter: true,
        display: true,
        sort: true,
        customBodyRenderLite: (rowIndex) => {
          switch (airports[rowIndex]?.type) {
            case 'large_airport':
              return 'Aeroporto grande';
            case 'medium_airport':
              return 'Aeroporto médio';
            case 'small_airport':
              return 'Aeroporto pequeno';
            case 'closed':
              return 'Aeroporto fechado';
            case 'heliport':
              return 'Heliporto';
            case 'balloonport':
              return 'Balão';
            case 'seaplane_base':
              return 'Base de seaplane';
            default:
              return '---';
          }
        },
      },
    },
  ];

  return (
    <Page
      title="Gerenciamento de Bases"
      breadcrumbs={[
        { to: '/', label: 'Painel' },
        { to: 'Bases', label: 'Bases' },
      ]}
      // query={airportsQuery}
    >
      <MUITSDatatable
        title="Bases"
        data={airports}
        columns={columns}
        options={{ enableNestedDataAccess: '.' }}
        idKey={id}
        showEditButton={false}
      />
    </Page>
  );
};

export default AeroportoList;
