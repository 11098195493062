import type { FC } from 'react';
import { Button, Grid } from '@material-ui/core';
import { Page } from 'src/components/layouts/Page';
import { useNavigate } from 'react-router';
import { Add } from '@material-ui/icons';
import TypeEquipmentMaintenanceList from './TypeEquipmentMaintenanceList';

const TypeEquipmentsTab: FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <Page
        title="Frequência de manutenção"
        subtitle="Listagem de frequências de manutenção de equipamentos"
        buttons={[
          <Button
            color="primary"
            variant="outlined"
            onClick={() => navigate('/frequencia-manutencao/novo')}
            startIcon={<Add fontSize="small" />}
            sx={{ m: 1 }}
          >
            Nova frequência
          </Button>,
        ]}
        breadcrumbs={[
          { to: '/', label: 'Painel' },
          { to: '/frequencia', label: 'Frequencia' },
        ]}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TypeEquipmentMaintenanceList />
          </Grid>
        </Grid>
      </Page>
    </>
  );
};

export default TypeEquipmentsTab;
