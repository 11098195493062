/**
 * Converts seconds to hours.
 *
 * @param seconds - The number of seconds.
 * @returns The equivalent number of hours with 2 decimal places.
 */
export function secondsToHours(seconds: number) {
  return (seconds / 3600).toFixed(2); // 1 hour = 3600 seconds
}

/**
 * Converts hours to seconds.
 *
 * @param hours - The number of hours.
 * @returns The equivalent number of seconds with 2 decimal places.
 */
export function hoursToSeconds(hours: number) {
  return (hours * 3600).toFixed(2); // 1 hour = 3600 seconds
}
