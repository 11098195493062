import React from 'react';
import MUIDataTable, {
  DisplayData,
  MUIDataTableColumn,
  MUIDataTableOptions,
  MUIDataTableProps,
} from 'mui-datatables';
import { Box, IconButton } from '@material-ui/core';
import { Edit, MoreVertOutlined } from '@material-ui/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import slugify from 'slugify';
import { MUI_DATABLE_DEFAULT_OPTIONS } from './MUITSDatatableConfig';

interface MUITSDatatableInterface<T>
  extends Omit<MUIDataTableProps, 'columns' | 'title'> {
  options: Partial<MUIDataTableOptions>;
  columns: MUITSColumns<T>;
  idKey: keyof T;
  showEditButton?: boolean;
  showViewButton?: boolean;
  pathname?: string;
  title?: React.ReactNode | string;
  customToolbar?:
    | ((data: { displayData: DisplayData }) => React.ReactNode)
    | undefined;
}

interface MUITSTableColumn<T> extends Omit<MUIDataTableColumn, 'name'> {
  name: keyof T;
}

export type MUITSColumn<T> = MUITSTableColumn<T>;
export type MUITSColumns<T> = MUITSColumn<T>[];

const MUITSDatatable: <T>(
  props: MUITSDatatableInterface<T>,
) => React.ReactElement = ({
  title,
  data,
  columns,
  options,
  components,
  idKey,
  showEditButton = true,
  showViewButton = true,
  pathname,
  customToolbar,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  // const isEmpty = data

  const columnsCustom: MUITSColumns<any> = [
    ...columns,
    {
      name: 'id',
      label: 'Ações',
      options: {
        searchable: false,
        display: showEditButton || showViewButton,
        filter: false,
        setCellProps: () => ({
          align: 'right',
        }),
        customBodyRenderLite: (dataIndex) => {
          const obj = data[dataIndex] as any;

          const id = obj[idKey] || '';

          return (
            <Box display="flex" flexDirection="row">
              {showEditButton && (
                <IconButton
                  onClick={() =>
                    navigate(`${pathname || location.pathname}/${id}/editar`)
                  }
                >
                  <Edit />
                </IconButton>
              )}
              {showViewButton && (
                <IconButton
                  onClick={() =>
                    navigate(`${pathname || location.pathname}/${id}`)
                  }
                >
                  <MoreVertOutlined />
                </IconButton>
              )}
            </Box>
          );
        },
      },
    },
  ];

  return (
    <MUIDataTable
      title={title}
      data={data}
      // @ts-ignore: unsupported extended needs to be casted
      columns={columnsCustom}
      options={{
        ...MUI_DATABLE_DEFAULT_OPTIONS,
        ...options,
        setTableProps: () => ({
          // material ui v4 only
          size: 'small',
        }),
        customToolbar,
        downloadOptions: {
          ...options.downloadOptions,
          separator: options?.downloadOptions?.separator || '-',
          filename: slugify(
            options?.downloadOptions?.filename || `${title}.csv`,
            {
              lower: true,
            },
          ),
        },
      }}
      components={components}
    />
  );
};

export default MUITSDatatable;
