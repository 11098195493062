import { useRef, useState } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from '@material-ui/core';
import KeyIcon from '@material-ui/icons/VpnKey';
import useAuth from '../../hooks/useAuth';
// import UserIcon from '../../icons/User';

const AccountPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { user, logout, resetPassword } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Avatar
          src="https://res.cloudinary.com/ddfytxkn4/image/upload/v1688753597/Design_sem_nome_1_1_anzlt4.png"
          sx={{
            bgcolor: 'white',
            height: 32,
            width: 32,
          }}
        >
          {user?.usu_name[0].toUpperCase()}
        </Avatar>
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {user?.usu_name}
          </Typography>
          <Typography color="textSecondary" variant="subtitle2">
            {user?.usu_email}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          {/* <MenuItem component={RouterLink} to="//social/profile">
            <ListItemIcon>
              <UserIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Perfil
                </Typography>
              }
            />
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              if (user?.usu_email) {
                resetPassword(user?.usu_email);
                toast.success(
                  `Um e-mail será enviado a você para redefinir sua senha. Por gentileza, confira sua caixa de entrada no endereço ${user.usu_email} e não se esqueça de verificar a pasta de spam também.`,
                  {
                    duration: 10000,
                  },
                );
              } else {
                toast.error('Não foi possível trocar a senha');
              }
            }}
          >
            <ListItemIcon>
              <KeyIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Trocar senha
                </Typography>
              }
            />
          </MenuItem>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            Sair
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
