import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/analytics';
import { firebaseConfig } from '../config';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const app = firebase.app(); // Get the Firebase app instance

// Initialize Firebase Analytics
const analytics = firebase.analytics(app);

export const firebaseAdapter = {
  app: firebase.app,
  database: firebase.database,
  firebase,
  analytics,
};

export default firebase;
