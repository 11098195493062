// StatisticsCard.jsx

import React from 'react';
import { Card, CardContent, Typography, makeStyles } from '@material-ui/core';

interface StatisticsCardProps {
  number: number;
  legend: string;
  isClicked: boolean;
  setIsClicked: React.Dispatch<React.SetStateAction<boolean>>;
  onCardClick: () => void;
}

const useStyles = makeStyles({
  card: {
    cursor: 'pointer',
    transition: 'box-shadow 0.3s ease',
    '&:hover': {
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '100%',
  },
});

const StatisticsCard: React.FC<StatisticsCardProps> = ({
  number,
  legend,
  isClicked,
  setIsClicked,
  onCardClick,
}) => {
  const classes = useStyles();

  const handleClick = () => {
    setIsClicked(!isClicked);
    onCardClick(); // Call the function passed from the parent
  };

  const handleDoubleClick = () => {
    setIsClicked(false);
  };

  return (
    <Card
      className={classes.card}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
      elevation={isClicked ? 12 : 1}
    >
      <CardContent className={classes.cardContent}>
        <Typography variant="h4" gutterBottom>
          {number}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {legend}
        </Typography>
      </CardContent>
    </Card>
  );
};

export { StatisticsCard };
