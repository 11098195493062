/* eslint-disable yoda */
/* eslint-disable no-nested-ternary */
import { forwardRef, useCallback, useContext, useMemo, useState } from 'react';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from '@material-ui/core';
import {
  IMaintenanceStatusMtsConstantEnum,
  IVehicleMaintenancePlanner,
} from 'src/services/touchway-base-api';
import { AnimatedComponent } from 'src/pages/gses/components/GseSocketCard';
import useRealtime from 'src/hooks/firebase/useRealtime';
import { IVehicleFirebaseRealtimeType } from 'src/pages/gses/types/IGSEFirebaseRealtime.type';
import { firebaseRealtimeEnum } from 'src/config';
import { KanbanContext } from 'src/contexts/KanbanContext';
import { getMaintenanceById } from 'src/utils/getMaintenanceById';
import { useSelector } from '../../../store';
import type { RootState } from '../../../store';
import type { Card as CardType, Column, Member } from '../../../types/kanban';
import KanbanCardModal from './KanbanCardModal';
import { KanbanCardContent } from './KanbanCardContext';

interface KanbanCardProps {
  cardId: string;
  dragging: boolean;
  index?: number;
  column: Column;
  style?: Record<any, any>;
}

interface PopulatedCard extends CardType {
  members: Member[];
  extraProps: IVehicleMaintenancePlanner;
}

const cardSelector = (state: RootState, cardId: string): PopulatedCard => {
  const { cards, members } = state.kanban;
  const card = cards.byId[cardId];

  return {
    ...card,
    members: card.memberIds.map((memberId: string) => members.byId[memberId]),
  };
};

const KanbanCard = forwardRef<HTMLDivElement, KanbanCardProps>((props, ref) => {
  const { formats } = useContext(KanbanContext);
  const { cardId, dragging, column, ...other } = props;
  const card = useSelector((state) => cardSelector(state, cardId));
  const [open, setOpen] = useState<boolean>(false);
  const { searchValue, hasFilter } = useContext(KanbanContext);

  const gseRealtime = useRealtime<IVehicleFirebaseRealtimeType>({
    collection: firebaseRealtimeEnum.vehicles,
    key: card?.extraProps?.vehicle?.vhc_id,
    onValueChange: () => {},
    enabled: !!card?.extraProps?.vehicle?.vhc_id,
  });

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const sanatizeSearchValue = useCallback(
    (value: string | number) =>
      String(value)
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '') // Remove spaces
        .toString(),
    [],
  );

  const iata = gseRealtime?.iata || '';
  const isFilteredByValue = useMemo(() => {
    if (!searchValue) return true;

    const propertiesToFilter = [card.name, iata];

    const sanatizedSearchValue = sanatizeSearchValue(searchValue);

    return propertiesToFilter.some((property) => {
      const sanatizedProperty = sanatizeSearchValue(property);

      return sanatizedProperty.includes(sanatizedSearchValue);
    });
  }, [card.name, iata, sanatizeSearchValue, searchValue]);

  if (!formats.includes(card.extraProps.typeMaintenance.tpm_id)) return null;
  if (!isFilteredByValue && hasFilter) return null;

  return (
    <Box
      ref={ref}
      sx={{
        outline: 'none',
        py: 1,
      }}
      {...other}
    >
      <Card
        onClick={handleOpen}
        raised={dragging}
        sx={{
          ...(dragging && {
            backgroundColor: 'background.paper',
          }),
          '&:hover': {
            backgroundColor: 'background.default',
          },
        }}
        variant={dragging ? 'elevation' : 'outlined'}
      >
        {card.cover && <CardMedia image={card.cover} sx={{ height: 200 }} />}
        <AnimatedComponent onClick={() => {}} enableBlink={false} elevation={1}>
          <CardHeader
            mb={-2}
            title={
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="row"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="row"
                    justifyContent="center"
                  >
                    {card.extraProps.vmp_completion_percentage === 100 &&
                      getMaintenanceById({ mts_id: column.id }).mts_constant ===
                        IMaintenanceStatusMtsConstantEnum.PLANNED && (
                        <Box mr={1}>
                          <img
                            src="/static/output-onlinegiftools.gif"
                            alt={card?.name}
                            width={22}
                            height={22}
                          />
                        </Box>
                      )}
                    <Typography color="textPrimary" variant="h6">
                      {card?.name}
                    </Typography>
                  </Box>
                  {card?.extraProps?.vmp_execution_date ? (
                    <>{`${card.extraProps.typeMaintenance.tpm_in_hours}h`}</>
                  ) : (
                    <>{gseRealtime?.iata || '---'}</>
                  )}
                </Box>
              </>
            }
          />
          <CardContent>
            <KanbanCardContent
              extraProps={card.extraProps}
              comments={card.comments}
            />
          </CardContent>
        </AnimatedComponent>
      </Card>
      <KanbanCardModal
        card={card}
        column={column}
        onClose={handleClose}
        open={open}
        maintenance={card.extraProps}
      />
    </Box>
  );
});

export default KanbanCard;
