import { Skeleton, Stack } from '@material-ui/core';
import React from 'react';
import FormSection from 'src/components/forms/FormSection';
import { useTouchwayApi } from 'src/services/touchway-api';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'src/config/query';
import EmptyPlaceholder from 'src/components/layouts/EmptyPlaceholder';
import {
  IEquipmentListDetailedResponse,
  IMaintenanceStatusMtsConstantEnum,
} from 'src/services/touchway-base-api';
import { EquipmentMaintenanceCardDetails } from '../components/EquipmentMaintenanceCardDetails';
import EquipmentMaintenanceForm from '../forms/EquipmentMaintenanceForm';

interface EquipmentTabActualMaintenanceProps {
  eqp_id: string;
}

export const EquipmentTabActualMaintenance: React.FC<EquipmentTabActualMaintenanceProps> =
  ({ eqp_id }) => {
    const { api } = useTouchwayApi();

    const eclParams = { eqp_id, with_relations: true };
    const equipmentQuery = useQuery(queryKeys.equipment.get(eclParams), () =>
      api.equipment.equipmentControllerList(eclParams),
    );

    if (equipmentQuery.isLoading) {
      return <Skeleton />;
    }

    const typeEqp =
      (equipmentQuery.data as any).equipments &&
      (equipmentQuery.data as any).equipments.length > 0
        ? (equipmentQuery.data as IEquipmentListDetailedResponse).equipments[0]
        : undefined;

    if (!typeEqp) {
      return (
        <EmptyPlaceholder title="Equipamento não encontrado" subtitle="" />
      );
    }

    if (!typeEqp?.maintenances || typeEqp.maintenances.length === 0) {
      return (
        <EquipmentMaintenanceForm
          eqpMaintenance={null}
          eqp_id={eqp_id}
          allowChangeEquipment={false}
          editableFields={['tem_id', 'eqm_start']}
          personalization={{
            title: 'Primeira manutenção',
            description:
              'Esse equipamento ainda não possui manutenções cadastradas, por conta disso é necessário criar uma primeira manutenção informando a data inicial da próxima manutenção e a frequência de manutenção.',
            submitText: 'Criar manutenção',
          }}
        />
      );
    }

    return (
      <Stack spacing={2}>
        <FormSection
          title="Manutenção atual"
          description="Manutenções que estão em andamento ou planejadas para o futuro."
          actions={undefined}
          showCard={false}
        >
          {typeEqp.eqp_id && (
            <EquipmentMaintenanceCardDetails
              eqp_id={typeEqp.eqp_id}
              filter={[
                IMaintenanceStatusMtsConstantEnum.PLANNED,
                IMaintenanceStatusMtsConstantEnum.DOING,
              ]}
            />
          )}
        </FormSection>
      </Stack>
    );
  };
