import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'src/config/query';
import { useTouchwayApi } from 'src/services/touchway-api';
import { addDays, subDays } from 'date-fns';
import { ILocatorHistory } from 'src/services/touchway-base-api';
import { MUITSColumns, MUITSDatatable } from 'src/components/MUITSDatatable';
import { OverviewDateRange } from '../devices/components/OverviewDateRange';

interface LocatorsHistoryProps {
  lct_id: string;
}

const LocatorsHistory: React.FC<LocatorsHistoryProps> = ({ lct_id }) => {
  const [startDate, setStartDate] = useState<Date>(subDays(new Date(), 7));
  const [endDate, setEndDate] = useState<Date>(addDays(new Date(), 7));
  const touchwayApi = useTouchwayApi();

  const historyQuery = useQuery({
    queryKey: queryKeys.locatorControllerList.history({
      end: endDate.toISOString(),
      lct_id,
      limit: 100,
      start: startDate.toISOString(),
    }),
    queryFn: () =>
      touchwayApi.api.locator.locatorControllerHistory({
        end: endDate.toISOString(),
        lct_id,
        limit: 100,
        start: startDate.toISOString(),
      }),
  });

  const id: keyof ILocatorHistory = 'lch_id';
  const columns: MUITSColumns<ILocatorHistory> = [
    {
      name: 'lch_id',
      label: 'UUID',
      options: {
        filter: false,
        display: false,
        sort: false,
      },
    },
    {
      name: 'lch_type',
      label: 'Tipo',
      options: {
        filter: false,
        display: true,
        sort: true,
      },
    },
    {
      name: 'lch_raw_data',
      label: 'Evento',
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: 'lch_report_date',
      label: 'Data/hora',
      options: {
        filter: false,
        display: true,
        sort: true,
        customBodyRender: (value: string) => new Date(value).toLocaleString(),
      },
    },
  ];

  return (
    <>
      <MUITSDatatable
        title={
          <OverviewDateRange
            startDate={startDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
          />
        }
        data={historyQuery.data?.locator_history || []}
        columns={columns}
        options={{
          enableNestedDataAccess: '.',
          print: false,
          sortOrder: {
            name: 'vhh_ignition_on_date',
            direction: 'desc',
          },
          filter: true,
        }}
        idKey={id}
        showEditButton={false}
        showViewButton={false}
      />
    </>
  );
};

export default LocatorsHistory;
