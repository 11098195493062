/* eslint-disable @typescript-eslint/naming-convention */
import type { FC, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Divider, Grid, Tab, Tabs } from '@material-ui/core';
import { Page } from 'src/components/layouts/Page';
import { useQuery } from '@tanstack/react-query';
import useSettings from 'src/hooks/useSettings';
import LoadingScreen from 'src/components/LoadingScreen';
import { IEquipment } from 'src/services/touchway-base-api';
import { useTouchwayApi } from 'src/services/touchway-api';
import { queryKeys } from 'src/config/query';
import { EquipmentTabDetails } from './tabs/EquipmentTabDetails';
import { EquipmentTabActualMaintenance } from './tabs/EquipmentTabActualMaintenance';
import { EquipmentTabMaintenanceHistory } from './tabs/EquipmentTabMaintenanceHistory';
import {
  EquipmentTabsEnum,
  useEquipmentTabs,
} from './provider/EquipmentContext';
import EquipmentMap from './EquipmentMap';

const EquipmentDetails: FC = () => {
  const touchwayApi = useTouchwayApi();
  const { allTabs, currentTab, changeTab } = useEquipmentTabs();
  const { eqp_id } = useParams();
  const { settings } = useSettings();

  const eqpQuery = useQuery(
    queryKeys.equipment.get({
      eqp_id,
    }),
    () =>
      touchwayApi.api.equipment.equipmentControllerList({
        eqp_id,
      }),
  );

  const handleTabsChange = (
    event: ChangeEvent<{}>,
    value: EquipmentTabsEnum,
  ): void => {
    changeTab(value);
  };

  const localEquipment = eqpQuery.data?.equipments[0] as IEquipment;

  if (!localEquipment?.eqp_id) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Page
        title={localEquipment?.eqp_name || 'Carregando...'}
        query={eqpQuery}
        breadcrumbs={[
          { to: '/', label: 'Painel' },
          { to: '/equipamentos', label: 'Equipamentos' },
          { to: localEquipment?.eqp_id, label: localEquipment?.eqp_name },
        ]}
      >
        <Box sx={{ mt: 3 }}>
          <Tabs
            indicatorColor="primary"
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="primary"
            value={currentTab}
            variant="scrollable"
          >
            {allTabs
              .filter((tab) => (tab.debug ? settings.debugMode : true))
              .map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
          </Tabs>
        </Box>
        <Divider />
        <Box sx={{ mt: 3 }}>
          {currentTab === EquipmentTabsEnum.maintenance && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {localEquipment && (
                  <EquipmentTabActualMaintenance
                    eqp_id={localEquipment.eqp_id}
                  />
                )}
              </Grid>
            </Grid>
          )}

          {currentTab === EquipmentTabsEnum.history && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {localEquipment && (
                  <EquipmentTabMaintenanceHistory
                    eqp_id={localEquipment.eqp_id}
                  />
                )}
              </Grid>
            </Grid>
          )}

          {currentTab === EquipmentTabsEnum.details && (
            <EquipmentTabDetails eqp_id={eqp_id} />
          )}

          {currentTab === EquipmentTabsEnum.map && eqp_id && (
            <EquipmentMap eqp_id={eqp_id} />
          )}
        </Box>
      </Page>
    </>
  );
};

export default EquipmentDetails;
