import { Chip } from '@material-ui/core';
import React from 'react';
import {
  IMaintenanceStatus,
  IMaintenanceStatusMtsConstantEnum,
} from 'src/services/touchway-base-api';

interface RenderMaintenanceStatusEnumProps {
  maintenanceStatus: IMaintenanceStatus;
}

export const RenderMaintenanceStatusEnum: React.FC<RenderMaintenanceStatusEnumProps> =
  ({ maintenanceStatus }) => {
    const emoji = getEmoji(maintenanceStatus);
    const color = getColor(maintenanceStatus);

    return (
      <Chip
        variant="outlined"
        key={maintenanceStatus.mts_id}
        label={`${emoji}${'  '}${maintenanceStatus.mts_name}`}
        style={{
          backgroundColor: 'transparent',
          borderColor: color,
        }}
      />
    );
  };

function getEmoji({ mts_constant }: IMaintenanceStatus): string {
  switch (mts_constant) {
    case IMaintenanceStatusMtsConstantEnum.DOING:
      return '🔧';
    case IMaintenanceStatusMtsConstantEnum.DONE:
      return '✅';
    case IMaintenanceStatusMtsConstantEnum.PLANNED:
      return '📅';
    default:
      return '❓';
  }
}

function getColor({ mts_constant }: IMaintenanceStatus): string {
  switch (mts_constant) {
    case IMaintenanceStatusMtsConstantEnum.PLANNED:
      return '#688eff';
    case IMaintenanceStatusMtsConstantEnum.DOING:
      return '#C28687';
    case IMaintenanceStatusMtsConstantEnum.DONE:
      return '#4caf50';
    default:
      return '#f44336';
  }
}
