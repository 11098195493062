/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Divider,
  Grid,
  Tab,
  Tabs,
  Card,
  CardHeader,
  CardContent,
  Typography,
} from '@material-ui/core';
import { Page } from 'src/components/layouts/Page';
import { useQuery } from '@tanstack/react-query';
import useSettings from 'src/hooks/useSettings';
import LoadingScreen from 'src/components/LoadingScreen';
import { useTouchwayApi } from 'src/services/touchway-api';
import { queryKeys } from 'src/config/query';
// import { IGSEHistoryList } from './GseHistoryList';
// import { GSEDetailsWithMap } from './GSEDetailsWithMap';
// import { EquipmentInstallation } from './components/EquipmentImage/EquipmentInstallation';

enum TabsEnum {
  maintenance,
  maps,
  history,
  installation,
  details,
  telemetry,
  events,
}

const tabs: { label: string; value: TabsEnum; debug: boolean }[] = [
  { label: 'Manutenção', value: TabsEnum.maintenance, debug: false },
  { label: 'Histórico', value: TabsEnum.history, debug: false },
  { label: 'Mapa', value: TabsEnum.maps, debug: false },
  { label: 'Detalhes', value: TabsEnum.details, debug: false },
  { label: 'Fotos', value: TabsEnum.installation, debug: false },
  { label: 'Telemetria', value: TabsEnum.telemetry, debug: false },
  { label: 'Eventos', value: TabsEnum.events, debug: false },
];

const TypeEquipmentMaintenanceDetails: FC = () => {
  const touchwayApi = useTouchwayApi();
  const { tem_id } = useParams();
  const { settings } = useSettings();
  const [currentTab, setCurrentTab] = useState<TabsEnum>(TabsEnum.maintenance);

  const params: { tem_id: string } = { tem_id };
  const typeEqpMainQuery = useQuery(
    queryKeys.typeEquipmentMaintenance.list(params),
    () =>
      touchwayApi.api.typeEquipmentMaintenance.typeEquipmentMaintenanceControllerList(
        params,
      ),
  );

  const handleTabsChange = (event: ChangeEvent<{}>, value: TabsEnum): void => {
    setCurrentTab(value);
  };

  const localEquipment = typeEqpMainQuery.data?.type_equipment_maintenances[0];

  if (!localEquipment?.tem_id) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Page
        title={localEquipment?.tem_name || 'Carregando...'}
        query={typeEqpMainQuery}
        breadcrumbs={[
          { to: '/', label: 'Painel' },
          { to: 'veiculos', label: 'Equipamentos' },
          { to: localEquipment?.tem_id, label: localEquipment?.tem_name },
        ]}
      >
        <Box sx={{ mt: 3 }}>
          <Tabs
            indicatorColor="primary"
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="primary"
            value={currentTab}
            variant="scrollable"
          >
            {tabs
              .filter((tab) => (tab.debug ? settings.debugMode : true))
              .map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
          </Tabs>
        </Box>
        <Divider />
        <Box sx={{ mt: 3 }}>
          {/* {currentTab === TabsEnum.maintenance && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {localEquipment && <EquipmentMaintenance eqpId={tem_id} />}
              </Grid>
            </Grid>
          )} */}

          {currentTab === TabsEnum.history && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {/* {localEquipment && (
                  <IGSEHistoryList
                    startDate={startDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                    gse={localEquipment}
                    tem_id={tem_id}
                  />
                )} */}
              </Grid>
            </Grid>
          )}

          {currentTab === TabsEnum.details && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    title={<Typography variant="h6">Editar</Typography>}
                    subheader={localEquipment?.tem_name}
                  />
                  <CardContent>
                    {/* {localEquipment && (
                      <EquipmentForm equipmentToBeEdited={localEquipment} />
                    )} */}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}

          {currentTab === TabsEnum.maps && (
            <Grid container spacing={3}>
              <Grid item md={8} xs={12}>
                {/* {localEquipment ? (
                  <GSEDetailsWithMap gse={localEquipment} />
                ) : (
                  <LoadingScreen />
                )} */}
              </Grid>
              <Grid item md={4} xs={12} mt={-2}>
                {/* {localEquipment ? (
                  <GseSocketCard
                    vehicle={localEquipment}
                    showPhoto
                    showTelemetry
                    showLastUpdate
                  />
                ) : (
                  <CircularProgress />
                )} */}
              </Grid>
            </Grid>
          )}

          {/* {currentTab === TabsEnum.installation && (
            <EquipmentInstallation tem_id={tem_id} />
          )} */}

          {/* {currentTab === TabsEnum.telemetry && tem_id && (
            <LocatorTelemetryDetailed tem_id={tem_id} />
          )} */}

          {/* {currentTab === TabsEnum.events && tem_id && (
            <LocatorsHistory lct_id={localEquipment.lct_id} />
          )} */}
        </Box>
      </Page>
    </>
  );
};

export default TypeEquipmentMaintenanceDetails;
