/* eslint-disable import/order */
/* eslint-disable react/no-array-index-key */
// Modificações no VehiclesRealtime.tsx
// Adicione um novo prop opcional para indicar o modo TV
// No componente, use esse prop para ajustar a renderização conforme necessário
// Por exemplo, você pode usar isTvMode para alterar estilos ou lógica de filtragem

// Modificações no LiveTv.tsx
import React, { useContext, useMemo } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider, { Settings } from 'react-slick';
import { Page } from 'src/components/layouts/Page';
import { VehiclesRealtime } from './VehiclesRealtime';
import { Grouping, useRemoteControl } from './contexts/RemoteControlContext';
import { MapsAirport } from '../devices/components/MapsAirport';
import { Button } from '@material-ui/core';
import { MonitorRounded } from '@material-ui/icons';
import { BrowserView } from 'react-device-detect';
import { LayoutContext } from 'src/contexts/LayoutContext/LayoutContext';
import Kanban from '../dashboard/Kanban';
import EquipmentCalendar from '../equipments/EquipmentCalendar';

export const LiveTv: React.FC = () => {
  const { tvMode, toggleFullScreen } = useContext(LayoutContext);
  const { state } = useRemoteControl();

  const selectedAirports = useMemo(
    () => state.selectedBases,
    [state.selectedBases],
  );

  const renderVehiclesRealtime = () => {
    if (state.grouping === Grouping.NONE) {
      return (
        <div>
          <Page title="Todas as bases">
            <VehiclesRealtime selectedAirports={[]} />
          </Page>
        </div>
      );
    }
    if (state.grouping === Grouping.BASE && selectedAirports.length > 0) {
      return selectedAirports.map((airport, index) => (
        <div key={`selected-${airport.id}-${index}`}>
          <Page title={`Base: ${airport}`}>
            <VehiclesRealtime selectedAirports={[airport]} />
          </Page>
        </div>
      ));
    }
    return (
      <div>
        {/* Fallback para quando não há aeroportos selecionados */}
        <VehiclesRealtime selectedAirports={[]} />
      </div>
    );
  };

  const slickSettings: Settings = useMemo(
    () => ({
      dots: false,
      infinite: state.settings.infinite,
      autoplaySpeed: state.settings.speed,
      slidesToShow: state.settings.slidesToShow,
      centerMode: false,
      arrows: false,
      autoplay: state.settings.autoplay,
      slidesToScroll: state.settings.slidesToScroll,
      pauseOnFocus: state.settings.pauseOnFocus,
    }),
    [state.settings],
  );

  const Content = () => (
    <div className="slider-container" style={{ paddingTop: 5 }}>
      <Slider {...slickSettings}>
        {renderVehiclesRealtime()}
        {state.otherSettings.showMap && (
          <div>
            <MapsAirport
              defaultHeight="100vh"
              defaultZoom={state.otherSettings.mapZoom}
            />
          </div>
        )}
        <div>
          <Kanban showOnlyKanban />
        </div>
        <div>
          <EquipmentCalendar
            defaultHeight="100vh"
            showOnlyCalendar
            defaultView="listWeek"
          />
        </div>
        <div>
          <EquipmentCalendar
            defaultHeight="100vh"
            showOnlyCalendar
            defaultView="dayGridMonth"
          />
        </div>
      </Slider>
    </div>
  );

  if (tvMode) {
    return <Content />;
  }

  return (
    <Page
      title="Modo TV"
      breadcrumbs={
        !tvMode
          ? [
              { to: '/', label: 'Painel' },
              { to: 'Dispositivos', label: 'Equipamentos' },
            ]
          : undefined
      }
      buttons={[
        <BrowserView>
          <Button
            color="primary"
            size="small"
            variant="outlined"
            style={{ borderRadius: 4 }}
            onClick={toggleFullScreen}
            startIcon={<MonitorRounded fontSize="small" />}
          >
            {!tvMode ? 'Abrir modo TV' : 'Fechar modo TV'}
          </Button>
        </BrowserView>,
      ]}
    >
      <Content />
    </Page>
  );
};
