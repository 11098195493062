import { Stack } from '@material-ui/core';
import React from 'react';
import FormSection from 'src/components/forms/FormSection';
import { EquipmentCard } from '../components/EquipmentCard';

interface EquipmentTabDetailsProps {
  eqp_id: string;
}

export const EquipmentTabDetails: React.FC<EquipmentTabDetailsProps> = ({
  eqp_id,
}) => (
  <Stack spacing={2}>
    <FormSection title="Equipamento não energizado" actions={undefined}>
      <EquipmentCard eqp_id={eqp_id} />
    </FormSection>
  </Stack>
);
