/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { TextfieldController } from 'src/components/forms/TextfieldController';
import {
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  InputAdornment,
  Box,
  Stack,
  FormHelperText,
} from '@material-ui/core';
import { number, object, SchemaOf, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isNaN } from 'lodash';
import { Restore } from '@material-ui/icons';
import {
  ITypeEquipmentMaintenance,
  ITypeEquipmentMaintenanceUpsertDto,
} from 'src/services/touchway-base-api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { queryKeys } from 'src/config/query';
import { useTouchwayApi } from 'src/services/touchway-api';
import { useConfirm } from 'material-ui-confirm';
import { getServerErrorArrayMessage } from 'src/utils/get-server-error-message';

interface ITypeEquipmentMaintenanceFormProps {
  typeEquipmentMaintenanceToBeEdited: ITypeEquipmentMaintenance | null;
  open: boolean;
  onClose: () => void;
  onSuccess?: (data: ITypeEquipmentMaintenance) => void;
}

enum TimeUnit {
  Days = 'dias',
  Months = 'meses',
  Years = 'anos',
}

interface FormITypeEquipmentMaintenanceUpsertDto
  extends Omit<
    ITypeEquipmentMaintenanceUpsertDto,
    'tem_duration_in_decimal_hours'
  > {
  tmp_duration: number;
}

const schema: SchemaOf<FormITypeEquipmentMaintenanceUpsertDto> = object({
  tem_id: string().optional(),
  tem_name: string().required(),
  tmp_duration: number().required(),
});

export const TypeEquipmentMaintenanceForm: React.FC<ITypeEquipmentMaintenanceFormProps> =
  ({ open, onClose, typeEquipmentMaintenanceToBeEdited, onSuccess }) => {
    const { api } = useTouchwayApi();
    const queryCache = useQueryClient();
    const confirm = useConfirm();
    const isEditForm = !!typeEquipmentMaintenanceToBeEdited;

    const { handleSubmit, control, watch, setValue, formState } =
      useForm<FormITypeEquipmentMaintenanceUpsertDto>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        // @ts-ignore
        resolver: yupResolver(schema),
        defaultValues: {
          tem_id: typeEquipmentMaintenanceToBeEdited?.tem_id || undefined,
          tem_name: typeEquipmentMaintenanceToBeEdited?.tem_name || undefined,
          tmp_duration:
            typeEquipmentMaintenanceToBeEdited?.tem_duration_in_decimal_hours
              ? convertHoursToDays(
                  typeEquipmentMaintenanceToBeEdited.tem_duration_in_decimal_hours,
                )
              : 0,
        },
      });

    const [timeUnit, setTimeUnit] = React.useState<TimeUnit>(TimeUnit.Days);
    const { tmp_duration, tem_name } = watch();

    const getConvertedValue = useCallback(() => {
      switch (timeUnit) {
        case TimeUnit.Days:
          return convertDaysToHours(tmp_duration);
        case TimeUnit.Months:
          return convertMonthsToHours(tmp_duration);
        case TimeUnit.Years:
          return convertYearsToHours(tmp_duration);
        default:
          return 0;
      }
    }, [tmp_duration, timeUnit]);

    useEffect(() => {
      const convertedValue = getConvertedValue();

      if (
        formState.dirtyFields.tem_name === undefined &&
        !isNaN(convertedValue)
      ) {
        setValue('tem_name', `Frequência de ${tmp_duration} ${timeUnit}`);
      }
    }, [
      timeUnit,
      tmp_duration,
      setValue,
      tem_name,
      formState.dirtyFields,
      getConvertedValue,
    ]);

    const mutateEdit = useMutation(
      api.typeEquipmentMaintenance.typeEquipmentMaintenanceControllerUpsert,
      {
        onSuccess: (data) => onSuccess && onSuccess(data),
      },
    );

    const deleteMutation = useMutation(
      api.typeEquipmentMaintenance.typeEquipmentMaintenanceControllerDelete,
      {
        onSuccess: () => {
          queryCache.refetchQueries(queryKeys.equipment.get());
          queryCache.refetchQueries(
            queryKeys.typeEquipmentMaintenance.list({}),
          );
          onClose();
          toast.success('Frequência excluída com sucesso!');
        },
        onError: (error) =>
          getServerErrorArrayMessage(error).forEach((message) =>
            toast.error(message),
          ),
      },
    );

    const handleEditMaintenance = (data: ITypeEquipmentMaintenanceUpsertDto) =>
      mutateEdit.mutate(data, {
        onError: (error) =>
          getServerErrorArrayMessage(error).forEach((message) =>
            toast.error(message),
          ),
        onSuccess: ({ tem_id }) => {
          toast.success('Frequência editado com sucesso!');
          queryCache.refetchQueries(
            queryKeys.typeEquipmentMaintenance.list({ tem_id }),
          );
        },
      });

    function handleCreateTypeEquipment(
      data: ITypeEquipmentMaintenanceUpsertDto,
    ) {
      mutateEdit.mutate(data, {
        onError: (error) =>
          getServerErrorArrayMessage(error).forEach((message) =>
            toast.error(message),
          ),
        onSuccess: () => {
          toast.success('Frequência criado com sucesso!');
          queryCache.refetchQueries(queryKeys.equipment.get());
        },
      });
    }

    const onSubmit = (data: FormITypeEquipmentMaintenanceUpsertDto) => {
      const _data: ITypeEquipmentMaintenanceUpsertDto = {
        tem_name: data.tem_name,
        tem_duration_in_decimal_hours: getConvertedValue(),
        tem_id: data?.tem_id || undefined,
      };
      return isEditForm
        ? handleEditMaintenance(_data)
        : handleCreateTypeEquipment(_data);
    };

    function resetName() {
      setValue('tem_name', `Frequência de ${tmp_duration} ${timeUnit}`);
    }

    function handleDelete() {
      if (!typeEquipmentMaintenanceToBeEdited) {
        toast.error('Equipamento não encontrado');
        return;
      }

      confirm({
        description:
          'Tem certeza que deseja excluir esta frequência? PS: Você só pode excluir frequências que não tenham manutenções em andamento.',
        title: 'Excluir Frequência',
      }).then(() => {
        deleteMutation.mutate({
          tem_id: typeEquipmentMaintenanceToBeEdited.tem_id ?? '',
        });
      });
    }

    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>
          {isEditForm ? 'Editar Frequência' : 'Criar Frequência'}
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)} id="create-equipment-form">
          <DialogContent>
            <Stack spacing={2}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="legend">Unidade de Tempo</FormLabel>
                <RadioGroup
                  row
                  aria-label="timeUnit"
                  name="timeUnit"
                  value={timeUnit}
                  defaultValue={TimeUnit.Days}
                  onChange={(e) => {
                    console.log(e.target.value);
                    return setTimeUnit(e.target.value as TimeUnit);
                  }}
                >
                  <FormControlLabel
                    value={TimeUnit.Days}
                    control={<Radio />}
                    label="Dias"
                  />
                  <FormControlLabel
                    value={TimeUnit.Months}
                    control={<Radio />}
                    label="Meses"
                  />
                  <FormControlLabel
                    value={TimeUnit.Years}
                    control={<Radio />}
                    label="Anos"
                  />
                </RadioGroup>
                <FormHelperText>
                  Selecione a unidade de tempo (dias, meses ou anos) para
                  determinar a frequência de manutenção do frequência.
                </FormHelperText>
              </FormControl>

              <Box>
                <TextfieldController
                  label={`Quantidade de ${timeUnit} (tmp)`}
                  placeholder="Ex: 30"
                  type="number"
                  control={control}
                  name="tmp_duration"
                  rules={{ required: 'Campo obrigatório' }}
                  helperMessage="Informe a quantidade de tempo na unidade selecionada. Este valor será usado para calcular a frequência da manutenção."
                />
              </Box>

              <TextfieldController
                control={control}
                name="tem_name"
                rules={{ required: 'Campo obrigatório' }}
                label="Nome da frequência"
                placeholder="Ex: Frequência de 20 dias"
                inputComponent={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        color="primary"
                        onClick={resetName}
                      >
                        <Restore />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperMessage="Defina um nome descritivo para a frequência de manutenção. Este nome será usado para identificar a manutenção nos registros."
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancelar</Button>
            <Button type="submit">
              {isEditForm ? 'Salvar Alterações' : 'Criar Frequência'}
            </Button>
            <Button onClick={handleDelete} variant="text" color="secondary">
              Excluir
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  };
/**
 * Converts days to hours.
 *
 * @param days - The number of days to convert.
 * @returns The equivalent number of hours.
 */
function convertDaysToHours(days: number) {
  return days * 24;
}

/**
 * Converts months to hours.
 *
 * @param months - The number of months to convert.
 * @returns The equivalent number of hours.
 */
function convertMonthsToHours(months: number) {
  return months * 30 * 24;
}

function convertYearsToHours(years: number) {
  return years * 365 * 24;
}

function convertHoursToDays(hours: number) {
  return hours / 24;
}
