import React, { FC, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { MUITSColumns, MUITSDatatable } from 'src/components/MUITSDatatable';
import { ILocator } from 'src/services/touchway-base-api';
import { useTouchwayApi } from 'src/services/touchway-api';
import { queryKeys } from 'src/config/query';
import { Divider, Grid } from '@material-ui/core';
import { StatisticsCard } from 'src/components/StatisticsCard';
import VinculateAndCreateVehicle from 'src/components/VinculateAndCreateVehicle';
import { RefetchStatusCard } from 'src/components/RefetchStatusCard';

const REFETCH_INTERVAL = 10000;

const LocatorsList: FC = () => {
  const touchwayApi = useTouchwayApi();

  const locatorsQuery = useQuery(
    // @ts-ignore
    queryKeys.locatorControllerList.get({
      with_relations: true,
    }),
    () =>
      // @ts-ignore: Object is possibly 'null'.
      touchwayApi.api.locator.locatorControllerList({
        with_relations: true,
      }),
    {
      refetchOnWindowFocus: false,
      // 60 seconds
      refetchInterval: REFETCH_INTERVAL,
    },
  );

  const locators = locatorsQuery?.data?.locators as unknown as ILocator[];

  const id: keyof ILocator = 'lct_id';
  const newColumns: MUITSColumns<ILocator> = [
    {
      // @ts-ignore
      name: 'lct_id',
      label: 'ID do Localizador',
      options: {
        filter: false,
        display: false,
        sort: false,
      },
    },
    {
      // @ts-ignore
      name: 'sim_id',
      label: 'ID do SIM Card',
      options: {
        filter: false,
        display: false,
        sort: false,
      },
    },
    {
      // @ts-ignore
      name: 'lct_imei',
      label: 'IMEI',
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      // @ts-ignore
      name: 'lct_password',
      label: 'Senha do Localizador',
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      // @ts-ignore
      name: 'lct_latitude',
      label: 'Latitude',
      options: {
        filter: false,
        display: false,
        sort: false,
      },
    },
    {
      // @ts-ignore
      name: 'lct_longitude',
      label: 'Longitude',
      options: {
        filter: false,
        display: false,
        sort: false,
      },
    },
    {
      // @ts-ignore
      name: 'vehicle.vhc_id',
      label: 'ID do Equipamento',
      options: {
        filter: false,
        display: false,
        sort: false,
      },
    },
    {
      // @ts-ignore
      name: 'vehicle.tpv_id',
      label: 'ID do Tipo de Equipamento',
      options: {
        filter: false,
        display: false,
        sort: false,
      },
    },
    {
      // @ts-ignore
      name: 'vehicle.vhc_name',
      label: 'Equipamento vinculado',
      options: {
        filter: true,
        display: true,
        sort: true,
        customBodyRender: (value, { rowData, columnIndex, rowIndex }) => {
          if (value) return `${value}`;

          const lctId = rowData[0];
          const imei = rowData[2];
          const key = `${columnIndex}-${rowIndex}`;

          return (
            <VinculateAndCreateVehicle key={key} lct_id={lctId} imei={imei} />
          );
        },
      },
    },
    {
      // @ts-ignore
      name: 'sim_card.sim_phone_number',
      label: 'Número de Telefone do SIM Card',
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      // @ts-ignore
      name: 'sim_card.sim_iccid',
      label: 'ICCID do SIM Card',
      options: {
        filter: false,
        display: false,
        sort: false,
      },
    },
  ];

  const locatorsWithVehicle = React.useMemo(
    () => locators?.filter((locator) => locator.vehicle !== null),
    [locators],
  );

  const locatorsWithoutVehicle = React.useMemo(
    () => locators?.filter((locator) => locator.vehicle === null),
    [locators],
  );

  const locatorsTotal = React.useMemo(() => locators?.length, [locators]);

  const [activeCard, setActiveCard] = useState('');

  const handleSetActiveCard = (cardName: string) => {
    setActiveCard(cardName);
  };

  const handleClearActiveCard = () => {
    setActiveCard('');
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={10}>
        {activeCard === '' && (
          <MUITSDatatable
            title="Localizadores"
            data={locators}
            columns={newColumns}
            options={{ enableNestedDataAccess: '.' }}
            idKey={id}
            showViewButton={false}
            showEditButton={false}
          />
        )}
        {activeCard === 'emUso' && (
          <MUITSDatatable
            title="Localizadores em uso"
            data={locatorsWithVehicle}
            columns={newColumns}
            options={{ enableNestedDataAccess: '.' }}
            idKey={id}
            showViewButton={false}
            showEditButton={false}
          />
        )}
        {activeCard === 'disponiveis' && (
          <MUITSDatatable
            title="Localizadores disponíveis"
            data={locatorsWithoutVehicle}
            columns={newColumns}
            options={{ enableNestedDataAccess: '.' }}
            idKey={id}
            showViewButton={false}
            showEditButton={false}
          />
        )}
      </Grid>
      <Grid item xs={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RefetchStatusCard
              refetchInterval={REFETCH_INTERVAL}
              query={locatorsQuery}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <StatisticsCard
              number={locatorsTotal}
              legend="Localizadores (GVs)"
              isClicked={activeCard === ''}
              onCardClick={() => handleSetActiveCard('')}
              setIsClicked={handleClearActiveCard}
            />
          </Grid>
          <Grid item xs={12}>
            <StatisticsCard
              number={locatorsWithVehicle?.length}
              legend="Localizadores em uso"
              isClicked={activeCard === 'emUso'}
              onCardClick={() => handleSetActiveCard('emUso')}
              setIsClicked={handleClearActiveCard}
            />
          </Grid>
          <Grid item xs={12}>
            <StatisticsCard
              number={locatorsWithoutVehicle?.length}
              legend="Localizadores disponíveis"
              isClicked={activeCard === 'disponiveis'}
              onCardClick={() => handleSetActiveCard('disponiveis')}
              setIsClicked={handleClearActiveCard}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LocatorsList;
