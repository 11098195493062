import { ILocator } from 'src/services/touchway-base-api';
import { LocatorConverter } from '../class/Locator';
import {
  ILocatorFirebaseRealtimeType,
  Locator,
} from '../types/ILocatorFirebaseRealtime.type';

export interface IMergedLocators {
  lct_id: string;
  fb_locator: LocatorConverter | null;
  db_locator: ILocator;
}

interface ITmpFirebaseRealtimeLocators {
  firebase_realtime_locators: ILocatorFirebaseRealtimeType;
  lct_id: string;
}

export function mergeLocators({
  firebase_realtime_locators,
  database_locators,
}: {
  firebase_realtime_locators: Record<string, ILocatorFirebaseRealtimeType>;
  database_locators: ILocator[];
}): IMergedLocators[] {
  const mergedLocators: IMergedLocators[] = [];
  const tmpFirebase: ITmpFirebaseRealtimeLocators[] = [];

  Object.entries(firebase_realtime_locators).forEach(
    ([lct_id, fb_locator]: [string, ILocatorFirebaseRealtimeType]) => {
      tmpFirebase.push({ firebase_realtime_locators: fb_locator, lct_id });
    },
  );

  database_locators.forEach((db_locator) => {
    const fb_locator = tmpFirebase.find(
      (tmp) => tmp.lct_id === db_locator.lct_id,
    );

    if (fb_locator && db_locator.lct_id) {
      // Ensure db_locator.lct_id is not undefined
      mergedLocators.push({
        lct_id: db_locator.lct_id,
        fb_locator: new LocatorConverter(fb_locator.firebase_realtime_locators),
        db_locator,
      });
    } else {
      mergedLocators.push({
        lct_id: String(db_locator.lct_id),
        fb_locator: null,
        db_locator,
      });
    }
  });

  return mergedLocators; // Ensure function returns a value
}
