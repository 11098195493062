import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@material-ui/core';
import MobileDatePicker from '@material-ui/lab/MobileDatePicker';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { SelectController } from 'src/components/forms/SelectController';
import { queryKeys } from 'src/config/query';
import { useTouchwayApi } from 'src/services/touchway-api';
import {
  IEquipmentMaintenance,
  IEquipmentMaintenanceUpsertDto,
  IMaintenanceStatusMtsConstantEnum,
} from 'src/services/touchway-base-api';
import { getServerErrorArrayMessage } from 'src/utils/get-server-error-message';
import { object, SchemaOf, string } from 'yup';
import { RenderBox } from './EquipmentMaintenanceForm';

interface EquipmentMaintenanceStatusChangeProps {
  maintenance: IEquipmentMaintenance;
  inDialog?: boolean;
}

interface IEquipmentMaintenanceStatusChangeForm
  extends Pick<
    IEquipmentMaintenanceUpsertDto,
    'mts_id' | 'eqp_id' | 'eqm_executed'
  > {}

const EquipmentMaintenanceStatusChange: React.FC<EquipmentMaintenanceStatusChangeProps> =
  ({ maintenance, inDialog = false }) => {
    const [open, setOpen] = React.useState(false);

    const { api } = useTouchwayApi();
    const queryClient = useQueryClient();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const schema: SchemaOf<IEquipmentMaintenanceStatusChangeForm> = object({
      mts_id: string().required(),
      eqp_id: string().required(),
      eqm_executed: string().optional().nullable(),
    });

    const { handleSubmit, control, watch, setValue } =
      useForm<IEquipmentMaintenanceStatusChangeForm>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        // @ts-ignore
        resolver: yupResolver(schema),
        defaultValues: {
          mts_id: maintenance.mts_id,
          eqp_id: maintenance.eqp_id,
          eqm_executed: maintenance.eqm_executed,
        },
      });

    const mtsQuery = useQuery({
      queryFn: () => api.autocomplete.autocompleteControllerStatusMaintenance(),
      queryKey: queryKeys.autocompleteControllerStatusMaintenance.get(),
    });

    const changeMaintenanceStatus = useMutation({
      mutationFn: api.equipment.equipmentControllerMaintenanceUpsert,
      onMutate(variables) {
        console.log('🚀 ~ onMutate ~ variables:', variables);
      },
      onSuccess() {
        toast.success('Status da manutenção alterado com sucesso');
        queryClient.invalidateQueries(queryKeys.equipment.get());
        queryClient.invalidateQueries(
          queryKeys.equipment.get({
            eqp_id: maintenance.eqp_id,
          }),
        );
        handleClose();
      },
      onError: (error) =>
        getServerErrorArrayMessage(error).forEach((message) =>
          toast.error(message),
        ),
    });

    function onSubmit(data: IEquipmentMaintenanceStatusChangeForm) {
      changeMaintenanceStatus.mutate({
        eqm_id: maintenance.eqm_id,
        tem_id: maintenance.tem_id,
        eqp_id: data.eqp_id,
        mts_id: data.mts_id,
        eqm_executed: data.eqm_executed
          ? new Date(data.eqm_executed).toISOString()
          : undefined,
      });
    }

    const isDone =
      maintenance?.maintenanceStatus?.mts_constant ===
      IMaintenanceStatusMtsConstantEnum.DONE;

    const canEditExecutedDate =
      isDone ||
      mtsQuery.data?.options.find(({ value }) => value === 'Concluída')?.id ===
        watch('mts_id');

    const SelectControllerComponent = () => (
      <Stack spacing={2}>
        <SelectController
          control={control}
          name="mts_id"
          label="Status"
          values={(mtsQuery.data?.options || []).map(({ id, value }) => ({
            key: id,
            label: value,
          }))}
          loading={mtsQuery.isLoading}
          defaultValue={watch('mts_id')}
          rules={{ required: true }}
          helperMessage="Selecione o novo status"
        />

        <RenderBox
          allowedFields={['eqm_executed']}
          field="eqm_executed"
          otherConditions={[canEditExecutedDate]}
        >
          <MobileDatePicker
            label="Data do ínicio da manutenção"
            onChange={(newDate) =>
              setValue('eqm_executed', newDate ?? undefined)
            }
            inputFormat="dd/MM/yyyy"
            renderInput={(inputProps) => (
              <TextField fullWidth variant="outlined" {...inputProps} />
            )}
            value={watch('eqm_executed')}
          />
        </RenderBox>
      </Stack>
    );

    const SaveButtonComponent = () => (
      <Button type="submit" variant="contained" color="primary">
        Salvar
      </Button>
    );

    return (
      <>
        {inDialog ? (
          <>
            <Button
              disabled={isDone}
              style={{
                display: isDone ? 'none' : 'block',
              }}
              onClick={handleOpen}
              variant="contained"
              color="primary"
            >
              Alterar status
            </Button>
            <Dialog open={open} onClose={handleClose}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Alterar status da manutenção</DialogTitle>
                <DialogContent>
                  <SelectControllerComponent />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancelar
                  </Button>
                  <SaveButtonComponent />
                </DialogActions>
              </form>
            </Dialog>
          </>
        ) : (
          <Stack>
            <form onSubmit={handleSubmit(onSubmit)}>
              <SelectControllerComponent />
              <SaveButtonComponent />
            </form>
          </Stack>
        )}
      </>
    );
  };

export default EquipmentMaintenanceStatusChange;
