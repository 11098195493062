import type { FC, ChangeEvent } from 'react';
import { Box, Divider, Grid, Tab, Tabs } from '@material-ui/core';
import { Page } from 'src/components/layouts/Page';
import useSettings from 'src/hooks/useSettings';
import { useLocalStorage } from '@penseapp/uselocalstorage';
import { Configurator } from '../gses/Configurator';
import ConfiguratorLocatorsAvailable from './ConfiguratorLocatorsAvailable';
import { MapsConfigurator } from './components/MapsConfigurator';
import SimcardsList from '../simcards/SimcardsList';
import LocatorsList from './LocatorsList';

enum TabsEnum {
  configurator,
  vehicle,
  maps,
  locators,
  simcards,
}

const tabs: { label: string; value: TabsEnum; debug: boolean }[] = [
  { label: 'Configurador', value: TabsEnum.configurator, debug: false },
  { label: 'Equipamentos Prontos', value: TabsEnum.vehicle, debug: false },
  { label: 'Equipamentos no mapa', value: TabsEnum.maps, debug: false },
  { label: 'Localizadores', value: TabsEnum.locators, debug: false },
  { label: 'Simcards', value: TabsEnum.simcards, debug: false },
];

const ConfiguratorTab: FC = () => {
  const { settings } = useSettings();
  const [currentTab, setCurrentTab] = useLocalStorage<TabsEnum>(
    'configuratorTab',
    TabsEnum.configurator,
  );

  const handleTabsChange = (event: ChangeEvent<{}>, value: TabsEnum): void => {
    setCurrentTab(value);
  };

  return (
    <>
      <Page
        title="Configurador"
        // query={gseQuery}
        // disabledFetching
        breadcrumbs={[
          { to: '/', label: 'Painel' },
          { to: 'veiculos', label: 'Equipamentos' },
          // { to: localGSE?.vhc_id, label: localGSE?.vhc_name },
        ]}
      >
        <Box sx={{ mt: 3 }}>
          <Tabs
            indicatorColor="primary"
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="primary"
            value={currentTab}
            variant="scrollable"
          >
            {tabs
              .filter((tab) => (tab.debug ? settings.debugMode : true))
              .map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
          </Tabs>
        </Box>
        <Divider />
        <Box sx={{ mt: 3 }}>
          {currentTab === TabsEnum.configurator && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Configurator />
              </Grid>
            </Grid>
          )}

          {currentTab === TabsEnum.vehicle && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ConfiguratorLocatorsAvailable />
              </Grid>
            </Grid>
          )}

          {currentTab === TabsEnum.simcards && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SimcardsList />
              </Grid>
            </Grid>
          )}

          {currentTab === TabsEnum.locators && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <LocatorsList />
              </Grid>
            </Grid>
          )}

          {currentTab === TabsEnum.maps && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MapsConfigurator />
              </Grid>
            </Grid>
          )}
        </Box>
      </Page>
    </>
  );
};

export default ConfiguratorTab;
