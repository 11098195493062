import { Stack, Skeleton } from '@material-ui/core';
import React from 'react';
import FormSection from 'src/components/forms/FormSection';
import {
  IEquipmentListDetailedResponse,
  IMaintenanceStatusMtsConstantEnum,
} from 'src/services/touchway-base-api';
import EmptyPlaceholder from 'src/components/layouts/EmptyPlaceholder';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'src/config/query';
import { useTouchwayApi } from 'src/services/touchway-api';
import { EquipmentMaintenanceCardDetails } from '../components/EquipmentMaintenanceCardDetails';

interface EquipmentTabMaintenanceHistoryProps {
  eqp_id: string;
}

export const EquipmentTabMaintenanceHistory: React.FC<EquipmentTabMaintenanceHistoryProps> =
  ({ eqp_id }) => {
    const { api } = useTouchwayApi();

    const eclParams = { eqp_id, with_relations: true };
    const equipmentQuery = useQuery(queryKeys.equipment.get(eclParams), () =>
      api.equipment.equipmentControllerList(eclParams),
    );

    if (equipmentQuery.isLoading) {
      return <Skeleton />;
    }

    const typeEqp =
      (equipmentQuery.data as any).equipments &&
      (equipmentQuery.data as any).equipments.length > 0
        ? (equipmentQuery.data as IEquipmentListDetailedResponse).equipments[0]
        : undefined;

    if (!typeEqp) {
      return (
        <EmptyPlaceholder title="Equipamento não encontrado" subtitle="" />
      );
    }

    return (
      <Stack spacing={2}>
        <FormSection
          title="Histórico de manutenção"
          description="Aqui você pode visualizar todas as manutenções que estão concluídas e/ou arquivadas"
          actions={undefined}
          showCard={false}
        >
          {typeEqp.eqp_id && (
            <EquipmentMaintenanceCardDetails
              showCreateOnEmpty={false}
              eqp_id={typeEqp.eqp_id}
              filter={[IMaintenanceStatusMtsConstantEnum.DONE]}
            />
          )}
        </FormSection>
      </Stack>
    );
  };
