import { Box, Typography, Badge } from '@material-ui/core';
import { format } from 'date-fns';
import {
  GseSocketRow,
  CustomLinearProgress,
} from 'src/pages/gses/components/GseMaintenances';
import {
  IMaintenanceStatusMtsConstantEnum,
  IVehicleMaintenancePlanner,
} from 'src/services/touchway-base-api';
import { ptBR } from 'date-fns/locale';
import { countTimeDays } from 'src/utils/countTimeDays';
import ChatAltIcon from '../../../icons/ChatAlt';
import type { Comment } from '../../../types/kanban';

interface KanbanContentCardProps {
  extraProps: IVehicleMaintenancePlanner;
  comments?: Comment[];
}

export const KanbanCardContent: React.FC<KanbanContentCardProps> = ({
  extraProps,
  comments,
}) => {
  const horimeter = extraProps?.vehicle?.vhc_hour_meter || 0;

  const remainingPercentage = Number(
    100 - extraProps?.vmp_completion_percentage || 0,
  );

  const calcHorimeter = Number(extraProps.vmp_hour_meter_forecast - horimeter);
  const roundedHorimeter = calcHorimeter.toFixed(0);
  const days = countTimeDays({
    created_at: extraProps.created_at,
    tpm_in_hours: extraProps.typeMaintenance.tpm_in_hours,
  });
  const messageForecast =
    calcHorimeter > 0
      ? `Faltam ${roundedHorimeter} horas (${days} dias) para a prox. manutenção`
      : `Manutenção atrasada em ${Number(calcHorimeter * -1).toFixed(
          0,
        )} horas (${days} dias)`;

  function renderHorimeter() {
    const isLegacy =
      !extraProps.vmp_horimeter_at_done &&
      extraProps.maintenanceStatus.mts_constant ===
        IMaintenanceStatusMtsConstantEnum.DONE;

    if (isLegacy) {
      return null;
    }

    if (extraProps.vmp_horimeter_at_done) {
      return (
        <GseSocketRow
          label="Concluído com"
          value={`${extraProps.vmp_horimeter_at_done} horas`}
        />
      );
    }

    return (
      <GseSocketRow
        label="Horímetro"
        value={horimeter ? `${horimeter.toFixed(2)} horas` : '---'}
      />
    );
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
        mt={-2}
      >
        {renderHorimeter()}

        <GseSocketRow
          label="Forecast"
          value={
            extraProps.vmp_hour_meter_forecast
              ? `${extraProps.vmp_hour_meter_forecast.toFixed(2)} horas`
              : '---'
          }
        />
        {extraProps?.vmp_execution_date && (
          <Box mt={2}>
            <GseSocketRow
              label="Data conclusão"
              value={format(
                new Date(extraProps.vmp_execution_date),
                'dd/MM/yyyy',
                {
                  locale: ptBR,
                },
              )}
            />
          </Box>
        )}
        <Box mb={2} />
        {!extraProps?.vmp_execution_date && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
            >
              <Typography fontWeight="bold" color="textPrimary" variant="body1">
                {extraProps.typeMaintenance.tpm_name}
              </Typography>
              <Typography color="textPrimary" variant="body1">
                {remainingPercentage.toFixed(2)}%
              </Typography>
            </Box>

            <CustomLinearProgress
              value={remainingPercentage}
              maintence={100}
              color={remainingPercentage > 0 ? 'primary' : 'secondary'}
              variant={remainingPercentage > 0 ? 'determinate' : 'determinate'}
            />

            <Typography variant="body2" color="textSecondary">
              {messageForecast}
            </Typography>
          </>
        )}
      </Box>

      {(comments || []).length > 0 && (
        <Box mt={2}>
          <Badge badgeContent={comments?.length} color="secondary">
            <ChatAltIcon fontSize="small" />
          </Badge>
        </Box>
      )}
    </>
  );
};
