import { FC, useMemo } from 'react';
import { Page } from 'src/components/layouts/Page';
import { MUITSColumns, MUITSDatatable } from 'src/components/MUITSDatatable';
import { Box, Button, Link } from '@material-ui/core';
import {
  SignalCellular0BarRounded,
  SignalCellular3BarRounded,
  SignalCellular1BarRounded,
  SignalCellular2BarRounded,
  SignalCellular4BarRounded,
  SignalCellularOffRounded,
  SignalWifi3Bar,
  SignalWifiOffRounded,
  SignalWifi0Bar,
  SignalWifi1Bar,
  SignalWifi2Bar,
  BatteryUnknownRounded,
  Battery20Rounded,
  Battery30Rounded,
  Battery50Rounded,
  Battery60Rounded,
  Battery80Rounded,
  Battery90Rounded,
  BatteryFullRounded,
  BatteryAlertOutlined,
  PlaceOutlined,
} from '@material-ui/icons';
import PulsingBadge from './components/PulsingBadge';
import { mockedAirports } from './components/MapsTrolleys';

const fakeTrolleys = () =>
  mockedAirports.flatMap((airport) => {
    const { lat, lng } = airport;
    const fakeCoords2 = [];
    // qnt between 10 and 40
    const qnt = Math.floor(Math.random() * 30) + 10;
    for (let i = 0; i < qnt; i++) {
      fakeCoords2.push({
        name: Math.floor(Math.random() * 1000000),
        bateria: Math.floor(Math.random() * 100) + 1,
        gps: Math.floor(Math.random() * 3),
        gsm: Math.floor(Math.random() * 110) * -1,
        isInMaintenance: Math.random() > 0.8,
        lat: lat + (Math.random() - 0.5) * 0.01,
        lng: lng + (Math.random() - 0.5) * 0.01,
        aeroporto: airport.iata,
      });
    }
    return fakeCoords2;
  });

const TrolleysList: FC = () => {
  const arr = useMemo(() => fakeTrolleys(), []);

  const id = 'id';
  const columns: MUITSColumns<any> = [
    {
      name: 'name',
      label: 'Nome ',
      options: { filter: true, display: true, sort: true },
    },
    {
      name: 'isInMaintenance',
      label: 'Status ',
      options: {
        filter: true,
        display: true,
        sort: true,
        customBodyRender: (value) => (
          <Box ml={-8}>
            <PulsingBadge
              variant={!value ? 'danger' : 'success'}
              withBorder={false}
              badgeLabel={!value ? 'Em manutenção' : 'Ativo'}
            />
          </Box>
        ),
      },
    },
    {
      // @ts-ignore
      name: 'aeroporto',
      label: 'Base',
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: 'name',
      label: 'Sinal',
      options: {
        filter: true,
        display: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const signalLevel = arr[dataIndex].gsm;

          if (!signalLevel) return <SignalCellularOffRounded />;
          if (signalLevel === 0) return <SignalCellular0BarRounded />;
          if (signalLevel >= -30) return <SignalCellular4BarRounded />;
          if (signalLevel >= -50) return <SignalCellular3BarRounded />;
          if (signalLevel >= -70) return <SignalCellular2BarRounded />;
          if (signalLevel >= -90) return <SignalCellular1BarRounded />;
          if (signalLevel >= -110) return <SignalCellular0BarRounded />;

          return <SignalCellularOffRounded />;
        },
      },
    },
    {
      name: 'name',
      label: 'GPS',
      options: {
        filter: true,
        display: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const { gps } = arr[dataIndex];

          if (!gps) return <SignalWifiOffRounded />;
          if (gps === 0) return <SignalWifiOffRounded />;
          if (gps === 1) return <SignalWifi3Bar color="primary" />;
          if (gps >= 40) return <SignalWifi3Bar />;
          if (gps >= 30) return <SignalWifi3Bar />;
          if (gps >= 20) return <SignalWifi2Bar />;
          if (gps >= 10) return <SignalWifi1Bar />;
          if (gps >= 1) return <SignalWifi0Bar />;

          return <SignalWifiOffRounded />;
        },
      },
    },
    {
      name: 'name',
      label: 'Bateria',
      options: {
        filter: true,
        display: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const batteryPercent = arr[dataIndex].bateria;

          if (!batteryPercent) return <BatteryUnknownRounded />;
          if (batteryPercent === 0) return <BatteryUnknownRounded />;
          if (batteryPercent >= 90) return <BatteryFullRounded />;
          if (batteryPercent >= 80) return <Battery90Rounded />;
          if (batteryPercent >= 70) return <Battery80Rounded />;
          if (batteryPercent >= 60) return <Battery60Rounded />;
          if (batteryPercent >= 50) return <Battery60Rounded />;
          if (batteryPercent >= 40) return <Battery50Rounded />;
          if (batteryPercent >= 30) return <Battery50Rounded />;
          if (batteryPercent >= 20) return <Battery30Rounded />;
          if (batteryPercent >= 10) return <Battery20Rounded />;
          if (batteryPercent >= 1) return <Battery20Rounded />;
          if (batteryPercent >= 0) return <BatteryAlertOutlined />;
          return <BatteryUnknownRounded />;
        },
      },
    },
    {
      name: 'name',
      label: 'Mapa',
      options: {
        filter: false,
        display: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const { lat, lng } = arr[dataIndex];

          return (
            <Link
              target="_blank"
              href={`https://maps.google.com/maps?z=1&t=m&q=loc:${lat}+${lng}`}
            >
              <Button
                variant="outlined"
                size="small"
                startIcon={<PlaceOutlined />}
              >
                Ver no mapa
              </Button>
            </Link>
          );
        },
      },
    },
  ];

  return (
    <Page
      title="Gerenciamento de Trolleys"
      breadcrumbs={[
        { to: '/', label: 'Painel' },
        { to: 'Trolleys', label: 'Equipamentos' },
      ]}
    >
      <MUITSDatatable
        title="Trolleys"
        data={arr}
        columns={columns}
        options={{ enableNestedDataAccess: '.' }}
        idKey={id}
        showEditButton={false}
      />
    </Page>
  );
};

export default TrolleysList;
