import type { FC } from 'react';
import type { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Tooltip,
  Typography,
} from '@material-ui/core';
import type { CardProps } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { IVehicleFirebaseRealtimeType } from 'src/pages/gses/types/IGSEFirebaseRealtime.type';
import useRealtime from 'src/hooks/firebase/useRealtime';
import { firebaseRealtimeEnum } from 'src/config';
import InformationCircleIcon from '../../../icons/InformationCircle';

const transformData = (
  data: IVehicleFirebaseRealtimeType | null,
): ITransformedData => {
  if (!data) {
    return {
      seriesNames: [],
      seriesValues: [],
    };
  }

  const countByIata = Object.values(data).reduce<Record<string, number>>(
    (acc, cur) => {
      if (cur.iata) {
        acc[cur.iata] = (acc[cur.iata] || 0) + 1;
      }
      return acc;
    },
    {},
  );

  const sortedData = Object.entries(countByIata).sort(
    ([, aCount], [, bCount]) => bCount - aCount,
  );

  return {
    seriesNames: sortedData.map(([iata]) => iata),
    seriesValues: sortedData.map(([, count]) => count),
  };
};

interface ITransformedData {
  seriesNames: string[];
  seriesValues: number[];
}

const UnitsPerBase: FC<CardProps> = (props) => {
  const theme = useTheme();

  const gseRealtime = useRealtime<IVehicleFirebaseRealtimeType>({
    collection: firebaseRealtimeEnum.vehicles,
    key: undefined,
    onValueChange: () => {},
  });

  const { seriesNames, seriesValues } = transformData(gseRealtime);

  const chartOptions: ApexOptions = {
    chart: {
      type: 'bar',
      background: 'transparent',
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
    },
    xaxis: {
      categories: seriesNames,
      axisBorder: {
        color: theme.palette.divider,
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true,
      },
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
        formatter: (value) => `${parseFloat(String(value)).toFixed(0)}`,
      },
    },
    yaxis: {
      axisBorder: {
        color: theme.palette.divider,
        show: true,
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true,
      },
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    colors: [theme.palette.primary.main],
  };

  return (
    <Card {...props}>
      <CardHeader
        disableTypography
        title={
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography color="textPrimary" variant="h6">
              Número de unidades por base
            </Typography>
            <Tooltip title="Mostra a quantidade de equipamentos agrupados por cada base ordenados pela maior quantidade">
              <InformationCircleIcon fontSize="small" />
            </Tooltip>
          </Box>
        }
      />
      <CardContent>
        <Chart
          height={seriesNames.length * 18}
          options={chartOptions}
          series={[{ data: seriesValues, name: 'Unidades' }]}
          type="bar"
        />
      </CardContent>
    </Card>
  );
};

export default UnitsPerBase;
