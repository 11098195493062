import { differenceInDays, parseISO } from 'date-fns';

export function countTimeDays({
  created_at,
  tpm_in_hours,
}: {
  created_at: string;
  tpm_in_hours: number;
}): number {
  const startDate = parseISO(created_at);
  const today = new Date();

  let maintenanceDays = 0;
  switch (Number(tpm_in_hours)) {
    case 300:
      maintenanceDays = 90; // 300 hours corresponds to 90 days
      break;
    case 5000:
      maintenanceDays = 730; // -5000 hours corresponds to 2 years
      break;
    case 10000:
      maintenanceDays = 1460; // -10000 hours corresponds to 4 years
      break;
    default:
      break;
  }

  const diffBetweenStartAndToday = differenceInDays(today, startDate);
  const response = maintenanceDays - diffBetweenStartAndToday;

  return response;
}
