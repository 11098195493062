const DEFAULT_CENTER_MAPS = {
  lat: -23.007404,
  lng: -47.134502,
};

const DEFAULT_ZOOM_MAPS = 15;

const DEFAULT_ICON_MAPS = {
  technical_icon:
    'https://cdn3.iconfinder.com/data/icons/home-services-4/130/180-512.png',
  // size: new window.google.maps.Size(40, 40),
  size: {
    width: 20,
    height: 20,
  },
  // scaledSize: new window.google.maps.Size(20, 20),
  scaledSize: {
    width: 20,
    height: 20,
  },
  // labelOrigin: new window.google.maps.Point(20, 50), // Ajustado aqui
  labelOrigin: {
    x: 0,
    y: 3,
  },
  scale: 10,
  color: {
    ignition: {
      on: '#4CAF50',
      off: '#D42929',
    },
  },
};

// size: new window.google.maps.Size(DEFAULT_MAPS.icon.size.width, DEFAULT_MAPS.icon.size.height),
// scaledSize: new window.google.maps.Size(DEFAULT_MAPS.icon.size.width, DEFAULT_MAPS.icon.size.height),
// labelOrigin: new window.google.maps.Point(DEFAULT_MAPS.icon.size.width, DEFAULT_MAPS.icon.size.x, DEFAULT_MAPS.icon.size.width, DEFAULT_MAPS.icon.size.y),

export const DEFAULT_MAPS = {
  center: DEFAULT_CENTER_MAPS,
  zoom: DEFAULT_ZOOM_MAPS,
  icon: DEFAULT_ICON_MAPS,
};
