import { Grid } from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { queryKeys } from 'src/config/query';
import { useTouchwayApi } from 'src/services/touchway-api';
import { IOption } from 'src/services/touchway-base-api';
import { DevicePhotoCard } from './DevicePhotoCard';

interface DeviceInstallationProps {
  vhc_id: string;
}

export const DeviceInstallation: React.FC<DeviceInstallationProps> = ({
  vhc_id,
}) => {
  const [images, setImages] = useState<IOption[]>([]);
  const { api } = useTouchwayApi();

  const queryImagesAutocomplete = useQuery({
    queryFn: async () => api.autocomplete.autocompleteControllerTypeImages(),
    queryKey: queryKeys.autocompleteControllerTypeImages.get(),
    cacheTime: undefined,
    staleTime: undefined,
  });

  const queryVehicleImages = useQuery({
    queryFn: async () =>
      api.images.imageControllerList({
        vhc_id,
      }),
    queryKey: queryKeys.images.get(vhc_id),
    cacheTime: 0,
    staleTime: 0,
  });

  useEffect(() => {
    if (queryImagesAutocomplete.isSuccess) {
      setImages(queryImagesAutocomplete.data.options);
    }
  }, [queryImagesAutocomplete.data, queryImagesAutocomplete.isSuccess]);

  if (queryImagesAutocomplete.isLoading) {
    return <div>Carregando...</div>;
  }

  return (
    <Grid container spacing={3} alignItems="stretch">
      {images.map((image) => {
        const tpiId = image.id;
        const tpiName = image.value;

        return (
          <Grid key={tpiId} item xs={4}>
            <DevicePhotoCard
              tpi_id={tpiId}
              tpi_name={tpiName}
              vhc_id={vhc_id}
              queryVehicleImages={queryVehicleImages}
              image={queryVehicleImages.data?.images.find(
                (img) => img.tpi_id === tpiId,
              )}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
