import { TextField } from '@material-ui/core';
import {
  Control,
  FieldError,
  RegisterOptions,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import InputMask from 'react-input-mask';

/**
 * Extract from MDN docs
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types
 */
type FormInputTypes =
  | 'button'
  | 'checkbox'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';

interface TextfieldControllerInterface
  extends Omit<UseControllerProps<any, any>, 'control' | 'rules'> {
  placeholder?: string;
  control: Control<any>;
  rules: Omit<
    RegisterOptions<any, any>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >;
  label?: string;
  disabled?: boolean;
  readonly?: boolean;
  variant?: 'filled' | 'outlined';
  helperMessage?: string;
  mask?: MaskInterface;
  type?: FormInputTypes;
  inputComponent?: any;
  multiline?: boolean;
  rows?: number;
  rowsMax?: number;
}

/**
 * @param mask for numbers, use "9", for letters use "a", for everything use "*"
 * @param maskChar Character to cover unfilled parts of the mask.
 *    Default character is "_". If set to null or empty string, unfilled parts
 *    will be empty as in ordinary input.
 * @param formatChars Defines format characters with characters as a keys and
 *    corresponding RegExp strings as a values
 * @param alwaysShowMask Show mask when input is empty and has no focus
 * @param inputRef Use inputRef instead of ref if you need input node to manage
 *    focus, selection, etc.
 *
 */
interface MaskInterface {
  mask: string;
  formatChars?: {};
  maskChar?: string;
  alwaysShowMask?: boolean;
  inputRef?: Function;
}

export const TextfieldController: React.FC<TextfieldControllerInterface> = ({
  control,
  name,
  rules,
  defaultValue,
  placeholder,
  label,
  mask,
  helperMessage,
  disabled = false,
  readonly = false,
  type,
  inputComponent,
  variant = 'outlined',
  multiline = false,
  rows = 1,
  ...props
}) => {
  const {
    field: { ref, ...fieldProps },
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return mask?.mask ? (
    <InputMask
      mask={mask?.mask}
      alwaysShowMask={mask?.alwaysShowMask || true}
      disabled={isSubmitting || disabled}
      readOnly={readonly}
      {...fieldProps}
    >
      {(maskInputProps: Record<string, any>) => (
        <TextField
          key={`field-name-${fieldProps.name}`}
          {...maskInputProps}
          inputRef={ref}
          id={`field-${name}`}
          // placeholder={placeholder || ''}
          label={label}
          defaultValue={defaultValue}
          variant={variant}
          fullWidth
          error={!!error}
          helperText={getHelperMessage(error || helperMessage)}
          multiline={multiline}
          rows={rows}
          {...props}
        />
      )}
    </InputMask>
  ) : (
    <TextField
      key={`field-name-${fieldProps.name}`}
      inputRef={ref}
      id={`field-${name}`}
      placeholder={placeholder || ''}
      defaultValue={defaultValue}
      label={label}
      InputLabelProps={{ shrink: !!fieldProps.value }}
      disabled={isSubmitting || disabled}
      variant={variant}
      fullWidth
      type={type || 'text'}
      error={!!error}
      helperText={getHelperMessage(error || helperMessage)}
      multiline={multiline}
      rows={rows}
      InputProps={{
        // @ts-ignore
        ...inputComponent,
        readOnly: readonly,
      }}
      {...props}
      {...fieldProps}
    />
  );
};

export function getHelperMessage(
  message: string | FieldError | undefined,
): string | undefined {
  if (!message) return undefined;
  if (typeof message === 'string') return message;
  if (message?.message) return message.message;

  const DEFAULT_MSG = 'Ooops, campo inválido!';

  switch (message?.type) {
    case 'max':
      return 'O campo ultrapassou o limite máximo de caracteres';

    case 'maxLength':
      return 'O campo ultrapassou o tamanho máximo';

    case 'min':
      return 'O campo não atingiu o limite mínimo de caracteres';

    case 'minLength':
      return 'O campo não atingiu o tamanho mínimo';

    case 'pattern':
      return 'O campo não segue o padrão necessário';

    case 'required':
      return 'O campo é obrigatório';

    case 'setValueAs':
      return DEFAULT_MSG;

    case 'shouldUnregister':
      return DEFAULT_MSG;

    case 'validate':
      return DEFAULT_MSG;

    case 'value':
      return DEFAULT_MSG;

    case 'valueAsDate':
      return 'O campo precisa ser uma data';

    case 'valueAsNumber':
      return 'O campo precisa ser um número';

    default:
      return DEFAULT_MSG;
  }
}
