import { FC, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { MUITSColumns, MUITSDatatable } from 'src/components/MUITSDatatable';
import { IVehicle } from 'src/services/touchway-base-api';
import { useTouchwayApi } from 'src/services/touchway-api';
import { queryKeys } from 'src/config/query';
import useAuth from 'src/hooks/useAuth';
import { format } from 'date-fns';
import { ImageTpinameEnum } from 'src/utils/getImageName';
import { DevicePhotoAvatar } from './components/DeviceImage/DevicePhotoAvatar';

const VehicleList: FC = () => {
  const { isAdmin } = useAuth();
  const touchwayApi = useTouchwayApi();
  // const gsesRealtime = useRealtime<IGseFirebaseCollection>({
  //   collection: firebaseRealtimeEnum.vehicles,
  //   path: 'root',
  // });

  const vehiclesQuery = useQuery(
    queryKeys.vehicleControllerList.get({
      with_relations: true,
    }),
    () =>
      touchwayApi.api.vehicles.vehicleControllerList({
        // @ts-ignore
        with_relations: true,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const vehiclesRes = vehiclesQuery?.data?.vehicles;
  const vehicles = useMemo(() => {
    const vehiclesRaw = vehiclesRes || [];
    return vehiclesRaw.filter(({ vhc_name }) => !vhc_name.startsWith('IMEI'));
  }, [vehiclesRes]);

  const id: keyof IVehicle = 'vhc_id';
  const columns: MUITSColumns<IVehicle> = [
    {
      name: 'vhc_id',
      label: 'vhc_id',
      options: { filter: true, display: false, sort: true },
    },
    {
      name: 'vhc_id',
      label: 'Foto',
      options: {
        filter: false,
        display: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const vhcId = vehicles[dataIndex].vhc_id;
          return (
            <DevicePhotoAvatar
              vhc_id={vhcId}
              image={ImageTpinameEnum.VEHICLE}
            />
          );
        },
      },
    },
    {
      name: 'vhc_name',
      label: 'Nome',
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      // @ts-ignore
      name: 'typeVehicle.tpv_name',
      label: 'Tipo',
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: 'vhc_fuel_avg_cons_lph',
      label: 'Consumo médio (L/h)',
      options: {
        filter: true,
        display: true,
        sort: true,
        customBodyRender: (value) => `${value.toFixed(2)}`,
      },
    },
    {
      name: 'vhc_hour_meter',
      label: 'Horímetro (h)',
      options: {
        filter: true,
        display: true,
        sort: true,
        customBodyRender: (value) => `${value.toFixed(2)}`,
      },
    },
    {
      // @ts-ignore
      name: 'maintenances.0.vmp_completion_percentage',
      label: '300h (%)',
      options: {
        filter: true,
        display: true,
        sort: true,
        customBodyRender: (value) => {
          const remainingPercentage = 100 - value;
          return `${remainingPercentage.toFixed(2)}%`;
        },
      },
    },
    {
      // @ts-ignore
      name: 'maintenances.1.vmp_completion_percentage',
      label: '5000h (%)',
      options: {
        filter: true,
        display: true,
        sort: true,
        customBodyRender: (value) => {
          const remainingPercentage = 100 - value;
          return `${remainingPercentage.toFixed(2)}%`;
        },
      },
    },
    {
      // @ts-ignore
      name: 'maintenances.2.vmp_completion_percentage',
      label: '1000h (%)',
      options: {
        filter: true,
        display: true,
        sort: true,
        customBodyRender: (value) => {
          const remainingPercentage = 100 - value;
          return `${remainingPercentage.toFixed(2)}%`;
        },
      },
    },
    {
      // @ts-ignore
      name: 'locator.lct_imei',
      label: 'IMEI',
      options: {
        filter: true,
        display: isAdmin,
        sort: true,
      },
    },
    {
      // @ts-ignore
      name: 'locator.sim_card.sim_iccid',
      label: 'ICCID',
      options: {
        filter: false,
        display: isAdmin,
        sort: false,
      },
    },
    {
      // @ts-ignore
      name: 'locator.updated_at',
      label: 'Última comunicação',
      options: {
        filter: false,
        display: isAdmin,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          // @ts-ignore: Unreachable code error
          const { locator } = vehicles[dataIndex];
          if (locator) {
            return format(new Date(locator.updated_at), 'dd/MM/yyyy HH:mm:ss');
          }
          return 'N/A';
        },
      },
    },
  ];

  return (
    <MUITSDatatable
      title="Equipamentos"
      data={vehicles}
      columns={columns}
      options={{ enableNestedDataAccess: '.' }}
      idKey={id}
      showViewButton
      showEditButton={false}
    />
  );
};

export default VehicleList;
