/* eslint-disable no-alert */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@material-ui/core';
import { Save } from '@material-ui/icons';
import MutatingButton from 'src/components/forms/MutatingButton';
import { TextfieldController } from 'src/components/forms/TextfieldController';
import {
  IEquipment,
  IEquipmentListSimple,
  IEquipmentUpsertDto,
} from 'src/services/touchway-base-api';
import { useTouchwayApi } from 'src/services/touchway-api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/config/query';
import useAuth from 'src/hooks/useAuth';
import { yupResolver } from '@hookform/resolvers/yup';
import { SchemaOf, object, string } from 'yup';
import FormSection from 'src/components/forms/FormSection';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { getServerErrorArrayMessage } from 'src/utils/get-server-error-message';
import { SelectController } from 'src/components/forms/SelectController';

interface EquipmentFormInterface {
  equipmentToBeEdited: IEquipment | null;
  defaultValues?: Partial<IEquipmentUpsertDto>;
  onSuccess?: (data: IEquipmentListSimple) => void;
}

const EquipmentForm: React.FC<EquipmentFormInterface> = ({
  equipmentToBeEdited,
  onSuccess,
}) => {
  const navigate = useNavigate();
  const queryCache = useQueryClient();
  const { api } = useTouchwayApi();
  const isEditForm = !!equipmentToBeEdited;
  const [goToMaintenance, setGoToMaintenance] = useState<boolean>(!isEditForm);
  const { isTouchway } = useAuth();

  const mutateEdit = useMutation(api.equipment.equipmentControllerUpsert, {
    onSuccess: (data) => onSuccess && onSuccess(data),
  });

  const params = { limit: 999 };
  const airportsQuery = useQuery(
    queryKeys.autocompleteControllerAirportList.get(params),
    () => api.autocomplete.autocompleteControllerAirportList(params),
  );
  const airports = airportsQuery.data?.options || [];

  const schema: SchemaOf<IEquipmentUpsertDto> = object({
    air_id: string().required('A base do equipamento é obrigatória'),
    eqp_id: isEditForm ? string().required() : string().nullable(),
    eqp_external_id: string().optional(),
    eqp_name: string().max(250).required('O nome do equipamento é obrigatório'),
    eqp_serial_number: string().optional(),
    eqp_fleet: string().optional(),
    eqp_pn: string().optional(),
    eqp_tag: string().optional(),
  });

  const { handleSubmit, control } = useForm<IEquipmentUpsertDto>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    // @ts-ignore
    resolver: yupResolver(schema),
    defaultValues: {
      air_id: equipmentToBeEdited?.air_id,
      eqp_id: equipmentToBeEdited?.eqp_id,
      eqp_external_id: equipmentToBeEdited?.eqp_external_id,
      eqp_name: equipmentToBeEdited?.eqp_name,
      eqp_serial_number: equipmentToBeEdited?.eqp_serial_number,
      eqp_fleet: equipmentToBeEdited?.eqp_fleet,
      eqp_pn: equipmentToBeEdited?.eqp_pn,
      eqp_tag: equipmentToBeEdited?.eqp_tag ?? '',
    },
  });

  const handleEditEquipment = (data: IEquipmentUpsertDto) =>
    mutateEdit.mutate(data, {
      onError: (error) =>
        getServerErrorArrayMessage(error).forEach((message) =>
          toast.error(message),
        ),
      onSuccess: () => {
        toast.success('Equipamento editado com sucesso!');
        queryCache.refetchQueries(queryKeys.equipment.get());
        queryCache.refetchQueries(queryKeys.equipment.get({}));
      },
    });

  function handleCreateEquipment(data: IEquipmentUpsertDto) {
    mutateEdit.mutate(data, {
      onError: (error) =>
        getServerErrorArrayMessage(error).forEach((message) =>
          toast.error(message),
        ),
      onSuccess: ({ eqp_id }) => {
        toast.success('Equipamento criado com sucesso!');
        queryCache.refetchQueries(queryKeys.equipment.get());
        navigate(`/equipamentos/${eqp_id}`);
      },
    });
  }

  const onSubmit = (data: IEquipmentUpsertDto) =>
    isEditForm ? handleEditEquipment(data) : handleCreateEquipment(data);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormSection
          title={isEditForm ? 'Editar' : 'Criar'}
          description={
            <Stack>
              <Typography variant="body2">
                {isEditForm
                  ? 'Edite as informações do equipamento'
                  : 'Preencha as informações do novo equipamento'}
              </Typography>
            </Stack>
          }
          actions={
            <MutatingButton
              startIcon={<Save fontSize="small" />}
              type="submit"
              variant="contained"
              disabled={mutateEdit.isLoading}
              loadingText="Salvando..."
            >
              {isEditForm ? 'Editar' : 'Criar'}
            </MutatingButton>
          }
        >
          <TextfieldController
            control={control}
            name="eqp_name"
            label="Nome do equipamento"
            placeholder="Ex: TLD 40"
            rules={{ required: true }}
          />
          {/* <TextfieldController
            control={control}
            name="eqp_tag"
            placeholder="Ex: 123456"
            label="Tag"
            disabled={!isTouchway}
            rules={{ required: false }}
          /> */}
          <TextfieldController
            control={control}
            name="eqp_external_id"
            label="ID externo"
            placeholder="Ex: 10"
            rules={{ required: false }}
          />
          <TextfieldController
            control={control}
            name="eqp_serial_number"
            placeholder="Ex: 123456"
            label="Número de série"
            disabled={!isTouchway}
            rules={{ required: false }}
          />
          <TextfieldController
            control={control}
            name="eqp_fleet"
            label="Frota"
            rules={{ required: false }}
          />
          <TextfieldController
            control={control}
            name="eqp_pn"
            label="P/N"
            rules={{ required: false }}
          />
          <SelectController
            control={control}
            name="air_id"
            label="Base"
            values={(airports || []).map(({ id, value }) => ({
              key: id,
              label: value,
            }))}
            loading={airportsQuery.isLoading}
            defaultValue={equipmentToBeEdited?.airport?.air_id}
            rules={{ required: false }}
            helperMessage="Selecione a base do equipamento"
          />

          {goToMaintenance && (
            <Stack>
              <Box my={2}>
                <Divider />
              </Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={goToMaintenance}
                      checked={goToMaintenance}
                      onChange={() => setGoToMaintenance(!goToMaintenance)}
                    />
                  }
                  label="Definir manutenção após criação?"
                />
                <Typography variant="caption" color="textSecondary">
                  Você será redirecionado para a página de manutenção após a
                  criação do equipamento.
                </Typography>
              </FormGroup>
            </Stack>
          )}
        </FormSection>
      </form>
    </>
  );
};

export default EquipmentForm;
