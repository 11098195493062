import { LayoutContext } from './../contexts/LayoutContext/LayoutContext';
import { useContext, useState } from 'react';
import { LayoutTypesEnum } from 'src/contexts/LayoutContext/LayoutInterface';

interface UseLayoutInterface {
  setPageTitle: (pageTitle: string) => void;
  pageTitle: string;
  isSidebarMobileOpen: boolean;
  openMenu: () => void;
  closeMenu: () => void;
}

export default function useLayout(): UseLayoutInterface {
  const { isSidebarMobileOpen, setIsSidebarMobileOpen, stateLayout, dispatchLayout } = useContext(LayoutContext);
  
  const closeMenu = () => setIsSidebarMobileOpen(false);
  const openMenu = () => setIsSidebarMobileOpen(true);

  const setPageTitle = (pageTitle: string): void => dispatchLayout({
    type: LayoutTypesEnum.CONFIGURE_PAGE,
    page: { title: pageTitle },
  });

  return {
    pageTitle: stateLayout.title,
    isSidebarMobileOpen,
    openMenu,
    closeMenu,
    setPageTitle,
  };
}
