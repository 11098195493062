import { Box, Toolbar, Typography } from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import axios from 'axios';
import { PrismicRef } from './prismic-ref';

interface BannerNotificationProps {}

export interface PrismicResponse {
  page: number;
  results_per_page: number;
  results_size: number;
  total_results_size: number;
  total_pages: number;
  next_page: null;
  prev_page: null;
  results: Result[];
  version: string;
  license: string;
}

export interface Result {
  id: string;
  uid: string;
  url: null;
  type: string;
  href: string;
  tags: any[];
  first_publication_date: string;
  last_publication_date: string;
  slugs: any[];
  linked_documents: any[];
  lang: string;
  alternate_languages: any[];
  data: Data;
}

export interface Data {
  banner_title: string;
  banner_description: BannerDescription[];
  banner_background_color: string;
  banner_font_color: string;
  ligar_o_banner: boolean;
  banner_height__px_: number;
}

export interface BannerDescription {
  type: string;
  text: string;
  spans: any[];
  direction: string;
}

const ENDPOINT = 'https://touchway.prismic.io/api/v2';

export const BannerNotification: React.FC<BannerNotificationProps> = () => {
  const res = useQuery<PrismicResponse>({
    queryKey: ['banner-primsic'],
    cacheTime: undefined,
    staleTime: undefined,
    // 1 minute
    refetchInterval: 1000 * 60 * 1,
    queryFn: async () => {
      const refREs = await axios.get<PrismicRef>(ENDPOINT);
      const master = refREs.data.refs.find((ref) => ref.isMasterRef);
      const response = await axios.get(
        `${ENDPOINT}/documents/search?ref=${master?.ref}&src=apibrowser&q=[[at(document.id,%20%22ZULdCxAAAJZDtQAS%22)]]?v=c298c7f8233d`,
      );
      return response.data;
    },
  });

  const bannerData = res.data?.results[0].data;

  return res.isSuccess && bannerData?.ligar_o_banner ? (
    <Toolbar
      style={{
        backgroundColor: bannerData.banner_background_color,
        color: bannerData.banner_font_color,
        height: bannerData.banner_height__px_,
      }}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h6">{bannerData.banner_title}</Typography>
        {bannerData.banner_description.map((desc, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <Typography key={`${idx}`} variant="body2">
            {desc.text}
          </Typography>
        ))}
      </Box>
    </Toolbar>
  ) : null;
};

export default BannerNotification;
