import {
  Card,
  CardContent,
  Grid,
  Typography,
  Stack,
  Box,
} from '@material-ui/core';
import React from 'react';

interface FormSectionProps {
  title: string;
  description?: string | React.ReactNode;
  children: React.ReactNode;
  actions: React.ReactNode;
  showCard?: boolean;
}

const FormSection: React.FC<FormSectionProps> = ({
  title,
  description,
  children,
  actions,
  showCard = true,
}) => {
  const Content = () => (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Typography variant="h6">{title}</Typography>
        {description && (
          <Typography mt={1} variant="body2" color="text.secondary">
            {description}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} md={8}>
        <Stack spacing={3}>
          <Stack alignItems="flex-start" direction="row" spacing={2}>
            <Stack spacing={2} sx={{ flexGrow: 1 }}>
              {children}
            </Stack>
          </Stack>

          <Stack
            alignItems="flex-end"
            justifyContent="flex-end"
            direction="row"
          >
            {actions}
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );

  return showCard ? (
    <Card elevation={showCard ? 1 : 0}>
      <CardContent>
        <Content />
      </CardContent>
    </Card>
  ) : (
    <Box p={1}>
      <Content />
    </Box>
  );
};

export default FormSection;
