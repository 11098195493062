import type { FC } from 'react';
import { Button, Grid } from '@material-ui/core';
import { Page } from 'src/components/layouts/Page';
import { useNavigate } from 'react-router';
import { Add } from '@material-ui/icons';
import EquipmentList from './EquipmentList';

const EquipmentsTab: FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <Page
        title="Equipamentos"
        // query={gseQuery}
        buttons={[
          <Button
            color="primary"
            variant="outlined"
            onClick={() => navigate('/equipamentos/novo')}
            startIcon={<Add fontSize="small" />}
            sx={{ m: 1 }}
          >
            Novo equipamento
          </Button>,
        ]}
        breadcrumbs={[
          { to: '/', label: 'Painel' },
          { to: 'equipamentos', label: 'Equipamentos' },
        ]}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <EquipmentList />
          </Grid>
        </Grid>
      </Page>
    </>
  );
};

export default EquipmentsTab;
