import { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import {
  Box,
  Button,
  Drawer,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Settings as ISettings } from 'src/contexts/SettingsContext';
import { Settings } from '@material-ui/icons';
import { THEMES, getThemeName } from '../../constants';
import useSettings from '../../hooks/useSettings';

const getValues = (settings: ISettings): ISettings => ({
  compact: settings.compact,
  direction: settings.direction,
  responsiveFontSizes: settings.responsiveFontSizes,
  roundedCorners: settings.roundedCorners,
  theme: settings.theme,
  debugMode: settings.debugMode,
  showTrolleys: settings.showTrolleys,
  showCardPhotoOnRealtime: settings.showCardPhotoOnRealtime,
});

const SettingsDrawer: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { settings, saveSettings } = useSettings();
  const [open, setOpen] = useState<boolean>(false);
  const [values, setValues] = useState(getValues(settings));

  useEffect(() => {
    setValues(getValues(settings));
  }, [settings]);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleChange = (field: string, value: string | boolean): void => {
    setValues({
      ...values,
      [field]: value,
    });
  };

  const handleSave = (): void => {
    saveSettings(values);
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Configurações">
        <IconButton color="inherit" ref={anchorRef} onClick={handleOpen}>
          <Settings fontSize="small" />
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="right"
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            p: 2,
            // width: 320,
          },
        }}
      >
        <Typography color="textPrimary" variant="h6">
          Configurações
        </Typography>
        <Box sx={{ mt: 3 }}>
          <TextField
            fullWidth
            label="Cor de fundo"
            name="theme"
            onChange={(event): void =>
              handleChange('theme', event.target.value)
            }
            select
            SelectProps={{ native: true }}
            value={values.theme}
            variant="outlined"
          >
            {Object.keys(THEMES).map((theme) => {
              const label = getThemeName(theme);
              return (
                <option key={theme} value={theme}>
                  {label
                    .split('_')
                    .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
                    .join(' ')}
                </option>
              );
            })}
          </TextField>
        </Box>

        <Box sx={{ mt: 2, px: 1.5 }}>
          <FormControlLabel
            control={
              <Switch
                checked={values.debugMode}
                color="primary"
                edge="start"
                name="direction"
                onChange={(event): void =>
                  handleChange('debugMode', event.target.checked)
                }
              />
            }
            label={
              <div>
                Modo avançado
                <Typography
                  color="textSecondary"
                  component="p"
                  variant="caption"
                >
                  Mostra mais informações que o comum
                </Typography>
              </div>
            }
          />
        </Box>
        {/* <Box sx={{ mt: 2, px: 1.5 }}>
          <FormControlLabel
            control={
              <Switch
                checked={values.showTrolleys}
                color="primary"
                edge="start"
                name="direction"
                onChange={(event): void =>
                  handleChange('showTrolleys', event.target.checked)
                }
              />
            }
            label={
              <div>
                Mostrar Trolleys
                <Typography
                  color="textSecondary"
                  component="p"
                  variant="caption"
                >
                  Habilita os menus que contém gestão de trolleys
                </Typography>
              </div>
            }
          />
        </Box> */}

        <Box
          sx={{
            mt: 2,
            px: 1.5,
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={values.direction === 'rtl'}
                color="primary"
                edge="start"
                name="direction"
                onChange={(event): void =>
                  handleChange(
                    'direction',
                    event.target.checked ? 'rtl' : 'ltr',
                  )
                }
              />
            }
            label={
              <div>
                RTL
                <Typography
                  color="textSecondary"
                  component="p"
                  variant="caption"
                >
                  Mude a direção do texto
                </Typography>
              </div>
            }
          />
        </Box>
        <Box
          sx={{
            mt: 2,
            px: 1.5,
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={values.responsiveFontSizes}
                color="primary"
                edge="start"
                name="direction"
                onChange={(event): void =>
                  handleChange('responsiveFontSizes', event.target.checked)
                }
              />
            }
            label={
              <div>
                Tamanhos de fonte responsivos
                <Typography
                  color="textSecondary"
                  component="p"
                  variant="caption"
                >
                  Ajuste a fonte para pequenos veiculos
                </Typography>
              </div>
            }
          />
        </Box>

        <Box
          sx={{
            mt: 2,
            px: 1.5,
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={values.showCardPhotoOnRealtime}
                color="primary"
                edge="start"
                name="direction"
                onChange={(event): void =>
                  handleChange('showCardPhotoOnRealtime', event.target.checked)
                }
              />
            }
            label={
              <div>
                Foto do equipamento
                <Typography
                  color="textSecondary"
                  component="p"
                  variant="caption"
                >
                  Exibe a foto do equipamento no card de tempo real
                </Typography>
              </div>
            }
          />
        </Box>

        <Box
          sx={{
            mt: 2,
            px: 1.5,
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={values.enableTelemetry}
                color="primary"
                edge="start"
                name="direction"
                onChange={(event): void =>
                  handleChange('enableTelemetry', event.target.checked)
                }
              />
            }
            label={
              <div>
                Habilitar telemetria
                <Typography
                  color="textSecondary"
                  component="p"
                  variant="caption"
                >
                  Mostra informações de telemetria em tempo real nos cards do
                  painel
                </Typography>
              </div>
            }
          />
        </Box>

        <Box
          sx={{
            mt: 2,
            px: 1.5,
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={values.enableLastUpdate}
                color="primary"
                edge="start"
                name="direction"
                onChange={(event): void =>
                  handleChange('enableLastUpdate', event.target.checked)
                }
              />
            }
            label={
              <div>
                Habilitar ultíma atualização
                <Typography
                  color="textSecondary"
                  component="p"
                  variant="caption"
                >
                  Mostra informações da última atualização do localizador
                  vinculado
                </Typography>
              </div>
            }
          />
        </Box>
        {/* <Box
          sx={{
            mt: 2,
            px: 1.5,
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={values.compact}
                color="primary"
                edge="start"
                name="compact"
                onChange={(event): void =>
                  handleChange('compact', event.target.checked)
                }
              />
            }
            label={
              <div>
                Compact
                <Typography
                  color="textSecondary"
                  component="p"
                  variant="caption"
                >
                  Largura fixa em algumas telas
                </Typography>
              </div>
            }
          />
        </Box>
        <Box
          sx={{
            mt: 2,
            px: 1.5,
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={values.roundedCorners}
                color="primary"
                edge="start"
                name="roundedCorners"
                onChange={(event): void =>
                  handleChange('roundedCorners', event.target.checked)
                }
              />
            }
            label={
              <div>
                Cantos arredondados
                <Typography
                  color="textSecondary"
                  component="p"
                  variant="caption"
                >
                  Aumenta a suavidade
                </Typography>
              </div>
            }
          />
        </Box>
         */}
        <Box sx={{ mt: 3 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleSave}
            variant="contained"
          >
            Salvar configurações
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

export default SettingsDrawer;
