import { FC, useMemo, useState } from 'react';
import { MUITSColumns, MUITSDatatable } from 'src/components/MUITSDatatable';
import { format } from 'date-fns/esm';
import ptBR from 'date-fns/locale/pt-BR';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  LinearProgress,
  TextField,
} from '@material-ui/core';
import {
  IOption,
  IReportSimple,
  IVehicleSimple,
} from 'src/services/touchway-base-api';
import { useQuery } from '@tanstack/react-query';
import { AUTH_TOKEN } from 'src/services/axiosService';
import { useTouchwayApi } from 'src/services/touchway-api';
import { secondsToHours } from 'src/utils/time-convert';
import { makeReportEndpoint } from 'src/utils/makeReportEndpoint';
import { PictureAsPdfOutlined, Save } from '@material-ui/icons';
import axios from 'axios';
import { OverviewDateRange } from '../devices/components/OverviewDateRange';

interface IGSEHistory {}

export const VehiclesReport: FC<IGSEHistory> = () => {
  const [isLoadingExcel, setIsLoadingExcel] = useState<boolean>(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedVehicles, setSelectedVehicles] = useState<IVehicleSimple[]>(
    [],
  );
  const [selectedAirports, setSelectedAirports] = useState<IOption[]>([]);

  const touchwayApi = useTouchwayApi();

  const vehiclesIdsArr = selectedVehicles.map((v) => v.vhc_id).filter(Boolean);
  const airportsIdsArr = selectedAirports.map((a) => a.value).filter(Boolean);

  const apiParams = useMemo(
    () => ({
      end: endDate,
      start: startDate,
      iata: airportsIdsArr,
      vhc_ids: vehiclesIdsArr,
    }),
    [startDate, endDate, vehiclesIdsArr, airportsIdsArr],
  );

  const downloadEndpoint = useMemo(() => {
    const authToken = localStorage.getItem(AUTH_TOKEN) || '';

    return makeReportEndpoint({
      ...apiParams,
      token: authToken,
    });
  }, [apiParams]);

  async function downloadExcelFromServer() {
    setIsLoadingExcel(true);
    const token = await window.localStorage.getItem(AUTH_TOKEN);
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND}/report/excel`,
      apiParams,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      },
    );

    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `relatorio-touchway-${format(
      new Date(),
      'yyyy-MM-dd_HH-mm',
    )}.xlsx`;
    link.click();

    setIsLoadingExcel(false);
  }

  const historyQuery = useQuery({
    queryFn: () =>
      touchwayApi.api.report.reportControllerReport({
        start: format(startDate, 'yyyy-MM-dd'),
        end: format(endDate, 'yyyy-MM-dd'),
        vhc_ids: vehiclesIdsArr,
        iata: airportsIdsArr,
      }),
    queryKey: ['report', startDate, endDate, vehiclesIdsArr, airportsIdsArr],
  });

  const vehiclesQuery = useQuery(['vehicles'], {
    queryFn: () => touchwayApi.api.vehicles.vehicleControllerList(),
  });
  const vehiclesQueryData = vehiclesQuery.data?.vehicles || [];

  const airportsQuery = useQuery(['airports-v2', `limit-${999}`], {
    queryFn: () =>
      touchwayApi.api.autocomplete.autocompleteControllerAirports({
        limit: 999,
      }),
  });
  const airportsQueryData = airportsQuery.data?.options || [];

  const disableExcelButton =
    isLoadingExcel ||
    !historyQuery.data?.data?.length ||
    historyQuery.isLoading;

  const id: keyof IReportSimple = 'vhh_id';
  const columns: MUITSColumns<IReportSimple> = [
    {
      name: 'vhh_id',
      label: 'UUID',
      options: {
        filter: false,
        display: false,
        sort: false,
      },
    },
    {
      name: 'vhc_id',
      label: 'ID',
      options: {
        filter: true,
        display: false,
        sort: true,
      },
    },
    {
      name: 'vhc_name',
      label: 'Equipamento',
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: 'vhh_iata',
      label: 'Base',
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: 'vhh_ignition_on_date',
      label: 'Ignição',
      options: {
        filter: false,
        display: true,
        sort: true,
        customBodyRender: (value: string) =>
          value
            ? format(new Date(value), 'dd/MM/yyyy HH:mm', {
                locale: ptBR,
              })
            : '---',
      },
    },
    {
      name: 'vhh_ignition_off_date',
      label: 'Desligamento',
      options: {
        filter: false,
        display: true,
        sort: true,
        customBodyRender: (value: string) =>
          value
            ? format(new Date(value), 'dd/MM/yyyy HH:mm', {
                locale: ptBR,
              })
            : '---',
      },
    },
    {
      name: 'vhh_duration_in_seconds',
      label: 'Duração (h)',
      options: {
        filter: false,
        display: true,
        sort: true,
        customBodyRender: (value: string) => {
          if (!value) return '---';
          const durationInSeconds = parseInt(value, 10);
          return secondsToHours(durationInSeconds);
        },
      },
    },
    {
      name: 'vhh_fuel_consumed_in_liters',
      label: 'Combustível Consumido (L)',
      options: {
        filter: false,
        display: true,
        sort: true,
        customBodyRender: (value: number) => {
          if (!value) return '---';
          return value.toFixed(2);
        },
      },
    },
    // {
    //   name: 'created_at',
    //   label: 'Data de Criação',
    //   options: {
    //     filter: false,
    //     display: true,
    //     sort: true,
    //     customBodyRender: (value: string) =>
    //       value
    //         ? format(new Date(value), 'dd/MM/yyyy HH:mm', { locale: ptBR })
    //         : '---',
    //   },
    // },
    // {
    //   name: 'updated_at',
    //   label: 'Data de Atualização',
    //   options: {
    //     filter: false,
    //     display: true,
    //     sort: true,
    //     customBodyRender: (value: string) =>
    //       value
    //         ? format(new Date(value), 'dd/MM/yyyy HH:mm', { locale: ptBR })
    //         : '---',
    //   },
    // },
  ];

  // const data = useMemo(
  //   () =>
  //   historyQuery.data.filter((h) => {
  //       const createdAt = new Date(h.created_at);
  //       return createdAt >= startDate && createdAt <= endDate;
  //     }),
  //   [history, startDate, endDate],
  // );

  const CustomFilter = (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      alignContent={{ xs: 'center', sm: 'flex-start' }}
      flexWrap="wrap"
      flexDirection={{ xs: 'column', sm: 'row' }}
      gap={2}
    >
      <Box>
        <OverviewDateRange
          startDate={startDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
        />
      </Box>
      <Box flexGrow={1}>
        <Autocomplete
          disableCloseOnSelect
          id="vehicles"
          options={vehiclesQueryData}
          size="small"
          freeSolo
          multiple
          fullWidth
          getOptionLabel={(option) => option.vhc_name}
          onChange={(_, value) => {
            if (value) {
              setSelectedVehicles(value as unknown as IVehicleSimple[]);
            } else {
              setSelectedVehicles([]);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              style={{
                marginBottom: 0,
              }}
              label="Filtrar por equipamento"
              variant="outlined"
            />
          )}
        />
      </Box>
      <Box flexGrow={1}>
        <Autocomplete
          disableCloseOnSelect
          id="airports"
          options={airportsQueryData}
          size="small"
          freeSolo
          multiple
          fullWidth
          getOptionLabel={(option) => option.value}
          onChange={(_, value) => {
            if (value) {
              setSelectedAirports(value as unknown as IOption[]);
            } else {
              setSelectedAirports([]);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{
                marginBottom: 0,
              }}
              label="Filtrar por base"
              variant="outlined"
            />
          )}
        />
      </Box>

      <a target="_blank" href={downloadEndpoint} rel="noreferrer">
        <Button
          variant="contained"
          color="primary"
          startIcon={<PictureAsPdfOutlined />}
        >
          Baixar PDF
        </Button>
      </a>

      <Button
        startIcon={
          !isLoadingExcel ? (
            <Save />
          ) : (
            <CircularProgress size={16} color="primary" />
          )
        }
        disabled={disableExcelButton}
        onClick={downloadExcelFromServer}
        variant="contained"
        style={{
          color: 'white',
          backgroundColor: '#4CAF50',
        }}
      >
        {isLoadingExcel ? 'Gerando...' : 'Baixar Excel'}
      </Button>
    </Box>
  );

  return (
    <Box p={2}>
      <Box mb={2}>
        <Card>
          <CardContent>{CustomFilter}</CardContent>
          <div
            style={{
              position: 'relative',
              marginTop: 'auto',
              display:
                historyQuery.isLoading || historyQuery.isFetching
                  ? 'block'
                  : 'none',
            }}
          >
            <LinearProgress
              color="primary"
              style={{
                position: 'absolute',
                bottom: 0,
                left: '0',
                right: '0',
              }}
            />
          </div>
        </Card>
      </Box>
      <MUITSDatatable
        components={{
          TableToolbar: () => null,
        }}
        data={historyQuery.data?.data || []}
        columns={columns}
        options={{
          enableNestedDataAccess: '.',
          print: true,
          sort: false,
          filter: true,
        }}
        idKey={id}
        showEditButton={false}
        showViewButton={false}
      />
    </Box>
  );
};
