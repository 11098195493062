import React, { useState } from 'react';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
} from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import DeviceForm from 'src/pages/devices/forms/DeviceForm';
import { IVehicleSimple } from 'src/services/touchway-base-api';
import { queryKeys } from 'src/config/query';
import { useTouchwayApi } from 'src/services/touchway-api';

interface VinculateAndCreateVehicleProps {
  lct_id: string;
  imei: string;
}

const VinculateAndCreateVehicle: React.FC<VinculateAndCreateVehicleProps> = ({
  lct_id,
  imei,
}) => {
  const { api } = useTouchwayApi();
  const [open, setOpen] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState<IVehicleSimple | null>(
    null,
  );
  const [newVehicleName, setNewVehicleName] = useState('');

  const vehiclesQuery = useQuery(
    queryKeys.vehicleControllerList.get({
      with_relations: false,
    }),
    () =>
      api.vehicles.vehicleControllerList({
        // @ts-ignore
        with_relations: false,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const vehiclesRaw = vehiclesQuery.data
    ?.vehicles as unknown as IVehicleSimple[];
  const vehicles = vehiclesRaw?.filter(({ vhc_name }) =>
    vhc_name.includes('IMEI'),
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedVehicle(null);
    setNewVehicleName('');
  };

  const handleVinculateVehicle = () => {
    // Implement logic to create a new vehicle with the newVehicleName
    // For example, send a POST request to your API endpoint
    console.log('Creating new vehicle:', newVehicleName);
    handleClose();
  };

  const handleCreateVehicle = () => {
    vehiclesQuery.refetch();
  };

  const handleVehicleSelect = (
    event: React.ChangeEvent<{}>,
    value: IVehicleSimple | null,
  ) => {
    setSelectedVehicle(value);
  };

  return (
    <div>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        onClick={handleOpen}
      >
        Vincular equipamento
      </Button>
      <Dialog open={open} onClose={handleClose} unmountOnExit>
        <DialogTitle>Escolha ou crie um equipamento</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Selecione um equipamento existente ou crie um novo para prosseguir
            com o processo de vinculação.
          </DialogContentText>
          <Autocomplete
            key={`vehicle-select-${lct_id}`}
            id={`vehicle-select-${lct_id}`}
            value={selectedVehicle}
            onChange={handleVehicleSelect}
            options={vehicles || []}
            getOptionLabel={(option: IVehicleSimple | null) =>
              option ? option.vhc_name : ''
            }
            renderInput={(params: any) => (
              <TextField {...params} label="Selecionar equipamento" />
            )}
          />

          <Divider variant="middle" sx={{ mt: 2, mb: 4 }}>
            ou
          </Divider>
          <DeviceForm
            defaultValues={{
              lct_id,
              vhc_name: `IMEI - ${imei?.slice(-4)}`,
              vhc_hour_meter: 1000,
              vhc_fuel_avg_cons_lph: 12,
            }}
            deviceToBeEdited={null}
            onSuccess={handleCreateVehicle}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={handleVinculateVehicle}
            color="primary"
            disabled={!selectedVehicle && !newVehicleName}
          >
            {selectedVehicle ? 'Prosseguir' : 'Criar e prosseguir'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VinculateAndCreateVehicle;
