import { IGSE } from 'src/pages/gses/IGSE.type';
import { axiosGET, CustomAxiosResponse } from 'src/services/axiosService';
import { ConvertDevice } from '../models/DeviceModel';

/**
 * @deprecated
 * @returns
 */
export const getAllDevices = async (): Promise<CustomAxiosResponse<IGSE[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/gses`;

    const response = await axiosGET<IGSE[]>(endpoint);

    return {
      ...response,
      message: 'Pronto!',
    };
  } catch (error: any) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.',
    );
  }
};
