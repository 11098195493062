import type { FC, ReactNode } from 'react';

interface AirportGuardProps {
  children: ReactNode;
}

const AirportGuard: FC<AirportGuardProps> = (props) => {
  const { children } = props;
  // const { hasAirportSelected } = useAirports();
  // const location = useLocation();
  // const [requestedLocation, setRequestedLocation] = useState<string | null>(
  //   null,
  // );

  // if (!hasAirportSelected) {
  //   if (location.pathname !== requestedLocation) {
  //     setRequestedLocation(location.pathname);
  //   }

  //   return <SelectAirport />;
  // }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  // if (requestedLocation && location.pathname !== requestedLocation) {
  //   setRequestedLocation(null);
  //   return <Navigate to={requestedLocation} />;
  // }

  return <>{children}</>;
};

export default AirportGuard;
