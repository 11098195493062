import { useEffect } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
} from '@material-ui/core';
import {
  LoginAmplify,
  LoginAuth0,
  LoginFirebase,
  LoginJWT,
} from '../../../components/authentication/login';
import Logo from '../../../components/Logo';
import useAuth from '../../../hooks/useAuth';
import gtm from '../../../lib/gtm';

const Login: FC = () => {
  const { method } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>Login | Touchway</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          // backgroundImage:
          //   "url('https://vemvoar.voeazul.com.br/wp-content/uploads/2015/04/Avi%C3%A3o-Azul-Linhas-Aereas.jpg')",
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center bottom',
          backgroundAttachment: 'fixed',
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            py: 0,
            my: 'auto',
          }}
        >
          {/* <Box display="flex" justifyContent="center" mb={isMobile ? 3 : 8}>
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box> */}
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3,
                }}
              >
                <div>
                  {/* <Typography color="textPrimary" gutterBottom variant="h4">
                    Touchway
                  </Typography> */}
                  <Logo />
                  <Typography color="textSecondary" variant="body2">
                    Acesse a área restrita
                  </Typography>
                </div>
                <Box
                  sx={{
                    height: 32,
                    '& > img': {
                      maxHeight: '100%',
                      width: 'auto',
                    },
                  }}
                >
                  {/* @ts-ignore */}
                  <img
                    alt="Auth platform"
                    src="https://res.cloudinary.com/ddfytxkn4/image/upload/v1688753597/Design_sem_nome_1_1_anzlt4.png"
                  />
                </Box>
              </Box>
              <Box
                mb={1}
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}
              >
                {method === 'Amplify' && <LoginAmplify />}
                {method === 'Auth0' && <LoginAuth0 />}
                {method === 'Firebase' && <LoginFirebase />}
                {method === 'JWT' && <LoginJWT />}
                <Box
                  sx={{
                    mt: 3,
                    textAlign: 'end',
                  }}
                >
                  <Button
                    color="primary"
                    variant="text"
                    onClick={() =>
                      navigate('/authentication/password-recovery')
                    }
                  >
                    Esqueci minha senha
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
