/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useReducer,
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { firebaseRealtimeEnum } from 'src/config'; // Enum das coleções do Firebase
import useRealtime, { useUpdateRealtime } from 'src/hooks/firebase/useRealtime';
import { IOption } from 'src/services/touchway-base-api';

enum Grouping {
  BASE = 'base',
  NONE = 'none',
}

enum Ordering {
  ACTIVE_FIRST = 'activeFirst',
  MAINTENANCE_SOON = 'maintenanceSoon',
}

type FilterName =
  | 'base'
  | 'maintenance300h'
  | 'maintenance5000h'
  | 'maintenance10000h';

type Settings = {
  dots: boolean;
  infinite: boolean;
  speed: number;
  slidesToShow: number;
  autoplay: boolean;
  slidesToScroll: number;
  pauseOnHover: boolean;
  pauseOnFocus: boolean;
};

type Action =
  | { type: 'SET_GROUPING'; payload: Grouping }
  | { type: 'SET_ORDERING'; payload: Ordering }
  | { type: 'RESET' }
  | {
      type: 'TOGGLE_FILTER';
      payload: { filterName: FilterName; value: boolean };
    }
  | { type: 'SET_SELECTED_BASES'; payload: IOption[] }
  | { type: 'SET_SETTINGS'; payload: Partial<Settings> }
  | { type: 'SET_OTHER_SETTINGS'; payload: Partial<OtherSettings> };

interface OtherSettings {
  cardsPerRow: number;
  mapZoom?: number;
  showMap: boolean;
}

interface State {
  grouping: Grouping;
  ordering: Ordering;
  filters: {
    maintenance300h: boolean;
    maintenance5000h: boolean;
    maintenance10000h: boolean;
  };
  selectedBases: IOption[];
  settings: Settings;
  otherSettings: OtherSettings;
}

const initialSettings: Settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  autoplay: true,
  slidesToScroll: 1,
  pauseOnHover: false,
  pauseOnFocus: false,
};

const initialState: State = {
  grouping: Grouping.BASE,
  ordering: Ordering.ACTIVE_FIRST,
  filters: {
    maintenance300h: true,
    maintenance5000h: true,
    maintenance10000h: true,
  },
  selectedBases: [],
  settings: initialSettings,
  otherSettings: {
    cardsPerRow: 6,
    mapZoom: 5,
    showMap: true,
  },
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_GROUPING':
      return { ...state, grouping: action.payload };
    case 'SET_ORDERING':
      return { ...state, ordering: action.payload };
    case 'TOGGLE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterName]: action.payload.value,
        },
      };
    case 'SET_SELECTED_BASES':
      return {
        ...state,
        selectedBases: Array.isArray(action.payload) ? action.payload : [],
      };
    case 'SET_SETTINGS':
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload,
        },
      };
    case 'SET_OTHER_SETTINGS':
      return {
        ...state,
        otherSettings: {
          ...state.otherSettings,
          ...action.payload,
        },
      };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};

const RemoteControlContext = createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
}>({ state: initialState, dispatch: () => undefined });

const RemoteControlProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isFirebaseInitialized, setIsFirebaseInitialized] =
    useState<boolean>(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  // Use a hook useRealtime para carregar o estado inicial do Firebase
  const firebaseState = useRealtime<State>({
    collection: firebaseRealtimeEnum.remote,
    key: '',
  });

  // Use a hook useUpdateRealtime para salvar o estado no Firebase
  const updateRealtime = useUpdateRealtime<State>({
    collection: firebaseRealtimeEnum.remote,
    key: '',
  });

  // Atualize o estado do contexto com o estado carregado do Firebase
  useEffect(() => {
    if (firebaseState) {
      dispatch({ type: 'SET_GROUPING', payload: firebaseState.grouping });
      dispatch({ type: 'SET_ORDERING', payload: firebaseState.ordering });
      dispatch({ type: 'SET_SETTINGS', payload: firebaseState.settings });
      dispatch({
        type: 'SET_OTHER_SETTINGS',
        payload: firebaseState.otherSettings,
      });
      dispatch({
        type: 'SET_SELECTED_BASES',
        payload: firebaseState.selectedBases,
      });
      Object.keys(firebaseState.filters).forEach((filterName) => {
        dispatch({
          type: 'TOGGLE_FILTER',
          payload: {
            filterName: filterName as FilterName,
            // @ts-ignore
            value: firebaseState.filters[filterName],
          },
        });
      });

      setIsFirebaseInitialized(true);
    }
  }, [firebaseState]);

  useEffect(() => {
    // Evita salvar o estado inicial imediatamente no Firebase
    if (!isFirebaseInitialized) return;

    // Ajusta a lógica para evitar a dependência direta do estado completo
    const cleanedSelectedBases = state.selectedBases.filter(
      (base) => base !== undefined,
    );
    const cleanedState = { ...state, selectedBases: cleanedSelectedBases };

    // Converte o estado para string para comparação simples
    const stateAsString = JSON.stringify(cleanedState);
    const initialStateAsString = JSON.stringify(initialState);

    // Verifica se o estado atual é diferente do estado inicial antes de atualizar o Firebase
    if (stateAsString !== initialStateAsString) {
      updateRealtime(cleanedState);
    }
  }, [
    state.grouping,
    state.ordering,
    state.filters,
    state.selectedBases,
    state.settings,
    state.otherSettings,
    isFirebaseInitialized,
  ]); // Dependências específicas

  // Salve o estado no Firebase sempre que ele for alterado
  return (
    <RemoteControlContext.Provider value={{ state, dispatch }}>
      {children}
    </RemoteControlContext.Provider>
  );
};

const useRemoteControl = () => useContext(RemoteControlContext);

export { RemoteControlProvider, useRemoteControl, Grouping, Ordering };
export type { FilterName, Settings };
