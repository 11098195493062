import { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Page } from 'src/components/layouts/Page';
import { useParams } from 'react-router';
import { Box, Card } from '@material-ui/core';
import { useTouchwayApi } from 'src/services/touchway-api';
import { queryKeys } from 'src/config/query';

const DeviceEdit: FC = () => {
  const { api } = useTouchwayApi();
  const { gseId } = useParams();
  const query = useQuery(
    queryKeys.vehicleControllerList.get({
      vhc_id: gseId,
    }),
    () => api.vehicles.vehicleControllerList({ vhc_id: gseId }),
  );
  const device = query?.data?.vehicles[0];

  return (
    <Page
      title={`Editar ${device?.vhc_name}`}
      query={query}
      breadcrumbs={[
        { to: '/', label: 'Painel' },
        { to: 'Dispositivos', label: 'Equipamentos' },
        { to: device?.vhc_id, label: device?.vhc_name },
      ]}
    >
      <Card>
        <Box sx={{ p: 3 }}>
          {/* <DeviceForm deviceToBeEdited={device || undefined} /> */}
        </Box>
      </Card>
    </Page>
  );
};

export default DeviceEdit;
