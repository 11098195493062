import { IReportListDto } from 'src/services/touchway-base-api';

interface IReportEndpoint extends Omit<IReportListDto, 'start' | 'end'> {
  token: string;
  start: Date;
  end: Date;
}

export function makeReportEndpoint({
  iata,
  start,
  end,
  vhc_ids,
  token,
}: IReportEndpoint) {
  const baseUrl = new URL(`${process.env.REACT_APP_REPORT_URL}`);
  const params = new URLSearchParams();

  const _start = start.toISOString();
  const _end = end.toISOString();

  params.set('start_date', _start);
  params.set('end_date', _end);
  if (iata) {
    params.set('iata_code', iata.join(','));
  }
  if (vhc_ids) {
    params.set('vhc_id', vhc_ids.join(','));
  }
  params.set('token', token);

  return `${baseUrl.protocol}//${baseUrl.host}${
    baseUrl.pathname
  }?${params.toString()}`;
}
