import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormHelperText,
  CircularProgress,
} from '@material-ui/core';
import {
  Control,
  RegisterOptions,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { getHelperMessage } from './TextfieldController';

interface KeyLabel {
  key: string | number;
  label: string;
}

interface SelectControllerInterface
  extends Omit<UseControllerProps<any, any>, 'control' | 'rules'> {
  placeholder?: string;
  control: Control<any>;
  rules: Omit<
    RegisterOptions<any, any>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >;
  values: KeyLabel[];
  label?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  variant?: 'filled' | 'outlined';
  helperMessage?: string;
  loading?: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const SelectController: React.FC<SelectControllerInterface> = ({
  control,
  name,
  rules,
  values,
  defaultValue,
  placeholder,
  label,
  helperMessage,
  fullWidth = true,
  disabled = false,
  variant = 'outlined',
  loading = false,
}) => {
  const {
    field: { value, onChange, ...fieldProps },
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value || undefined);
  };

  // Correção: Garantir que o valor selecionado seja exibido corretamente
  const selectedValue = values.find((obj) => obj.key === value) ? value : '';

  return (
    <FormControl error={!!error} variant={variant} fullWidth={fullWidth}>
      <InputLabel error={!!error}>{label}</InputLabel>
      <Select
        multiple={false}
        variant={variant}
        disabled={disabled || isSubmitting || loading}
        placeholder={placeholder}
        onChange={handleChange}
        error={!!error}
        fullWidth
        input={<OutlinedInput label={label} />}
        value={selectedValue} // Atualizado para usar selectedValue
        renderValue={(selected) =>
          values.find((obj) => obj.key === selected)?.label || ''
        }
        MenuProps={MenuProps}
        {...fieldProps}
      >
        {loading ? (
          <MenuItem disabled>
            <CircularProgress size={24} />
          </MenuItem>
        ) : (
          values.map((keyValue) => (
            <MenuItem key={keyValue.key} value={keyValue.key}>
              {keyValue.label}
            </MenuItem>
          ))
        )}
      </Select>
      <FormHelperText error={!!error} id="my-helper-text">
        {getHelperMessage(error || helperMessage)}
      </FormHelperText>
    </FormControl>
  );
};
