import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import firebase from 'firebase/app';
import { useContext } from 'react';
import { AuthContext } from 'src/contexts/AuthContext/AuthContext';
import { AuthTypesEnum } from 'src/contexts/AuthContext/AuthInterface';
import { useCookies } from 'react-cookie';
import { AUTH_TOKEN, VariantEnum } from 'src/services/axiosService';
import { AirportContext } from '../contexts/AirportsContext';
import { IOption } from 'src/services/touchway-base-api';

interface UseAirportsInterface {
  airports: IOption[];
  hasAirportSelected: boolean;
  selectedAirports: IOption[];
  selectAirports: (airport: IOption[]) => void;
}

export default function useAirports(): UseAirportsInterface {
  const { airports, selectedAirports, setSelectedAirports } =
    useContext(AirportContext);

  const hasAirportSelected = selectedAirports.length > 0;

  function selectAirports(airports: IOption[]) {
    // add this airport to the selected airports
    setSelectedAirports(airports);
  }

  return {
    airports,
    hasAirportSelected,
    selectedAirports,
    selectAirports,
  };
}
