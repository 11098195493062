/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useMemo, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@material-ui/core';
import styled, { css, keyframes } from 'styled-components';
import PulsingBadge from 'src/components/PulsingBadge';
import { IVehicleSimple } from 'src/services/touchway-base-api';
import { DEFAULT_PLACEHOLDER } from 'src/hooks/useVehiclePhoto';
import { IVehicleFirebaseRealtimeType } from '../types/IGSEFirebaseRealtime.type';
import { GseMaintenances, GseSocketRow } from './GseMaintenances';

interface VehicleSocketCardProps {
  vehicle: IVehicleSimple;
  showCard?: boolean;
  showIgnitionStatus?: boolean;
  fb: IVehicleFirebaseRealtimeType;
}

function blinkingEffect() {
  return keyframes`
    50% {
      opacity: 0.15;
    }
  `;
}

interface IBlink {
  enableBlink: boolean;
}

const BlinkingCard = styled(Card)<IBlink>`
  ${({ enableBlink }) =>
    enableBlink &&
    css`
      animation: ${blinkingEffect} 1.33s linear infinite;
    `}
`;

interface AnimatedComponentProps extends IBlink {
  onClick?: () => void;
  elevation?: number;
  style?: React.CSSProperties;
}

export const AnimatedComponent: React.FC<AnimatedComponentProps> = ({
  enableBlink,
  children,
  ...props
}) => (
  <BlinkingCard enableBlink={enableBlink} {...props}>
    {children}
  </BlinkingCard>
);

const MIN = 0;
export const MAINTENANCE1_TIME = 300;
export const MAINTENANCE2_TIME = 5000;
export const MAINTENANCE3_TIME = 10000;

export const normaliseFun = (value: number, max: number) =>
  ((value - MIN) * 100) / (max - MIN);

interface IBlinkState {
  hours_300: false | 'danger' | 'warning';
  hours_5000: false | 'danger' | 'warning';
  hours_10000: false | 'danger' | 'warning';
}

export const VehicleSocketCard: React.FC<VehicleSocketCardProps> = ({
  vehicle,
  showCard = true,
  showIgnitionStatus = true,
  fb,
}) => {
  const [blinkState, setShouldBlinkState] = useState<IBlinkState>({
    hours_300: false,
    hours_5000: false,
    hours_10000: false,
  });

  const vehicleRealtime = fb;

  const horimeter = vehicleRealtime?.hour_meter || vehicle?.vhc_hour_meter;
  const isDeviceOn = vehicleRealtime?.ignition;
  const assetUrl = vehicleRealtime?.asset_url || DEFAULT_PLACEHOLDER;

  function triggerBlinkingEffect(
    key: 'hours_300' | 'hours_5000' | 'hours_10000',
    newValue: false | 'danger' | 'warning',
  ) {
    setShouldBlinkState((prevState) => ({
      ...prevState,
      [key]: newValue,
    }));
  }

  const isAtLeastOneWithAlert = useMemo(
    () => Object.values(blinkState).some((value) => value === 'danger'),
    [blinkState],
  );

  const isAtLeastOneWithWarning = useMemo(
    () => Object.values(blinkState).some((value) => value === 'warning'),
    [blinkState],
  );

  const shouldBlink = isAtLeastOneWithAlert && !!isDeviceOn;

  return (
    <AnimatedComponent enableBlink={false} elevation={showCard ? 1 : 0}>
      <CardHeader
        mb={-2}
        title={
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
            >
              <Typography color="textPrimary" variant="h6">
                {vehicleRealtime?.name || vehicle?.vhc_name}
              </Typography>
              {showIgnitionStatus && (
                <PulsingBadge
                  variant={
                    isDeviceOn === undefined
                      ? 'danger'
                      : isDeviceOn
                      ? 'success'
                      : 'danger'
                  }
                  withBorder={false}
                  badgeLabel={isDeviceOn ? 'Ligado' : 'Desligado'}
                />
              )}
            </Box>
          </>
        }
      />
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
          mt={-2}
        >
          {/* <GseSocketRow
              label="Aeronave"
              value={vehicleRealtime?.tail_number || '---'}
            /> */}
          <GseSocketRow
            label="Localização"
            value={vehicleRealtime?.iata || '---'}
          />

          <Box mx={-2} my={2}>
            <Divider style={{ borderStyle: 'dotted' }} />
          </Box>

          <Box mb={2}>
            <GseSocketRow
              label="Horímetro"
              value={
                horimeter
                  ? `${
                      // segundo para hora
                      horimeter.toFixed(2)
                    } horas`
                  : '---'
              }
            />
          </Box>

          {vehicleRealtime && (
            <GseMaintenances
              ignition={vehicleRealtime.ignition}
              triggerBlinkingEffect={triggerBlinkingEffect}
              vhc_id={vehicle.vhc_id}
            />
          )}
        </Box>
      </CardContent>
    </AnimatedComponent>
  );
};

export default VehicleSocketCard;
