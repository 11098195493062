import {
  Box,
  Drawer,
  IconButton,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { addDays, set } from 'date-fns';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { isDesktop, isMobile } from 'react-device-detect';
import { DEFAULT_LOCALE } from 'src/config';
import styled, { css } from 'styled-components';

interface Dictonary {
  [x: string]: any;
  Today?: string;
  Yesterday?: string;
  'This Week'?: string;
  'Last Week'?: string;
  'This Month'?: string;
  'Last Month'?: string;
  'days up to today'?: string;
  'days starting today'?: string;
}

interface OverviewDateRangeInterface {
  startDate?: Date;
  setStartDate?: Dispatch<SetStateAction<Date>>;
  endDate?: Date;
  setEndDate?: Dispatch<SetStateAction<Date>>;
}

interface MobileInterface {
  muiTheme: Theme;
  openDialog: boolean;
}

const START_TIME = { hours: 0, minutes: 0, seconds: 0 };
const END_TIME = { hours: 23, minutes: 59, seconds: 59 };

const MobileDateRangePicker = styled(DateRangePicker)<MobileInterface>`
  .rdrDateDisplayWrapper {
    ${({ muiTheme }) => css`
      ${muiTheme.breakpoints.up('xs')} {
        width: calc(100vw - ${muiTheme.spacing(4)});
      }
      ${muiTheme.breakpoints.up('md')} {
        width: calc(100vw - ${muiTheme.spacing(8)});
      }
    `}

    .rdrDateDisplay {
      margin: 0;
    }
  }

  .rdrDefinedRangesWrapper,
  .rdrMonthAndYearWrapper {
    display: none;
  }

  .rdrDefinedRangesWrapper,
  .rdrMonths {
    display: ${({ openDialog }) => openDialog && 'none'};
  }
`;

const DesktopDateRangePicker = styled(DateRangePicker)<MobileInterface>`
  .rdrDateDisplayWrapper {
    .rdrDateDisplay {
      margin: 0;
      margin-bottom: 0.375em;
    }
  }

  .rdrDefinedRangesWrapper,
  .rdrMonthAndYearWrapper {
    visibility: hidden;
    margin-bottom: -60px;
  }

  .rdrDefinedRangesWrapper,
  .rdrMonths {
    display: ${({ openDialog }) => openDialog && 'none'};
  }

  .rdrDateDisplayWrapper {
    background-color: white !important;
  }
`;

const MobileDateRangePickerDrawer = styled(DateRangePicker)<MobileInterface>`
  .rdrCalendarWrapper {
    width: 100%;
  }
  .rdrDateDisplayWrapper {
    margin-left: 10px;
    margin-right: 10px;
    background-color: white;

    .rdrDateDisplay {
      margin: 0;
    }
  }

  .rdrDefinedRangesWrapper {
    display: none;
  }

  .rdrDefinedRangesWrapper,
  .rdrMonths {
    display: ${({ openDialog }) => openDialog && 'none'};
  }

  .rdrMonth {
    width: 100%;
  }
`;

export const OverviewDateRange: React.FC<OverviewDateRangeInterface> = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const theme = useTheme();

  function openCalendar() {
    setIsCalendarOpen(true);
  }

  function closeCalendar() {
    setIsCalendarOpen(false);
  }

  const [internalState, setInternalState] = useState([
    {
      startDate: startDate || new Date(),
      endDate: endDate || addDays(new Date(), 7),
      key: 'selection',
    },
  ]);

  // @ts-ignore
  const handleChangeState = (item: any) => setInternalState([item.selection]);

  useEffect(() => {
    if (!internalState[0]) return;
    if (setStartDate) setStartDate(set(internalState[0].startDate, START_TIME));
    if (setEndDate) setEndDate(set(internalState[0].endDate, END_TIME));
  }, [internalState, setEndDate, setStartDate]);

  const staticRangesLabels = {
    Today: 'Hoje',
    Yesterday: 'Ontem',
    'This Week': 'Essa semana',
    'Last Week': 'Última semana',
    'This Month': 'Esse mês',
    'Last Month': 'Último mês',
  };

  const inputRangesLabels = {
    'days up to today': 'Dias até hoje',
    'days starting today': 'Dias a partir de hoje',
  };

  function translateRange(dictionary: Dictonary) {
    return (item: { label: string | number }) =>
      dictionary[item.label]
        ? { ...item, label: dictionary[item.label] }
        : item;
  }

  const ruStaticRanges = defaultStaticRanges.map(
    // @ts-ignore
    translateRange(staticRangesLabels),
  );
  const ruInputRanges = defaultInputRanges.map(
    // @ts-ignore
    translateRange(inputRangesLabels),
  );

  return (
    <>
      {isDesktop ? (
        // @ts-ignore
        <Box onClick={() => openCalendar()}>
          {/* @ts-ignore */}
          <DesktopDateRangePicker
            onChange={handleChangeState}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={internalState}
            locale={DEFAULT_LOCALE}
            direction="horizontal"
            staticRanges={ruStaticRanges}
            inputRanges={ruInputRanges}
            muiTheme={theme}
            openDialog
          />
        </Box>
      ) : (
        <>
          <Box onClick={() => openCalendar()}>
            {/* @ts-ignore */}
            <MobileDateRangePicker
              editableDateInputs
              // @ts-ignore
              autoFocus={false}
              focusedRange={undefined}
              onChange={handleChangeState}
              moveRangeOnFirstSelection={false}
              ranges={internalState}
              locale={DEFAULT_LOCALE}
              direction="vertical"
              muiTheme={theme}
              staticRanges={ruStaticRanges}
              inputRanges={ruInputRanges}
              openDialog
            />
          </Box>
          {/* <Dialog open={openDialog} onClose={() => setOpenDialog(false)}> */}
        </>
      )}
      <Drawer
        anchor="right"
        onClose={() => closeCalendar()}
        open={isCalendarOpen}
        PaperProps={{
          sx: {
            p: 2,
            width: isMobile ? window.innerWidth / 1.3 : 'calc(560px + 3em)',
          },
        }}
      >
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography color="textPrimary" variant="h6">
            Selecione o período
          </Typography>
          <Box mr={1}>
            <IconButton onClick={() => closeCalendar()} edge="end">
              <Close />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ mt: 3 }}>
          {isMobile ? (
            // @ts-ignore
            <MobileDateRangePickerDrawer
              editableDateInputs
              autoFocus={false}
              focusedRange={undefined}
              // @ts-ignore
              onChange={handleChangeState}
              moveRangeOnFirstSelection={false}
              ranges={internalState}
              locale={DEFAULT_LOCALE}
              direction="vertical"
              muiTheme={theme}
              months={5}
              staticRanges={ruStaticRanges}
              inputRanges={ruInputRanges}
              openDialog={!isCalendarOpen}
            />
          ) : (
            // @ts-ignore
            <DateRangePicker
              editableDateInputs
              // @ts-ignore
              onChange={handleChangeState}
              moveRangeOnFirstSelection={false}
              ranges={internalState}
              locale={DEFAULT_LOCALE}
              direction="vertical"
              staticRanges={ruStaticRanges}
              inputRanges={ruInputRanges}
              months={5}
              preventSnapRefocus
            />
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default OverviewDateRange;
