import { useState, useEffect } from 'react';
import { AUTH_TOKEN } from './axiosService';
import { Api } from './touchway-base-api';

export const useTouchwayApi = () => {
  const bearerToken = window.localStorage.getItem(AUTH_TOKEN);

  const defaultInit = {
    baseURL: process.env.REACT_APP_BACKEND,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
  };

  const [api, setApi] = useState(new Api(defaultInit));

  useEffect(() => {
    const initializedApi = new Api(defaultInit);

    setApi(initializedApi);
  }, [bearerToken]);

  return {
    api,
  };
};

export const touchwayApi = new Api({
  baseURL: process.env.REACT_APP_BACKEND,
  headers: {
    'Content-Type': 'application/json',
  },
  securityWorker: async () => ({
    headers: {
      Authorization: `Bearer ${await window.localStorage.getItem(AUTH_TOKEN)}`,
    },
  }),
});
