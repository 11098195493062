/* eslint-disable @typescript-eslint/naming-convention */
import { FC, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { MUITSColumns, MUITSDatatable } from 'src/components/MUITSDatatable';
import {
  IEquipment,
  IEquipmentMaintenance,
} from 'src/services/touchway-base-api';
import { useTouchwayApi } from 'src/services/touchway-api';
import { queryKeys } from 'src/config/query';
import { format } from 'date-fns';

const EquipmentList: FC = () => {
  const touchwayApi = useTouchwayApi();
  // const gsesRealtime = useRealtime<IGseFirebaseCollection>({
  //   collection: firebaseRealtimeEnum.equipments,
  //   path: 'root',
  // });

  const params = {
    with_relations: true,
  };
  const equipmentsQuery = useQuery(
    queryKeys.equipment.get(params),
    () => touchwayApi.api.equipment.equipmentControllerList(params),
    {
      refetchOnWindowFocus: false,
    },
  );

  const equipmentsRes = equipmentsQuery?.data?.equipments;
  const equipments = useMemo(() => {
    const equipmentsRaw = equipmentsRes || [];
    return equipmentsRaw.filter(({ eqp_name }) => !eqp_name.startsWith('IMEI'));
  }, [equipmentsRes]);

  const id: keyof IEquipment = 'eqp_id';
  const columns: MUITSColumns<IEquipment> = [
    {
      name: 'eqp_id',
      label: 'eqp_id',
      options: { filter: true, display: false, sort: true },
    },
    {
      name: 'eqp_name',
      label: 'Nome',
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: 'eqp_pn',
      label: 'P/N',
      options: {
        filter: true,
        display: true,
        sort: true,
        customBodyRender: (value) => value || 'S/ Info',
      },
    },
    {
      name: 'eqp_fleet',
      label: 'Frota',
      options: {
        filter: true,
        display: true,
        sort: true,
        customBodyRender: (value) => value || 'S/ Info',
      },
    },
    {
      name: 'airport',
      label: 'Base',
      options: {
        filter: true,
        display: true,
        sort: true,
        customBodyRender: (value) => (value ? value.air_iata : 'S/ Info'),
      },
    },
    // {
    //   name: 'eqp_tag',
    //   label: 'Tag',
    //   options: {
    //     filter: true,
    //     display: true,
    //     sort: true,
    //   },
    // },
    {
      name: 'eqp_serial_number',
      label: 'Número de Série',
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: 'eqp_external_id',
      label: 'ID Externo',
      options: {
        filter: true,
        display: false,
        sort: true,
      },
    },
    {
      name: 'maintenances',
      label: 'Data Próxima manutenção',
      options: {
        filter: true,
        display: true,
        sort: true,
        customBodyRender: (values) => {
          const maintenances: IEquipmentMaintenance[] = values;
          if (maintenances === undefined || maintenances.length === 0) {
            return 'Não possui';
          }

          const { eqm_forecast, eqm_executed } = maintenances[0];
          if (eqm_executed) {
            return `Realizada em ${format(
              new Date(eqm_executed),
              'dd/MM/yyyy',
            )}`;
          }
          // eqm_forecast: "2022-09-01"
          // convert string to pt-br date format
          return format(new Date(eqm_forecast), 'dd/MM/yyyy');
        },
      },
    },
    {
      name: 'maintenances',
      label: 'Dias',
      options: {
        filter: true,
        display: true,
        sort: true,
        customBodyRender: (values) => {
          const maintenances: IEquipmentMaintenance[] = values;
          if (maintenances === undefined || maintenances.length === 0) {
            return 'Não possui';
          }

          const { eqm_forecast, eqm_executed } = maintenances[0];
          if (eqm_executed) {
            return `Realizada há ${Math.ceil(
              (new Date().getTime() - new Date(eqm_executed).getTime()) /
                (1000 * 60 * 60 * 24),
            )} dias`;
          }
          const days = Math.ceil(
            (new Date(eqm_forecast).getTime() - new Date().getTime()) /
              (1000 * 60 * 60 * 24),
          );
          return days;
        },
      },
    },
    {
      name: 'maintenances',
      label: 'Frequência Man.',
      options: {
        filter: true,
        display: true,
        sort: true,
        customBodyRender: (values) => {
          const maintenances: IEquipmentMaintenance[] = values;
          if (maintenances === undefined || maintenances.length === 0) {
            return 'Não possui';
          }

          const maintenance = maintenances[0];
          const { typeMaintenance } = maintenance;
          return typeMaintenance?.tem_name || 'Não possui';
        },
      },
    },
  ];

  return (
    <>
      <MUITSDatatable
        title="Equipamentos não energizados"
        data={equipments}
        columns={columns}
        options={{ enableNestedDataAccess: '.' }}
        idKey={id}
        showViewButton
        showEditButton={false}
      />
    </>
  );
};

export default EquipmentList;
