import React from 'react';
import { Outlet } from 'react-router-dom';

interface EquipmentsRootPageInterface {}

const EquipmentsRootPage: React.FC<EquipmentsRootPageInterface> = () => (
  <Outlet />
);

export default EquipmentsRootPage;
