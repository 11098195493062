import { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { MUITSColumns, MUITSDatatable } from 'src/components/MUITSDatatable';
import { ITypeEquipmentMaintenance } from 'src/services/touchway-base-api';
import { useTouchwayApi } from 'src/services/touchway-api';
import { queryKeys } from 'src/config/query';

const TypeEquipmentMaintenanceList: FC = () => {
  const touchwayApi = useTouchwayApi();
  // const gsesRealtime = useRealtime<IGseFirebaseCollection>({
  //   collection: firebaseRealtimeEnum.typeEquipmentMaintenances,
  //   path: 'root',
  // });

  const params = {};
  const typeEquipmentMaintenancesQuery = useQuery(
    queryKeys.typeEquipmentMaintenance.list(params),
    () =>
      touchwayApi.api.typeEquipmentMaintenance.typeEquipmentMaintenanceControllerList(),
    {
      refetchOnWindowFocus: false,
    },
  );

  const typeEquipmentMaintenancesRes =
    typeEquipmentMaintenancesQuery?.data?.type_equipment_maintenances;

  const typeEquipmentMaintenances = typeEquipmentMaintenancesRes || [];

  const id: keyof ITypeEquipmentMaintenance = 'tem_id';
  const columns: MUITSColumns<ITypeEquipmentMaintenance> = [
    {
      name: 'tem_id',
      label: 'ID',
      options: { filter: true, display: false, sort: true },
    },
    {
      name: 'tem_name',
      label: 'Nome',
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: 'tem_duration_in_decimal_hours',
      label: 'Duração em dias',
      options: {
        filter: true,
        display: true,
        sort: true,
        customBodyRender: (value) => (value / 24).toFixed(0),
      },
    },
    {
      name: 'tem_duration_in_decimal_hours',
      label: 'Duração em meses',
      options: {
        filter: true,
        display: true,
        sort: true,
        customBodyRender: (value) => (value / 24 / 30).toFixed(0),
      },
    },
    {
      name: 'tem_duration_in_decimal_hours',
      label: 'Duração em anos',
      options: {
        filter: true,
        display: false,
        sort: true,
        customBodyRender: (value) => (value / 24 / 30 / 12).toFixed(2),
      },
    },
  ];

  return (
    <MUITSDatatable
      title="Frequências de manutenção"
      data={typeEquipmentMaintenances}
      columns={columns}
      options={{ enableNestedDataAccess: '.' }}
      idKey={id}
      showViewButton={false}
      showEditButton
    />
  );
};

export default TypeEquipmentMaintenanceList;
