/* eslint-disable no-alert */
import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, Grid } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import MutatingButton from 'src/components/forms/MutatingButton';
import { TextfieldController } from 'src/components/forms/TextfieldController';
import {
  IVehicle,
  IVehicleHourMeterUpdateDto,
  IVehicleSimple,
} from 'src/services/touchway-base-api';
import { useTouchwayApi } from 'src/services/touchway-api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { SchemaOf, object, number, string } from 'yup';
import { queryKeys } from 'src/config/query';

interface HourMeterFormInterface {
  vehicleToBeEdited: IVehicle | IVehicleSimple | null;
  onSuccess?: (data: IVehicle) => void;
}

export const HourMeterForm: React.FC<HourMeterFormInterface> = ({
  vehicleToBeEdited,
  onSuccess,
}) => {
  const queryCache = useQueryClient();
  const { api } = useTouchwayApi();

  const schema: SchemaOf<IVehicleHourMeterUpdateDto> = object({
    vhc_id: string().required('O ID do equipamento é obrigatório'),
    vhc_hour_meter: number().required(
      'O valor do horímetro do equipamento é obrigatório',
    ),
  });

  const { handleSubmit, control } = useForm<IVehicleHourMeterUpdateDto>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    // @ts-ignore
    resolver: yupResolver(schema),
    defaultValues: {
      vhc_id: vehicleToBeEdited?.vhc_id,
      vhc_hour_meter: vehicleToBeEdited?.vhc_hour_meter,
    },
  });

  const mutateEdit = useMutation(
    api.vehicles.vehicleControllerUpdateHourMeter,
    {
      onSuccess: (data) => onSuccess && onSuccess(data),
    },
  );

  const onSubmit = (data: IVehicleHourMeterUpdateDto) => {
    const isConfirmed = window.confirm(
      'Você tem certeza que deseja atualizar o horímetro? Essa é uma operação perigosa que pode resetar toda a configuração de manutenção atual do equipamento e zerá-la.',
    );

    if (isConfirmed) {
      mutateEdit.mutate(data, {
        onSuccess: () => {
          alert('Horímetro atualizado com sucesso!');
          queryCache.refetchQueries(queryKeys.vehicleControllerList.get());
          queryCache.refetchQueries(
            queryKeys.vehicleControllerList.get({
              vhc_id: vehicleToBeEdited?.vhc_id,
            }),
          );
          window.location.reload();
        },
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextfieldController
            control={control}
            name="vhc_hour_meter"
            placeholder="Ex: 1000.23"
            label="Horímetro"
            rules={{
              required: true,
            }}
          />
        </Grid>
      </Grid>

      <Box display="flex" flexDirection="row-reverse">
        <MutatingButton
          startIcon={<Save fontSize="small" />}
          type="submit"
          variant="contained"
          disabled={mutateEdit.isLoading}
          loadingText="Salvando..."
        >
          Atualizar horímetro
        </MutatingButton>
      </Box>
    </form>
  );
};

export default HourMeterForm;
