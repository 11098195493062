import type { FC } from 'react';
import { Box, Card, Grid, Skeleton, Typography } from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'src/config/query';
import { touchwayApi } from 'src/services/touchway-api';
import {
  IMaintenanceDetailedResponse,
  IMaintenanceStatusMtsConstantEnum,
} from 'src/services/touchway-base-api';

const PERCENTAGE = 90;

const AnalyticsGeneralOverview: FC = () => {
  const { data, isLoading } = useQuery(
    queryKeys.maintenanceControllerList.get({
      with_relations: true,
    }),
    () =>
      touchwayApi.maintenance.maintenanceControllerList({
        with_relations: true,
      }),
  );

  const inMaintenance =
    (data as IMaintenanceDetailedResponse)?.maintenances.filter(
      (maintenance) =>
        maintenance.maintenanceStatus.mts_constant ===
          IMaintenanceStatusMtsConstantEnum.PLANNED ||
        maintenance.maintenanceStatus.mts_constant ===
          IMaintenanceStatusMtsConstantEnum.DOING,
    ) || [];

  // const completedMaintenance =
  //   (data as IMaintenanceDetailedResponse)?.maintenances.filter(
  //     (maintenance) =>
  //       maintenance.maintenanceStatus.mts_constant ===
  //       IMaintenanceStatusMtsConstantEnum.DONE,
  //   ) || [];

  const inProgressMaintenance =
    (data as IMaintenanceDetailedResponse)?.maintenances.filter(
      (maintenance) =>
        maintenance.maintenanceStatus.mts_constant ===
        IMaintenanceStatusMtsConstantEnum.DOING,
    ) || [];

  const numberOfUnitsToMaintenance =
    inMaintenance.filter(
      (maintenance) => maintenance.vmp_completion_percentage > PERCENTAGE,
    ) || [];

  const numberOfUnitsToMaintenance300 = numberOfUnitsToMaintenance.filter(
    (maintenance) =>
      String(maintenance?.typeMaintenance?.tpm_in_hours || 0) === '300',
  );
  const numberOfUnitsToMaintenance5000 = numberOfUnitsToMaintenance.filter(
    (maintenance) =>
      String(maintenance?.typeMaintenance?.tpm_in_hours || 0) === '5000',
  );
  const numberOfUnitsToMaintenance10000 = numberOfUnitsToMaintenance.filter(
    (maintenance) =>
      String(maintenance?.typeMaintenance?.tpm_in_hours || 0) === '10000',
  );

  return (
    <Grid container spacing={2}>
      <Grid item md={3} sm={6} xs={12}>
        <Card>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              p: 3,
            }}
          >
            <div>
              <Typography color="textPrimary" variant="subtitle2">
                Em manutenção
              </Typography>
              <Typography color="textPrimary" variant="caption">
                Equipamentos em manutenção de 300, 5000 e 10000 horas
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  flexDirection: 'row',
                  mt: 1,
                }}
              >
                {!isLoading ? (
                  <Typography color="textPrimary" variant="h4">
                    {inProgressMaintenance.length}
                  </Typography>
                ) : (
                  <Skeleton width={50} height={50} />
                )}
              </Box>
            </div>
          </Box>
        </Card>
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <Card>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              p: 3,
            }}
          >
            <div>
              <Typography color="textPrimary" variant="subtitle2">
                Manutenção de 300 horas
              </Typography>
              <Typography color="textPrimary" variant="caption">
                Nº de eqps. com percentual de manutenção acima de {PERCENTAGE}%
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  flexDirection: 'row',
                  mt: 1,
                }}
              >
                {!isLoading ? (
                  <Typography color="textPrimary" variant="h4">
                    {numberOfUnitsToMaintenance300.length}
                  </Typography>
                ) : (
                  <Skeleton width={50} height={50} />
                )}
              </Box>
            </div>
          </Box>
        </Card>
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <Card>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              p: 3,
            }}
          >
            <div>
              <Typography color="textPrimary" variant="subtitle2">
                Manutenção de 5000 horas
              </Typography>
              <Typography color="textPrimary" variant="caption">
                Nº de eqps. com percentual de manutenção acima de {PERCENTAGE}%
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  flexDirection: 'row',
                  mt: 1,
                }}
              >
                {!isLoading ? (
                  <Typography color="textPrimary" variant="h4">
                    {numberOfUnitsToMaintenance5000.length}
                  </Typography>
                ) : (
                  <Skeleton width={50} height={50} />
                )}
              </Box>
            </div>
          </Box>
        </Card>
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <Card>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              p: 3,
            }}
          >
            <div>
              <Typography color="textPrimary" variant="subtitle2">
                Manutenção de 10000 horas
              </Typography>
              <Typography color="textPrimary" variant="caption">
                Nº de eqps. com percentual de manutenção acima de {PERCENTAGE}%
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  flexDirection: 'row',
                  mt: 1,
                }}
              >
                {!isLoading ? (
                  <Typography color="textPrimary" variant="h4">
                    {numberOfUnitsToMaintenance10000.length}
                  </Typography>
                ) : (
                  <Skeleton width={50} height={50} />
                )}
              </Box>
            </div>
          </Box>
        </Card>
      </Grid>
      {/* <Grid item md={3} sm={6} xs={12}>
        <Card>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              p: 3,
            }}
          >
            <div>
              <Typography color="textPrimary" variant="subtitle2">
                Manutenções Finalizadas
              </Typography>
              <Typography color="textPrimary" variant="caption">
                Nº de eqps. com manutenção concluída
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  flexDirection: 'row',
                  mt: 1,
                }}
              >
                {!isLoading ? (
                  <Typography color="textPrimary" variant="h4">
                    {completedMaintenance.length}
                  </Typography>
                ) : (
                  <Skeleton width={50} height={50} />
                )}
              </Box>
            </div>
          </Box>
        </Card>
      </Grid> */}
    </Grid>
  );
};

export default AnalyticsGeneralOverview;
