import { FC } from 'react';
import { Page } from 'src/components/layouts/Page';
import EquipmentForm from './forms/EquipmentForm';

const EquipmentCreate: FC = () => (
  <Page
    title="Criar novo dispositivo"
    breadcrumbs={[
      { to: '/', label: 'Painel' },
      { to: 'equipamentos', label: 'Equipamentos' },
      { to: 'novo', label: 'Novo equipamento' },
    ]}
  >
    <EquipmentForm equipmentToBeEdited={null} />
  </Page>
);

export default EquipmentCreate;
