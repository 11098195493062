import { Airplane, IGSE } from 'src/pages/gses/IGSE.type'
import { axiosGET, CustomAxiosResponse } from 'src/services/axiosService'
import { ConvertDevice } from '../models/DeviceModel'

export const getAllAirplanes = async (): Promise<CustomAxiosResponse<
  Airplane[]
>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/airplanes`

    const response = await axiosGET<Airplane[]>(endpoint)

    return {
      ...response,
      message: 'Pronto!'
    }
  } catch (error: any) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.'
    )
  }
}
