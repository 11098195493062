import { FC, useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

interface IInstallationReport {}

const calculateIframeHeight = () => {
  const isMobileSizePx = isMobile ? 64 : 0; // Set numeric values, not strings
  const windowHeight = window.innerHeight;
  return windowHeight - 64 - isMobileSizePx;
};

export const InstallationReport: FC<IInstallationReport> = () => {
  const [containerHeight, setContainerHeight] = useState(
    calculateIframeHeight(),
  );

  useEffect(() => {
    const handleResize = () => {
      setContainerHeight(calculateIframeHeight());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const iframeStyle: React.CSSProperties = {
    width: '100%',
    height: `${containerHeight}px`,
    border: '0',
    margin: '0', // Set margin to zero
    padding: '0', // Set padding to zero
    position: 'absolute',
    // top: '64px', // Set top position to 64px to account for the top bar
    left: '0',
    overflow: 'hidden',
  };

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      body.style.margin = '0'; // Set body margin to zero to remove any extra spacing
      body.style.padding = '0'; // Set body padding to zero to remove any extra spacing
    }
  }, []);

  return (
    <iframe
      width="100%"
      frameBorder="0"
      style={iframeStyle}
      title="Google sheets"
      src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQvuveT8ukI8JkIQhli9tks1_by1F0l_lGHLB_QKlpZOTZbutKn-JSGki0IhKlPqQ/pubhtml?widget=true&amp;headers=false"
    />
  );
};
