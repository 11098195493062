import firebase from 'firebase';
import { User } from './../../types/user';
import { Cookie, CookieSetOptions } from 'universal-cookie';

export type FirebaseUser = firebase.User | null;
export type FirebaseAuth = firebase.auth.Auth | undefined;
export type SetCookieType = (
  name: string,
  value: Cookie,
  options?: CookieSetOptions,
) => void;

export interface IAuthContext {
  loggedUser: User | undefined;
  token: string | undefined;
  firebaseUser: FirebaseUser;
}

export enum AuthTypesEnum {
  UPDATE_STATE,
  LOGOUT_USER,
  AUTHENTICATE_USER,
  SET_FIREBASE_USER,
}

export type UPDATE_STATE = {
  type: AuthTypesEnum.UPDATE_STATE;
  newState: IAuthContext;
};

export type AUTHENTICATE_USER = {
  type: AuthTypesEnum.AUTHENTICATE_USER;
  loggedUser: User;
  firebaseUser?: FirebaseUser;
  token: string;
  setCookie?: SetCookieType;
  navigate: any; //NavigateFunction;
};

export type SET_FIREBASE_USER = {
  type: AuthTypesEnum.SET_FIREBASE_USER;
  firebaseUser: FirebaseUser;
};

export type LOGOUT_USER = {
  type: AuthTypesEnum.LOGOUT_USER;
};
