import React, { createContext, useLayoutEffect, useState } from 'react';
import { useLocalStorage } from '@penseapp/uselocalstorage';
import { useQuery } from '@tanstack/react-query';
import { useTouchwayApi } from 'src/services/touchway-api';
import { IOption } from 'src/services/touchway-base-api';

interface AirportProviderInterface {
  airports: IOption[];
  selectedAirports: IOption[];
  setSelectedAirports: (value: IOption[]) => void;
  currentLocation: GeolocationCoordinates | null;
}

export const AirportContext = createContext<AirportProviderInterface>({
  airports: [],
  selectedAirports: [],
  setSelectedAirports: () => console.warn('setSelectedAirports not ready'),
  currentLocation: null,
});

const LIMIT_AIRPORTS = 999;

export const AirportProvider: React.FC = ({ children }) => {
  const { api } = useTouchwayApi();
  const [currentLocation, setCurrentLocation] =
    useState<GeolocationCoordinates | null>(null);
  const [selectedAirports, setSelectedAirports] = useLocalStorage<IOption[]>(
    '@selected-airports-v4',
    [],
  );
  const [airports, setAirports] = useState<IOption[]>([]);

  useQuery(
    ['airports-v4', `limit-${LIMIT_AIRPORTS}`],
    () => api.autocomplete.autocompleteControllerAirports({ limit: 999 }),
    {
      onSuccess: (data) => {
        const sortedByAlphabet = data.options
          .sort((a, b) => a.value.localeCompare(b.value))
          .filter((airport) => airport.value !== '');
        return setAirports(sortedByAlphabet);
      },
    },
  );

  useLayoutEffect(() => {
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      setCurrentLocation(coords);
    });
  }, []);

  const globals: AirportProviderInterface = {
    airports,
    selectedAirports,
    setSelectedAirports,
    currentLocation,
  };

  return (
    <AirportContext.Provider value={globals}>
      {children}
    </AirportContext.Provider>
  );
};
