import React, { useEffect } from 'react';
import {
  Stack,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  FormGroup,
  Select,
  MenuItem,
  InputLabel,
  ListItemText,
  Button,
  ButtonGroup,
  Box,
} from '@material-ui/core';
import FormSection from 'src/components/forms/FormSection';
import { IOption } from 'src/services/touchway-base-api';
import { Page } from 'src/components/layouts/Page';
import { useNavigate } from 'react-router';
import useAirports from '../airports/hooks/useAirports';
import {
  Grouping,
  Ordering,
  useRemoteControl,
} from './contexts/RemoteControlContext';

interface RemoteControlProps {}

type IChangeEvent = React.ChangeEvent<{
  name?: string;
  value: IOption[];
  event: Event | React.SyntheticEvent;
}>;

export const RemoteControl: React.FC<RemoteControlProps> = () => {
  const navigate = useNavigate();
  const { airports } = useAirports();
  const { state, dispatch } = useRemoteControl();

  useEffect(() => {
    console.log('Configuração atual:', state);
  }, [state]);

  const handleGroupingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'SET_GROUPING', payload: event.target.value as Grouping });
  };

  function handleOrderingChange(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch({ type: 'SET_ORDERING', payload: event.target.value as Ordering });
  }

  // function handleFilterChange(event: React.ChangeEvent<HTMLInputElement>) {
  //   dispatch({
  //     type: 'TOGGLE_FILTER',
  //     payload: {
  //       filterName: event.target.name as FilterName,
  //       value: event.target.checked,
  //     },
  //   });
  // }

  function handleBasesChange(event: IChangeEvent) {
    dispatch({
      type: 'SET_SELECTED_BASES',
      payload: event.target.value,
    });
  }

  function handleSelectAllBases() {
    dispatch({
      type: 'SET_SELECTED_BASES',
      payload: airports,
    });
  }

  function handleDeselectAllBases() {
    dispatch({
      type: 'SET_SELECTED_BASES',
      payload: [],
    });
  }

  return (
    <Page
      title="Controle remoto"
      subtitle="Configure o painel em tempo real por aqui."
      breadcrumbs={[
        { to: '/', label: 'Painel' },
        { to: 'controle', label: 'Controle' },
      ]}
      disabledFetching
      buttons={[
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            navigate('/live/tv');
          }}
        >
          Abrir modo TV
        </Button>,
      ]}
    >
      <Stack spacing={3}>
        <FormSection title="Painel tempo real" actions={undefined}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Agrupamento</FormLabel>
            <RadioGroup value={state.grouping} onChange={handleGroupingChange}>
              <FormControlLabel
                value={Grouping.NONE}
                control={<Radio />}
                label="Mostrar todos juntos"
              />
              <FormControlLabel
                value={Grouping.BASE}
                control={<Radio />}
                label="Mostrar bases individualmente"
              />
            </RadioGroup>
          </FormControl>

          {state.grouping === Grouping.BASE && (
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel id="select-multiple-checkbox-label">
                Selecionar Bases
              </InputLabel>
              <Select
                labelId="select-multiple-checkbox-label"
                multiple
                value={state.selectedBases}
                onChange={(event: IChangeEvent) => handleBasesChange(event)}
                renderValue={(selected) => selected.join(', ')}
              >
                {airports.map(({ id, value }) => (
                  <MenuItem key={id} value={value}>
                    {/* <MuiCheckbox
                    checked={state.selectedBases.indexOf(value) > -1}
                  /> */}
                    <ListItemText primary={value} />
                  </MenuItem>
                ))}
              </Select>

              <Box mt={1}>
                <ButtonGroup size="small">
                  <Button variant="outlined" onClick={handleSelectAllBases}>
                    Selecionar Tudo
                  </Button>
                  <Button variant="outlined" onClick={handleDeselectAllBases}>
                    Desmarcar Tudo
                  </Button>
                </ButtonGroup>
              </Box>
            </FormControl>
          )}

          <FormControl component="fieldset">
            <FormLabel component="legend">Ordenação</FormLabel>
            <RadioGroup value={state.ordering} onChange={handleOrderingChange}>
              <FormControlLabel
                value={Ordering.ACTIVE_FIRST}
                control={<Radio />}
                label="Ligados primeiro"
              />
              <FormControlLabel
                value={Ordering.MAINTENANCE_SOON}
                control={<Radio />}
                label="Manutenção próxima"
              />
            </RadioGroup>
          </FormControl>
        </FormSection>

        <FormSection
          title="Configuração do carrossel"
          description="Configure o carrossel de imagens do painel."
          actions={
            <Button onClick={() => dispatch({ type: 'RESET' })}>Resetar</Button>
          }
        >
          <FormGroup>
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel>Quantidade de equipamentos por linha</InputLabel>
              <Select
                value={Number(state.otherSettings.cardsPerRow)}
                name="cardsPerRow"
                onChange={(e) =>
                  dispatch({
                    type: 'SET_OTHER_SETTINGS',
                    payload: { cardsPerRow: Number(e.target.value) },
                  })
                }
                label="Quantidade de equipamentos por linha"
              >
                {[1, 2, 3, 4, 6, 12].map((value) => (
                  <MenuItem key={`card--per-row-${value}`} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControlLabel
              control={
                <Checkbox
                  checked={state.settings.infinite}
                  onChange={(e) =>
                    dispatch({
                      type: 'SET_SETTINGS',
                      payload: { infinite: e.target.checked },
                    })
                  }
                />
              }
              label="Começar do início ao chegar no final?"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.settings.autoplay}
                  onChange={(e) =>
                    dispatch({
                      type: 'SET_SETTINGS',
                      payload: { autoplay: e.target.checked },
                    })
                  }
                />
              }
              label="Iniciar ciclo automaticamente?"
            />
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel>Velocidade de transição entre os slides</InputLabel>
              <Select
                value={state.settings.speed}
                onChange={(e) =>
                  dispatch({
                    type: 'SET_SETTINGS',
                    payload: { speed: e.target.value },
                  })
                }
                label="Velocidade"
              >
                <MenuItem value={1000}>1 segundo</MenuItem>
                <MenuItem value={5000}>5 segundos</MenuItem>
                <MenuItem value={10000}>10 segundos</MenuItem>
                <MenuItem value={15000}>30 segundos</MenuItem>
                <MenuItem value={15000}>45 segundos</MenuItem>
                <MenuItem value={20000}>1 minuto</MenuItem>
                <MenuItem value={30000}>1 minuto e meio</MenuItem>
                <MenuItem value={60000}>2 minutos</MenuItem>
                <MenuItem value={120000}>4 minutos</MenuItem>
                <MenuItem value={180000}>6 minutos</MenuItem>
                <MenuItem value={300000}>10 minutos</MenuItem>
                <MenuItem value={600000}>20 minutos</MenuItem>
                <MenuItem value={900000}>30 minutos</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel>Slides para Mostrar</InputLabel>
              <Select
                value={state.settings.slidesToShow}
                onChange={(e) =>
                  dispatch({
                    type: 'SET_SETTINGS',
                    payload: { slidesToShow: e.target.value },
                  })
                }
                label="Slides para Mostrar"
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel>Slides para Rolar</InputLabel>
              <Select
                value={state.settings.slidesToScroll}
                onChange={(e) =>
                  dispatch({
                    type: 'SET_SETTINGS',
                    payload: {
                      slidesToScroll: e.target.value,
                    },
                  })
                }
                label="Slides para Rolar"
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>
        </FormSection>

        <FormSection
          title="Mapa"
          description="Configure o mapa do painel."
          actions={undefined}
        >
          {/* Zoom */}
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel>Zoom</InputLabel>
            <Select
              value={state.otherSettings.mapZoom}
              onChange={(e) =>
                dispatch({
                  type: 'SET_OTHER_SETTINGS',
                  payload: { mapZoom: e.target.value },
                })
              }
              label="Zoom"
            >
              {[...Array(20).keys()].map((value) => (
                <MenuItem key={`map-zoom-${value}`} value={value}>
                  {value}
                </MenuItem>
              ))}
              <MenuItem value={1}>1</MenuItem>
            </Select>
          </FormControl>
        </FormSection>

        <FormSection
          title="Resetar configurações"
          description="Clique no botão abaixo para resetar as configurações para o padrão."
          actions={
            <Button onClick={() => dispatch({ type: 'RESET' })}>Resetar</Button>
          }
        >
          <></>
        </FormSection>
      </Stack>
    </Page>
  );
};
