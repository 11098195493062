/* eslint-disable @typescript-eslint/naming-convention */
import React, { useMemo } from 'react';
import { MarkerClustererF, MarkerF } from '@react-google-maps/api';
import { Box, Link } from '@material-ui/core';
import useRealtime, {
  IGseFirebaseCollection,
} from 'src/hooks/firebase/useRealtime';
import { firebaseRealtimeEnum } from 'src/config';
import ReactDOMServer from 'react-dom/server';
import { DEFAULT_MAPS } from 'src/config/maps-default';
import { CustomMaps } from 'src/components/CustomMaps';
import useSettings from 'src/hooks/useSettings';
import { THEMES } from 'src/constants';

interface MapsProps {}

export const MapsConfigurator: React.FC<MapsProps> = () => {
  const { settings } = useSettings();
  const [map, setMap] = React.useState(null);
  const vehicles = useRealtime<IGseFirebaseCollection>({
    collection: firebaseRealtimeEnum.vehicles,
  });

  const labelColor = useMemo(
    () => (settings.theme === THEMES.LIGHT ? '#000' : '#fff'),
    [settings.theme],
  );

  return vehicles ? (
    <>
      <CustomMaps setMapCallback={setMap}>
        <MarkerClustererF minimumClusterSize={10}>
          {(clusterer) => (
            <>
              {Object.entries(vehicles).map((entry) => {
                const [key, value] = entry;
                // const vehicleDetail = vehiclesQuery.data?.vehicles.find(
                //   ({ vhc_id }) => vhc_id === key,
                // );
                const { ignition, latitude, longitude, name } = value;

                if (ignition) {
                  return (
                    <>
                      <MarkerF
                        // animation={window.google.maps.Animation.DROP}
                        icon={{
                          // ...DEFAULT_MAPS.icon,
                          path: window.google.maps.SymbolPath.CIRCLE,
                          size: new window.google.maps.Size(
                            DEFAULT_MAPS.icon.size.width,
                            DEFAULT_MAPS.icon.size.height,
                          ),
                          scale: DEFAULT_MAPS.icon.scale,
                          scaledSize: new window.google.maps.Size(
                            DEFAULT_MAPS.icon.size.width,
                            DEFAULT_MAPS.icon.size.height,
                          ),
                          labelOrigin: new window.google.maps.Point(
                            DEFAULT_MAPS.icon.labelOrigin.x,
                            DEFAULT_MAPS.icon.labelOrigin.y,
                          ),
                          strokeColor: DEFAULT_MAPS.icon.color.ignition.on,
                        }}
                        key={`location-${latitude}-${longitude}-${ignition}`}
                        position={{
                          lat: Number(latitude),
                          lng: Number(longitude),
                        }}
                        label={{
                          text: name,
                          color: labelColor,
                          fontWeight: 'bold',
                        }}
                        title="Clique para ver mais informações"
                        clusterer={clusterer}
                        onClick={() => {
                          if (!name) {
                            return;
                          }

                          const content = ReactDOMServer.renderToStaticMarkup(
                            <Box width="350px">
                              {/* <GseStaticCard
                                showIgnitionStatus
                                vhc_id={key}
                                showCard={false}
                                vehicle={value}
                              /> */}
                              <Box mx={2}>
                                <Link
                                  component="a"
                                  variant="button"
                                  href={`/veiculos/${key}`}
                                  underline="always"
                                >
                                  Ver mais
                                </Link>
                              </Box>
                            </Box>,
                          );

                          const lat = Number(latitude);
                          const lng = Number(longitude);

                          const pos = { lat, lng };
                          const infoWindow = new google.maps.InfoWindow({
                            position: pos,
                            pixelOffset: new google.maps.Size(0, -70),
                            content,
                          });

                          infoWindow.open(map);
                        }}
                      />
                    </>
                  );
                }

                return (
                  <>
                    <MarkerF
                      icon={{
                        // ...DEFAULT_MAPS.icon,
                        size: new window.google.maps.Size(
                          DEFAULT_MAPS.icon.size.width,
                          DEFAULT_MAPS.icon.size.height,
                        ),
                        scale: DEFAULT_MAPS.icon.scale,
                        scaledSize: new window.google.maps.Size(
                          DEFAULT_MAPS.icon.size.width,
                          DEFAULT_MAPS.icon.size.height,
                        ),
                        labelOrigin: new window.google.maps.Point(
                          DEFAULT_MAPS.icon.labelOrigin.x,
                          DEFAULT_MAPS.icon.labelOrigin.y,
                        ),
                        path: window.google.maps.SymbolPath.CIRCLE,
                        strokeColor: DEFAULT_MAPS.icon.color.ignition.off,
                      }}
                      key={`location-${latitude}-${longitude}-${ignition}`}
                      position={{
                        lat: Number(latitude),
                        lng: Number(longitude),
                      }}
                      label={{
                        text: name,
                        color: labelColor,
                        fontWeight: 'bold',
                      }}
                      title="Clique para ver mais informações"
                      clusterer={clusterer}
                      onClick={() => {
                        if (!name) {
                          return;
                        }

                        const content = ReactDOMServer.renderToStaticMarkup(
                          <Box width="350px">
                            {/* <GseStaticCard
                              showIgnitionStatus
                              vhc_id={key}
                              showCard={false}
                              vehicle={value}
                            /> */}
                            <Box mx={2}>
                              <Link
                                component="a"
                                variant="button"
                                href={`/veiculos/${key}`}
                                underline="always"
                              >
                                Ver mais
                              </Link>
                            </Box>
                          </Box>,
                        );

                        const lat = Number(latitude);
                        const lng = Number(longitude);

                        const pos = { lat, lng };
                        const infoWindow = new google.maps.InfoWindow({
                          position: pos,
                          pixelOffset: new google.maps.Size(0, -70),
                          content,
                        });

                        infoWindow.open(map);
                      }}
                    />
                  </>
                );
              })}
            </>
          )}
        </MarkerClustererF>
      </CustomMaps>
    </>
  ) : (
    <></>
  );
};
