import { useEffect } from 'react';
import type { FC } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Page } from 'src/components/layouts/Page';
import {
  AnalyticsGeneralOverview,
  AnalyticsSocialMediaSources,
  AnalyticsTrafficSources,
} from '../../components/dashboard/analytics';
import gtm from '../../lib/gtm';

const Analytics: FC = () => {
  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <Page
      title="Dashboard"
      breadcrumbs={[
        { to: '/', label: 'Painel' },
        { to: 'dashboard', label: 'Dashboard' },
      ]}
    >
      <Box sx={{ pb: 3 }}>
        <AnalyticsGeneralOverview />
      </Box>
      <Grid container spacing={3}>
        <Grid item xl={9} md={8} xs={12}>
          <AnalyticsTrafficSources sx={{ height: '100%' }} />
        </Grid>
        {/* <Grid item xl={3} md={4} xs={12}>
          <AnalyticsVisitsByCountry />
        </Grid> */}
        {/* <Grid item xl={9} md={8} xs={12}>
          <AnalyticsMostVisitedPages />
        </Grid> */}
        <Grid item xl={3} md={4} xs={12}>
          <AnalyticsSocialMediaSources />
        </Grid>
      </Grid>
    </Page>
  );
};

export default Analytics;
