/* eslint-disable react/no-array-index-key */
import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from '@material-ui/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import EmptyPlaceholder from 'src/components/layouts/EmptyPlaceholder';
import { queryKeys } from 'src/config/query';
import { useTouchwayApi } from 'src/services/touchway-api';
import { IEquipmentListDetailedResponse } from 'src/services/touchway-base-api';
import { useConfirm } from 'material-ui-confirm';
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';
import { getServerErrorArrayMessage } from 'src/utils/get-server-error-message';
import EquipmentForm from '../forms/EquipmentForm';

interface EquipmentCardProps {
  eqp_id: string;
}

export const EquipmentCard: React.FC<EquipmentCardProps> = ({ eqp_id }) => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState<boolean>(false);
  const { api } = useTouchwayApi();

  const eclParams = {
    eqp_id,
    with_relations: true,
  };
  const equipmentQuery = useQuery(queryKeys.equipment.get(eclParams), () =>
    api.equipment.equipmentControllerList(eclParams),
  );

  const statusQuery = useQuery(
    queryKeys.autocompleteControllerStatusMaintenance.get(),
    () => api.autocomplete.autocompleteControllerStatusMaintenance(),
  );

  const deleteMutation = useMutation({
    mutationFn: api.equipment.equipmentControllerDelete,
    onSuccess() {
      queryClient.invalidateQueries(queryKeys.equipment.get());
      queryClient.invalidateQueries(
        queryKeys.equipment.get({
          eqp_id,
        }),
      );

      toast.success('Equipamento excluído com sucesso');
      navigate('/equipamentos');
    },
    onError: (error) =>
      getServerErrorArrayMessage(error).forEach((message) =>
        toast.error(message),
      ),
  });

  const isLoading = equipmentQuery.isLoading || statusQuery.isLoading;
  if (isLoading) {
    return <Skeleton />;
  }

  const [eqp] = [equipmentQuery.data, statusQuery.data];
  const typeEqp =
    (eqp as any).equipments && (eqp as any).equipments.length > 0
      ? (eqp as IEquipmentListDetailedResponse).equipments[0]
      : undefined;

  if (!typeEqp) {
    return <EmptyPlaceholder title="Equipamento não encontrado" subtitle="" />;
  }

  function handleDelete() {
    if (!typeEqp) {
      toast.error('Equipamento não encontrado');
      return;
    }

    confirm({
      description: 'Deseja realmente excluir este equipamento?',
    }).then(() => {
      deleteMutation.mutate({
        eqp_id: typeEqp.eqp_id ?? '',
      });
    });
  }

  const equipmentDetails = [
    { label: 'Nome', value: typeEqp.eqp_name },
    { label: 'Número de série', value: typeEqp.eqp_serial_number },
    // { label: 'TAG', value: typeEqp.eqp_tag },
    {
      label: 'Número de identificação externo',
      value: typeEqp.eqp_external_id,
    },
    { label: 'Frota', value: typeEqp.eqp_fleet },
    { label: 'P/N', value: typeEqp.eqp_pn },
    { label: 'Base', value: typeEqp?.airport?.air_iata },
  ];

  return (
    <Stack spacing={2}>
      <Grid container spacing={2}>
        {equipmentDetails.map((detail, index) => (
          <Grid item xs={12} sm={6} key={`equipment-detail-${index}`}>
            <Box>
              <Typography variant="caption">{detail.label}</Typography>
              <Typography variant="body1">
                {detail.value || 'Não informado'}
              </Typography>
            </Box>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      <Stack spacing={2} direction="row" mt={2}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Editar equipamento
        </Button>

        <Button onClick={handleDelete} variant="contained" color="secondary">
          Excluir equipamento
        </Button>
      </Stack>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <EquipmentForm
          equipmentToBeEdited={typeEqp}
          onSuccess={() => setOpen(false)}
        />
      </Dialog>
    </Stack>
  );
};
