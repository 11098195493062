import type { FC } from 'react';
import type { Theme } from '@material-ui/core';
// @ts-ignore
import type { SxProps } from '@material-ui/system';

interface LogoProps {
  sx?: SxProps<Theme>;
  color?: 'white' | 'black';
}

const Logo: FC<LogoProps> = (props) => {
  const { color = 'black' } = props;
  return (
    <img
      width="auto"
      style={{
        marginTop: '-7px',
        marginBottom: '-15px',
      }}
      height="60px"
      src={color === 'white' ? '/static/logo-white.png' : '/static/logo.png'}
      alt="Logo"
      {...props}
    />
  );
};

export default Logo;
