import { FC, useState } from 'react';
import {
  Autocomplete,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import { useTouchwayApi } from 'src/services/touchway-api';
import { queryKeys } from 'src/config/query';
import { IVehicle, IVehicleSimple } from 'src/services/touchway-base-api';
import { format } from 'date-fns';
import PulsingBadge from 'src/components/PulsingBadge';
import useRealtime from 'src/hooks/firebase/useRealtime';
import { firebaseRealtimeEnum } from 'src/config';
import { ptBR } from 'date-fns/locale';
import { IVehicleFirebaseRealtimeType } from './types/IGSEFirebaseRealtime.type';
import { HourMeterForm } from '../devices/forms/DeviceHourMeterUpdate';

// interface IIgnitionList {
//   [key: string]: boolean;
// }

interface Checklist {
  lat: number | string | undefined;
  lng: number | string | undefined;
  ignition: boolean;
  ignitionHistory: {
    date: Date;
    ignition: boolean;
  }[];
}

export const Configurator: FC = () => {
  const [checklist, setChecklist] = useState<Checklist>({
    lat: undefined,
    lng: undefined,
    ignition: false,
    ignitionHistory: [],
  });
  const [vehicle, setVehicle] = useState<IVehicleSimple | undefined>(undefined);
  const touchwayApi = useTouchwayApi();

  const vehiclesQuery = useQuery(
    queryKeys.vehicleControllerList.get(),
    () =>
      touchwayApi.api.vehicles.vehicleControllerList({
        // @ts-ignore
        with_relations: true,
      }),
    {
      // 1 hour
      refetchInterval: 1000 * 60 * 60,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );

  useRealtime<IVehicleFirebaseRealtimeType>({
    collection: firebaseRealtimeEnum.vehicles,
    key: vehicle?.vhc_id,
    enabled: !!vehicle?.vhc_id,
    onValueChange: ({ last_update, ignition, latitude, longitude }) => {
      setChecklist((prevState) => ({
        ...prevState,
        lat: latitude,
        lng: longitude,
        ignition,
        ignitionHistory: [
          ...prevState.ignitionHistory,
          {
            date: new Date(last_update),
            ignition,
          },
        ],
      }));
    },
  });

  const vehicles = vehiclesQuery.data?.vehicles as IVehicle[] | undefined;

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Autocomplete
          id="vehicles"
          options={vehicles || []}
          freeSolo
          getOptionLabel={(option) =>
            `Eqp: ${option.vhc_name} - IMEI: ${
              option?.locator?.lct_imei
                ? `${option.locator.lct_imei.slice(-4)}`
                : 'N/A'
            }`
          }
          onChange={(_, value) => {
            if (typeof value !== 'string' && value !== null) {
              setVehicle(value);
              setChecklist({
                lat: undefined,
                lng: undefined,
                ignition: false,
                ignitionHistory: [],
              });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Nome do equipamento"
              variant="outlined"
            />
          )}
        />
      </Grid>

      <Grid item>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Status do Equipamento
            </Typography>

            <List dense>
              <ListItem>
                <ListItemIcon>
                  {/* search for ignitionHistory and check if at least one element is true */}
                  {vehicle?.vhc_name ? '✅' : '❌'}
                </ListItemIcon>
                <ListItemText primary="Equipamento conectado na plataforma?" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  {/* search for ignitionHistory and check if at least one element is true */}
                  {checklist?.ignitionHistory?.some((item) => item.ignition)
                    ? '✅'
                    : '❌'}
                </ListItemIcon>
                <ListItemText primary="(Ignição) Ligar funcionou?" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  {/* search for ignitionHistory and check if at least one element is false */}
                  {checklist?.ignitionHistory?.some(
                    (item) => item.ignition === false,
                  )
                    ? '✅'
                    : '❌'}
                </ListItemIcon>
                <ListItemText primary="(Ignição) Desligar funcionou?" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  {checklist?.lat && checklist?.lng ? '✅' : '❌'}
                </ListItemIcon>
                <ListItemText primary="Localização GPS está funcionando?" />
              </ListItem>
            </List>

            <Typography variant="h6" gutterBottom>
              Histórico de ignição
            </Typography>
            {checklist?.ignitionHistory?.length > 0 ? (
              <List dense>
                {checklist?.ignitionHistory?.map((item) => (
                  <ListItem
                    key={item.date.getTime()}
                    style={
                      {
                        // display: 'flex',
                        // justifyContent: 'space-between',
                      }
                    }
                  >
                    {item.ignition ? (
                      <PulsingBadge
                        variant="success"
                        withBorder={false}
                        badgeLabel="Ligou"
                      />
                    ) : (
                      <PulsingBadge
                        variant="danger"
                        withBorder={false}
                        badgeLabel="Desligou"
                      />
                    )}
                    <ListItemText
                      primary={format(item.date, 'dd/MM/yyyy HH:mm:ss', {
                        locale: ptBR,
                      })}
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography>Nenhum histórico de ignição</Typography>
            )}
          </CardContent>
        </Card>
      </Grid>

      {vehicle && (
        <Grid item mt={2}>
          <Card variant="outlined">
            <CardContent>
              <Typography mb={2} variant="h6" gutterBottom>
                Horímetro
              </Typography>

              {vehicle && <HourMeterForm vehicleToBeEdited={vehicle} />}
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export default Configurator;
