import { FC } from 'react';
import { Box, Card } from '@material-ui/core';
import { Page } from 'src/components/layouts/Page';
import DeviceForm from './forms/DeviceForm';

const DeviceCreate: FC = () => (
  <Page
    title="Criar novo dispositivo"
    breadcrumbs={[
      { to: '/', label: 'Painel' },
      { to: 'Dispositivos', label: 'Equipamentos' },
      { to: 'novo', label: 'Novo dispositivo' },
    ]}
  >
    <Card>
      <Box sx={{ p: 3 }}>
        <DeviceForm deviceToBeEdited={null} />
      </Box>
    </Card>
  </Page>
);

export default DeviceCreate;
