// @ts-nocheck
import { subDays, addDays } from 'date-fns';
import type {
  Board,
  Card,
  CheckItem,
  Checklist,
  Column,
  Comment,
} from '../types/kanban';
import createResourceId from '../utils/createResourceId';
import deepCopy from '../utils/deepCopy';
import { touchwayApi } from 'src/services/touchway-api';
import { convertMaintenanceToBoard } from 'src/utils/convertMaintenanceToBoard';
import {
  getMaintenanceById,
  isValidTransition,
} from 'src/utils/getMaintenanceById';
import {
  IMaintenanceDetailedResponse,
  IMaintenanceStatusMtsConstantEnum,
  IMaintenanceUpsertDto,
} from 'src/services/touchway-base-api';

// You'll see here that we start with a deep clone of the board.
// The reason for that is to create a db session wannabe strategy.
// If something fails, we do not affect the original data until everything worked as expected.

const now = new Date();

let board: Board = {
  cards: [
    {
      id: '5e849c8708bd72683b454747',
      attachments: [],
      checklists: [
        {
          id: '5e84a8175c48d3f5b1d01972',
          name: 'Update overview page',
          checkItems: [
            {
              id: '5e85af37da584c5e4bd8a06c',
              name: 'An item',
              state: 'complete',
            },
          ],
        },
      ],
      comments: [
        {
          id: '15e849c5a35d4dff4f88ebff6',
          cardId: '5e849c8708bd72683b454747',
          createdAt: subDays(now, 5).getTime(),
          memberId: '5e887ac47eed253091be10cb',
          message: 'This is a comment',
        },
      ],
      cover: null,
      description:
        'Duis condimentum lacus finibus felis pellentesque, ac auctor nibh fermentum. Duis sed dui ante. Phasellus id eros tincidunt, dictum lorem vitae, pellentesque sem. Aenean eu enim sit amet mauris rhoncus mollis. Sed enim turpis, porta a felis et, luctus faucibus nisi. Phasellus et metus fermentum, ultrices arcu aliquam, facilisis justo. Cras nunc nunc, elementum sed euismod ut, maximus eget nibh. Phasellus condimentum lorem neque. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Fusce sagittis pharetra eleifend. Suspendisse potenti.',
      due: addDays(now, 7).getTime(),
      isSubscribed: false,
      columnId: '5e849c39325dc5ef58e5a5db',
      memberIds: [],
      name: 'GPU VCP-11',
    },
    {
      id: '5e849c90fabe1f1f4b3557f6',
      attachments: [],
      checklists: [],
      comments: [],
      cover: null,
      description:
        'We are looking for vue experience and of course node js strong knowledge',
      due: addDays(now, 6).getTime(),
      isSubscribed: true,
      columnId: '5e849c39325dc5ef58e5a5db',
      memberIds: [],
      name: 'GPU VCP-23',
    },
    {
      id: '5e849c977ef6265938bfd90b',
      attachments: [],
      checklists: [],
      comments: [],
      cover: null,
      description: 'Ajustar filtro de óleo que está vazando',
      due: null,
      isSubscribed: false,
      columnId: '5e849c39325dc5ef58e5a5db',
      memberIds: [],
      name: 'GPU VCP-64',
    },
    {
      id: '5e849c9e34ee93bc7255c599',
      attachments: [],
      checklists: [],
      comments: [],
      cover: null,
      description: 'Ajustar filtro de óleo que está vazando',
      due: null,
      isSubscribed: false,
      columnId: '5e849c39325dc5ef58e5a5db',
      memberIds: [],
      name: 'GPU VCP-87',
    },
    {
      id: '5e849ca7d063dc3830d4b49c',
      attachments: [],
      checklists: [],
      comments: [],
      cover: null,
      description: 'Ajustar filtro de óleo que está vazando',
      due: null,
      isSubscribed: true,
      columnId: '5e849c2b38d238c33e516755',
      memberIds: ['5e887ac47eed253091be10cb'],
      name: 'GPU VCP-10',
    },
    {
      id: '5e849cb5d0c6e8894451fdfa',
      attachments: [],
      checklists: [],
      comments: [],
      cover: null,
      description: 'Ajustar filtro de óleo que está vazando',
      due: null,
      isSubscribed: true,
      columnId: '5e849c2b38d238c33e516755',
      memberIds: ['5e887b7602bdbc4dbb234b27'],
      name: 'GPU VCP-11',
    },
  ],
  columns: [
    {
      id: '5e849c39325dc5ef58e5a5db',
      name: 'Aguardando',
      cardIds: [
        '5e849c8708bd72683b454747',
        '5e849c90fabe1f1f4b3557f6',
        '5e849c977ef6265938bfd90b',
      ],
    },
    {
      id: '5e849c2b38d238c33e516755',
      name: 'Em manutenção',
      cardIds: ['5e849ca7d063dc3830d4b49c', '5e849cb5d0c6e8894451fdfa'],
    },
    {
      id: '5e849c2b38d238c33e5146755',
      name: 'Finalizado',
      cardIds: ['5e849c9e34ee93bc7255c599'],
    },
  ],
  members: [
    {
      id: '5e887a62195cc5aef7e8ca5d',
      avatar: '/static/mock-images/avatars/avatar-marcus_finn.png',
      name: 'Marcus Finn',
    },
    {
      id: '5e887ac47eed253091be10cb',
      avatar: '/static/mock-images/avatars/avatar-carson_darrin.png',
      name: 'Carson Darrin',
    },
    {
      id: '5e887b209c28ac3dd97f6db5',
      avatar: '/static/mock-images/avatars/avatar-fran_perez.png',
      name: 'Fran Perez',
    },
    {
      id: '5e887b7602bdbc4dbb234b27',
      avatar: '/static/mock-images/avatars/avatar-jie_yan_song.png',
      name: 'Jie Yan Song',
    },
    {
      id: '5e86809283e28b96d2d38537',
      avatar: '/static/mock-images/avatars/avatar-jane_rotanson.png',
      name: 'Jane Rotanson',
    },
  ],
};

class KanbanApi {
  async getBoard(): Promise<Board> {
    touchwayApi.maintenance.maintenanceControllerList({
      with_relations: true,
    });

    const maintenancesResRaw = touchwayApi.maintenance
      .maintenanceControllerList({
        with_relations: true,
      })
      .then((res) => {
        const { maintenances } = res as IMaintenanceDetailedResponse;
        return {
          maintenances: maintenances
            // vhc_id can not be null or vhc_name not contain the works "imei"
            .filter(({ vhc_id, vehicle }) => {
              if (!vhc_id) return false;
              if (!vehicle) return false;
              if (!vehicle.vhc_name) return false;
              return !vehicle.vhc_name.toLowerCase().includes('imei');
            })
            .sort(
              (a, b) =>
                b.vmp_completion_percentage - a.vmp_completion_percentage,
            ),
        };
      });

    const maintenancesStatusResRaw =
      touchwayApi.autocomplete.autocompleteControllerStatusMaintenance();

    const [maintenancesRes, maintenancesStatusRes] = await Promise.all([
      maintenancesResRaw,
      maintenancesStatusResRaw,
    ]);

    const converted = convertMaintenanceToBoard(
      maintenancesRes,
      maintenancesStatusRes,
    );

    return Promise.resolve(converted);
  }

  async createColumn({ name }): Promise<Column> {
    return new Promise(async (resolve, reject) => {
      try {
        // Make a deep copy
        const clonedBoard = await this.getBoard();

        // Create the new column
        const column: Column = {
          id: createResourceId(),
          name,
          cardIds: [],
        };

        clonedBoard.columns.push(column);

        // Save changes
        board = clonedBoard;

        resolve(deepCopy(column));
      } catch (err) {
        console.error('[Kanban Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  async updateColumn({ columnId, update }): Promise<Column> {
    return new Promise(async (resolve, reject) => {
      try {
        // Make a deep copy
        const clonedBoard = await this.getBoard();

        // Find the column to clear
        const column = clonedBoard.columns.find(
          (_column) => _column.id === columnId,
        );

        // Update the column
        Object.assign(column, update);

        // Save changes
        board = clonedBoard;

        resolve(deepCopy(column));
      } catch (err) {
        console.error('[Kanban Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  async clearColumn(columnId): Promise<true> {
    return new Promise(async (resolve, reject) => {
      try {
        // Make a deep copy
        const clonedBoard = await this.getBoard();

        // Find the column to clear
        const column = clonedBoard.columns.find(
          (_column) => _column.id === columnId,
        );

        if (!column) {
          reject(new Error('Column not found'));
          return;
        }

        // Remove the cards with columnId reference
        clonedBoard.cards = clonedBoard.cards.filter(
          (card) => card.columnId !== columnId,
        );

        // Remove all cardIds from the column
        column.cardIds = [];

        // Save changes
        board = clonedBoard;

        resolve(true);
      } catch (err) {
        console.error('[Kanban Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  async deleteColumn(columnId): Promise<true> {
    return new Promise(async (resolve, reject) => {
      try {
        // Make a deep copy
        const clonedBoard = await this.getBoard();

        // Find the column to remove
        const column = clonedBoard.columns.find(
          (_column) => _column.id === columnId,
        );

        if (!column) {
          reject(new Error('Column not found'));
          return;
        }

        // Remove the cards with columnId reference
        clonedBoard.cards = clonedBoard.cards.filter(
          (card) => card.columnId !== columnId,
        );

        // Remove the column from the board
        clonedBoard.columns = clonedBoard.columns.filter(
          (_column) => _column.id !== columnId,
        );

        // Save changes
        board = clonedBoard;

        resolve(true);
      } catch (err) {
        console.error('[Kanban Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  async createCard({ columnId, name }): Promise<Card> {
    return new Promise(async (resolve, reject) => {
      try {
        // Make a deep copy
        const clonedBoard = await this.getBoard();

        // Find the column where the new card will be added
        const column = clonedBoard.columns.find(
          (_column) => _column.id === columnId,
        );

        if (!column) {
          reject(new Error('Column not found'));
          return;
        }

        // Create the new card
        const card: Card = {
          id: createResourceId(),
          attachments: [],
          checklists: [],
          comments: [],
          cover: null,
          description: null,
          due: null,
          isSubscribed: false,
          columnId,
          memberIds: [],
          name,
        };

        // Add the new card
        clonedBoard.cards.push(card);

        // Add the cardId reference to the column
        column.cardIds.push(card.id);

        // Save changes
        board = clonedBoard;

        resolve(deepCopy(card));
      } catch (err) {
        console.error('[Kanban Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  async updateCard({ cardId, update }): Promise<Card> {
    return new Promise(async (resolve, reject) => {
      try {
        // Make a deep copy
        const clonedBoard = await this.getBoard();

        // Find the card that will be updated
        const card = clonedBoard.cards.find((_card) => _card.id === cardId);

        if (!card) {
          reject(new Error('Card not found'));
          return;
        }

        // Update the card
        if (update.name) {
          card.name = update.name;
        }

        if (update.description) {
          card.description = update.description;
        }

        // Update the card
        Object.assign(card, update);

        // Save changes
        board = clonedBoard;

        resolve(deepCopy(card));
      } catch (err) {
        console.error('[Kanban Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  async moveCard({ cardId, position, columnId }): Promise<true> {
    return new Promise(async (resolve, reject) => {
      try {
        console.log(
          '🚀 ~ file: kanbanApi.ts:410 ~ KanbanApi ~ moveCard ~  cardId, position, columnId :',
          cardId,
          position,
          columnId,
        );

        // Make a deep copy
        const clonedBoard = await this.getBoard();

        // Find the card that will be moved
        const card = clonedBoard.cards.find((_card) => _card.id === cardId);

        if (!card) {
          reject(new Error('Card not found'));
          return;
        }

        // Find the source column of the card
        const sourceList = clonedBoard.columns.find(
          (column) => column.id === card.columnId,
        );

        if (!sourceList) {
          reject(new Error('Column not found'));
          return;
        }

        // Remove the cardId reference from the source list
        sourceList.cardIds = sourceList.cardIds.filter(
          (_cardId) => _cardId !== cardId,
        );

        if (columnId) {
          // Find the destination column for the card
          const destinationList = clonedBoard.columns.find(
            (column) => column.id === columnId,
          );

          const from = getMaintenanceById({ mts_id: card.extraProps.mts_id });
          const to = getMaintenanceById({ mts_id: destinationList.id });

          if (!destinationList) {
            reject(new Error('Column not found'));
            return;
          }

          const { isValid, message, requiresConfirmation } = isValidTransition(
            from,
            to,
          );
          if (!isValid) {
            reject(new Error(message));
            return;
          }
          if (requiresConfirmation) {
            if (!window.confirm(message)) {
              reject(new Error('Operação cancelada'));
              return;
            }
          }

          // Add the cardId reference to the destination list
          destinationList.cardIds.splice(position, 0, card.id);

          // Store the new columnId reference
          card.columnId = destinationList.id;

          const data: IMaintenanceUpsertDto = {
            mts_id: destinationList.id,
            tpm_id: card.extraProps.tpm_id,
            vhc_id: card.extraProps.vhc_id,
            vmp_id: card.extraProps.vmp_id,
            vmp_notes: card.comments.map(({ message }) => message),
            vmp_hour_meter_forecast: card.extraProps.vmp_hour_meter_forecast,
          };

          if (to.mts_constant === IMaintenanceStatusMtsConstantEnum.DONE) {
            data.vmp_execution_date = new Date().toISOString();
          }

          await touchwayApi.maintenance.maintenanceControllerUpsert(data);
        } else {
          // If columnId is not provided, it means that we move the card in the same list
          sourceList.cardIds.splice(position, 0, card.id);
        }

        // Save changes
        board = clonedBoard;

        resolve(true);
      } catch (err) {
        console.error('[Kanban Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  async deleteCard(cardId): Promise<true> {
    return new Promise(async (resolve, reject) => {
      try {
        // Make a deep copy
        const clonedBoard = await this.getBoard();

        // Find the card that will be removed
        const card = clonedBoard.cards.find((_card) => _card.id === cardId);

        if (!card) {
          reject(new Error('Card not found'));
          return;
        }

        // Remove the card from board
        clonedBoard.cards = clonedBoard.cards.filter(
          (_card) => _card.id !== cardId,
        );

        // Find the column using the columnId reference
        const column = clonedBoard.columns.find(
          (_column) => _column.id === card.columnId,
        );

        // If for some reason it does not exist, there's no problem. Maybe something broke before.
        if (column) {
          column.cardIds = column.cardIds.filter(
            (_cardId) => _cardId !== cardId,
          );
        }

        // Save changes
        board = clonedBoard;

        resolve(true);
      } catch (err) {
        console.error('[Kanban Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  async addComment({ cardId, message }): Promise<Comment> {
    return new Promise(async (resolve, reject) => {
      try {
        // Make a deep copy
        const clonedBoard = await this.getBoard();

        // Find the card where the comment will be added
        const card = clonedBoard.cards.find((_card) => _card.id === cardId);

        if (!card) {
          reject(new Error('Card not found'));
          return;
        }

        // Create the new comment
        // Important: On server get memberId from request identity (user)
        const comment = {
          id: createResourceId(),
          cardId,
          createdAt: now.getTime(),
          memberId: '5e86809283e28b96d2d38537',
          message,
        };

        // Add the new comment to card
        card.comments.push(comment);

        await touchwayApi.maintenance.maintenanceControllerUpsert({
          mts_id: card.extraProps.mts_id,
          tpm_id: card.extraProps.tpm_id,
          vhc_id: card.extraProps.vhc_id,
          vmp_id: card.extraProps.vmp_id,
          vmp_notes: card.comments.map(({ message }) => message),
          vmp_hour_meter_forecast: card.extraProps.vmp_hour_meter_forecast,
        });

        // Save changes
        board = clonedBoard;

        resolve(deepCopy(comment));
      } catch (err) {
        console.error('[Kanban Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  async addChecklist({ cardId, name }): Promise<Checklist> {
    return new Promise(async (resolve, reject) => {
      try {
        // Make a deep copy
        const clonedBoard = await this.getBoard();

        // Find the card where the checklist will be added
        const card = clonedBoard.cards.find((_card) => _card.id === cardId);

        if (!card) {
          reject(new Error('Card not found'));
          return;
        }

        // Create the new checklist
        const checklist: Checklist = {
          id: createResourceId(),
          name,
          checkItems: [],
        };

        // Add the new checklist to card
        card.checklists.push(checklist);

        // Save changes
        board = clonedBoard;

        resolve(deepCopy(checklist));
      } catch (err) {
        console.error('[Kanban Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  async updateChecklist({ cardId, checklistId, update }): Promise<Checklist> {
    return new Promise(async (resolve, reject) => {
      try {
        // Make a deep copy
        const clonedBoard = await this.getBoard();

        // Find the card that contains the checklist that will be updated
        const card = clonedBoard.cards.find((_card) => _card.id === cardId);

        if (!card) {
          reject(new Error('Card not found'));
          return;
        }

        // Find the checklist that will be updated
        const checklist = card.checklists.find(
          (_checklist) => _checklist.id === checklistId,
        );

        if (!checklist) {
          reject(new Error('Checklist not found'));
          return;
        }

        // Update the checklist
        Object.assign(checklist, update);

        // Save changes
        board = clonedBoard;

        resolve(deepCopy(checklist));
      } catch (err) {
        console.error('[Kanban Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  async deleteChecklist({ cardId, checklistId }): Promise<true> {
    return new Promise(async (resolve, reject) => {
      try {
        // Make a deep copy
        const clonedBoard = await this.getBoard();

        // Find the card that contains the checklist that will be removed
        const card = clonedBoard.cards.find((_card) => _card.id === cardId);

        if (!card) {
          reject(new Error('Card not found'));
          return;
        }

        // Remove the checklist from the card
        card.checklists = card.checklists.filter(
          (checklists) => checklists.id !== checklistId,
        );

        // Save changes
        board = clonedBoard;

        resolve(true);
      } catch (err) {
        console.error('[Kanban Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  async addCheckItem({ cardId, checklistId, name }): Promise<CheckItem> {
    return new Promise(async (resolve, reject) => {
      try {
        // Make a deep copy
        const clonedBoard = await this.getBoard();

        // Find the card where the checklist will be added
        const card = clonedBoard.cards.find((_card) => _card.id === cardId);

        if (!card) {
          reject(new Error('Card not found'));
          return;
        }

        // Find the checklist where the check item will be added
        const checklist = card.checklists.find(
          (_checklist) => _checklist.id === checklistId,
        );

        if (!checklist) {
          reject(new Error('Checklist not found'));
          return;
        }

        // Create the new check item
        const checkItem: CheckItem = {
          id: createResourceId(),
          checklistId,
          name,
          state: 'incomplete',
        };

        // Add the check item to the checklist
        checklist.checkItems.push(checkItem);

        // Save changes
        board = clonedBoard;

        resolve(deepCopy(checkItem));
      } catch (err) {
        console.error('[Kanban Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  updateCheckItem({
    cardId,
    checklistId,
    checkItemId,
    update,
    async,
  }): Promise<CheckItem> {
    return new Promise(async (resolve, reject) => {
      try {
        // Make a deep copy
        const clonedBoard = await this.getBoard();

        // Find the card where the checklist will be added
        const card = clonedBoard.cards.find((_card) => _card.id === cardId);

        if (!card) {
          reject(new Error('Card not found'));
          return;
        }

        // Find the checklist where the check item will be updated
        const checklist = card.checklists.find(
          (_checklist) => _checklist.id === checklistId,
        );

        if (!checklist) {
          reject(new Error('Checklist not found'));
          return;
        }

        // Find the checklist where the check item will be updated
        const checkItem = checklist.checkItems.find(
          (_checkItem) => _checkItem.id === checkItemId,
        );

        if (!checkItem) {
          reject(new Error('Check item not found'));
          return;
        }

        // Update the check item
        Object.assign(checkItem, update);

        // Save changes
        board = clonedBoard;

        resolve(deepCopy(checkItem));
      } catch (err) {
        console.error('[Kanban Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  async deleteCheckItem({ cardId, checklistId, checkItemId }): Promise<true> {
    return new Promise(async (resolve, reject) => {
      try {
        // Make a deep copy
        const clonedBoard = await this.getBoard();

        // Find the card that contains the checklist that contains the check item that will be removed
        const card = clonedBoard.cards.find((_card) => _card.id === cardId);

        if (!card) {
          reject(new Error('Card not found'));
          return;
        }

        // Find the checklist where the check item will be updated
        const checklist = card.checklists.find(
          (_checklist) => _checklist.id === checklistId,
        );

        if (!checklist) {
          reject(new Error('Checklist not found'));
          return;
        }

        // Remove the check item from the checklist
        checklist.checkItems = checklist.checkItems.filter(
          (checkItem) => checkItem.id !== checkItemId,
        );

        // Save changes
        board = clonedBoard;

        resolve(true);
      } catch (err) {
        console.error('[Kanban Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }
}

export const kanbanApi = new KanbanApi();
