/* eslint-disable no-alert */
import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, Grid, Typography } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import MutatingButton from 'src/components/forms/MutatingButton';
import { TextfieldController } from 'src/components/forms/TextfieldController';
import { IVehicle, IVehicleUpsertDto } from 'src/services/touchway-base-api';
import { useTouchwayApi } from 'src/services/touchway-api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/config/query';
import useAuth from 'src/hooks/useAuth';
import { yupResolver } from '@hookform/resolvers/yup';
import { SchemaOf, object, string, number } from 'yup';
import { SelectController } from 'src/components/forms/SelectController';

interface DeviceFormInterface {
  deviceToBeEdited: IVehicle | null;
  defaultValues?: Partial<IVehicleUpsertDto>;
  onSuccess?: (data: IVehicle) => void;
}

const DeviceForm: React.FC<DeviceFormInterface> = ({
  deviceToBeEdited,
  onSuccess,
  defaultValues,
}) => {
  const queryCache = useQueryClient();
  const { api } = useTouchwayApi();
  const isEditForm = !!deviceToBeEdited;
  const mutateEdit = useMutation(api.vehicles.vehicleControllerUpsert, {
    onSuccess: (data) => onSuccess && onSuccess(data),
  });
  const { isTouchway } = useAuth();

  // @ts-ignore
  const schema: SchemaOf<IVehicleUpsertDto> = object({
    lct_id: string().required(),
    vhc_id: isEditForm ? string().required() : string().nullable(),
    tpv_id: string().required('O UUID do tipo de equipamento é obrigatório'),
    vhc_name: string().max(250).required('O nome do equipamento é obrigatório'),
    vhc_fuel_avg_cons_lph: number().required(
      'O consumo médio de combustível é obrigatório',
    ),
    vhc_hour_meter: number().required(
      'O valor do horímetro do equipamento é obrigatório',
    ),
  });

  const locatorsQuery = useQuery(
    // @ts-ignore
    queryKeys.locatorControllerList.get({
      with_relations: true,
    }),
    () =>
      // @ts-ignore: Object is possibly 'null'.
      api.locator.locatorControllerList({
        with_relations: true,
      }),
    {
      refetchOnWindowFocus: false,
      // 60 seconds
      refetchInterval: 10000,
    },
  );

  const { handleSubmit, control, formState, setValue } =
    useForm<IVehicleUpsertDto>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      // @ts-ignore
      resolver: yupResolver(schema),
      defaultValues: {
        lct_id: deviceToBeEdited?.lct_id || defaultValues?.lct_id,
        vhc_id: deviceToBeEdited?.vhc_id || defaultValues?.vhc_id,
        vhc_name: deviceToBeEdited?.vhc_name || defaultValues?.vhc_name,
        tpv_id: deviceToBeEdited?.tpv_id || defaultValues?.tpv_id,
        vhc_fuel_avg_cons_lph:
          deviceToBeEdited?.vhc_fuel_avg_cons_lph ||
          defaultValues?.vhc_fuel_avg_cons_lph ||
          12,
        vhc_hour_meter:
          deviceToBeEdited?.vhc_hour_meter ||
          defaultValues?.vhc_hour_meter ||
          0,
      },
    });
  console.error('❌ ~ file: DeviceForm.tsx:61 ~ formState:', formState.errors);

  const autocompleteQuery = useQuery(
    [queryKeys.autocompleteControllerTypeVehicles.get()],
    () => api.autocomplete.autocompleteControllerTypeVehicles(),
    {
      onSuccess: ({ options }) => {
        if (!isEditForm) {
          setValue('tpv_id', options[0].id);
        }
      },
    },
  );

  const handleEditDevice = (data: IVehicleUpsertDto) =>
    mutateEdit.mutate(data, {
      onSuccess: () => {
        alert('Equipamento editado com sucesso!');
        // invalidateQueries();
        queryCache.refetchQueries(queryKeys.vehicleControllerList.get());
        queryCache.refetchQueries(
          queryKeys.vehicleControllerList.get({
            vhc_id: deviceToBeEdited?.vhc_id,
          }),
        );
      },
    });

  function handleCreateDevice(data: IVehicleUpsertDto) {
    mutateEdit.mutate(
      // @ts-ignore
      {
        lct_id: data.lct_id,
        tpv_id: data.tpv_id,
        vhc_fuel_avg_cons_lph: data.vhc_fuel_avg_cons_lph,
        vhc_hour_meter: data.vhc_hour_meter,
        vhc_name: data.vhc_name,
      },
      {
        onSuccess: () => {
          alert('Equipamento criado com sucesso!');
          // invalidateQueries();
          queryCache.refetchQueries(queryKeys.vehicleControllerList.get());
        },
      },
    );
  }

  const onSubmit = (data: IVehicleUpsertDto) =>
    isEditForm ? handleEditDevice(data) : handleCreateDevice(data);

  const locatorFound = locatorsQuery.data?.locators.find(
    (locator) => locator.lct_id === defaultValues?.lct_id,
  );
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextfieldController
                control={control}
                name="vhc_name"
                disabled={!isTouchway}
                label="Nome do equipamento"
                placeholder="Ex: TLD 40"
                rules={{
                  required: true,
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextfieldController
                control={control}
                name="vhc_fuel_avg_cons_lph"
                label="Consumo médio de combustível (Litros/hora)"
                placeholder="Ex: 10"
                rules={{
                  required: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextfieldController
                control={control}
                name="vhc_hour_meter"
                placeholder="Ex: 1000.23"
                label="Horímetro"
                disabled={!isTouchway}
                rules={{
                  required: true,
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              {!autocompleteQuery.isLoading && autocompleteQuery.data && (
                <SelectController
                  control={control}
                  rules={{ required: true }}
                  name="tpv_id"
                  label="Tipo de equipamento"
                  values={autocompleteQuery.data?.options.map(
                    ({ id, value }) => ({
                      key: id,
                      label: value,
                    }),
                  )}
                />
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              {!isEditForm && (
                // flex column:
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom={false}>
                      {locatorFound?.lct_imei || 'Sem IMEI'}
                    </Typography>
                    <Typography gutterBottom={false} variant="caption">
                      IMEI
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Box display="flex" flexDirection="row-reverse">
            <MutatingButton
              startIcon={<Save fontSize="small" />}
              type="submit"
              variant="contained"
              disabled={mutateEdit.isLoading}
              loadingText="Salvando..."
            >
              {isEditForm ? 'Editar' : 'Criar'}
            </MutatingButton>
          </Box>
        </>
      </form>
    </>
  );
};

export default DeviceForm;
