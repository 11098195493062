import { useEffect } from 'react';
import type { FC } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import type { DropResult } from 'react-beautiful-dnd';
import toast from 'react-hot-toast';
import { Box } from '@material-ui/core';
import MaintenanceFilter from 'src/components/MaintenanceFilter';
import MaintenanceSearch from 'src/components/MaintenanceSearch';
import { KanbanColumn } from '../../components/dashboard/kanban';
import gtm from '../../lib/gtm';
import { getBoard, moveCard } from '../../slices/kanban';
import { useDispatch, useSelector } from '../../store';
import { Page } from '../../components/layouts/Page';

interface IKanban {
  showOnlyKanban?: boolean;
}

const Kanban: FC<IKanban> = ({ showOnlyKanban }) => {
  const dispatch = useDispatch();
  const { columns } = useSelector((state) => state.kanban);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  useEffect(() => {
    dispatch(getBoard());
  }, [dispatch]);

  const handleDragEnd = async ({
    source,
    destination,
    draggableId,
  }: DropResult): Promise<void> => {
    try {
      // Dropped outside the column
      if (!destination) {
        return;
      }

      // Card has not been moved
      if (
        source.droppableId === destination.droppableId &&
        source.index === destination.index
      ) {
        return;
      }

      if (source.droppableId === destination.droppableId) {
        // Moved to the same column on different position
        // @ts-ignore
        await dispatch(moveCard(draggableId, destination.index));
      } else {
        // Moved to another column
        await dispatch(
          // @ts-ignore
          moveCard(draggableId, destination.index, destination.droppableId),
        );

        dispatch(getBoard());
      }

      toast.success('Equipamento movido com sucesso');
    } catch (err: any) {
      console.error(err);
      toast.error(err.message);
    }
  };

  const KanbanRaw = () => (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexShrink: 1,
          overflowX: 'auto',
          overflowY: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
          }}
        >
          {columns.allIds.map((columnId: string) => (
            <KanbanColumn columnId={columnId} key={columnId} />
          ))}
        </Box>
      </Box>
    </DragDropContext>
  );

  if (showOnlyKanban) {
    return <KanbanRaw />;
  }

  return (
    <Page
      title="Manutenção"
      buttons={[<MaintenanceSearch />, <MaintenanceFilter />]}
      breadcrumbs={[
        { to: '/', label: 'Painel' },
        { to: '/kanban', label: 'Manutenção' },
      ]}
    >
      <KanbanRaw />
    </Page>
  );
};

export default Kanban;
