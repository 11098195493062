/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo } from 'react';
import {
  Box,
  LinearProgress,
  Typography,
  TypographyProps,
} from '@material-ui/core';
import { ITypeMaintenance, IVehicle } from 'src/services/touchway-base-api';
import { useQuery } from '@tanstack/react-query';
import { useTouchwayApi } from 'src/services/touchway-api';
import styled from 'styled-components';

interface GseMaintenancesProps {
  vhc_id: string;
  ignition: boolean;
  filter?: {
    tmp_id?: string;
  };
  typographyStyle?: TypographyProps;
  triggerBlinkingEffect?: (
    key: 'hours_300' | 'hours_5000' | 'hours_10000',
    newValue: false | 'warning' | 'danger',
  ) => void;
}
interface CustomLinearProgressInterface {
  maintence: number;
  value: number;
}
export const CustomLinearProgress = styled(
  LinearProgress,
)<CustomLinearProgressInterface>`
  .MuiLinearProgress-root,
  .MuiLinearProgress-colorPrimary,
  .MuiLinearProgress-determinate {
    height: 8px;
  }

  .MuiLinearProgress-bar {
    background-color: ${({ value }) => {
      if (value > 51) {
        return 'green';
      }

      if (value > 30) {
        return '#ffa500';
      }

      return '#ff0000';
    }};
    color: red;
  }
`;

const GseMaintenances: React.FC<GseMaintenancesProps> = ({
  vhc_id,
  ignition,
  filter,
  typographyStyle,
  triggerBlinkingEffect,
}) => {
  const touchwayApi = useTouchwayApi();

  const { data } = useQuery({
    queryKey: ['vehicle', vhc_id, 'maintenances', 'ignition', ignition],
    queryFn: () =>
      touchwayApi.api.vehicles.vehicleControllerList({
        vhc_id,
        // @ts-ignore
        with_relations: true,
      }),
  });

  const vehicle = useMemo(() => data?.vehicles[0] as IVehicle, [data]);

  const maintenances = useMemo(() => {
    if (!vehicle) {
      return [];
    }

    if (!filter?.tmp_id) {
      return vehicle.maintenances;
    }
    return vehicle.maintenances.filter(
      ({ typeMaintenance }) => typeMaintenance.tpm_id === filter.tmp_id,
    );
  }, [vehicle, filter]);

  return (
    <>
      {maintenances.map((maintenance) => {
        const remainingPercentage = 100 - maintenance.vmp_completion_percentage;

        return (
          <Box key={maintenance.vmp_id}>
            <GseSocketRow
              triggerBlinkingEffect={triggerBlinkingEffect}
              maintenanceType={maintenance.typeMaintenance}
              remainingPercentage={remainingPercentage}
              label={maintenance.typeMaintenance.tpm_name}
              value={`${remainingPercentage.toFixed(2)}%`}
              typographyStyle={typographyStyle}
            />
            <Box mb={1}>
              <CustomLinearProgress
                value={remainingPercentage}
                maintence={100}
                color={remainingPercentage > 0 ? 'primary' : 'secondary'}
                variant={
                  remainingPercentage > 0 ? 'determinate' : 'determinate'
                }
              />
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export { GseMaintenances };

export const GseSocketRow: React.FC<IGseSocketRow> = ({
  label,
  value,
  icon,
  typographyStyle,
  remainingPercentage,
  triggerBlinkingEffect,
  maintenanceType,
}) => (
  // useEffect(() => {
  //   if (!triggerBlinkingEffect || !maintenanceType || !remainingPercentage) {
  //     return;
  //   }

  //   const shouldBlink = remainingPercentage < 10;
  //   switch (maintenanceType.tpm_in_hours) {
  //     case 300:
  //       triggerBlinkingEffect('hours_300', shouldBlink);
  //       break;
  //     case 5000:
  //       triggerBlinkingEffect('hours_5000', shouldBlink);
  //       break;
  //     case 10000:
  //       triggerBlinkingEffect('hours_10000', shouldBlink);
  //       break;
  //     default:
  //       break;
  //   }
  // }, [maintenanceType, remainingPercentage, triggerBlinkingEffect]);

  <Box display="flex" justifyContent="space-between" flexDirection="row">
    {icon || null}
    <Typography color="textPrimary" variant="body1" {...typographyStyle}>
      {label}
    </Typography>
    <Typography color="textPrimary" variant="body1" {...typographyStyle}>
      {value}
    </Typography>
  </Box>
);
interface IGseSocketRow {
  label: string;
  value?: string | number | undefined;
  icon?: React.ReactNode;
  typographyStyle?: TypographyProps;
  remainingPercentage?: number;
  triggerBlinkingEffect?: (
    key: 'hours_300' | 'hours_5000' | 'hours_10000',
    newValue: false | 'warning' | 'danger',
  ) => void;
  maintenanceType?: ITypeMaintenance;
}
