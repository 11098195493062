import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { queryKeys } from 'src/config/query';
import { useTouchwayApi } from 'src/services/touchway-api';
import { ImageTpinameEnum } from 'src/utils/getImageName';

interface UseVehiclePhotoProps {
  vhc_id: string | undefined;
}

export const DEFAULT_PLACEHOLDER = '/static/placeholder.png';

export const useVehiclePhoto = (props: UseVehiclePhotoProps) => {
  const vhcId = String(props.vhc_id);
  const { api } = useTouchwayApi();

  const queryVehicleImages = useQuery({
    queryFn: async () =>
      api.images.imageControllerList({
        vhc_id: vhcId,
      }),
    enabled: !!props.vhc_id,
    queryKey: queryKeys.images.get(vhcId),
    cacheTime: 1000 * 60 * 60 * 24 * 7, // 1 week
    staleTime: 1000 * 60 * 60 * 24 * 7, // 1 week
  });

  const imgs = useMemo(
    () => queryVehicleImages?.data?.images || [],
    [queryVehicleImages.data],
  );
  const findImageUrl = useCallback(
    (type: ImageTpinameEnum) =>
      imgs.find(({ typeImage }) => typeImage.tpi_name === type)?.vhi_url ||
      DEFAULT_PLACEHOLDER,
    [imgs],
  );

  const res = {
    [ImageTpinameEnum.AFTER_INSTALL_LOCATOR_MEDIA]: findImageUrl(
      ImageTpinameEnum.AFTER_INSTALL_LOCATOR_MEDIA,
    ),
    [ImageTpinameEnum.BEFORE_INSTALL_LOCATOR_MEDIA]: findImageUrl(
      ImageTpinameEnum.BEFORE_INSTALL_LOCATOR_MEDIA,
    ),
    [ImageTpinameEnum.HOUR_METER]: findImageUrl(ImageTpinameEnum.HOUR_METER),
    [ImageTpinameEnum.LOCATOR_INSTALLED]: findImageUrl(
      ImageTpinameEnum.LOCATOR_INSTALLED,
    ),
    [ImageTpinameEnum.VEHICLE]: findImageUrl(ImageTpinameEnum.VEHICLE),
    [ImageTpinameEnum.VEHICLE_LOCATOR_ATTACHMENT]: findImageUrl(
      ImageTpinameEnum.VEHICLE_LOCATOR_ATTACHMENT,
    ),
  };
  console.log('🚀 ~ useVehiclePhoto ~ res:', res);

  return {
    query: queryVehicleImages,
    images: res,
  };
};
