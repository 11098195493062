import React from 'react';
import {
  BottomNavigation as MaterialBottomNavigation,
  AppBar,
  BottomNavigationAction,
} from '@material-ui/core';
import {
  AppSettingsAlt,
  PinDropRounded,
  Tv,
  ViewList,
} from '@material-ui/icons';
import { useNavigate } from 'react-router';
// eslint-disable-next-line import/no-extraneous-dependencies
// import { styled } from '@material-ui/styles';
// import useLayout from 'src/hooks/useLayout';

interface BottomNavigationInterface {}

// const StyledFab = styled(Fab)({
//   position: 'absolute',
//   zIndex: 1,
//   top: -30,
//   left: 0,
//   right: 0,
//   margin: '0 auto',
// });

export const BottomNavigation: React.FC<BottomNavigationInterface> = () => {
  const navigate = useNavigate();
  // const { openMenu } = useLayout();
  const [value, setValue] = React.useState(0);

  return (
    <AppBar
      position="fixed"
      color="transparent"
      style={{
        top: 'auto',
        bottom: -1,
        border: '1px solid transparent',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
      }}
    >
      <MaterialBottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
      >
        <BottomNavigationAction
          color="primary"
          label="Painel"
          onClick={() => navigate('//realtime')}
          icon={<Tv />}
        />

        <BottomNavigationAction
          color="primary"
          label="Mapa"
          onClick={() => navigate('//mapa')}
          icon={<PinDropRounded />}
        />

        <BottomNavigationAction
          color="primary"
          label="Equip."
          onClick={() => navigate('//veiculos')}
          icon={<ViewList />}
        />

        {/* <BottomNavigationAction
          color="primary"
          label="Manut."
          onClick={() => navigate('//kanban')}
          icon={<BuildRounded />}
        /> */}

        <BottomNavigationAction
          label="Instal."
          onClick={() => navigate('/instalador')}
          icon={<AppSettingsAlt />}
        />
      </MaterialBottomNavigation>
    </AppBar>
  );
};

export default BottomNavigation;
