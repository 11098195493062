/* eslint-disable @typescript-eslint/naming-convention */
import { FC, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Page } from 'src/components/layouts/Page';
import { useNavigate, useParams } from 'react-router';
import { Box, Card } from '@material-ui/core';
import { useTouchwayApi } from 'src/services/touchway-api';
import { queryKeys } from 'src/config/query';
import EmptyPlaceholder from 'src/components/layouts/EmptyPlaceholder';
import { TypeEquipmentMaintenanceForm } from './forms/TypeEquipmentMaintenanceForm';

export const TypeEquipmentMaintenanceEdit: FC = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(true);
  const { api } = useTouchwayApi();
  const { tem_id } = useParams();

  const params = { tem_id };
  const query = useQuery(queryKeys.typeEquipmentMaintenance.list(params), () =>
    api.typeEquipmentMaintenance.typeEquipmentMaintenanceControllerList(params),
  );
  const typeEqp = query?.data?.type_equipment_maintenances?.[0];

  function handleClose() {
    setOpen(false);
    navigate('/frequencia-manutencao');
  }

  function onSuccessfulSubmit() {
    handleClose();
  }

  return (
    <Page
      title={`Editar ${typeEqp?.tem_name}`}
      query={query}
      breadcrumbs={[
        { to: '/', label: 'Painel' },
        { to: '/frequencia-manutencao', label: 'Frequências de manutenção' },
        { to: typeEqp?.tem_id, label: typeEqp?.tem_name },
      ]}
    >
      <Card>
        <Box sx={{ p: 3 }}>
          {typeEqp ? (
            <TypeEquipmentMaintenanceForm
              typeEquipmentMaintenanceToBeEdited={typeEqp}
              open={open}
              onClose={handleClose}
              onSuccess={onSuccessfulSubmit}
            />
          ) : (
            <EmptyPlaceholder
              title="Frequência de manutenção não encontrada"
              subtitle="Tente recarregar a página ou contate o suporte"
            />
          )}
        </Box>
      </Card>
    </Page>
  );
};
