import { useTheme } from '@material-ui/core';
import { MarkerF } from '@react-google-maps/api';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { CustomMaps } from 'src/components/CustomMaps';
import LoadingScreen from 'src/components/LoadingScreen';
import { DEFAULT_MAPS } from 'src/config/maps-default';
import { queryKeys } from 'src/config/query';
import { useTouchwayApi } from 'src/services/touchway-api';
import { IEquipment } from 'src/services/touchway-base-api';

interface EquipmentMapProps {
  eqp_id: string;
}

const EquipmentMap: React.FC<EquipmentMapProps> = ({ eqp_id }) => {
  const theme = useTheme();
  const touchwayApi = useTouchwayApi();
  const [, setMap] = React.useState(null);

  const params = {
    eqp_id,
    with_relations: true,
  };

  const eqpQuery = useQuery(queryKeys.equipment.get(params), () =>
    touchwayApi.api.equipment.equipmentControllerList(params),
  );

  const equipmentRaw = eqpQuery.data?.equipments[0] as IEquipment | undefined;
  const eqp = equipmentRaw;

  if (!eqp?.airport) {
    return <LoadingScreen />;
  }

  const center: google.maps.LatLng | google.maps.LatLngLiteral = {
    lat: Number(eqp?.airport?.air_latitude) ?? DEFAULT_MAPS.center.lat,
    lng: Number(eqp?.airport?.air_longitude) ?? DEFAULT_MAPS.center.lng,
  };

  return (
    <CustomMaps
      mapContainerStyle={{
        height: '500px',
        width: '100%',
      }}
      center={center}
      setMapCallback={setMap}
      zoom={13}
    >
      <MarkerF
        animation={window.google.maps.Animation.DROP}
        icon={{
          // ...DEFAULT_MAPS.icon,
          path: window.google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
          // size: new window.google.maps.Size(
          //   DEFAULT_MAPS.icon.size.width,
          //   DEFAULT_MAPS.icon.size.height,
          // ),
          scale: DEFAULT_MAPS.icon.scale,
          // scaledSize: new window.google.maps.Size(
          //   DEFAULT_MAPS.icon.size.width,
          //   DEFAULT_MAPS.icon.size.height,
          // ),
          labelOrigin: new window.google.maps.Point(
            DEFAULT_MAPS.icon.labelOrigin.x,
            DEFAULT_MAPS.icon.labelOrigin.y,
          ),
          strokeColor: theme.palette.primary.main,
          fillColor: theme.palette.primary.main,
        }}
        key={`location-ignition-on-${eqp.airport.air_latitude}-${eqp.airport.air_longitude}`}
        label={{
          text: `${eqp.eqp_name} - ${eqp.airport.air_iata}`,
          color: theme.palette.primary.main,
          fontWeight: 'bold',
        }}
        position={{
          lat: Number(eqp.airport.air_latitude),
          lng: Number(eqp.airport.air_longitude),
        }}
      />
    </CustomMaps>
  );
};

export default EquipmentMap;
