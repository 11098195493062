import type { FC } from 'react';

import { Avatar, Box, Paper, Typography } from '@material-ui/core';
import { useSelector } from '../../../store';
import type { RootState } from '../../../store';
import type { Member } from '../../../types/kanban';

interface KanbanCommentProps {
  createdAt: number;
  memberId: string;
  message: string;
  index?: number;
}

const memberSelector = (state: RootState, memberId: string): Member => {
  const { members } = state.kanban;

  return members.byId[memberId];
};

const KanbanComment: FC<KanbanCommentProps> = (props) => {
  const { createdAt, memberId, message, index, ...other } = props;
  const member = useSelector((state) => memberSelector(state, memberId));

  return (
    <Box
      sx={{
        display: 'flex',
        mb: 2,
      }}
      {...other} // @ts-ignore
    >
      {member?.avatar && <Avatar src={member?.avatar} />}
      <Box
        sx={{
          ml: 2,
          flexGrow: 1,
        }}
      >
        {/* {member.name &&(
          <Typography color="textPrimary" variant="subtitle2">
            {member.name}
          </Typography>
        )} */}
        {/* <Typography color="textPrimary" variant="subtitle2">
          Nota #{index}
        </Typography> */}
        <Paper
          sx={{
            backgroundColor: 'background.default',
            mt: 1,
            p: 2,
          }}
          variant="outlined"
        >
          <Typography color="textPrimary" variant="body2">
            {message}
          </Typography>
        </Paper>
        {/* {createdAt && (
          <Typography
            color="textSecondary"
            component="p"
            sx={{ mt: 1 }}
            variant="caption"
          >
            {format(createdAt, "MMM dd, yyyy 'at' hh:mm a")}
          </Typography>
        )} */}
      </Box>
    </Box>
  );
};

export default KanbanComment;
