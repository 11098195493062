import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { SelectController } from 'src/components/forms/SelectController';
import { queryKeys } from 'src/config/query';
import { useTouchwayApi } from 'src/services/touchway-api';
import { ITypeEquipmentMaintenanceUpsertDto } from 'src/services/touchway-base-api';
import { number, object, SchemaOf, string } from 'yup';
import { TypeEquipmentMaintenanceForm } from './forms/TypeEquipmentMaintenanceForm';

interface EquipmentStatusMaintenanceSelectProps {
  onChange?: (value: string | undefined) => void;
  defaultValue?: string | undefined | null;
}

const schema: SchemaOf<ITypeEquipmentMaintenanceUpsertDto> = object({
  tem_duration_in_decimal_hours: number().required(),
  tem_id: string().required(),
  tem_name: string().required(),
});

const EquipmentStatusMaintenanceSelect: React.FC<EquipmentStatusMaintenanceSelectProps> =
  ({ onChange, defaultValue }) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const { api } = useTouchwayApi();

    const temQuery = useQuery({
      queryFn: () =>
        api.autocomplete.autocompleteControllerTypeEquipmentMaintenance(),
      queryKey: queryKeys.autocompleteControllerTypeEquipmentMaintenance.get(),
    });

    const { handleSubmit, control, watch } =
      useForm<ITypeEquipmentMaintenanceUpsertDto>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        // @ts-ignore
        resolver: yupResolver(schema),
        defaultValues: {
          tem_duration_in_decimal_hours: 0,
          tem_id: defaultValue || '',
          tem_name: '',
        },
      });

    function onSubmit(data: ITypeEquipmentMaintenanceUpsertDto) {
      console.log(data);
    }

    const temIdTmp = watch('tem_id');
    useEffect(() => {
      if (temIdTmp && onChange) {
        onChange(temIdTmp);
      }
    }, [onChange, temIdTmp]);

    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SelectController
            control={control}
            name="tem_id"
            label="Frequência da manutenção"
            values={(temQuery.data?.options || []).map(({ id, value }) => ({
              key: id,
              label: value,
            }))}
            loading={temQuery.isLoading}
            defaultValue={defaultValue || watch('tem_id')}
            rules={{ required: true }}
            helperMessage="Selecione a frequência da manutenção, caso não exista, crie uma nova"
          />
          <Button onClick={handleOpen}>Criar novo tipo de manutenção</Button>
        </form>

        <TypeEquipmentMaintenanceForm
          open={open}
          onClose={handleClose}
          typeEquipmentMaintenanceToBeEdited={null}
        />
      </>
    );
  };

export default EquipmentStatusMaintenanceSelect;
