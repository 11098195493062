// @ts-nocheck
import type { FC } from 'react';

import EquipmentMaintenanceForm from 'src/pages/equipments/forms/EquipmentMaintenanceForm';
import type { CalendarEvent } from '../../../types/calendar';

interface CalendarEventFormProps {
  event?: CalendarEvent;
  onAddComplete?: () => void;
  onCancel?: () => void;
  onDeleteComplete?: () => void;
  onEditComplete?: () => void;
  range?: { start: number; end: number };
}

const CalendarEventForm: FC<CalendarEventFormProps> = (props) => {
  const { event, onAddComplete, onCancel, onEditComplete, range } = props;
  const maintenance = event?.extraProps?.maintenanceSimple;
  const startDate = range?.start || event?.start || null;

  function handleFinish() {
    onAddComplete();
    onCancel();
    onEditComplete();
  }

  return (
    <>
      {/* <Box p={2}>
        <EquipmentMaintenanceStatusChange maintenance={maintenance} />
      </Box> */}
      <EquipmentMaintenanceForm
        eqpMaintenance={{
          ...maintenance,
          eqm_start: new Date(startDate).toISOString(),
        }}
        eqp_id={maintenance?.eqp_id || null}
        allowChangeEquipment={!maintenance?.eqp_id}
        onSuccessCallback={handleFinish}
        editableFields={[
          'eqm_start',
          'eqm_executed',
          'eqm_notes',
          'eqp_id',
          'mts_id',
          'tem_id',
          'eqm_id',
        ]}
      />
    </>
  );
};

export default CalendarEventForm;
