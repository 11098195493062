import React from 'react';
import { Outlet } from 'react-router-dom';

interface TypeEquipmentRootPageInterface {}

const TypeEquipmentRootPage: React.FC<TypeEquipmentRootPageInterface> = () => (
  <Outlet />
);

export default TypeEquipmentRootPage;
