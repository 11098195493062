/* eslint-disable max-len */
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import {
  WidgetLoader,
  Widget,
  WidgetResult,
  WidgetOptions,
} from 'react-cloudinary-upload-widget';

interface CloudinaryImageUploadProps {
  uploadButtonText?: string;
  uploadButtonStyle?: React.CSSProperties;
  onSuccess: (result: WidgetResult) => void;
  onFailure: (error: unknown, result: WidgetResult) => void;
  cloudinaryProps?: Partial<WidgetOptions>;
}

const CloudinaryImageUpload: React.FC<CloudinaryImageUploadProps> = ({
  onSuccess,
  onFailure,
  uploadButtonStyle,
  uploadButtonText,
  cloudinaryProps,
}) => {
  const theme = useTheme();

  return (
    <>
      <WidgetLoader />
      <Widget
        apiKey={process.env.REACT_APP_CLOUDINARY_API_KEY || ''} // cloudinary API key -> number format
        cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME || ''} // your cloudinary account cloud name.
        uploadPreset={process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || ''} // check that an upload preset exists and check mode is signed or unisgned
        onSuccess={onSuccess} // add success callback -> returns result
        onFailure={onFailure} // add failure callback -> returns 'response.error' + 'response.result'
        sources={['local', 'camera', 'dropbox', 'google_drive']} // set the sources available for uploading -> by default
        // all sources are available. More information on their use can be found at
        // https://cloudinary.com/documentation/upload_widget#the_sources_parameter
        sourceKeys={{}} // add source keys
        // and ID's as an object. More information on their use can be found at
        // https://cloudinary.com/documentation/upload_widget#the_sources_parameter
        resourceType="auto" // optionally set with 'auto', 'image', 'video' or 'raw' -> default = 'auto'
        // Located on https://cloudinary.com/console/
        buttonText={uploadButtonText || 'Fazer upload'} // default 'Upload Files'
        style={{
          color: theme.palette.primary.contrastText,
          border: 'none',
          width: '160px',
          backgroundColor: theme.palette.primary.main,
          borderRadius: '4px',
          height: '25px',
          ...uploadButtonStyle,
        }} // inline styling only or style id='cloudinary_upload_button'
        folder="azul" // set cloudinary folder name to send file
        cropping // set ability to crop images -> default = true
        // https://support.cloudinary.com/hc/en-us/articles/203062071-How-to-crop-images-via-the-Upload-Widget-#:~:text=Click%20on%20the%20%22Edit%22%20link,OK%22%20and%20Save%20the%20changes.
        // more information here on cropping. Coordinates are returned or upload preset needs changing
        multiple={false} // set to false as default. Allows multiple file uploading
        // will only allow 1 file to be uploaded if cropping set to true
        autoClose // will close the widget after success. Default true
        logging // logs will be provided for success and failure messages,
        // set to false for production -> default = true
        // customPublicId={'sample'} // set a specific custom public_id.
        // To use the file name as the public_id use 'use_filename={true}' parameter
        // eager="w_400,h_300,c_pad|w_260,h_200,c_crop" // add eager transformations -> deafult = null
        use_filename={false} // tell Cloudinary to use the original name of the uploaded
        // file as its public ID -> default = true,
        // widgetStyles={{
        //   palette: {
        //     window: '#737373',
        //     windowBorder: '#FFFFFF',
        //     tabIcon: '#FF9600',
        //     menuIcons: '#D7D7D8',
        //     textDark: '#DEDEDE',
        //     textLight: '#FFFFFF',
        //     link: '#0078FF',
        //     action: '#FF620C',
        //     inactiveTabIcon: '#B3B3B3',
        //     error: '#F44235',
        //     inProgress: '#0078FF',
        //     complete: '#20B832',
        //     sourceBg: '#909090',
        //   },
        //   fonts: {
        //     default: null,
        //     "'Fira Sans', sans-serif": {
        //       url: 'https://fonts.googleapis.com/css?family=Fira+Sans',
        //       active: true,
        //     },
        //   },
        // }} // ability to customise the style of the widget uploader
        destroy={false} // will destroy the widget on completion
        // 👇 FOR SIGNED UPLOADS ONLY 👇
        // generateSignatureUrl={'http://my_domain.com/api/v1/media/generate_signature'} // pass the api
        // endpoint for generating a signature -> check cloudinary docs and SDK's for signing uploads
        accepts="application/json" // for signed uploads only -> default = 'application/json'
        contentType="application/json" // for signed uploads only -> default = 'application/json'
        withCredentials // default = true -> check axios documentation for more information
        unique_filename // setting it to false, you can tell Cloudinary not to attempt to make
        // the Public ID unique, and just use the normalized file name -> default = true
        singleUploadAutoClose
        {...cloudinaryProps}
      />
    </>
  );
};

export { CloudinaryImageUpload };
