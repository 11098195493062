/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useMemo, useState } from 'react';
import { MarkerClustererF, MarkerF } from '@react-google-maps/api';
import useRealtime, {
  IGseFirebaseCollection,
  ITechnicalFirebaseCollection,
} from 'src/hooks/firebase/useRealtime';
import { firebaseRealtimeEnum } from 'src/config';
// import { useQuery } from '@tanstack/react-query';
// import { useTouchwayApi } from 'src/services/touchway-api';
import { IEsperDeviceLocationDto } from 'src/services/touchway-base-api';
import { DEFAULT_MAPS } from 'src/config/maps-default';
import { CustomMaps } from 'src/components/CustomMaps';
import useSettings from 'src/hooks/useSettings';
import { THEMES } from 'src/constants';
import MapsMarker from '../MapsMarker';
// import { queryKeys } from 'src/config/query';

interface MapsProps {
  defaultZoom?: number;
  defaultHeight?: string | number;
}

export const MapsAirport: React.FC<MapsProps> = ({
  defaultZoom = 10,
  defaultHeight,
}) => {
  const [zoomLevel, setZoomLevel] = useState<number>(defaultZoom);
  const [technicals, setTechnicals] = useState<IEsperDeviceLocationDto[]>([]);

  // const touchwayApi = useTouchwayApi();
  const [map, setMap] = React.useState(null);
  const vehicles = useRealtime<IGseFirebaseCollection>({
    collection: firebaseRealtimeEnum.vehicles,
  });

  const technicalsFB = useRealtime<ITechnicalFirebaseCollection>({
    collection: firebaseRealtimeEnum.technicals,
  });

  useEffect(() => {
    if (technicalsFB) {
      const arr = Object.values(technicalsFB);
      setTechnicals(arr);
    }
  }, [technicalsFB]);

  const { settings } = useSettings();

  const labelColor = useMemo(
    () => (settings.theme === THEMES.LIGHT ? '#000' : '#fff'),
    [settings.theme],
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (map) {
      // @ts-ignore
      const zoomChangedListener = map.addListener('zoom_changed', () => {
        // @ts-ignore
        setZoomLevel(map.getZoom());
      });

      return () => {
        google.maps.event.removeListener(zoomChangedListener);
      };
    }
  }, [map]);

  const getMinimumClusterSize = (zoom: number) => {
    if (zoom >= 14) return 11;
    if (zoom >= 13) return 10;
    if (zoom >= 12) return 9;
    if (zoom >= 11) return 8;
    if (zoom >= 10) return 7;
    if (zoom >= 9) return 6;
    if (zoom >= 8) return 5;
    if (zoom >= 7) return 4;
    if (zoom >= 6) return 3;
    if (zoom >= 5) return 2;
    if (zoom >= 4) return 1;

    return 10;
  };

  return vehicles && technicals ? (
    <>
      <CustomMaps
        setMapCallback={setMap}
        defaultZoom={defaultZoom}
        defaultHeight={defaultHeight}
      >
        <MarkerClustererF minimumClusterSize={getMinimumClusterSize(zoomLevel)}>
          {(clusterer) => (
            <>
              {technicals.map((technical) => {
                const {
                  location: { latitude, longitude },
                  device_name,
                } = technical;

                return (
                  <MarkerF
                    animation={window.google.maps.Animation.DROP}
                    icon={{
                      // ...DEFAULT_MAPS.icon,
                      url: DEFAULT_MAPS.icon.technical_icon,
                      size: new window.google.maps.Size(
                        DEFAULT_MAPS.icon.size.width,
                        DEFAULT_MAPS.icon.size.height,
                      ),
                      scale: DEFAULT_MAPS.icon.scale,
                      scaledSize: new window.google.maps.Size(
                        DEFAULT_MAPS.icon.size.width,
                        DEFAULT_MAPS.icon.size.height,
                      ),
                      labelOrigin: new window.google.maps.Point(
                        DEFAULT_MAPS.icon.labelOrigin.x,
                        DEFAULT_MAPS.icon.labelOrigin.y,
                      ),
                    }}
                    key={`location-${latitude}-${longitude}`}
                    position={{ lat: Number(latitude), lng: Number(longitude) }}
                    label={{
                      text: device_name,
                      color: labelColor,
                      fontWeight: 'bold',
                    }}
                  />
                );
              })}
              {Object.entries(vehicles).map((entry) => {
                const [key, value] = entry;

                return (
                  <MapsMarker
                    vhc_id={key}
                    value={value}
                    labelColor={labelColor}
                    map={map}
                    clusterer={clusterer}
                  />
                );
              })}
            </>
          )}
        </MarkerClustererF>
      </CustomMaps>
    </>
  ) : (
    <></>
  );
};
