/* eslint-disable react/no-array-index-key */
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import React, { forwardRef, useEffect, useCallback } from 'react';
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import useSettings from 'src/hooks/useSettings';
import { useIsFetching, UseQueryResult } from '@tanstack/react-query';
import LoadingScreen from '../LoadingScreen';
import ChevronRightIcon from '../../icons/ChevronRight';
import EmptyPlaceholder from './EmptyPlaceholder';
import gtm from '../../lib/gtm';

// ----------------------------------------------------------------------

interface BreadcrumbsInterface {
  to: string | undefined;
  label: string | undefined;
}

interface PageInterface {
  title: string;
  subtitle?: string;
  subSubtitle?: JSX.Element;
  breadcrumbs?: BreadcrumbsInterface[];
  query?: UseQueryResult<any, any> | undefined;
  buttons?: JSX.Element[];
  disabledFetching?: boolean;
}

export const Page: React.FC<PageInterface> = forwardRef(
  (
    {
      children,
      title,
      breadcrumbs,
      query,
      buttons,
      subtitle,
      disabledFetching = true,
      subSubtitle,
    },
    ref,
  ) => {
    const { settings } = useSettings();
    const isFetching = useIsFetching();

    const sendPageViewEvent = useCallback(() => {
      gtm.push({ event: 'page_view' });
    }, []);

    useEffect(() => {
      sendPageViewEvent();
    }, [sendPageViewEvent]);

    return (
      // @ts-ignore
      <div ref={ref}>
        <Helmet>
          <title>{title ?? ''} | Touchway</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            pb: 8,
            pt: 4,
          }}
        >
          {}
          <Container maxWidth={settings.compact ? 'xl' : false}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                {/* <Box display="flex" flexDirection="row"> */}
                <Typography color="textPrimary" variant="h5">
                  {title ?? ''}
                </Typography>
                {subtitle && (
                  <Typography color="textSecondary" variant="subtitle2">
                    {subtitle}
                  </Typography>
                )}
                {/* </Box> */}
                {breadcrumbs && (
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<ChevronRightIcon fontSize="small" />}
                    sx={{ mt: 1 }}
                  >
                    {breadcrumbs.map(({ label }, index) => {
                      const isLast = index === breadcrumbs.length - 1;

                      const toLink = breadcrumbs
                        .slice(0, index + 1)
                        .reduce(
                          (acc, val) =>
                            /* eslint-disable-next-line no-param-reassign */
                            (acc += `${val.to ? val.to : ''}/`),
                          '/',
                        )
                        .slice(0, -1);

                      return !isLast ? (
                        <Link
                          key={`link-${toLink}-${index}`}
                          color="textPrimary"
                          component={RouterLink}
                          to={toLink}
                          variant="subtitle2"
                        >
                          {label}
                        </Link>
                      ) : (
                        <Typography
                          key={`link-${toLink}-${index}`}
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          {label}
                        </Typography>
                      );
                    })}
                  </Breadcrumbs>
                )}
              </Grid>

              <Grid item>
                <Box
                  display="flex"
                  flexDirection="row"
                  // align on top
                  alignItems="flex-start"
                >
                  {subSubtitle && (
                    <Box display="flex" mr={2}>
                      {subSubtitle}
                    </Box>
                  )}
                  <Box display="flex">
                    {buttons &&
                      buttons.map((button, i) => (
                        <Box
                          // @ts-ignore
                          // eslint-disable-next-line react/no-array-index-key
                          key={`page-${title}-btn-${button.key}-${i}`}
                          mr={1}
                        >
                          {button}
                        </Box>
                      ))}
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Box sx={{ mt: 3 }}>
              {query?.isError && (
                <EmptyPlaceholder
                  title="Ooops, ocorreu um problema"
                  subtitle="Tente novamente"
                />
              )}
              {!disabledFetching && isFetching ? (
                <LoadingScreen />
              ) : (
                <>{(query?.isSuccess || query === undefined) && children}</>
              )}
            </Box>
          </Container>
        </Box>
      </div>
    );
  },
);
