import React, { useContext } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Skeleton } from '@material-ui/core';
import { KanbanContext } from 'src/contexts/KanbanContext';

export default function MaintenanceFilter() {
  const { formats, isLoading, setFormats, data } = useContext(KanbanContext);

  const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newFormats: string[],
  ) => {
    setFormats(newFormats);
  };

  return (
    <ToggleButtonGroup
      size="medium"
      color="primary"
      value={formats}
      onChange={handleFormat}
    >
      {isLoading && <Skeleton variant="rectangular" width={400} height={50} />}
      {!isLoading &&
        data.map(({ id, value }) => (
          <ToggleButton color="standard" value={id} aria-label="bold">
            {value}
          </ToggleButton>
        ))}
    </ToggleButtonGroup>
  );
}
