/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { firebaseRealtimeEnum } from 'src/config';
import useRealtime from 'src/hooks/firebase/useRealtime';
import {
  Box,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useTouchwayApi } from 'src/services/touchway-api';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'src/config/query';
import PulsingBadge from 'src/components/PulsingBadge';
import { LocatorConverter } from './class/Locator';
import { ILocatorFirebaseRealtimeType } from './types/ILocatorFirebaseRealtime.type';

interface LocatorTelemetryDetailedProps {
  vhc_id: string;
}

export const LocatorTelemetryDetailed: React.FC<LocatorTelemetryDetailedProps> =
  ({ vhc_id }) => {
    const touchwayApi = useTouchwayApi();
    const [loadedLctId, setLoadedLctId] = useState<string | undefined>(
      undefined,
    );
    const [locatorRealtime, setLocatorRealtime] = useState<LocatorConverter>(
      new LocatorConverter(undefined),
    );

    const fbRaw = useRealtime<ILocatorFirebaseRealtimeType>({
      collection: firebaseRealtimeEnum.locators,
      key: loadedLctId,
      enabled: loadedLctId !== undefined,
      onValueChange: (value) => {
        setLocatorRealtime(new LocatorConverter(value));
      },
    });

    const vehicleQuery = useQuery(
      queryKeys.vehicleControllerList.get({
        vhc_id,
      }),
      () =>
        touchwayApi.api.vehicles.vehicleControllerList({
          vhc_id,
        }),
    );

    const queryRes = vehicleQuery.data;

    useEffect(() => {
      if (queryRes) {
        setLoadedLctId(queryRes.vehicles[0].lct_id);
      }
    }, [queryRes]);

    if (vehicleQuery.isLoading || !locatorRealtime) {
      return <Card>Carregando...</Card>;
    }

    if (!fbRaw) {
      return <Card>Não há dados para exibir</Card>;
    }

    console.log('fbRaw', fbRaw);

    return (
      <>
        {Object.entries(fbRaw).map(([key, value], index) => {
          const title = key;

          // Se o valor for um objeto, mapeie seus campos
          const fields =
            typeof value === 'object' && value !== null
              ? Object.entries(value).map(([label, _value]) => ({
                  label: formatLabel(label),
                  value: _value,
                }))
              : [{ label: key, value }];

          return (
            <TableContainer
              component={Paper}
              style={{ marginBottom: 16 }}
              key={index}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                flexDirection="row"
                style={{ padding: 16 }}
              >
                <Box display="flex" flexDirection="column">
                  <Typography variant="h6">{title}</Typography>
                </Box>
                <Box mr={0}>
                  <PulsingBadge badgeLabel="Atualizando em tempo real" />
                </Box>
              </Box>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Parâmetro</TableCell>
                    <TableCell>Valor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields.map((field, index2) => (
                    <TableRow key={`${field.label}-${index}-${index2}`}>
                      <TableCell component="th" scope="row">
                        {formatLabel(field.label)}
                      </TableCell>
                      <TableCell>
                        {field.value !== null && typeof field.value === 'object'
                          ? JSON.stringify(field.value)
                          : String(field.value)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
        })}
      </>
    );
  };

function formatLabel(label: string) {
  return label
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
