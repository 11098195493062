import React, { createContext, useEffect } from 'react';
import useLocalStorageReducer from 'src/hooks/useLocalStorageReducer';
import firebase from '../../lib/firebase';
import Reducer, { AuthAction } from './AuthReducer';
import { authInitialState } from './AuthInitializer';
import { IAuthContext, AuthTypesEnum, FirebaseAuth } from './AuthInterface';

export type AuthDispatchType = React.Dispatch<AuthAction>;

interface AuthProviderInterface {
  stateAuth: IAuthContext;
  dispatchAuth: AuthDispatchType;
  firebaseAuth: FirebaseAuth;
}

interface AuthProviderMockInterface {
  injectMock?: Partial<IAuthContext>;
}

export const AuthContext = createContext<AuthProviderInterface>({
  stateAuth: authInitialState,
  dispatchAuth: () => console.warn('AuthDispatch not ready'),
  firebaseAuth: undefined,
});

const AuthProvider: React.FC<AuthProviderMockInterface> = ({
  children,
  injectMock,
}) => {
  // const [stateAuth, dispatchAuth] = useReducer(Reducer, authInitialState);
  const [stateAuth, dispatchAuth] = useLocalStorageReducer(
    'authState',
    Reducer,
    authInitialState,
    60 * 60 * 12, // Stays on the storage for 23 hours
  );

  const firebaseAuth = firebase.auth();

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      // dispatchAuth({
      //   type: AuthTypesEnum.SET_FIREBASE_USER,
      //   firebaseUser: user,
      // });
      if (user === null) {
        dispatchAuth({
          type: AuthTypesEnum.LOGOUT_USER,
        });
      }
    });
  }, [dispatchAuth]);

  const globals = {
    stateAuth,
    dispatchAuth,
    firebaseAuth,
    ...injectMock,
  };

  return (
    <AuthContext.Provider value={globals}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
